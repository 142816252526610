import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { getFieldProperties } from '../../../../../../../shared/validators/has-error';
import { MatSelectChange } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';
import { Step } from '../../../../../../../shared/constants/form-option';

@Component({
  selector: 'app-step-social-media',
  templateUrl: './step-social-media.component.html',
  styleUrls: ['./step-social-media.component.scss']
})
export class StepSocialMediaComponent  implements OnInit, OnDestroy {
    @ViewChildren('customInput') inputs: QueryList<ElementRef>;

    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Output() submitForm = new EventEmitter<void>();
    @Input() stepper: MatStepper;
    protected readonly getFieldProperties = getFieldProperties;
    destroy$ = new Subject();

    selectOptions = [
        { label: 'Instagram', value: 'Instagram' },
        { label: 'Facebook', value: 'Facebook' },
        { label: 'X', value: 'X' },
        { label: 'TikTok', value: 'TikTok' },
        { label: 'YouTube', value: 'YouTube' },
        { label: 'LinkedIn', value: 'LinkedIn' },
        { label: 'Custom', value: 'custom' }
    ]

    get socialMediaGroup(): any {
        return this.formGroup.get('socialMediaGroup') as FormArray;
    }

    constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.addControls();
        if (this.socialMediaGroup?.controls?.length === 0) {
            this.addSocialMediaControls();
        }
        this.stepper.selectionChange.pipe(takeUntil(this.destroy$)).subscribe((selection) => {
            if (selection.selectedIndex === 0) {
                console.log(this.socialMediaGroup.controls, 'this.socialMediaGroup.controls')
                this.socialMediaGroup.controls.forEach((formGroup: FormGroup) => {
                    Object.keys(formGroup.controls).forEach((key: string) => {
                        formGroup.get(key)?.setValidators(Validators.required);
                        formGroup.get(key)?.updateValueAndValidity();
                        formGroup.get(key)?.markAsUntouched();
                        formGroup.get(key)?.markAsPristine();
                    });
                });
            }
        });
    }

    onSubmit() {
        this.submitForm.emit();
        this.stepper.next();
    }
    addControls(): void {
        if (this.formGroup && Object.keys(this.formGroup?.controls)?.length === 0) {
            this.formGroup.addControl('socialMediaGroup', this.fb.array([]));
        }
    }

    addSocialMediaControls() {
            const control = this.fb.group({
                socialMedia: [ '', Validators.required ],
                nickName: [ '', Validators.required ],
                custom: [ false,  Validators.required ]
            });
            this.socialMediaGroup.push(control);
    }

    removeSocial(index: number) {
        this.socialMediaGroup.removeAt(index);
    }

    clearInput(control: FormControl) {
        control.get('socialMedia')?.setValue('');
        control.get('custom')?.setValue(false);
    }

    onSelectSocialMedia(event: MatSelectChange, control: FormControl, index: number) {
        if (event.value === 'custom') {
            control.get('socialMedia')?.setValue('');
            control.get('custom')?.setValue(true);
            this.cd.detectChanges();
            const input = this.inputs.toArray().find(input => input.nativeElement.id === `custom-${index}`);
            input?.nativeElement.focus();
        }
    }

    skip() {
        while (this.socialMediaGroup.controls.length > 1) {
            this.removeSocial(this.socialMediaGroup.controls.length - 1);
        }
        this.socialMediaGroup.controls.forEach((formGroup: FormGroup) => {
            Object.keys(formGroup.controls).forEach((key: string) => {
                if (key === 'custom') {
                    formGroup.get(key)?.setValue(false);
                } else {
                    formGroup.get(key)?.setValue('');
                }
                formGroup.get(key)?.clearValidators();
                formGroup.get(key)?.updateValueAndValidity();
            });
        });

        this.onSubmit();
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
    }
}
