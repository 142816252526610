<ng-container *ngIf="proceed === 1">
    <mat-stepper class="medium-block" [disableRipple]="true" [ngClass]="'proceed'+proceed.toString()" [orientation]="orientation" [linear]="isLinear" #stepper>
        <mat-step *ngFor="let step of stepData[1]; let i = index"
                  [stepControl]="formGroups[i]"
                  [completed]="step.completed"
                  [aria-labelledby]="hasDisabledPrevForms(i) ? 'disabled_af' : ''"
                  [optional]="step.optional">
            <ng-template matStepLabel>{{ step.stepName | translate }}</ng-template>
            <app-multi-step-form
                [part]="1"
                [step]="step"
                [formGroup]="formGroups[i]"
                (formChanged)="onFormChanged(i)"
                (proceed)="onProceed($event)"
                [stepper]="stepper"
                [signUpRole]="signUpRole"
            ></app-multi-step-form>
        </mat-step>
    </mat-stepper>
</ng-container>

<ng-container *ngIf="proceed === 2">
    <mat-stepper class="medium-block" [attr.hide-header]="signUpRole === UserRoles.Organisation" [disableRipple]="true" [ngClass]="'proceed'+proceed.toString()" [orientation]="orientation" [linear]="isLinear" #stepper>
        <mat-step *ngFor="let step of stepData[2]; let i = index"
                  [stepControl]="formGroups[stepData[1].length + i]"
                  [completed]="step.completed"
                  [aria-labelledby]="hasDisabledPrevForms(i) ? 'disabled_af' : ''"
                  [optional]="step.optional">
            <ng-container *ngIf="signUpRole !== UserRoles.Organisation">
                <ng-template matStepLabel>{{ step.stepName | translate }}</ng-template>
            </ng-container>
            <app-multi-step-form
                [part]="2"
                [step]="step"
                [formGroup]="formGroups[stepData[1].length + i]"
                (formChanged)="onFormChanged(stepData[1].length + i)"
                [stepper]="stepper"
                [signUpRole]="signUpRole"
                (onFinish)="onFinish($event)"
                (proceed)="onProceed($event)"
            ></app-multi-step-form>
        </mat-step>
    </mat-stepper>
</ng-container>

<ng-container *ngIf="proceed === 3">
    <mat-stepper class="large-block" [disableRipple]="true" [ngClass]="'proceed'+proceed.toString()" [orientation]="orientation" [linear]="isLinear" #stepper (selectionChange)="selectionChange($event)">
        <mat-step *ngFor="let step of stepData[3]; let i = index"
                  [stepControl]="formGroups[stepData[1].length + stepData[2].length + i]"
                  [completed]="step.completed"
                  [aria-labelledby]="hasDisabledPrevForms(i) ? 'disabled_af' : ''"
                  [optional]="step.optional">
            <ng-template matStepLabel>{{ step.stepName | translate }}</ng-template>
            <app-multi-step-form
                [part]="3"
                [step]="step"
                [signUpRole]="signUpRole"
                [formGroup]="formGroups[stepData[1].length + stepData[2].length + i]"
                (formChanged)="onFormChanged(stepData[1].length + stepData[2].length + i)"
                [stepper]="stepper"
                (proceed)="onProceed($event)"
                (onFinish)="onFinish($event)"
            ></app-multi-step-form>
        </mat-step>
    </mat-stepper>
</ng-container>
