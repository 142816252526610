import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DialogService } from './dialog.service';

@Injectable({
    providedIn: 'root',
})
export class DialogResolver implements Resolve<any> {
    constructor(private dialogService: DialogService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        const component = route.data['component']
        const dialogData = route.data['dialogData']
        const dialogConfig = route.data['dialogConfig']

        return this.dialogService.openDialog(dialogData, component, dialogConfig)
            .toPromise()
            .then(result => {
                console.log('Dialog closed with result:', result);
                return result;
            })
            .catch(error => {
                console.error('Error while opening dialog:', error);
                return null;
            });
    }
}
