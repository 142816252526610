import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DashboardTableService } from '../../../../services/dashboard-table.service';
import { StudentFormComponent } from '../../../profile/student-form/student-form.component';
import { MatchNotification, Student, StudentLesson, StudentsResponse, Teacher } from '../../../../services/api/api.type';
import { ApiService } from '../../../../services/api/api.service';
import { UserService } from '../../../../services/user/user.service';
import { UserInfo, UserRoles } from '../../../../../core/models/user-info.interface';
import { StudentProfileInfoComponent } from '../student-profile-info/student-profile-info.component';
import {
    MatchingTimeSelectComponent,
    TimeSelect
} from '../../../match-teachers/matching-time-select/matching-time-select.component';
import { PlatformService } from '../../../../services/platform.service';
import { ComponentType } from '@angular/cdk/overlay';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-dashboard-student-table',
  templateUrl: './dashboard-student-table.component.html',
  styleUrls: ['./dashboard-student-table.component.scss']
})
export class DashboardStudentTableComponent implements OnInit, AfterViewInit, OnDestroy {
    displayedColumns: string[] = [ 'firstName', 'lastName', 'instrument', 'signUpDate', 'genres', 'approve', 'profile'];
    dataSource: MatTableDataSource<Student>;
    currentIndex: number;
    selectedStudentId: string;
    dialogRef: MatDialogRef<StudentFormComponent | StudentProfileInfoComponent | MatchingTimeSelectComponent>;
    currentPage = 0;
    pageSize = 10;
    sort: Sort;

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild('title') title: ElementRef;

    @Input() students: StudentsResponse;
    @Input() teacher?: Teacher;
    @Input() studentLessons: StudentLesson[];
    @Input() schoolId: string;
    @Output() onApprove:EventEmitter<{ studentId: string, validated: boolean }> = new EventEmitter<{ studentId: string, validated: boolean }>();
    @Output() onLessonTimeSelect:  EventEmitter<void> = new EventEmitter<void>();
    @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

    protected readonly UserRoles = UserRoles;
    destroy$ = new Subject();

    get user(): UserInfo {
        return this.userService.userInfo;
    }

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    get paginatedData() {
        const startIndex = this.currentPage * this.pageSize;
        const endIndex = startIndex + 10;
        return this.dataSource.data.slice(startIndex, endIndex);
    }

    get hasApprovePermission(): boolean {
        if (this.teacher?.teacher?.school) {
            return this.teacher.teacher.school.matchNotification === MatchNotification.TEACHER;
        }
        return false;
    }

    constructor(private dialog: MatDialog,
                private userService: UserService,
                private dashboardTableService: DashboardTableService,
                private platformService: PlatformService,
                private apiService: ApiService ) {
    }

    ngOnInit() {
        this.dataSource = new MatTableDataSource<Student>(this.students.list);
        /* this.dashboardTableService.needUpdateTableStudents.subscribe(() => {
            this.dataSource = new MatTableDataSource<TeacherTableElement>(this.students?.list);
            this.dataSource._updateChangeSubscription();
            this.dataSource.paginator = this.paginator;
        }); */
        this.dashboardTableService.needUpdateStudentTable.pipe(takeUntil(this.destroy$)).subscribe((update) => {
            this.getStudents(!!update);
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    studentLessonTimeAndDuration(studentId: string): { duration: string,startTime: string } | null {
        const lesson = this.studentLessons.find((lesson) => lesson.studentId === studentId);
        if (lesson) {
            return ({
                duration: lesson.duration,
                startTime: lesson.startTime,
            })
        }
        return null;
    }

    addOrEditStudent() {
            this.openDialog(StudentFormComponent, 'student-form');
    }

    openDialog(component: ComponentType<StudentFormComponent | StudentProfileInfoComponent | MatchingTimeSelectComponent>, panelClass: string, dialogData?: Student | TimeSelect, width = '864px', position = '32px'): void {
        document.body.classList.add('modal-open');
        let config: MatDialogConfig = {
            panelClass: panelClass,
            hasBackdrop: true,
            width,
            autoFocus: false,
            disableClose: true,
            position: { top: position },
            data: dialogData
        }
        if (position) {
            config.position = { top: position }
        }

        if (this.platformService.isMobile) {
            config = {
                panelClass: panelClass,
                hasBackdrop: true,
                width: '100vw',
                height: '95vh',
                maxWidth: '100vw',
                disableClose: true,
                position: {top: '48px'},
                data: dialogData
            }
        }
        const dialogRef = this.dialog.open(component, config);

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
               this.getStudents();
            }
            if (result && panelClass === 'time-select') {
                this.onLessonTimeSelect.emit();
            }
            console.log('Dialog closed result', result);
        });
        if (panelClass === 'student-profile-info') {
            this.dialogRef = dialogRef;
            const modalComponentInstance = this.dialogRef.componentInstance as StudentProfileInfoComponent;
            modalComponentInstance.studentUpdated.subscribe((id) => this.selectedStudentId = id);
        }
    }

    onPaginatorPageChange(event: PageEvent) {
        const titleElement = this.title.nativeElement;
        if (titleElement) {
            setTimeout(() => titleElement.focus());
        }
        this.currentIndex = event.pageIndex * event.pageSize;
    }

    getStudents(updateProfile = false) {
        this.apiService.getStudentList( '1', '1000').subscribe(
            (students: StudentsResponse) => {
                this.students.list.length = 0;
                students.list.forEach((item) => {
                    this.students?.list.push(item);
                })
                this.dataSource = new MatTableDataSource<Student>(this.students?.list);
                this.dataSource._updateChangeSubscription();
                this.dataSource.paginator = this.paginator;
                if (updateProfile && this.selectedStudentId) {
                    const selectedStudent = this.students.list.find((student: Student) => student.id === this.selectedStudentId);
                    if (selectedStudent) {
                        const modalComponentInstance = this.dialogRef.componentInstance as StudentProfileInfoComponent;
                        modalComponentInstance.setStudent(selectedStudent);
                    }
                }
            });
    }

    getSortedStudents(sortBy: string, direction: string) {
        this.apiService.getSortedStudents( '1', '1000', sortBy, direction.toUpperCase()).subscribe(
            (students: StudentsResponse) => {
                this.students.list.length = 0;
                students.list.forEach((item) => this.students?.list.push(item));
                this.dataSource = new MatTableDataSource<Student>(this.students?.list);
                this.dataSource._updateChangeSubscription();
                this.dataSource.paginator = this.paginator;
            });
    }

    handleApprove(studentId: string, validated: boolean) {
        validated = !validated;
        this.onApprove.emit({studentId, validated})
    }

    showProfile(student: Student) {
        this.openDialog(StudentProfileInfoComponent, 'student-profile-info', student, '640px');
    }

    approve(student: Student) {
        const teacherId = this.teacher?.teacher?.id || '';
        this.apiService.getMatching(teacherId, student.student.id).subscribe(
            (data) => {
                const modalDescription = {
                    title: 'We’ve found a new student for you!',
                    question: 'When do you have time for a trial lesson?'
                }
                this.openDialog(MatchingTimeSelectComponent, 'time-select', { slots: data.timeSlots, teacher: this.teacher, student, modalDescription, multiple: false, hasTeacher: true }, '864px', '');
            }
        )
    }

    disapprove(student: Student) {
        this.apiService.updateMatching({
            teacherId: this.teacher?.teacher.id || '',
            studentId: student.student.id,
            acceptedTeacher: false,
        }).pipe(switchMap(() => {
            return this.apiService.getStudentList( '1', '1000');
        })).subscribe((students: StudentsResponse) => {
            this.students.list.length = 0;
            students.list.forEach((item) => {
                this.students?.list.push(item);
            })
            this.dataSource = new MatTableDataSource<Student>(this.students?.list);
            this.dataSource._updateChangeSubscription();
            this.dataSource.paginator = this.paginator;
            console.log('Successfully declined.')
        });
    }

    closeDialog() {
        this.closeModal.emit();
    }

    onPageChange(event: PageEvent) {
        this.currentIndex = event.pageIndex * event.pageSize;
        this.currentPage = event.pageIndex;
    }

    sortData(sort: Sort) {
        this.sort = sort;
        if (!sort.active || sort.direction === '') {
            this.getStudents();
            return;
        }
        this.getSortedStudents(sort.active, sort.direction);
    }

}
