export interface UserInfo {
    userId: string;
    email: string;
    role: UserRoles;
    manageSchoolId?: string;
    iat: number;
    exp: number;
    teacherId?: string;
    studentId?: string;
}

export interface UserIntroInfo {
    userFirstName?: string;
    organisationAmount?: number;
    studentsAmount?: number;
    teachersAmount?: number;
    pendingMatches?: number,
    acceptedMatches?: number,
    pastMatches?: number
}

export interface UserLoginCredentials {
    email: string;
    password: string;
}

export interface UserLoginOutCredentials {
    token: string;
}

export enum UserRoles {
    Student = 'student',
    Teacher = 'teacher',
    Organisation = 'school_admin',
    Root = 'root',
}
