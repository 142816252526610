import { Injectable } from '@angular/core';
import { Student } from '../../music/services/api/api.type';
import {
    StudentProfileInfoComponent
} from '../../music/components/dashboard/student-dashboard/student-profile-info/student-profile-info.component';

@Injectable({
  providedIn: 'root'
})
export class StudentProfileService {
    private studentProfileComponent: StudentProfileInfoComponent;

    constructor() { }
    setStudentProfileComponent(component: StudentProfileInfoComponent) {
        this.studentProfileComponent = component;
    }

    updateStudent(student: Student) {
        if (this.studentProfileComponent) {
            this.studentProfileComponent.setStudent(student);
        }
    }
}
