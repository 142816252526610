<div class="form-content welcome-form">
    <form [formGroup]="formGroup" class="form-wrapper">
        <div class="title-wrapper">
            <div class="title-container">
                <div class="title-1">{{ 'welcome-popup.title1' | translate }}</div>
                <div class="title-2">{{ 'welcome-popup.title2' | translate }}</div>
            </div>
        </div>

        <mat-chip-listbox aria-label="Role selection" formControlName="selectedRole"
                          (change)="onRoleSelectionChange($event)">
            <mat-chip-option *ngFor="let chip of roles" [value]="chip.value">
                <label>{{ 'welcome-popup.role_translations.roles.' + chip.value | translate }}</label>
            </mat-chip-option>
        </mat-chip-listbox>
    </form>
    <div class="footer">
        <div class="btn-container custom-theme">
            <button mat-raised-button color="primary" type="submit" [disabled]="formGroup.invalid" (click)="onSubmit()">{{ 'welcome-popup.nextButton' | translate }}</button>
        </div>
    </div>
</div>
