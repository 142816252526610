import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatLessonStartTime'
})
export class FormatLessonStartTimePipe implements PipeTransform {
    transform(time: Date, hideSeconds = false): string {
        const startTime = new Date(time);

        const formattedDate = startTime.toLocaleDateString('en-US', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit'
        }).replace(/\//g, '');

        const formattedTime = startTime.toLocaleTimeString('en-US', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
            second: !hideSeconds ? '2-digit' : undefined
        });

        return `${formattedDate.slice(0, 2)}/${formattedDate.slice(2, 4)}/${formattedDate.slice(4, 6)} ${formattedTime}`;
    }
}
