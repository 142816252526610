import { Injectable } from '@angular/core';
import { OrganisationProfileInfoComponent } from '../../music/components/dashboard/organisation-dashboard/organisation-profile-info/organisation-profile-info.component';

@Injectable({
    providedIn: 'root'
})
export class OrganisationProfileService {
    private organisationProfileComponent: OrganisationProfileInfoComponent;

    setOrganisationProfileComponent(component: OrganisationProfileInfoComponent) {
        this.organisationProfileComponent = component;
    }

    updateSchool(organisation: any) {
        if (this.organisationProfileComponent) {
            this.organisationProfileComponent.setSchool(organisation);
            this.organisationProfileComponent.setImage();
        }
    }
}
