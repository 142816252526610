<div class="org-form-wrapper" appTabNavigation [containerSelector]="'mat-dialog-container'">
    <div class="header-wrapper-mobile">
<mat-toolbar class="toolbar-form">
    <span>{{ 'organisation-profile.toolbar_title' | translate }}</span>
    <span class="example-spacer"></span>
    <button tabindex="1" mat-icon-button class="example-icon" (click)="closeDialog()">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
</mat-toolbar>
<mat-divider></mat-divider>
    <div class="footer mobile">
        <div class="btn-container custom-theme">
            <div class="right-side">
                <button mat-stroked-button color="primary" (click)="discard()">{{ 'organisation-profile.buttons.discard' | translate }}</button>
                <button mat-raised-button color="primary" [disabled]="loading" type="submit" (click)="onSubmit()">{{ 'organisation-profile.buttons.save_changes' | translate }}</button>
            </div>
        </div>
        <mat-divider></mat-divider>
    </div>
    </div>
<div mat-dialog-content class="form-content">
    <form [formGroup]="organisationProfileForm">
        <!-- DESKTOP-->
        <div *ngIf="!isMobilePlatform">
            <div class="form-title personal-data">{{ 'organisation-profile.toolbar_sub_title' | translate }}</div>
            <div class="form-row">
                <div class="form-column">
                    <div class="form-field">
                        <p>{{ 'organisation-profile.sections.organisation.labels.name.label' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input tabindex="2"
                                   placeholder="{{ 'organisation-profile.sections.organisation.labels.name.placeholder' | translate }}"
                                   matInput maxlength="100" formControlName="name" required>
                        </mat-form-field>
                        <mat-error
                            *ngIf="getFieldProperties('name').isRequired">
                            {{ 'organisation-profile.sections.organisation.labels.name.required_error' | translate }}
                        </mat-error>
                    </div>
                    <div class="form-field">
                        <p>{{ 'organisation-profile.sections.organisation.labels.postalCode.label' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input appNumericInput matInput formControlName="postalCode" tabindex="4"
                                   placeholder="00000" maxlength="10">
                        </mat-form-field>
                        <mat-error
                            *ngIf="getFieldProperties('postalCode').isRequired">
                            {{ 'organisation-profile.sections.organisation.labels.postalCode.required_error' | translate }}
                        </mat-error>
                    </div>
                    <div class="form-field">
                        <p>{{ 'organisation-profile.sections.organisation.labels.phoneNumber.label' | translate }}</p>
                        <p class="small-label">{{ 'organisation-profile.sections.organisation.labels.phoneNumber_internal_use' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input
                                placeholder="{{ 'organisation-profile.sections.organisation.labels.phoneNumber.placeholder' | translate }}"
                                matInput tabindex="6" formControlName="phoneNumber" required maxlength="15"
                                minlength="5" appNumericInput>
                        </mat-form-field>
                        <mat-error *ngIf="getFieldProperties('phoneNumber').isRequired">
                            {{ 'organisation-profile.sections.organisation.labels.phoneNumber.required_error' | translate }}
                        </mat-error>
                    </div>
                </div>

                <div class="form-column">
                    <div class="form-field">
                        <p>{{ 'organisation-profile.sections.organisation.labels.sponsoringCompany.label' | translate }}</p>
                        <p class="small-label">{{ 'organisation-profile.sections.organisation.labels.sponsoringCompany.optional_text' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input
                                placeholder="{{ 'organisation-profile.sections.organisation.labels.sponsoringCompany.placeholder' | translate }}"
                                maxlength="100" matInput tabindex="3" formControlName="sponsoringCompany">
                        </mat-form-field>
                        <mat-error *ngIf="getFieldProperties('name').hasAllErrors"></mat-error>
                    </div>

                    <div class="form-field">
                        <p>{{ 'signup-form.school_admin.step-profile.websiteLabel' | translate }}</p>
                        <p class="small-label">{{ 'signup-form.school_admin.step-profile.optional' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input
                                placeholder="{{ 'signup-form.school_admin.step-profile.websitePlaceholder' | translate }}"
                                matInput tabindex="5" formControlName="website">
                        </mat-form-field>
                    </div>

                </div>
            </div>
            <div class="form-field">
                <p>{{ 'organisation-profile.sections.organisation.labels.description.label' | translate }}</p>
                <p class="small-label">{{ 'signup-form.school_admin.step-profile.optional' | translate }}</p>
                <mat-form-field>
                    <textarea class="text-area-mobile" tabindex="7"
                              placeholder="{{ 'organisation-profile.sections.organisation.labels.description.placeholder' | translate }}"
                              maxlength="300" matInput formControlName="description"></textarea>
                </mat-form-field>
            </div>

            <div class="form-row">
                <div class="form-column">
                    <div class="form-field mobile" *ngFor="let control of socialMediaGroup?.controls; let i = index">
                        <p *ngIf="i < 1">{{ 'organisation-profile.sections.social-media.labels.socialMedia.label' | translate }}</p>
                        <p *ngIf="i < 1"
                           class="small-label">{{ 'organisation-profile.sections.social-media.optional_text' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <div *ngIf="control.get('custom').value" class="custom-type-wrapper">
                                <input id="custom-{{i}}" #customInput
                                       placeholder="{{ 'organisation-profile.sections.social-media.labels.socialMedia.placeholder' | translate }}"
                                       matInput
                                       [formControl]="control.get('socialMedia')">
                                <mat-icon (click)="clearInput(control)">clear</mat-icon>
                            </div>
                            <mat-select tabindex="8" *ngIf="!control.get('custom').value"
                                        (selectionChange)="onSelectSocialMedia($event, control, i)"
                                        placeholder="{{ 'organisation-profile.sections.social-media.labels.socialMedia.placeholder' | translate }}"
                                        [ngClass]="{'default-select-icon-color': !((control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched))}"
                                        [formControl]="control.get('socialMedia')">
                                <mat-option *ngFor="let option of selectOptions"
                                            [value]="option.value">{{ option.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error
                            *ngIf="(control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched)">
                            {{ 'organisation-profile.sections.social-media.labels.socialMedia.required_error' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="!!((control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched)) &&
                !((control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched))">
                        </mat-error>
                        <!--                    <p *ngIf="i < 1" style="visibility: hidden">.</p>-->
                        <div class="todo-list mobile">
                            <mat-form-field subscriptSizing="dynamic">
                                <input placeholder="{{ 'organisation-profile.sections.social-media.labels.link.' +
                            (['TikTok', 'Instagram'].includes(control.get('socialMedia').value) ?
                            'selectedPlaceholder.' + control.get('socialMedia').value :
                            'selectedPlaceholder.custom') | translate }}" matInput [formControl]="control.get('link')">
                            </mat-form-field>
                            <button mat-icon-button class="example-icon"
                                    [disabled]="socialMediaGroup?.controls.length < 2" (click)="removeSocial(i)">
                                <mat-icon class="close-icon"
                                          [ngClass]="{'disabled': socialMediaGroup?.controls.length < 2}">close
                                </mat-icon>
                            </button>
                        </div>
                        <div class="error-wrapper mobile">
                            <mat-error
                                *ngIf="(control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched)">
                                {{ 'organisation-profile.sections.social-media.labels.link.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-column desktop">
                    <div *ngFor="let control of socialMediaGroup?.controls; let i = index" class="form-field">
                        <p *ngIf="i < 1" style="visibility: hidden">.</p>
                        <div class="todo-list">
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="9" placeholder="{{ 'organisation-profile.sections.social-media.labels.link.' +
                            (['TikTok', 'Instagram'].includes(control.get('socialMedia').value) ?
                            'selectedPlaceholder.' + control.get('socialMedia').value :
                            'selectedPlaceholder.custom') | translate }}" matInput
                                       [formControl]="control.get('link')">
                            </mat-form-field>
                            <button mat-icon-button class="example-icon"
                                    [disabled]="socialMediaGroup?.controls.length < 2"
                                    (click)="removeSocial(i)">
                                <mat-icon class="close-icon"
                                          [ngClass]="{'disabled': socialMediaGroup?.controls.length < 2}">close
                                </mat-icon>
                            </button>
                        </div>
                        <mat-error
                            *ngIf="(control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched)">
                            {{ 'organisation-profile.sections.social-media.labels.link.required_error' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="!!((control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched)) &&
                !((control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched))">
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="custom-theme add-more">
                <button tabindex="10" mat-stroked-button color="primary"
                        (click)="addSocialMediaControls()">{{ 'organisation-profile.buttons.add_another' | translate }}
                </button>
            </div>

            <div class="form-row">
                <div class="form-column">
                    <div class="form-field mobile" *ngFor="let control of associationsGroup?.controls; let i = index">
                        <p *ngIf="i < 1">{{ 'organisation-profile.sections.unions.labels.name.label' | translate }}</p>
                        <p *ngIf="i < 1"
                           class="small-label">{{ 'organisation-profile.sections.unions.optional_text' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input tabindex="11"
                                   placeholder="{{ 'organisation-profile.sections.unions.labels.name.placeholder' | translate }}"
                                   matInput [formControl]="control.get('name')">
                        </mat-form-field>
                        <mat-error
                            *ngIf="(control.get('name')?.hasError('requiredName') && control.get('name')?.dirty) || (control.get('name')?.hasError('requiredName') && control.get('name')?.touched)">
                            {{ 'organisation-profile.sections.unions.labels.name.required_error' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="!!((control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.dirty) || (control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.touched)) &&
                !((control.get('name')?.hasError('requiredName') && control.get('name')?.dirty) || (control.get('name')?.hasError('requiredName') && control.get('name')?.touched))">
                        </mat-error>
                        <div class="todo-list mobile">
                            <mat-form-field subscriptSizing="dynamic">
                                <input placeholder="{{ 'organisation-profile.sections.unions.labels.membershipNumber.placeholder' | translate }}"
                                       matInput [formControl]="control.get('membership')">
                            </mat-form-field>
                            <button mat-icon-button class="example-icon"
                                    [disabled]="associationsGroup?.controls.length < 2" (click)="removeAssociations(i)">
                                <mat-icon class="close-icon"
                                          [ngClass]="{'disabled': associationsGroup?.controls.length < 2}">close
                                </mat-icon>
                            </button>
                        </div>
                        <div class="error-wrapper mobile">
                            <mat-error
                                *ngIf="(control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.dirty) || (control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.touched)">
                                {{ 'organisation-profile.sections.unions.labels.membershipNumber.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-column desktop">
                    <div *ngFor="let control of associationsGroup?.controls; let i = index" class="form-field">
                        <p *ngIf="i < 1" style="visibility: hidden">.</p>
                        <div class="todo-list">
                            <mat-form-field subscriptSizing="dynamic">
                                <input tabindex="12"
                                       placeholder="{{ 'organisation-profile.sections.unions.labels.membershipNumber.placeholder' | translate }}"
                                       matInput [formControl]="control.get('membership')">
                            </mat-form-field>
                            <button mat-icon-button class="example-icon"
                                    [disabled]="associationsGroup?.controls.length < 2" (click)="removeAssociations(i)">
                                <mat-icon class="close-icon"
                                          [ngClass]="{'disabled': associationsGroup?.controls.length < 2}">close
                                </mat-icon>
                            </button>
                        </div>
                        <mat-error
                            *ngIf="(control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.dirty) || (control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.touched)">
                            {{ 'organisation-profile.sections.unions.labels.membershipNumber.required_error' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="!!((control.get('name')?.hasError('requiredName') && control.get('name')?.dirty) || (control.get('name')?.hasError('requiredName') && control.get('name')?.touched)) && !((control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.dirty) || (control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.touched))">
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="custom-theme add-more">
                <button tabindex="13" mat-stroked-button color="primary"
                        (click)="addAssociationsControls()">{{ 'organisation-profile.buttons.add_another' | translate }}
                </button>
            </div>

            <div
                class="form-title personal-data matches">{{ 'signup-form.school_admin.step-profile.sub_title' | translate }}
            </div>
            <div class="form-row">
                <div class="form-column">
                    <div class="form-field storybook-field mobile" *ngFor="let control of priceRangeGroup?.controls; let i = index" >
                        <p *ngIf="i < 1">{{ 'signup-form.school_admin.step-profile.priceLabel' | translate }}</p>
                        <mat-form-field  subscriptSizing="dynamic">
                            <input tabindex="14" currencyInput placeholder="{{ 'signup-form.school_admin.step-profile.pricePlaceholder' | translate }}" matInput [formControl]="control.get('price')" required>
                        </mat-form-field>
                        <mat-error
                            *ngIf="(control.get('price')?.hasError('required') && control.get('price')?.dirty) || (control.get('price')?.hasError('required') && control.get('price')?.touched)">
                            {{ 'signup-form.school_admin.step-profile.form.error.priceIsRequired' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="!!((control.get('length')?.hasError('required') && control.get('length')?.dirty) || (control.get('length')?.hasError('required') && control.get('length')?.touched)) &&
                        !((control.get('price')?.hasError('required') && control.get('price')?.dirty) || (control.get('price')?.hasError('required') && control.get('price')?.touched))">
                        </mat-error>
                        <div class="todo-list mobile">
                            <mat-form-field  subscriptSizing="dynamic">
                                <input placeholder="{{ 'signup-form.school_admin.step-profile.unitPlaceholder' | translate }}" matInput [formControl]="control.get('length')" required>
                            </mat-form-field>
                            <button mat-icon-button tabindex="15" class="example-icon" [disabled]="priceRangeGroup?.controls.length < 2" (click)="removePriceRange(i)">
                                <mat-icon class="close-icon" [ngClass]="{'disabled': priceRangeGroup?.controls.length < 2}">close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-column desktop">
                    <div  *ngFor="let control of priceRangeGroup?.controls; let i = index" class="form-field storybook-field">
                        <p *ngIf="i < 1" style="visibility: hidden">.</p>
                        <div class="todo-list">
                            <mat-form-field  subscriptSizing="dynamic">
                                <input tabindex="17" placeholder="{{ 'signup-form.school_admin.step-profile.unitPlaceholder' | translate }}" matInput [formControl]="control.get('length')" required>
                            </mat-form-field>
                            <button mat-icon-button class="example-icon" [disabled]="priceRangeGroup?.controls.length < 2" (click)="removePriceRange(i)">
                                <mat-icon class="close-icon" [ngClass]="{'disabled': priceRangeGroup?.controls.length < 2}">close</mat-icon>
                            </button>
                        </div>
                        <mat-error
                            *ngIf="(control.get('length')?.hasError('required') && control.get('length')?.dirty) || (control.get('length')?.hasError('required') && control.get('length')?.touched)">
                            {{ 'signup-form.school_admin.step-profile.form.error.unitIsRequired' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="!!((control.get('price')?.hasError('required') && control.get('price')?.dirty) || (control.get('price')?.hasError('required') && control.get('price')?.touched)) && !((control.get('length')?.hasError('required') && control.get('length')?.dirty) || (control.get('length')?.hasError('required') && control.get('length')?.touched))">
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="custom-theme add-more additional-spacer-bottom">
                <button tabindex="18" mat-stroked-button color="primary" (click)="addPriceRangeControls()">{{'signup-form.school_admin.step-profile.btnAddAnother' | translate }}</button>
            </div>
            <div class="form-row">
                <div class="form-column">
                    <div class="storybook-field form-field">
                        <p class="ellipsis">{{ 'signup-form.school_admin.step-profile.notifyLabel' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <mat-select tabindex="19"
                                        placeholder="{{ 'signup-form.school_admin.step-profile.notifyPlaceholder' | translate }}"
                                        [ngClass]="{'default-select-icon-color': !getFieldProperties('matchNotification', organisationProfileForm).hasAllErrors}"
                                        formControlName="matchNotification">
                                <mat-option *ngFor="let option of matchNotificationOptions"
                                            [value]="option.value">{{ 'match_notification_options.'+ option.value | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error
                            *ngIf="getFieldProperties('matchNotification').isRequired">{{ 'signup-form.school_admin.step-profile.form.error.matchNotificationsIsRequired' | translate }}
                        </mat-error>
                    </div>
                </div>

                <div class="form-column">
                    <div class="form-field">
                        <p [ngStyle]="{opacity: matchNotification?.value !== 'send_to_another_email' ? 0.4 : 1}">{{ 'organisation-profile.sections.admin.labels.email.label' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input #matchEmailInput tabindex="20" placeholder="{{ 'organisation-profile.sections.admin.labels.email.placeholder' | translate }}" matInput formControlName="matchEmail" required>
                        </mat-form-field>
                        <mat-error
                            *ngIf="getFieldProperties('matchEmail').isRequired">
                            {{ 'organisation-profile.sections.admin.labels.email.required_error' | translate }}
                        </mat-error>
                        <mat-error *ngIf="getFieldProperties('matchEmail').hasError">
                            {{ 'organisation-profile.sections.admin.labels.email.invalid_error' | translate }}
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
        <!-- MOBILE-->
        <div *ngIf="isMobilePlatform">
            <div class="form-title personal-data">{{ 'organisation-profile.toolbar_sub_title' | translate }}</div>
            <div class="form-row">
                <div class="form-column">
                    <div class="form-field">
                        <p>{{ 'organisation-profile.sections.organisation.labels.name.label' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input
                                   placeholder="{{ 'organisation-profile.sections.organisation.labels.name.placeholder' | translate }}"
                                   matInput maxlength="100" formControlName="name" required>
                        </mat-form-field>
                        <mat-error
                            *ngIf="getFieldProperties('name').isRequired">
                            {{ 'organisation-profile.sections.organisation.labels.name.required_error' | translate }}
                        </mat-error>
                    </div>

                    <div class="form-field">
                        <p>{{ 'organisation-profile.sections.organisation.labels.sponsoringCompany.label' | translate }}</p>
                        <p class="small-label">{{ 'organisation-profile.sections.organisation.labels.sponsoringCompany.optional_text' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input
                                placeholder="{{ 'organisation-profile.sections.organisation.labels.sponsoringCompany.placeholder' | translate }}"
                                maxlength="100" matInput tabindex="3" formControlName="sponsoringCompany">
                        </mat-form-field>
                        <mat-error *ngIf="getFieldProperties('name').hasAllErrors"></mat-error>
                    </div>

                    <div class="form-field">
                        <p>{{ 'organisation-profile.sections.organisation.labels.postalCode.label' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input appNumericInput matInput formControlName="postalCode"
                                   placeholder="00000" maxlength="10">
                        </mat-form-field>
                        <mat-error
                            *ngIf="getFieldProperties('postalCode').isRequired">
                            {{ 'organisation-profile.sections.organisation.labels.postalCode.required_error' | translate }}
                        </mat-error>
                    </div>

                    <div class="form-field">
                        <p>{{ 'organisation-profile.sections.organisation.labels.phoneNumber.label' | translate }}</p>
                        <p class="small-label">{{ 'organisation-profile.sections.organisation.labels.phoneNumber_internal_use' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input
                                placeholder="{{ 'organisation-profile.sections.organisation.labels.phoneNumber.placeholder' | translate }}"
                                matInput formControlName="phoneNumber" required maxlength="15"
                                minlength="5" appNumericInput>
                        </mat-form-field>
                        <mat-error *ngIf="getFieldProperties('phoneNumber').isRequired">
                            {{ 'organisation-profile.sections.organisation.labels.phoneNumber.required_error' | translate }}
                        </mat-error>
                    </div>

                    <div class="form-field">
                        <p>{{ 'signup-form.school_admin.step-profile.websiteLabel' | translate }}</p>
                        <p class="small-label">{{ 'signup-form.school_admin.step-profile.optional' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input
                                placeholder="{{ 'signup-form.school_admin.step-profile.websitePlaceholder' | translate }}"
                                matInput formControlName="website">
                        </mat-form-field>
                    </div>
                </div>

            </div>
            <div class="form-field">
                <p>{{ 'organisation-profile.sections.organisation.labels.description.label' | translate }}</p>
                <p class="small-label">{{ 'signup-form.school_admin.step-profile.optional' | translate }}</p>
                <mat-form-field>
                    <textarea class="text-area-mobile"
                              placeholder="{{ 'organisation-profile.sections.organisation.labels.description.placeholder' | translate }}"
                              maxlength="300" matInput formControlName="description"></textarea>
                </mat-form-field>
            </div>

            <div class="form-row">
                <div class="form-column">
                    <div class="form-field mobile" *ngFor="let control of socialMediaGroup?.controls; let i = index">
                        <p *ngIf="i < 1">{{ 'organisation-profile.sections.social-media.labels.socialMedia.label' | translate }}</p>
                        <p *ngIf="i < 1"
                           class="small-label">{{ 'organisation-profile.sections.social-media.optional_text' | translate }}</p>
                        <div class="fields-container-wrapper">
                            <div class="fields-container">
                                <mat-form-field subscriptSizing="dynamic">
                                    <div *ngIf="control.get('custom').value" class="custom-type-wrapper">
                                        <input id="custom-{{i}}" #customInput
                                               placeholder="{{ 'organisation-profile.sections.social-media.labels.socialMedia.placeholder' | translate }}"
                                               matInput
                                               [formControl]="control.get('socialMedia')">
                                        <mat-icon (click)="clearInput(control)">clear</mat-icon>
                                    </div>
                                    <mat-select *ngIf="!control.get('custom').value"
                                                (selectionChange)="onSelectSocialMedia($event, control, i)"
                                                placeholder="{{ 'organisation-profile.sections.social-media.labels.socialMedia.placeholder' | translate }}"
                                                [ngClass]="{'default-select-icon-color': !((control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched))}"
                                                [formControl]="control.get('socialMedia')">
                                        <mat-option *ngFor="let option of selectOptions"
                                                    [value]="option.value">{{ option.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error
                                    *ngIf="(control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched)">
                                    {{ 'organisation-profile.sections.social-media.labels.socialMedia.required_error' | translate }}
                                </mat-error>
                                <div class="todo-list mobile">
                                    <mat-form-field subscriptSizing="dynamic">
                                        <input placeholder="{{ 'organisation-profile.sections.social-media.labels.link.' +
                            (['TikTok', 'Instagram'].includes(control.get('socialMedia').value) ?
                            'selectedPlaceholder.' + control.get('socialMedia').value :
                            'selectedPlaceholder.custom') | translate }}" matInput [formControl]="control.get('link')">
                                    </mat-form-field>
                                </div>
                            </div>
                            <button mat-icon-button class="example-icon"
                                    [disabled]="socialMediaGroup?.controls.length < 2"
                                    (click)="removeSocial(i)">
                                <mat-icon class="close-icon"
                                          [ngClass]="{'disabled': socialMediaGroup?.controls.length < 2}">close
                                </mat-icon>
                            </button>
                        </div>
                        <div class="error-wrapper mobile">
                            <mat-error
                                *ngIf="(control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched)">
                                {{ 'organisation-profile.sections.social-media.labels.link.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                    <div class="custom-theme add-more">
                        <button mat-stroked-button color="primary"
                                (click)="addSocialMediaControls()">{{ 'organisation-profile.buttons.add_another' | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="form-column">
                    <div class="form-field mobile" *ngFor="let control of associationsGroup?.controls; let i = index">
                        <p *ngIf="i < 1">{{ 'organisation-profile.sections.unions.labels.name.label' | translate }}</p>
                        <p *ngIf="i < 1"
                           class="small-label">{{ 'organisation-profile.sections.unions.optional_text' | translate }}</p>
                        <div class="fields-container-wrapper">
                            <div class="fields-container">
                                <mat-form-field subscriptSizing="dynamic">
                                    <input
                                           placeholder="{{ 'organisation-profile.sections.unions.labels.name.placeholder' | translate }}"
                                           matInput [formControl]="control.get('name')">
                                </mat-form-field>
                                <mat-error
                                    *ngIf="(control.get('name')?.hasError('requiredName') && control.get('name')?.dirty) || (control.get('name')?.hasError('requiredName') && control.get('name')?.touched)">
                                    {{ 'organisation-profile.sections.unions.labels.name.required_error' | translate }}
                                </mat-error>
                                <mat-error
                                    *ngIf="!!((control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.dirty) || (control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.touched)) &&
                !((control.get('name')?.hasError('requiredName') && control.get('name')?.dirty) || (control.get('name')?.hasError('requiredName') && control.get('name')?.touched))">
                                </mat-error>
                                <div class="todo-list mobile">
                                    <mat-form-field subscriptSizing="dynamic">
                                        <input
                                               placeholder="{{ 'organisation-profile.sections.unions.labels.membershipNumber.placeholder' | translate }}"
                                               matInput [formControl]="control.get('membership')">
                                    </mat-form-field>
                                </div>
                            </div>
                            <button mat-icon-button class="example-icon"
                                    [disabled]="associationsGroup?.controls.length < 2" (click)="removeAssociations(i)">
                                <mat-icon class="close-icon"
                                          [ngClass]="{'disabled': associationsGroup?.controls.length < 2}">close
                                </mat-icon>
                            </button>
                        </div>
                        <div class="error-wrapper mobile">
                            <mat-error
                                *ngIf="(control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.dirty) || (control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.touched)">
                                {{ 'organisation-profile.sections.unions.labels.membershipNumber.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>

            <div class="custom-theme add-more">
                <button mat-stroked-button color="primary"
                        (click)="addAssociationsControls()">{{ 'organisation-profile.buttons.add_another' | translate }}
                </button>
            </div>

            <div
                class="form-title personal-data matches">{{ 'signup-form.school_admin.step-profile.sub_title' | translate }}
            </div>

            <div class="form-row">
                <div class="form-column">
                    <div class="form-field storybook-field mobile" *ngFor="let control of priceRangeGroup?.controls; let i = index" >
                        <p *ngIf="i < 1">{{ 'signup-form.school_admin.step-profile.priceLabel' | translate }}</p>
                        <mat-form-field  subscriptSizing="dynamic">
                            <input tabindex="12" currencyInput placeholder="{{ 'signup-form.school_admin.step-profile.pricePlaceholder' | translate }}" matInput [formControl]="control.get('price')" required>
                        </mat-form-field>
                        <mat-error
                            *ngIf="(control.get('price')?.hasError('required') && control.get('price')?.dirty) || (control.get('price')?.hasError('required') && control.get('price')?.touched)">
                            {{ 'signup-form.school_admin.step-profile.form.error.priceIsRequired' | translate }}
                        </mat-error>
                        <div class="todo-list mobile">
                            <mat-form-field  subscriptSizing="dynamic">
                                <input tabindex="13" placeholder="{{ 'signup-form.school_admin.step-profile.unitPlaceholder' | translate }}" matInput [formControl]="control.get('length')" required>
                            </mat-form-field>
                            <button mat-icon-button class="example-icon" [disabled]="priceRangeGroup?.controls.length < 2" (click)="removePriceRange(i)">
                                <mat-icon class="close-icon" [ngClass]="{'disabled': priceRangeGroup?.controls.length < 2}">close</mat-icon>
                            </button>
                        </div>
                        <mat-error class="error mobile"
                                   *ngIf="(control.get('length')?.hasError('required') && control.get('length')?.dirty) || (control.get('length')?.hasError('required') && control.get('length')?.touched)">
                            {{ 'signup-form.school_admin.step-profile.form.error.unitIsRequired' | translate }}
                        </mat-error>
                    </div>
                </div>
                <div class="form-column desktop">
                    <div  *ngFor="let control of priceRangeGroup?.controls; let i = index" class="form-field storybook-field">
                        <p *ngIf="i < 1" style="visibility: hidden">.</p>
                        <div class="todo-list">
                            <mat-form-field  subscriptSizing="dynamic">
                                <input tabindex="13" placeholder="{{ 'signup-form.school_admin.step-profile.unitPlaceholder' | translate }}" matInput [formControl]="control.get('length')" required>
                            </mat-form-field>
                            <button mat-icon-button class="example-icon" [disabled]="priceRangeGroup?.controls.length < 2" (click)="removePriceRange(i)">
                                <mat-icon class="close-icon" [ngClass]="{'disabled': priceRangeGroup?.controls.length < 2}">close</mat-icon>
                            </button>
                        </div>
                        <mat-error
                            *ngIf="(control.get('length')?.hasError('required') && control.get('length')?.dirty) || (control.get('length')?.hasError('required') && control.get('length')?.touched)">
                            {{ 'signup-form.school_admin.step-profile.form.error.unitIsRequired' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="!!((control.get('price')?.hasError('required') && control.get('price')?.dirty) || (control.get('price')?.hasError('required') && control.get('price')?.touched)) && !((control.get('length')?.hasError('required') && control.get('length')?.dirty) || (control.get('length')?.hasError('required') && control.get('length')?.touched))">
                        </mat-error>
                    </div>
                </div>
            </div>
            <div class="custom-theme add-more additional-spacer-bottom">
                <button tabindex="11" mat-stroked-button color="primary" (click)="addPriceRangeControls()">{{'signup-form.school_admin.step-profile.btnAddAnother' | translate }}</button>
            </div>

            <div class="form-row">
               <div class="form-column">
                <div class="storybook-field form-field">
                    <p class="ellipsis">{{ 'signup-form.school_admin.step-profile.notifyLabel' | translate }}</p>
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-select
                                    placeholder="{{ 'signup-form.school_admin.step-profile.notifyPlaceholder' | translate }}"
                                    [ngClass]="{'default-select-icon-color': !getFieldProperties('matchNotification', organisationProfileForm).hasAllErrors}"
                                    formControlName="matchNotification">
                            <mat-option *ngFor="let option of matchNotificationOptions"
                                        [value]="option.value">{{ 'match_notification_options.'+ option.value | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error
                        *ngIf="getFieldProperties('matchNotification').isRequired">{{ 'signup-form.school_admin.step-profile.form.error.matchNotificationsIsRequired' | translate }}
                    </mat-error>
                </div>
            </div>
                <div class="form-column">
                    <div class="form-field">
                        <p [ngStyle]="{opacity: matchNotification?.value !== 'send_to_another_email' ? 0.4 : 1}">{{ 'organisation-profile.sections.admin.labels.email.label' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input #matchEmailInput placeholder="{{ 'organisation-profile.sections.admin.labels.email.placeholder' | translate }}" matInput formControlName="matchEmail" required>
                        </mat-form-field>
                        <mat-error
                            *ngIf="getFieldProperties('matchEmail').isRequired">
                            {{ 'organisation-profile.sections.admin.labels.email.required_error' | translate }}
                        </mat-error>
                        <mat-error *ngIf="getFieldProperties('matchEmail').hasError">
                            {{ 'organisation-profile.sections.admin.labels.email.invalid_error' | translate }}
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!data || data?.hasEditMyProfile">
            <!-- DESKTOP-->
            <div *ngIf="!isMobilePlatform">
            <div class="form-title">{{ 'organisation-profile.sections.admin.title' | translate }}</div>
            <div class="form-row">
                <div class="form-column">
                    <div class="form-field">
                        <p>{{ 'organisation-profile.sections.admin.labels.email.label' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input tabindex="21" placeholder="{{ 'organisation-profile.sections.admin.labels.email.placeholder' | translate }}" matInput formControlName="email" required>
                        </mat-form-field>
                        <mat-error
                            *ngIf="getFieldProperties('email').isRequired">
                            {{ 'organisation-profile.sections.admin.labels.email.required_error' | translate }}
                        </mat-error>
                        <mat-error *ngIf="getFieldProperties('email').hasError && !email?.hasError('alreadyExist')">
                            {{ 'organisation-profile.sections.admin.labels.email.invalid_error' | translate }}
                        </mat-error>
                        <mat-error *ngIf="email?.hasError('alreadyExist')">
                            <!-- Replace key after json update   -->
                            {{  'signup-form.' + 'student' + '.step-contact.userExistError' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="getFieldProperties('gender').hasAllErrors && !getFieldProperties('email').hasAllErrors">
                        </mat-error>
                    </div>
                    <div class="form-field">
                        <p>{{ 'organisation-profile.sections.admin.labels.firstName.label' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input tabindex="23" placeholder="{{ 'organisation-profile.sections.admin.labels.firstName.placeholder' | translate }}" matInput formControlName="firstName" required>
                        </mat-form-field>
                        <mat-error *ngIf="getFieldProperties('firstName').isRequired">
                            {{ 'organisation-profile.sections.admin.labels.firstName.required_error' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="getFieldProperties('firstName').hasError">
                            {{ 'organisation-profile.sections.admin.labels.firstName.invalid_error' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="getFieldProperties('lastName').hasAllErrors && !getFieldProperties('firstName').hasAllErrors">
                        </mat-error>
                    </div>

                    <div class="form-field">
                        <p>{{ 'organisation-profile.sections.admin.labels.language.label' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <mat-select tabindex="25" placeholder="{{ 'organisation-profile.sections.admin.labels.language.placeholder' | translate }}" formControlName="language">
                                <mat-option *ngFor="let option of languageOptions"
                                            [value]="option.value">{{ option.label }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error
                            *ngIf="getFieldProperties('language').isRequired">
                            {{ 'organisation-profile.sections.admin.labels.language.required_error' | translate }}
                        </mat-error>
                    </div>
                </div>
                <div class="form-column">
                    <div class="form-field">
                        <p>{{ 'organisation-profile.sections.admin.labels.gender.label' | translate }}</p>
                        <p class="small-label">{{ 'signup-form.school_admin.step-profile.optional' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <mat-select tabindex="22" placeholder="{{ 'organisation-profile.sections.admin.labels.gender.placeholder' | translate }}"
                                        [ngClass]="{'default-select-icon-color': !getFieldProperties('gender').hasAllErrors}"
                                        formControlName="gender">
                                <mat-option *ngFor="let option of selectGender"
                                            [value]="option.value">{{ 'genders.' + option.value | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                       <!-- <mat-error
                            *ngIf="getFieldProperties('gender').isRequired">
                            {{ 'organisation-profile.sections.admin.labels.gender.required_error' | translate }}
                        </mat-error> -->
                        <mat-error
                            *ngIf="getFieldProperties('email').hasAllErrors && !getFieldProperties('gender').hasAllErrors">
                        </mat-error>
                    </div>

                    <div class="form-field">
                        <p>{{ 'organisation-profile.sections.admin.labels.lastName.label' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input tabindex="24" placeholder="{{ 'organisation-profile.sections.admin.labels.lastName.placeholder' | translate }}" matInput formControlName="lastName" required>
                        </mat-form-field>
                        <mat-error
                            *ngIf="getFieldProperties('lastName').isRequired">
                            {{ 'organisation-profile.sections.admin.labels.lastName.required_error' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="getFieldProperties('lastName').hasError">
                            {{ 'organisation-profile.sections.admin.labels.lastName.invalid_error' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="getFieldProperties('firstName').hasAllErrors && !getFieldProperties('lastName').hasAllErrors">
                        </mat-error>
                    </div>
                </div>
            </div>
            </div>
            <!-- MOBILE-->
            <div *ngIf="isMobilePlatform">
                <div class="form-title">{{ 'organisation-profile.sections.admin.title' | translate }}</div>
                <div class="form-row">
                    <div class="form-column">
                        <div class="form-field">
                            <p>{{ 'organisation-profile.sections.admin.labels.email.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input placeholder="{{ 'organisation-profile.sections.admin.labels.email.placeholder' | translate }}" matInput formControlName="email" required>
                            </mat-form-field>
                            <mat-error
                                *ngIf="getFieldProperties('email').isRequired">
                                {{ 'organisation-profile.sections.admin.labels.email.required_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="getFieldProperties('email').hasError && !email?.hasError('alreadyExist')">
                                {{ 'organisation-profile.sections.admin.labels.email.invalid_error' | translate }}
                            </mat-error>
                            <mat-error *ngIf="email?.hasError('alreadyExist')">
                                <!-- Replace key after json update   -->
                                {{  'signup-form.' + 'student' + '.step-contact.userExistError' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="getFieldProperties('gender').hasAllErrors && !getFieldProperties('email').hasAllErrors">
                            </mat-error>
                        </div>
                        <div class="form-field">
                            <p>{{ 'organisation-profile.sections.admin.labels.firstName.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input placeholder="{{ 'organisation-profile.sections.admin.labels.firstName.placeholder' | translate }}" matInput formControlName="firstName" required>
                            </mat-form-field>
                            <mat-error *ngIf="getFieldProperties('firstName').isRequired">
                                {{ 'organisation-profile.sections.admin.labels.firstName.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="getFieldProperties('firstName').hasError">
                                {{ 'organisation-profile.sections.admin.labels.firstName.invalid_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="getFieldProperties('lastName').hasAllErrors && !getFieldProperties('firstName').hasAllErrors">
                            </mat-error>
                        </div>
                        <div class="form-field">
                            <p>{{ 'organisation-profile.sections.admin.labels.lastName.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <input placeholder="{{ 'organisation-profile.sections.admin.labels.lastName.placeholder' | translate }}" matInput formControlName="lastName" required>
                            </mat-form-field>
                            <mat-error
                                *ngIf="getFieldProperties('lastName').isRequired">
                                {{ 'organisation-profile.sections.admin.labels.lastName.required_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="getFieldProperties('lastName').hasError">
                                {{ 'organisation-profile.sections.admin.labels.lastName.invalid_error' | translate }}
                            </mat-error>
                            <mat-error
                                *ngIf="getFieldProperties('firstName').hasAllErrors && !getFieldProperties('lastName').hasAllErrors">
                            </mat-error>
                        </div>
                        <div class="form-field">
                            <p>{{ 'organisation-profile.sections.admin.labels.gender.label' | translate }}</p>
                            <p class="small-label">{{ 'signup-form.school_admin.step-profile.optional' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select placeholder="{{ 'organisation-profile.sections.admin.labels.gender.placeholder' | translate }}"
                                            [ngClass]="{'default-select-icon-color': !getFieldProperties('gender').hasAllErrors}"
                                            formControlName="gender">
                                    <mat-option *ngFor="let option of selectGender"
                                                [value]="option.value">{{ 'genders.' + option.value | translate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- <mat-error
                                 *ngIf="getFieldProperties('gender').isRequired">
                                 {{ 'organisation-profile.sections.admin.labels.gender.required_error' | translate }}
                             </mat-error> -->
                            <mat-error
                                *ngIf="getFieldProperties('email').hasAllErrors && !getFieldProperties('gender').hasAllErrors">
                            </mat-error>
                        </div>
                        <div class="form-field">
                            <p>{{ 'organisation-profile.sections.admin.labels.language.label' | translate }}</p>
                            <mat-form-field subscriptSizing="dynamic">
                                <mat-select placeholder="{{ 'organisation-profile.sections.admin.labels.language.placeholder' | translate }}" formControlName="language">
                                    <mat-option *ngFor="let option of languageOptions"
                                                [value]="option.value">{{ option.label }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error
                                *ngIf="getFieldProperties('language').isRequired">
                                {{ 'organisation-profile.sections.admin.labels.language.required_error' | translate }}
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>

</div>

<div class="footer desktop">
    <mat-divider></mat-divider>
    <div class="btn-container custom-theme">
        <div>
            <button tabindex="26" *ngIf="data && !data?.hasEditMyProfile" mat-stroked-button color="primary" (click)="deleteOrganisation('organisation-profile.buttons.delete_organisation')">{{ 'organisation-profile.buttons.delete_organisation' | translate }}</button>
        </div>
        <div class="right-side">
            <button tabindex="27" mat-stroked-button color="primary" (click)="discard()">{{ 'organisation-profile.buttons.discard' | translate }}</button>
            <button tabindex="28" mat-raised-button color="primary" [disabled]="loading" type="submit" (click)="onSubmit()">{{ 'organisation-profile.buttons.save_changes' | translate }}</button>
        </div>
    </div>
</div>
</div>
