<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{step.title1 | translate}} {{step.title2 | translate}}</div>
    </div>
    <div class="storybook-field">
        <div class="form-field checkbox" *ngFor="let education of educationGroup?.controls; let i = index">
            <div class="fields-container">
                    <mat-form-field subscriptSizing="dynamic">
                        <input placeholder="{{ 'signup-form.teacher.step-musicalEducation.institutionPlaceholder' | translate }}" matInput [formControl]="education.get('institution')" maxlength="50" required>
                    </mat-form-field>
                <mat-error
                    *ngIf="(education.get('institution')?.hasError('required') && education.get('institution')?.dirty) || (education.get('institution')?.hasError('required') && education.get('institution')?.touched)">
                    {{ 'signup-form.teacher.step-musicalEducation.institutionError' | translate }}
                </mat-error>
                <div class="todo-list">
                    <mat-form-field  subscriptSizing="dynamic">
                        <input placeholder="{{ 'signup-form.teacher.step-musicalEducation.degreePlaceholder' | translate }}" maxlength="50" matInput  [formControl]="education.get('degree')">
                    </mat-form-field>
                </div>
                <mat-error
                    *ngIf="(education.get('degree')?.hasError('required') && education.get('degree')?.dirty) || (education.get('degree')?.hasError('required') && education.get('degree')?.touched)">
                    {{ 'signup-form.teacher.step-musicalEducation.degreeError' | translate }}
                </mat-error>
            </div>
            <button mat-icon-button class="example-icon" [disabled]="educationGroup?.controls.length < 2"  (click)="removeEducation(i)">
                <mat-icon class="close-icon" [ngClass]="{'disabled': educationGroup?.controls.length < 2}">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="custom-theme add-more">
        <button mat-stroked-button color="primary" (click)="addEducationControls()">{{ 'signup-form.teacher.step-musicalEducation.btnAddAnother' | translate }}</button>
    </div>

    <div align="end" class="custom-theme btn-container storybook-field">
        <button mat-stroked-button [color]="step.btn2Color" (click)="skip()">{{ 'signup-form.teacher.step-musicalEducation.btnSkip' | translate }}</button>
        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">{{step.btnText2 | translate}}</button>
    </div>
</form>

