import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, tap, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UserLoginCredentials, UserLoginOutCredentials } from '../../../core/models/user-info.interface';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private apiUrl = environment.apiUrl;
    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    private _accessToken: string;
    private _refreshToken: string;

    private logoutSubject = new Subject<void>();
    _successfullyLoggedOut$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get logoutSignal() {
        return this.logoutSubject.asObservable();
    }

    constructor(private http: HttpClient, private router: Router, private httpClient: HttpClient) {
        this.accessToken = localStorage.getItem('accessToken') || '';
        this.refreshToken = localStorage.getItem('refreshToken') || '';
    }

    public get refreshToken(): string {
        return this._refreshToken;
    }
    public set refreshToken(value: string) {
        this._refreshToken = value;
    }

    public get accessToken(): string {
        return this._accessToken;
    }
    public set accessToken(value: string) {
        this._accessToken = value;
    }

    logIn(data: UserLoginCredentials): Observable<{accessToken: string, refreshToken: string}> {
        return this.http.post<{accessToken: string, refreshToken: string}>(`${this.apiUrl}/auth/login`, data, this.httpOptions).pipe(tap(() => {
            this._successfullyLoggedOut$.next(false);
        }));
    }

    logOut(data: UserLoginOutCredentials): Observable<{token: string}> {
        return this.http.post<{token: string}>(`${this.apiUrl}/auth/logout`, data, this.httpOptions).pipe(tap(() => {
            this.clearTokens();
            this._successfullyLoggedOut$.next(true);
        }));
    }

    refreshAccessToken(): Observable<{accessToken: string, refreshToken: string}> {
        return this.http.post<{accessToken: string, refreshToken: string}>(`${this.apiUrl}/auth/token`, { token: this.refreshToken }, this.httpOptions).pipe(
            tap((tokens) => {
                this.saveTokens(tokens.accessToken, tokens.refreshToken);
            }),
            catchError((error) => {
                this.clearTokens();
                return throwError(error);
            })
        );
    }

   clearTokens() {
       this.refreshToken = '';
       this.accessToken = '';
       localStorage.removeItem('accessToken');
       localStorage.removeItem('refreshToken');
       this.logoutSubject.next();
   };

   saveTokens(accessToken: string, refreshToken: string) {
       this.accessToken = accessToken;
       this.refreshToken = refreshToken;
       localStorage.setItem('accessToken', this.accessToken);
       localStorage.setItem('refreshToken', this.refreshToken);
   }

    isAuthorized(): boolean {
        const accessToken = this.accessToken;
        if (!accessToken) {
            return false;
        }

        const expiryDate = this.getAccessTokenExpiryDate();
        return !!expiryDate && expiryDate > new Date();
    }

    getAccessTokenExpiryDate(): Date | null {
        if (!this.accessToken) {
            return null;
        }

        const tokenParts = this.accessToken.split('.');
        if (tokenParts.length !== 3) {
            console.error('Invalid JWT token format');
            return null;
        }

        const payload = JSON.parse(atob(tokenParts[1]));
        if (payload.exp) {
            return new Date(payload.exp * 1000);
        } else {
            return null;
        }
    }

}
