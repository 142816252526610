<div class="details-container">
    <div class="title">
        {{'schedule.teacher-details-title' | translate}}
    </div>
    <div class="items-container">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.name' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>{{userTeacher.firstName}} {{userTeacher.lastName}}</p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.instruments.label' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>
            <span *ngFor="let instrument of userTeacher.teacher?.instruments; let last = last">
                {{ 'user.instruments.' + instrument | translate }}{{ !last ? ', ' : '' }}
            </span>
                </p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.genres.label' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>
            <span *ngFor="let genre of userTeacher.teacher?.genres; let last = last">
                {{ 'user.genres.' + genre | translate }}{{ !last ? ', ' : '' }}
            </span>
                </p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                       {{'user.zip-code' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>{{userTeacher.postalCode}}</p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-divider></mat-divider>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.language.label' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>{{'user.language.' + userTeacher.language | translate}}</p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-divider></mat-divider>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.gender.label' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>{{'user.gender.' + userTeacher.gender | translate}}</p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-divider></mat-divider>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.age' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>{{userTeacher.age}}</p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-divider></mat-divider>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.years-experience' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>{{userTeacher.teacher?.yearsExperience}}</p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-divider></mat-divider>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.teachingLocation.label' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>
                <span *ngFor="let location of userTeacher.teacher?.teachingLocation; let last = last">
                    {{ 'user.teachingLocation.' + location | translate }}{{ !last ? ', ' : '' }}
                </span>
                </p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-divider></mat-divider>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.education' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>
                <span *ngFor="let edu of userTeacher.teacher?.education; let last = last">
                    {{ edu }}{{ !last ? ', ' : '' }}
                </span>
                </p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.socials' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>
                <span *ngFor="let social of userTeacher.teacher?.socials; let last = last">
                    <a [href]="social" target="_blank">{{ social }}</a>{{ !last ? ', ' : '' }}
                </span>
                </p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.description' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>{{userTeacher.teacher?.description}}</p>
            </mat-expansion-panel>

        </mat-accordion>
    </div>
</div>
