import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { UserRoles } from '../../../../../../../../core/models/user-info.interface';
import { Step } from '../../../../../../../../shared/constants/form-option';

@Component({
  selector: 'app-step-area',
  templateUrl: './step-area.component.html',
  styleUrls: ['./step-area.component.scss']
})
export class StepAreaComponent {
    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Output() submitForm = new EventEmitter<void>();
    @Input() stepper: MatStepper;
    @Input() signUpRole: UserRoles;


    get zipCode() {
        return this.formGroup.get('zipCode');
    }

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.addControls()
    }

    onSubmit() {
        this.submitForm.emit();
        this.stepper.next();
    }
    addControls(): void {
        if (this.formGroup && Object.keys(this.formGroup?.controls)?.length === 0) {
            this.formGroup.addControl('zipCode', this.fb.control('', [Validators.required]));
        }
    }
}
