<div class="session-item-container">
      <div class="item-toolbar">
          <div class="title">{{'integration.realtime_session' | translate}}</div>
          <svg (click)="openSettings()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="icon">
                  <path id="settings" d="M9.2502 22L8.8502 18.8C8.63353 18.7167 8.42936 18.6167 8.2377 18.5C8.04603 18.3833 7.85853 18.2583 7.6752 18.125L4.7002 19.375L1.9502 14.625L4.5252 12.675C4.50853 12.5583 4.5002 12.4458 4.5002 12.3375V11.6625C4.5002 11.5542 4.50853 11.4417 4.5252 11.325L1.9502 9.375L4.7002 4.625L7.6752 5.875C7.85853 5.74167 8.0502 5.61667 8.2502 5.5C8.4502 5.38333 8.6502 5.28333 8.8502 5.2L9.2502 2H14.7502L15.1502 5.2C15.3669 5.28333 15.571 5.38333 15.7627 5.5C15.9544 5.61667 16.1419 5.74167 16.3252 5.875L19.3002 4.625L22.0502 9.375L19.4752 11.325C19.4919 11.4417 19.5002 11.5542 19.5002 11.6625V12.3375C19.5002 12.4458 19.4835 12.5583 19.4502 12.675L22.0252 14.625L19.2752 19.375L16.3252 18.125C16.1419 18.2583 15.9502 18.3833 15.7502 18.5C15.5502 18.6167 15.3502 18.7167 15.1502 18.8L14.7502 22H9.2502ZM12.0502 15.5C13.0169 15.5 13.8419 15.1583 14.5252 14.475C15.2085 13.7917 15.5502 12.9667 15.5502 12C15.5502 11.0333 15.2085 10.2083 14.5252 9.525C13.8419 8.84167 13.0169 8.5 12.0502 8.5C11.0669 8.5 10.2377 8.84167 9.5627 9.525C8.8877 10.2083 8.5502 11.0333 8.5502 12C8.5502 12.9667 8.8877 13.7917 9.5627 14.475C10.2377 15.1583 11.0669 15.5 12.0502 15.5Z" fill="#C97D70"/>
              </g>
          </svg>
      </div>
    <mat-divider class="separator"></mat-divider>

    <div class="link-container">
        <div class="wrapper">
            <div class="title">{{'integration.your_session_link' | translate}}</div>
            <div class="link">{{integration.personalLink}}</div>
        </div>
        <svg *ngIf="!copied" (click)="copyInviteUrl()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon">
                <path id="Vector" d="M9 18C8.45 18 7.97917 17.8042 7.5875 17.4125C7.19583 17.0208 7 16.55 7 16V4C7 3.45 7.19583 2.97917 7.5875 2.5875C7.97917 2.19583 8.45 2 9 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18H9ZM9 16H18V4H9V16ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6H5V20H16V22H5Z" fill="#C97D70"/>
            </g>
        </svg>
        <div *ngIf="copied">{{'teacher-dashboard-table.button.copied' | translate }}</div>
    </div>
<div class="btn-container custom-theme">
    <button mat-raised-button color="primary" (click)="join()">{{'integration.join_session' | translate}}</button>
</div>

    <div class="footer">
        <div class="name">{{'integration.powered_by' | translate}}</div>
        <svg *ngIf="integration.integration.website.includes('digital')" xmlns="http://www.w3.org/2000/svg" width="83" height="32" viewBox="0 0 83 32" fill="none">
            <mask id="mask0_3752_2108985" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="14" width="24" height="17">
                <path d="M1.18859 15.2502C0.879268 16.4102 0.724609 17.5701 0.724609 18.8074C0.724609 19.194 0.72461 19.5807 0.801939 19.9673C8.92151 18.1888 17.1957 22.7512 19.9796 30.5614C21.3715 29.8655 22.6861 28.8602 23.7687 27.7003C20.4436 19.9673 12.8653 15.0183 4.43641 15.0183C3.27647 14.9409 2.19387 15.0183 1.18859 15.2502Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_3752_2108985)">
                <path d="M-4.5332 28.54L3.58637 7.27441L28.873 16.9406L20.7535 38.2061L-4.5332 28.54Z" fill="#151515"/>
            </g>
            <mask id="mask1_3752_2108985" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="3" y="0" width="25" height="24">
                <path d="M13.8586 0.135742V5.47146C9.76012 5.47146 5.81633 7.40469 3.3418 10.6525C12.776 10.2659 21.5915 15.1376 26.2313 23.3345C26.7726 21.8653 27.0819 20.2413 27.0819 18.6948V0.135742H13.8586Z" fill="white"/>
            </mask>
            <g mask="url(#mask1_3752_2108985)">
                <path d="M-4.3877 20.4017L6.4384 -7.74609L34.8956 3.15733L24.0695 31.3052L-4.3877 20.4017Z" fill="#151515"/>
            </g>
            <mask id="mask2_3752_2108985" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="1" y="23" width="15" height="9">
                <path d="M1.79395 24.0386C2.64457 26.0491 4.03649 27.8277 5.73774 29.1423C6.74302 28.9103 7.7483 29.1423 8.52159 29.7609C9.06289 30.1476 9.44954 30.6889 9.68153 31.3075C11.6921 31.9262 13.78 32.1581 15.7905 31.8488C13.7026 26.1265 7.7483 22.8013 1.79395 24.0386Z" fill="white"/>
            </mask>
            <g mask="url(#mask2_3752_2108985)">
                <path d="M-0.987305 30.9241L3.57512 19.0928L18.577 24.8151L14.0146 36.6465L-0.987305 30.9241Z" fill="#151515"/>
            </g>
            <path d="M37.066 3.72135C38.1487 3.72135 39.2313 4.18533 39.9272 5.11328V0.9375H42.1698V12.6915H39.9272V11.3769C39.3086 12.3049 38.226 12.8462 37.066 12.8462C34.8235 12.8462 33.0449 10.9903 33.0449 8.28378C33.0449 5.57725 34.8235 3.72135 37.066 3.72135ZM37.6073 5.65458C36.2927 5.65458 35.2875 6.73719 35.2875 8.05179C35.2875 8.12912 35.2875 8.20645 35.2875 8.28378C35.2875 9.98502 36.4474 10.913 37.6073 10.913C38.9219 10.913 39.9272 9.83036 39.9272 8.51576C39.9272 8.43843 39.9272 8.36111 39.9272 8.36111C40.0046 7.04651 39.0766 5.88657 37.762 5.80924C37.6847 5.73191 37.6847 5.73191 37.6073 5.65458Z" fill="#151515"/>
            <path d="M44.1042 1.4758C44.0269 0.702503 44.6455 0.0838693 45.4188 0.00654006C46.1921 -0.0707892 46.8107 0.547845 46.888 1.32114C46.9654 2.09443 46.3467 2.71306 45.5735 2.79039H45.4961C44.8002 2.86772 44.1815 2.32642 44.1042 1.55312C44.1042 1.55312 44.1042 1.55312 44.1042 1.4758ZM44.3362 3.873H46.5787V12.6112H44.3362V3.873Z" fill="#151515"/>
            <path d="M52.1451 3.71629C53.3051 3.63896 54.3877 4.18027 55.0837 5.10822V3.87095H57.3262V12.6865C57.3262 15.0837 55.8569 16.9396 52.9958 16.9396C50.5212 16.9396 48.82 15.7023 48.588 13.6918H50.7532C50.9852 14.4651 51.8358 15.0064 52.9184 15.0064C54.1557 15.0064 55.0837 14.3104 55.0837 12.6865V11.2946C54.3877 12.2225 53.3051 12.7638 52.1451 12.7638C49.9026 12.7638 48.124 10.9079 48.124 8.20139C48.124 5.49486 49.9799 3.71629 52.1451 3.71629ZM52.7638 5.64952C51.4492 5.64952 50.4439 6.73213 50.4439 8.04673C50.4439 8.12406 50.4439 8.20139 50.4439 8.27872C50.4439 9.97996 51.6038 10.9079 52.7638 10.9079C54.0784 10.9079 55.0837 9.8253 55.0837 8.5107C55.0837 8.43337 55.0837 8.35605 55.0837 8.35605C55.161 7.04145 54.233 5.88151 52.9184 5.80418C52.8411 5.72685 52.8411 5.64952 52.7638 5.64952Z" fill="#151515"/>
            <path d="M59.2565 1.4758C59.1792 0.702503 59.7978 0.0838693 60.5711 0.00654006C61.3444 -0.0707892 61.9631 0.547845 62.0404 1.32114C62.1177 2.09443 61.4991 2.71306 60.7258 2.79039H60.6485C59.9525 2.86772 59.3339 2.32642 59.2565 1.63045C59.2565 1.55312 59.2565 1.55312 59.2565 1.4758ZM59.4885 3.873H61.7311V12.6112H59.4885V3.873Z" fill="#151515"/>
            <path d="M64.2086 5.65686H63.126V3.80096H64.2086V1.63574H66.4511V3.80096H68.3844V5.65686H66.4511V9.90997C66.4511 10.5286 66.6831 10.7606 67.3791 10.7606H68.3844V12.6165H66.9924C65.2912 12.6165 64.2086 11.9205 64.2086 9.90997V5.65686Z" fill="#151515"/>
            <path d="M73.4908 3.71629C74.6508 3.63896 75.7334 4.18027 76.4294 5.10822V3.87095H78.6719V12.6092H76.4294V11.2946C75.7334 12.2225 74.6508 12.7638 73.4908 12.6865C71.2483 12.6865 69.4697 10.8306 69.4697 8.12406C69.4697 5.41753 71.2483 3.71629 73.4908 3.71629ZM74.0321 5.64952C72.7175 5.64952 71.7123 6.73213 71.7123 8.04673C71.7123 8.12406 71.7123 8.20139 71.7123 8.27872C71.7123 9.97996 72.8722 10.9079 74.0321 10.9079C75.3467 10.9079 76.352 9.8253 76.352 8.5107C76.352 8.43337 76.352 8.35605 76.352 8.35605C76.4294 7.04145 75.5014 5.88151 74.1868 5.80418C74.1868 5.72685 74.1095 5.64952 74.0321 5.64952Z" fill="#151515"/>
            <path d="M82.9994 0.9375H80.7568V12.6915H82.9994V0.9375Z" fill="#151515"/>
            <path d="M36.9895 27.7723C34.8243 27.7723 33.2777 26.4577 33.123 24.8338H35.3656C35.5203 25.6071 36.2162 26.0711 36.9895 25.9937C37.8401 25.9937 38.3041 25.6071 38.3041 25.0658C38.3041 23.5192 33.355 24.5245 33.355 21.2767C33.355 19.8074 34.6696 18.6475 36.8348 18.6475C39.0001 18.6475 40.2373 19.8074 40.392 21.5086H38.3041C38.2268 20.8127 37.6081 20.2714 36.9122 20.3487H36.8348C35.9842 20.3487 35.5976 20.658 35.5976 21.1993C35.5976 22.8232 40.4693 21.818 40.5467 25.0658C40.392 26.6897 39.0774 27.8496 36.9895 27.7723Z" fill="#151515"/>
            <path d="M42.5553 20.6538H41.4727V18.8752H42.5553V16.71H44.7978V18.8752H46.8084V20.6538H44.8751V24.9069C44.8751 25.5255 45.1071 25.7575 45.8031 25.7575H46.8084V27.6134H45.4164C43.7152 27.6134 42.6326 26.9174 42.6326 24.9069V20.6538H42.5553Z" fill="#151515"/>
            <path d="M51.8377 18.7173C52.9976 18.6399 54.0803 19.1812 54.6989 20.1092V18.7946H56.9414V27.5328H54.6989V26.3729C54.0029 27.3008 52.9203 27.8421 51.7604 27.7648C49.5178 27.7648 47.7393 25.9089 47.7393 23.2024C47.7393 20.4958 49.5952 18.7173 51.8377 18.7173ZM52.379 20.6505C51.0644 20.6505 50.0591 21.7331 50.0591 22.9704C50.0591 23.0477 50.0591 23.125 50.0591 23.2024C50.0591 24.9036 51.2191 25.8316 52.379 25.8316C53.5389 25.8316 54.6989 24.9036 54.6989 23.2797C54.7762 21.9651 53.8483 20.8052 52.5337 20.7278C52.5337 20.7278 52.4563 20.7278 52.379 20.6505Z" fill="#151515"/>
            <path d="M62.5836 18.7173C63.7436 18.6399 64.8262 19.1812 65.5221 20.1092V18.7946H67.7647V27.6875C67.7647 30.0847 66.2954 31.9406 63.4342 31.9406C60.9597 31.9406 59.2585 30.7033 59.0265 28.6927H61.1917C61.4237 29.466 62.2743 30.0073 63.3569 30.0073C64.5942 30.0073 65.5221 29.3114 65.5221 27.6875V26.3729C64.8262 27.3008 63.7436 27.8421 62.5836 27.8421C60.3411 27.8421 58.5625 25.9862 58.5625 23.2797C58.5625 20.5732 60.3411 18.7173 62.5836 18.7173ZM63.1249 20.6505C61.8103 20.6505 60.805 21.7331 60.805 22.9704C60.805 23.0477 60.805 23.125 60.805 23.2024C60.805 24.9036 61.965 25.8316 63.1249 25.8316C64.5169 25.8316 65.6768 24.6716 65.6768 23.2797C65.6768 21.8878 64.5169 20.6505 63.1249 20.6505Z" fill="#151515"/>
            <path d="M73.7212 27.7669C71.1694 27.7669 69.3135 25.9883 69.3135 23.2044C69.3135 20.4206 71.0921 18.7193 73.7212 18.7193C75.9638 18.5647 77.897 20.3433 78.0517 22.5858C78.0517 22.7405 78.0517 22.8951 78.0517 23.0498C78.0517 23.3591 78.0517 23.5911 77.9744 23.9004H71.556C71.556 25.0603 72.484 25.911 73.6439 25.911C74.4172 25.9883 75.1132 25.5243 75.4225 24.8283H77.8197C77.2784 26.6842 75.5771 27.9215 73.7212 27.7669ZM71.556 22.4311H75.7318C75.7318 21.3485 74.8039 20.4979 73.7986 20.5752C73.7212 20.5752 73.7212 20.5752 73.6439 20.5752C72.5613 20.5752 71.6334 21.3485 71.556 22.4311Z" fill="#151515"/>
        </svg>

        <svg *ngIf="integration.integration.website.includes('sirius')" xmlns="http://www.w3.org/2000/svg" width="147" height="28" viewBox="0 0 147 28" fill="none">
            <path d="M59.5232 11.2893C63.2588 12.0546 68.1129 13.8475 68.0648 19.317C68.0648 21.9234 67.0907 23.9678 65.0998 25.4021C63.1571 26.7828 60.7006 27.5 57.7892 27.5C52.523 27.5 48.9427 25.4556 47.0535 21.415L52.7799 18.1932C53.5452 20.3928 55.1829 21.5167 57.7892 21.5167C60.144 21.5167 61.3161 20.7995 61.3161 19.317C61.3161 17.9363 59.2717 17.171 56.6654 16.502C52.8816 15.528 48.1238 13.9438 48.1238 8.62943C48.1238 6.12478 49.0444 4.12853 50.8854 2.69959C52.7799 1.21714 55.0759 0.5 57.8374 0.5C61.9797 0.5 65.5601 2.44272 67.551 5.92141L61.9262 8.988C61.1074 7.24865 59.7266 6.38166 57.8374 6.38166C56.1516 6.38166 54.8725 7.20048 54.8725 8.47957C54.8725 9.86034 56.9169 10.6257 59.5232 11.2893Z" fill="black"/>
            <path d="M69.2686 26.7828V1.21716H75.8674V26.7828H69.2686Z" fill="black"/>
            <path d="M83.6867 5.61635C84.9123 2.34103 87.4598 1.22784 91.4576 1.22784V8.0675C89.4614 7.81061 87.6203 8.22269 86.0362 9.29306C84.452 10.3688 83.6814 12.1081 83.6814 14.5593V26.7828H77.0826V1.21716H83.6814V5.61635H83.6867Z" fill="black"/>
            <path d="M92.5333 26.7828V1.21716H99.1321V26.7828H92.5333Z" fill="black"/>
            <path d="M117.746 1.21716H124.344V26.7828H117.746V23.9196C116.161 26.3226 113.603 27.5 110.125 27.5C107.309 27.5 105.008 26.5795 103.173 24.6849C101.385 22.7904 100.464 20.2375 100.464 16.9141V1.21716H107.063V16.0952C107.063 19.5204 109.06 21.3614 112.126 21.3614C115.551 21.3614 117.751 19.2635 117.751 15.0195V1.21716H117.746Z" fill="black"/>
            <path d="M137.89 11.2893C141.626 12.0546 146.48 13.8475 146.432 19.317C146.432 21.9234 145.458 23.9678 143.467 25.4021C141.524 26.7828 139.067 27.5 136.156 27.5C130.89 27.5 127.309 25.4556 125.415 21.415L131.141 18.1932C131.907 20.3928 133.544 21.5167 136.151 21.5167C138.505 21.5167 139.678 20.7995 139.678 19.317C139.678 17.9363 137.633 17.171 135.021 16.502C131.238 15.528 126.48 13.9438 126.48 8.62943C126.48 6.12478 127.4 4.12853 129.241 2.69959C131.136 1.21714 133.437 0.5 136.193 0.5C140.336 0.5 143.916 2.44272 145.907 5.92141L140.282 8.988C139.463 7.24865 138.083 6.38166 136.193 6.38166C134.508 6.38166 133.229 7.20048 133.229 8.47957C133.239 9.86034 135.284 10.6257 137.89 11.2893Z" fill="black"/>
            <path d="M0 1.21716V26.7828H45.2712V1.21716H0ZM36.1409 20.8477C34.1447 20.8477 32.3572 19.9914 31.1049 18.632V18.6427C31.1049 18.6427 28.0061 14.9446 22.6757 14.9446C17.3453 14.9446 14.2466 18.5517 14.2466 18.5517V18.541C12.9943 19.9539 11.1693 20.8477 9.13023 20.8477C5.34648 20.8477 2.28523 17.7811 2.28523 14.0027C2.28523 10.2189 5.35183 7.15768 9.13023 7.15768C11.1693 7.15768 12.9943 8.05143 14.2466 9.46432V9.4536C14.2466 9.4536 17.3453 13.0607 22.6757 13.0607C28.0061 13.0607 31.1049 9.36263 31.1049 9.36263V9.36799C32.3572 8.00862 34.1447 7.15232 36.1409 7.15232C39.9247 7.15232 42.9859 10.2189 42.9859 13.9973C42.9859 17.781 39.9193 20.8477 36.1409 20.8477Z" fill="black"/>
        </svg>
    </div>

</div>
