import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Step } from '../../../../../../../../shared/constants/form-option';
import { MatChipListboxChange } from '@angular/material/chips';

@Component({
  selector: 'app-step-language',
  templateUrl: './step-language.component.html',
  styleUrls: ['./step-language.component.scss']
})
export class StepLanguageComponent implements OnInit {
    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Output() submitForm = new EventEmitter<void>();
    @Output() proceed = new EventEmitter<number>();
    @Input() part: number;

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.addControls();
    }

    addControls(): void {
        if (this.formGroup && Object.keys(this.formGroup?.controls)?.length === 0) {
            this.formGroup.addControl('language', this.fb.control('', Validators.required));
        }
    }

    onSelectionChange(event: MatChipListboxChange): void {
        const selectedRole = event.source.value;
        this.formGroup.get('language')?.setValue(selectedRole);
        this.submitForm.emit();
    }

    onSubmit() {
        this.submitForm.emit();
        this.proceed.emit(3);
    }

    backToPrevious() {
        this.proceed.emit(this.part -1);
    }

}
