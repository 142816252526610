import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appTabNavigation]'
})
export class TabNavigationDirective {
    @Input() containerSelector: string = 'mat-dialog-container';

    constructor(private el: ElementRef) { }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();

            const dialogContainer = this.el.nativeElement.closest(this.containerSelector);
            if (!dialogContainer) {
                return;
            }

            const inputs = Array.from(dialogContainer.querySelectorAll('input, select, textarea, button, mat-select, mat-list-option'));

            inputs.sort((a: any, b: any) => (a.tabIndex || 0) - (b.tabIndex || 0));
            const activeElement = dialogContainer.querySelector(':focus') as HTMLElement;
            const currentIndex = inputs.findIndex((input: any) => input === activeElement);

            let nextIndex: number;
            if (event.shiftKey) {
                nextIndex = currentIndex === 0 ? inputs.length - 1 : currentIndex - 1;
            } else {
                nextIndex = currentIndex === inputs.length - 1 ? 0 : currentIndex + 1;
            }


            let nextElement: HTMLElement | undefined;
            for (let i = nextIndex; i !== currentIndex; i = (i + 1) % inputs.length) {
                const element: any = inputs[i] as HTMLElement;
                if (!element.disabled) {
                    nextElement = element;
                    break;
                }
            }

            if (nextElement) {
                nextElement.focus();
            }
        }
    }
}
