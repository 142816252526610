import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UserRoles } from '../../../../core/models/user-info.interface';

@Component({
  selector: 'app-notify-banner',
  templateUrl: './notify-banner.component.html',
  styleUrls: ['./notify-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotifyBannerComponent {
    protected readonly UserRoles = UserRoles;
    @Input() backgroundColor: string;
    @Input() title: string;
    @Input() buttonText: string;
    @Input() iconColor: string;
    @Input() textColor: string;
    @Input() btnColor: string;
    @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() closeEvent = new EventEmitter<void>();
    @Input() role: UserRoles;

    onButtonClick(): void {
        this.buttonClick.emit();
    }

    closeBanner() {
        this.closeEvent.emit();
    }
}
