import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError, finalize } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../music/services/authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService {
    private isRefreshing = false;

    constructor(private authService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = this.authService.accessToken;
        if (accessToken) {
            request = this.addTokenToRequest(request, accessToken);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    if (!this.isRefreshing) {
                        this.isRefreshing = true;
                        return this.authService.refreshAccessToken().pipe(
                            switchMap((tokens) => {
                                const newRequest= this.addTokenToRequest(request, this.authService.accessToken);
                                return next.handle(newRequest);
                            }),
                            finalize(() => {
                                this.isRefreshing = false;
                            })
                        );
                    }
                }
                return throwError(error);
            })
        );
    }

    private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }
}
