<ng-container [ngSwitch]="role">
    <ng-container *ngSwitchCase="'root'">
        <div *ngIf="!isMobilePlatform" class="container">
            <div class="statistic-item">
                <div class="name">{{ 'statistics.organisation' | translate }}</div>
                <div class="value">{{rootIntroInfo?.organisationAmount}}</div>
            </div>
            <div class="line"></div>
            <div class="statistic-item">
                <div class="name">{{ 'statistics.teachers' | translate }}</div>
                <div class="value">{{rootIntroInfo?.teachersAmount}}</div>
            </div>
            <div class="line"></div>
            <div class="statistic-item">
                <div class="name">{{ 'statistics.students' | translate }}</div>
                <div class="value">{{rootIntroInfo?.studentsAmount}}</div>
            </div>
            <div class="line"></div>
            <div class="statistic-item">
                <div class="name">{{ 'statistics.accepted-matches' | translate }}</div>
                <div class="value">—</div>
            </div>
            <div class="line"></div>
            <div class="statistic-item">
                <div class="name">{{ 'statistics.pending-matches' | translate }}</div>
                <div class="value">—</div>
            </div>
        </div>
        <mat-list *ngIf="isMobilePlatform" class="list-container" role="list">
            <a mat-list-item matRipple (click)="openOrganisationList()">
                <div class="statistic-item">
                    <div class="name">{{ 'statistics.organisation' | translate }}</div>
                    <div class="value">{{ rootIntroInfo?.organisationAmount }}</div>
                </div>
            </a>
            <mat-divider></mat-divider>
            <a mat-list-item matRipple (click)="openTeacherList()">
                <div class="statistic-item">
                    <div class="name">{{ 'statistics.teachers' | translate }}</div>
                    <div class="value">{{ rootIntroInfo?.teachersAmount }}</div>
                </div>
            </a>
            <mat-divider></mat-divider>
            <a mat-list-item matRipple  (click)="openStudentList()">
                <div class="statistic-item">
                    <div class="name">{{ 'statistics.students' | translate }}</div>
                    <div class="value">{{ rootIntroInfo?.studentsAmount }}</div>
                </div>
            </a>
            <mat-divider></mat-divider>
            <a mat-list-item matRipple>
                <div class="statistic-item">
                    <div class="name">{{ 'statistics.accepted-matches' | translate }}</div>
                    <div class="value">—</div>
                </div>
            </a>
            <mat-divider></mat-divider>
            <a mat-list-item matRipple>
                <div class="statistic-item">
                    <div class="name">{{ 'statistics.pending-matches' | translate }}</div>
                    <div class="value">—</div>
                </div>
            </a>
        </mat-list>
    </ng-container>
    <ng-container *ngSwitchCase="'school_admin'">
        <div *ngIf="!isMobilePlatform" class="container">
            <div class="statistic-item">
                <div class="name">{{ 'statistics.teachers' | translate }}</div>
                <div class="value">{{rootIntroInfo?.teachersAmount}}</div>
            </div>
            <div class="line"></div>
            <div class="statistic-item">
                <div class="name">{{ 'statistics.accepted-matches' | translate }}</div>
                <div class="value">{{rootIntroInfo?.acceptedMatches}}</div>
            </div>
            <div class="line"></div>
            <div class="statistic-item">
                <div class="name">{{ 'statistics.pending-matches' | translate }}</div>
                <div class="value">{{rootIntroInfo?.pendingMatches}}</div>
            </div>
            <div class="line"></div>
            <div class="statistic-item">
                <div class="name">{{ 'statistics.students' | translate }}</div>
                <div class="value">{{rootIntroInfo?.studentsAmount}}</div>
            </div>
        </div>
        <mat-list *ngIf="isMobilePlatform" class="list-container" role="list">
            <mat-divider></mat-divider>
            <a mat-list-item matRipple (click)="openTeacherList()">
                <div class="statistic-item">
                    <div class="name">{{ 'statistics.teachers' | translate }}</div>
                    <div class="value">{{ rootIntroInfo?.teachersAmount }}</div>
                </div>
            </a>
            <mat-divider></mat-divider>
            <a mat-list-item matRipple (click)="openStudentList()">
                <div class="statistic-item">
                    <div class="name">{{ 'statistics.students' | translate }}</div>
                    <div class="value">{{ rootIntroInfo?.studentsAmount }}</div>
                </div>
            </a>
            <mat-divider></mat-divider>
            <a mat-list-item matRipple (click)="openActiveMatchList()">
                <div class="statistic-item">
                    <div class="name">{{ 'statistics.accepted-matches' | translate }}</div>
                    <div class="value">{{ rootIntroInfo?.acceptedMatches }}</div>
                </div>
            </a>
            <mat-divider></mat-divider>
            <a mat-list-item matRipple (click)="openPendingMatchList()">
                <div class="statistic-item">
                    <div class="name">{{ 'statistics.pending-matches' | translate }}</div>
                    <div class="value">{{rootIntroInfo?.pendingMatches}}</div>
                </div>
            </a>
            <mat-divider></mat-divider>
            <a mat-list-item matRipple (click)="openPastMatchList()">
                <div class="statistic-item">
                    <div class="name">{{ 'statistics.past-matches' | translate }}</div>
                    <div class="value">{{rootIntroInfo?.pastMatches}}</div>
                </div>
            </a>
        </mat-list>
    </ng-container>
    <ng-container *ngSwitchCase="'student'">
        <mat-list *ngIf="isMobilePlatform" class="list-container" role="list">
            <mat-divider></mat-divider>
            <a mat-list-item matRipple (click)="teachersAmount > 0 && openTeacherList()"
               [disabled]="teachersAmount < 1">
                <div class="statistic-item">
                    <div class="name">{{ 'statistics.teachers' | translate }}</div>
                    <div class="value">{{ rootIntroInfo?.teachersAmount }}</div>
                </div>
            </a>
            <mat-divider></mat-divider>
        </mat-list>
    </ng-container>
</ng-container>

