<ng-container *ngIf="part === 1">
    <ng-container [ngSwitch]="step.type">

        <ng-container *ngSwitchCase="'name'">
            <app-step-name [step]="step"
                           (submitForm)="onSubmit()"
                           [stepper]="stepper"
                           [signUpRole]="signUpRole"
                           [formGroup]="formGroup">
            </app-step-name>
        </ng-container>
        <ng-container *ngSwitchCase="'instrument'">
            <app-step-instrument [step]="step"
                                 [stepper]="stepper"
                                 (submitForm)="onSubmit()"
                                 [signUpRole]="signUpRole"
                                 [formGroup]="formGroup">
            </app-step-instrument>
        </ng-container>
        <ng-container *ngSwitchCase="'genres'">
            <app-step-genres [step]="step"
                             [stepper]="stepper"
                             (submitForm)="onSubmit()"
                             [signUpRole]="signUpRole"
                             [formGroup]="formGroup">
            </app-step-genres>
        </ng-container>

        <ng-container *ngSwitchCase="'contact'">
            <app-step-contact [step]="step"
                              (proceed)="onProceed($event)"
                              (submitForm)="onSubmit()"
                              [part]="part"
                              [signUpRole]="signUpRole"
                              [formGroup]="formGroup">
            </app-step-contact>
        </ng-container>

    </ng-container>
</ng-container>
<ng-container *ngIf="part === 2">
    <ng-container [ngSwitch]="step.type">
        <ng-container *ngSwitchCase="'organisation'">
            <app-organisation-sign-up
                (proceed)="onProceed($event)"
                [step]="step"
                [part]="part"
                (submitForm)="onSubmit()"
                [formGroup]="formGroup"
                (finish)="finish($event)"
                [stepper]="stepper">
            </app-organisation-sign-up>
        </ng-container>
        <ng-container *ngSwitchCase="'socialMedia'">
            <app-step-social-media
                [step]="step"
                (submitForm)="onSubmit()"
                [formGroup]="formGroup"
                [stepper]="stepper">
            </app-step-social-media>
        </ng-container>
        <ng-container *ngSwitchCase="'musicalEducation'">
            <app-step-musical-education
                [step]="step"
                (submitForm)="onSubmit()"
                [formGroup]="formGroup"
                [stepper]="stepper">
            </app-step-musical-education>
        </ng-container>
        <ng-container *ngSwitchCase="'preferStudentPreference'">
            <app-step-prefer-student-preference
                [step]="step"
                (submitForm)="onSubmit()"
                [formGroup]="formGroup"
                [stepper]="stepper">
            </app-step-prefer-student-preference>
        </ng-container>
        <ng-container *ngSwitchCase="'priorKnowledge'">
            <app-step-prior-knowledge
                [stepper]="stepper"
                [step]="step"
                (submitForm)="onSubmit()"
                [formGroup]="formGroup">
            </app-step-prior-knowledge>
        </ng-container>
        <!-- <ng-container *ngSwitchCase="'genderPreference'">
            <app-step-student-preference
                [stepper]="stepper"
                [step]="step"
                (submitForm)="onSubmit()"
                [formGroup]="formGroup">
            </app-step-student-preference>
        </ng-container> -->
        <ng-container *ngSwitchCase="'area'">
            <app-step-area
                [stepper]="stepper"
                [step]="step"
                [signUpRole]="signUpRole"
                (submitForm)="onSubmit()"
                [formGroup]="formGroup">
            </app-step-area>
        </ng-container>
        <ng-container *ngSwitchCase="'teachingLocation'">
            <app-step-teaching-location
                [stepper]="stepper"
                [step]="step"
                (submitForm)="onSubmit()"
                [formGroup]="formGroup">
            </app-step-teaching-location>
        </ng-container>
        <ng-container *ngSwitchCase="'language'">
            <app-step-language
                (proceed)="onProceed($event)"
                [step]="step"
                (submitForm)="onSubmit()"
                [part]="part"
                [formGroup]="formGroup">
            </app-step-language>
        </ng-container>
    </ng-container>
</ng-container>
<ng-container *ngIf="part === 3">
    <ng-container [ngSwitch]="step.type">
        <ng-container *ngSwitchCase="'teacherArea'">
            <app-step-area-teacher
                [stepper]="stepper"
                [step]="step"
                (submitForm)="onSubmit()"
                [formGroup]="formGroup">
            </app-step-area-teacher>
        </ng-container>
        <ng-container *ngSwitchCase="'area'">
            <app-step-area
                [stepper]="stepper"
                [step]="step"
                [signUpRole]="signUpRole"
                (submitForm)="onSubmit()"
                [formGroup]="formGroup">
            </app-step-area>
        </ng-container>
        <ng-container *ngSwitchCase="'additionalInfo'">
            <app-step-additional-info
                [stepper]="stepper"
                [step]="step"
                (submitForm)="onSubmit()"
                [signUpRole]="signUpRole"
                [formGroup]="formGroup">
            </app-step-additional-info>
        </ng-container>
        <ng-container *ngSwitchCase="'dateTime'">
            <app-step-date-and-time
                [stepper]="stepper"
                [step]="step"
                (submitForm)="onSubmit()"
                [formGroup]="formGroup">
            </app-step-date-and-time>
        </ng-container>
        <ng-container *ngSwitchCase="'goals'">
            <app-step-personal-goals
                [stepper]="stepper"
                [step]="step"
                (submitForm)="onSubmit()"
                [signUpRole]="signUpRole"
                [part]="part"
                (finish)="finish($event)"
                (proceed)="onProceed($event)"
                [formGroup]="formGroup">
            </app-step-personal-goals>
        </ng-container>
    </ng-container>
</ng-container>
