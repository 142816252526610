import { SchedulesBody } from '../../music/services/api/api.type';

export function getSchedulesBody(availabilityTimeStamp: {
    day: string;
    date: string;
    selectedDate: Date;
    selectedTime: string[];
}[]): SchedulesBody[] {
    let schedulesBody: SchedulesBody[] = [];
    const schedules: {
        day: string;
        date: string;
        selectedDate: Date;
        selectedTime: string[];
    }[] = availabilityTimeStamp;
    const selectedSchedules = schedules.filter((data) => data.selectedTime.length > 0);
    selectedSchedules.forEach((schedule) => {
        schedule.selectedTime.forEach((time: string) => {
            const startDateSelectedDate = new Date(schedule.selectedDate);
            const endDateSelectedDate = new Date(schedule.selectedDate);
            const containsEnDash = time.includes('–');
            let startT = '';
            let endT = '';
            if (containsEnDash) {
                let [startTime, endTime] = time.split(' – ');
                endT = endTime;
                startT = startTime;
            }

            endT = endT.replace(/^.*?(\d{2}:\d{2})$/, '$1').trim();
            const endTimeParts = endT.split(':');
            const endHour = parseInt(endTimeParts[0], 10);
            const endMinutes = parseInt(endTimeParts[1], 10);
            endDateSelectedDate.setUTCHours(endHour, endMinutes, 0);
            const formattedEndTime = endDateSelectedDate.toISOString();

            startT = startT.replace(/^.*?(\d{2}:\d{2})$/, '$1').trim();
            const startTimeParts = startT.split(':');
            const startHour = parseInt(startTimeParts[0], 10);
            const startMinutes = parseInt(startTimeParts[1], 10);

            startDateSelectedDate.setUTCHours(startHour, startMinutes, 0);
            const formattedStartTime = startDateSelectedDate.toISOString();

            let dayNum =  schedules.findIndex((d) => d.day === schedule.day);
            dayNum = dayNum === 6 ? 0 : dayNum + 1;

            schedulesBody.push({ startTime: formattedStartTime, endTime: formattedEndTime, day: dayNum });
        });
    });

    schedulesBody = schedulesBody.filter((value, index, self) =>
            index === self.findIndex((t) =>
                t.startTime === value.startTime &&
                t.endTime === value.endTime &&
                t.day === value.day
            )
    );
    console.log(schedulesBody, 'schedulesBody')
    return schedulesBody;
}
