import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DashboardTableService {
  needUpdateTable = new Subject();
  needUpdateOrganisationTable= new Subject();
  needUpdateStudentTable = new Subject();
  updateSchoolProfile =  new Subject<string>();
  needUpdateTeacherTable = new Subject();
  constructor() { }
}
