import { Injectable, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root'
})

export class CustomMatPaginatorIntlService extends MatPaginatorIntl implements OnDestroy {
    unsubscribe: Subject<void> = new Subject<void>();
    OF_LABEL = 'of';

    constructor(private translate: TranslateService) {
        super();

        this.translate.onLangChange.pipe(
            takeUntil(this.unsubscribe)
    ).subscribe(() => {
                this.getAndInitTranslations();
            });

        this.getAndInitTranslations();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getAndInitTranslations() {
        this.translate
            .get([
                'paginator.itemsPerPage',
                'paginator.nextPage',
                'paginator.lastPage',
                'paginator.previousPage',
                'paginator.of_label',
                'paginator.firstPage'
            ]).pipe(
            takeUntil(this.unsubscribe))
            .subscribe(translation => {
                this.itemsPerPageLabel = translation['paginator.itemsPerPage'];
                this.nextPageLabel = translation['paginator.nextPage'];
                this.lastPageLabel = translation['paginator.lastPage'];
                this.firstPageLabel = translation['paginator.firstPage'];
                this.previousPageLabel = translation['paginator.previousPage'];
                this.OF_LABEL = translation['paginator.of_label'];
                this.changes.next();
            });
    }
    override getRangeLabel = (
        page: number,
        pageSize: number,
        length: number,
    ) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.OF_LABEL} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
            startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${
            this.OF_LABEL
        } ${length}`;
    };
}
