<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{ step.title1 | translate }} {{ step.title2 | translate }}</div>
    </div>
    <div class="sign-up-form-field" [ngClass]="{'additional-spacer': signUpRole === UserRoles.Organisation}">
        <p>{{ 'signup-form.' + signUpRole + '.step-contact.emailLabel' | translate }}</p>
        <mat-form-field subscriptSizing="dynamic">
            <input matInput formControlName="email" placeholder="{{ 'signup-form.' + signUpRole + '.step-contact.emailPlaceholder' | translate }}" required>
        </mat-form-field>
        <mat-error *ngIf="(email?.hasError('required') && email?.dirty) || (email?.hasError('required') && email?.touched)">
            {{  'signup-form.' + signUpRole + '.step-contact.emailRequiredError' | translate }}
        </mat-error>
        <mat-error *ngIf="!!((email?.errors && !email?.hasError('required') && !email?.hasError('alreadyExist') && email?.dirty) || (email?.errors && !email?.hasError('required') && !email?.hasError('alreadyExist') && email?.touched));">
            {{  'signup-form.' + signUpRole + '.step-contact.emailFormatError' | translate }}
        </mat-error>
        <mat-error *ngIf="email?.hasError('alreadyExist')">
            {{  'signup-form.' + signUpRole + '.step-contact.userExistError' | translate }}
        </mat-error>
    </div>
    <div class="custom-theme sign-up-form-field" *ngIf="signUpRole !== UserRoles.Organisation">
        <mat-checkbox formControlName="allowContactViaEmail">{{ step.checkBoxLabelTitle[signUpRole][0] | translate }}</mat-checkbox>
    </div>
    <div class="sign-up-form-field">
        <p>{{ 'signup-form.' + signUpRole + '.step-contact.phoneLabel' | translate }} <span *ngIf="signUpRole !== UserRoles.Organisation" class="optional">{{  'signup-form.' + signUpRole + '.step-contact.optionalLabel' | translate }}</span></p>
        <mat-form-field subscriptSizing="dynamic">
            <input matInput formControlName="phone" placeholder="{{  'signup-form.' + signUpRole + '.step-contact.phonePlaceholder' | translate }}"  maxlength="15" minlength="5" appNumericInput>
        </mat-form-field>
        <mat-error *ngIf="(phone?.hasError('required') && phone?.dirty) || (phone?.hasError('required') && phone?.touched)">
            {{ 'signup-form.' + signUpRole + '.step-contact.phoneRequiredError' | translate }}
        </mat-error>
        <mat-error *ngIf="!!((phone?.errors && !phone?.hasError('required') && phone?.dirty) || (phone?.errors && !phone?.hasError('required') && phone?.touched));">
            {{ 'signup-form.' + signUpRole + '.step-contact.phoneLengthError' | translate }}
        </mat-error>
    </div>
    <div class="custom-theme sign-up-form-field" *ngIf="signUpRole !== UserRoles.Organisation">
        <mat-checkbox formControlName="allowContactViaPhone">{{ step.checkBoxLabelTitle[signUpRole][1] | translate }}</mat-checkbox>
    </div>

    <div align="end" class="custom-theme btn-container sign-up-form-field" [ngStyle]="{'justify-content': step.hasPreviousBtn ? 'space-between' : 'flex-end'}">
        <button *ngIf="step.hasPreviousBtn" mat-stroked-button [color]="step.btn2Color" (click)="backToPrevious()">{{ step.btnText1 | translate }}</button>

        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">{{ step.btnText2 | translate }}</button>
    </div>
</form>
