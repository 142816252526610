<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{ step.title1 | translate }} {{ step.title2 | translate }}</div>
    </div>
    <mat-chip-listbox formControlName="selectedInstruments" #chipList [multiple]="true" class="mat-chip-list-stacked"
                      [selectable]="true" (change)="handleSelectedInstrumentsChange()">
        <mat-chip-option *ngFor="let chip of displayedChips" [value]="chip.name" [selected]="chip.selected"
                         (click)="chip.selected=!chip.selected"
                         [selectable]="true">
            {{ 'instrument_translations.' + chip.name | translate }}
        </mat-chip-option>
    </mat-chip-listbox>
    <div class="custom-theme storybook-field">
        <button mat-stroked-button *ngIf="displayedChips.length < step.chips.length" [color]="step.btn2Color" (click)="showMoreChips()">{{ 'signup-form.' + signUpRole + '.step-instrument.showMoreButton' | translate:{ count: step.chips.length - displayedChips.length } }}</button>
        <button mat-stroked-button *ngIf="displayedChips.length > 14" [color]="step.btn2Color" (click)="showLessChips()">{{ 'signup-form.' + signUpRole + '.step-instrument.showLessButton' | translate }}</button>
    </div>
    <div align="end" class="custom-theme btn-container sign-up-form-field">
        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">{{ step.btnText2 | translate }}</button>
    </div>
</form>
