<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{step.title1 | translate}} {{step.title2 | translate}}</div>
    </div>

    <mat-chip-listbox aria-label="Prior Knowledge selection"  [multiple]="true" formControlName="language" (change)="onSelectionChange($event)">
        <mat-chip-option *ngFor="let chip of step.chips" [value]="chip.value">{{chip.label}}</mat-chip-option>
    </mat-chip-listbox>
    <div align="end" class="custom-theme btn-container sign-up-form-field" [ngStyle]="{'justify-content': step.hasPreviousBtn ? 'space-between' : 'flex-end'}">
        <button *ngIf="step.hasPreviousBtn" mat-stroked-button [color]="step.btn2Color" (click)="backToPrevious()">{{step.btnText1 | translate}}</button>

        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">{{step.btnText2 | translate}}</button>
    </div>
</form>
