<div *ngIf="!isMobilePlatform" class="title-container"  [ngClass]="{'hide-empty-dashboard-line': organisations?.list?.length === 0}">
    <div class="title">
        <span>{{ 'school-dashboard-table.title.organisation' | translate }}</span>
    </div>
    <div class="btn-container custom-theme">
        <div>
            <button mat-button (click)="addOrEditOrganisation(true)">{{ 'school-dashboard-table.button.addOrganisation' | translate }}</button>
        </div>
        <button [disabled]="true" style="opacity: 0.3" mat-stroked-button>{{ 'school-dashboard-table.button.copyInviteLink' | translate }}</button>
    </div>
</div>
<div *ngIf="organisations?.list && organisations.list.length > 0 && !isMobilePlatform; else emptyDesktopContainer" class="table-container">
    <table matSort (matSortChange)="sortData($event)" mat-table [dataSource]="dataSource">

        <!-- Name Column -->
        <ng-container matColumnDef="name" sticky>
            <th mat-sort-header="name" mat-header-cell *matHeaderCellDef> {{ 'school-dashboard-table.header.name' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Email Location Column -->
        <ng-container matColumnDef="location">
            <th mat-sort-header="postalCode" mat-header-cell *matHeaderCellDef> {{ 'school-dashboard-table.header.location' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.postalCode ? element.postalCode : '—'}} </td>
        </ng-container>

        <!-- Teachers Column -->
        <ng-container matColumnDef="teachers">
            <th mat-sort-header="teachersAmount" mat-header-cell *matHeaderCellDef> {{ 'school-dashboard-table.header.teachers' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.teachersAmount}} </td>
        </ng-container>

        <!-- Sign-up Date Column -->
        <ng-container matColumnDef="signUpDate">
            <th mat-sort-header="created" mat-header-cell *matHeaderCellDef> {{ 'school-dashboard-table.header.sign-up.date' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element?.created ? (element?.created | formatLessonStartTime) : '—'}} </td>
        </ng-container>

        <!-- Social Media -->
        <ng-container matColumnDef="socialMedia">
            <th mat-sort-header="socials" mat-header-cell *matHeaderCellDef> {{ 'school-dashboard-table.header.socialMedia' | translate }} </th>
            <td [ngClass]="{'social-meida-cell': formatSocials(element.socials) !== '—'}" mat-cell *matCellDef="let element">
                <span *ngFor="let social of element.socials" (click)="redirectToLink(social.link)">
                     {{ social.type }}
               </span>
                <span *ngIf="!element.socials || element.socials?.length === 0">—</span>
            </td>
        </ng-container>

        <!-- Association -->
        <ng-container matColumnDef="association">
            <th mat-sort-header="unions" mat-header-cell *matHeaderCellDef> {{ 'school-dashboard-table.header.association' | translate }} </th>
            <td mat-cell  *matCellDef="let element">
               <span *ngFor="let union of element.unions">
                   {{ union.name }}{{ union.membership ? ' (' + union.membership + ')' : '' }}
               </span>
                <span *ngIf="!element.unions || element.unions?.length === 0">—</span>
            </td>
        </ng-container>

        <!-- Approval status -->
        <ng-container matColumnDef="approvalStatus">
            <th mat-sort-header="validated" mat-header-cell *matHeaderCellDef>{{ 'school-dashboard-table.header.approvalStatus' | translate }}</th>
            <td mat-cell *matCellDef="let element; let i = index">
                {{ organisations.list[!currentIndex ? i : currentIndex + i].validated ? ('school-dashboard-table.status.approved' | translate) : ('school-dashboard-table.status.pending' | translate) }}
            </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef>{{ 'school-dashboard-table.header.actions' | translate }}</th>
            <td mat-cell *matCellDef="let element; let i = index">
                <div class="actions-container">
                    <button *ngIf="!organisations.list[!currentIndex ? i : currentIndex + i].validated"
                            class="raised-button"
                            color="primary"
                            mat-raised-button
                            (click)="handleApprove(element.id, organisations.list[!currentIndex ? i : currentIndex + i].validated)">
                        {{ 'school-dashboard-table.button.approve' | translate }}
                    </button>

                    <button *ngIf="organisations.list[!currentIndex ? i : currentIndex + i].validated"
                            mat-button
                            [disabled]="element.id === schoolId"
                            [ngStyle]="{opacity: element.id === schoolId ? 0.3 : 1}"
                            (click)="handleApprove(element.id, organisations.list[!currentIndex ? i : currentIndex + i].validated)">
                        {{ 'school-dashboard-table.button.disapprove' | translate }}
                    </button>
                </div>
            </td>
        </ng-container>


        <!--<ng-container matColumnDef="edit" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index">
                <div class="actions-container">
                    <button mat-button
                            (click)="addOrEditOrganisation(false, organisations?.list[!currentIndex ? i : currentIndex + i])">
                        {{ 'school-dashboard-table.button.edit' | translate }}
                    </button>
                </div>
            </td>
        </ng-container>-->

        <ng-container matColumnDef="profile" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index" #td>
                    <mat-menu class="menu" #menu="matMenu">
                        <button class="desk-menu-item" mat-menu-item (click)="deleteOrganisation('organisation-profile.buttons.delete_organisation', organisations.list[!this.currentIndex ? i : this.currentIndex + i])">
                            {{ 'confirm_delete.btnDelete' | translate }}
                        </button>
                        <mat-divider></mat-divider>
                        <button class="desk-menu-item" mat-menu-item (click)="showProfile(organisations.list[!this.currentIndex ? i : this.currentIndex + i])">
                            {{ 'school-dashboard-table.button.showProfile' | translate }}
                        </button>
                    </mat-menu>
                    <div class="actions-container">
                        <button [matMenuTriggerFor]="menu" mat-button><mat-icon>more_horiz</mat-icon></button>
                    </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<div class="mobile-table" *ngIf="organisations?.list && organisations.list.length > 0 && isMobilePlatform; else emptyMobileContainer">
    <ng-container *ngIf="isMobilePlatform">
        <div class="toolbar-wrapper">
            <mat-toolbar class="toolbar-form">
                <div class="left-side">
                    <button mat-icon-button class="example-icon" (click)="closeDialog()">
                        <mat-icon class="close-icon">chevron_left</mat-icon>
                    </button>
                    <span>{{'statistics.organisation' | translate }}</span>
                </div>
                <div class="more-container">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="addOrEditOrganisation(true)">{{ 'school-dashboard-table.button.addOrganisation' | translate }}</button>
                        <mat-divider></mat-divider>
                        <button [disabled]="true" style="opacity: 0.3" mat-menu-item>{{ 'school-dashboard-table.button.copyInviteLink' | translate }}</button>
                    </mat-menu>
                </div>
            </mat-toolbar>
            <mat-divider></mat-divider>
        </div>
    </ng-container>
    <mat-list class="main-list-container" role="list">
        <ng-container *ngFor="let item of paginatedData; let i = index">
            <a mat-list-item matRipple (click)="showProfile(organisations.list[!this.currentIndex ? i : this.currentIndex + i])">
                <div class="list-wrapper">
                    <div class="logo-container">
                        <img src="{{ item.files?.[0]?.url ? item.files?.[0]?.url : '/assets/images/profile/unknown.jpg' }}" alt="Logo" class="logo">
                    </div>
                    <div class="text-container">
                        <span class="name">{{ item?.name }}</span>
                        <span class="city">{{ item?.city }}</span>
                    </div>
                </div>
            </a>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-list>
</div>
<ng-template #emptyDesktopContainer>
    <div *ngIf="!isMobilePlatform" class="empty-container">
        {{ 'school-dashboard-table.message.noDataAvailable' | translate }}
    </div>
</ng-template>

<ng-template #emptyMobileContainer>
    <ng-container *ngIf="isMobilePlatform">
        <mat-toolbar class="toolbar-form">
            <div class="left-side">
                <button mat-icon-button class="example-icon" (click)="closeDialog()">
                    <mat-icon class="close-icon">chevron_left</mat-icon>
                </button>
                <span>{{'statistics.organisation' | translate }}</span>
            </div>
            <div class="more-container">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="addOrEditOrganisation(true)">{{ 'school-dashboard-table.button.addOrganisation' | translate }}</button>
                    <mat-divider></mat-divider>
                    <button [disabled]="true" style="opacity: 0.3" mat-menu-item>{{ 'school-dashboard-table.button.copyInviteLink' | translate }}</button>
                    <mat-divider></mat-divider>
                </mat-menu>
            </div>
        </mat-toolbar>
        <mat-divider></mat-divider>
        <div class="empty-container">
            {{ 'school-dashboard-table.message.noDataAvailable' | translate }}
        </div>
    </ng-container>
</ng-template>

<mat-paginator [ngClass]="{'hide-empty-dashboard-line': organisations?.list?.length === 0}" *ngIf="!isMobilePlatform"
               [pageSizeOptions]="[10, 20, 30]"
    (page)="onPageChange($event)"
    showFirstLastButtons>
</mat-paginator>

<div *ngIf="isMobilePlatform && organisations?.list && organisations.list.length > 0" class="paginator-wrapper">
    <mat-paginator
        [pageSize]="10"
        (page)="onPageChange($event)">
    </mat-paginator>
</div>
