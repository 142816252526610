import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { convertTimeSlots } from '../../../utils';
import { Student, UserLanguage, UserStudent } from '../../../services/api/api.type';

@Component({
  selector: 'app-student-schedule',
  templateUrl: './student-schedule.component.html',
  styleUrls: ['./student-schedule.component.scss']
})
export class StudentScheduleComponent implements OnChanges {

    @Input() userStudent: Student;
    @Input() studentTimeSlots: string[] = [];
    @Output() onStudentConfirmed = new EventEmitter<string>();
    @Output() onStudentDeclined = new EventEmitter();
    convertedTimeSlots: string[];
    selectedTime: string = '';

    ngOnChanges(): void {
        this.convertedTimeSlots = this.convertTimeSlots(this.studentTimeSlots);
    }

    convertTimeSlots(timeSlots: string[]): string[] {
        if (!timeSlots) {
            return [];
        }
        this.selectedTime = timeSlots[0];

        return convertTimeSlots(timeSlots, this.userStudent.language as UserLanguage, this.userStudent.timezone || undefined);
    }

    onTimeSelected (index: number) {
        this.selectedTime = this.studentTimeSlots[index];
    }

    confirmMatching(time: string) {
        this.onStudentConfirmed.emit(time);
    }

    declineMatching() {
        this.onStudentDeclined.emit();
    }
}
