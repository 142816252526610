<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{step.title1 | translate}} {{step.title2 | translate}}</div>
    </div>

    <mat-chip-listbox aria-label="Role selection" [multiple]="true" formControlName="genres" (change)="onRoleSelectionChange($event)">
        <mat-chip-option *ngFor="let chip of step.chips" [value]="chip">
            {{ 'genre_translations.' + chip | translate }}
        </mat-chip-option>
    </mat-chip-listbox>
    <div align="end" class="custom-theme btn-container sign-up-form-field">
        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">{{step.btnText2 | translate}}</button>
    </div>
</form>
