import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-matching-confirmation',
    templateUrl: './matching-confirmation.component.html',
    styleUrls: ['./matching-confirmation.component.scss'],
})
export class MatchingConfirmationComponent implements OnChanges {
    @Input() userRole: 'teacher' | 'student';
    @Input() matching: 'accepted' | 'declined';
    title: string = '';
    message: string = '';

    ngOnChanges() {
        if (this.userRole === 'teacher') {
            if (this.matching === 'accepted') {
                this.title = 'teacher-request-accepted-title';
                this.message = 'teacher-request-accepted-description';
            } else {
                this.title = 'teacher-request-declined-title';
                this.message = 'teacher-request-declined-description';
            }
        }

        if (this.userRole === 'student') {
            if (this.matching === 'accepted') {
                this.title = 'student-request-accepted-title';
                this.message = 'student-request-accepted-description';
            } else {
                this.title = 'student-request-declined-title';
                this.message = 'student-request-declined-description';
            }
        }
    }
}
