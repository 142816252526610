<div class="edit-form-wrapper" appTabNavigation [containerSelector]="'mat-dialog-container'">
    <div class="header-wrapper-mobile">
        <mat-toolbar class="toolbar-form">
            <span>{{ 'profile-dialog.title' | translate }}</span>
            <span class="example-spacer"></span>
            <button tabindex="1" mat-icon-button class="example-icon" (click)="closeDialog()">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
        </mat-toolbar>
        <mat-divider></mat-divider>
        <div class="footer mobile">
            <div class="btn-container custom-theme">
                <button tabindex="7" mat-stroked-button color="primary"
                        (click)="discard()">{{ 'profile-dialog.buttons.discard' | translate }}
                </button>
                <button tabindex="8" mat-raised-button color="primary" [disabled]="loading" type="submit"
                        (click)="onSubmit()">
                    {{ data ? ('profile-dialog.buttons.editChanges' | translate) : ('profile-dialog.buttons.saveChanges' | translate) }}
                </button>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>
<div mat-dialog-content class="form-content">
    <form [formGroup]="profileForm">
        <!-- DESKTOP-->
        <div *ngIf="!isMobilePlatform">
           <div class="form-row">
            <div class="form-column">
                <div class="form-field">
                    <p>{{ 'profile-dialog.labels.email' | translate }}</p>
                    <mat-form-field subscriptSizing="dynamic">
                        <input tabindex="2" placeholder="{{ 'profile-dialog.placeholders.email' | translate }}" matInput formControlName="email" required>
                    </mat-form-field>
                    <mat-error *ngIf="getFieldProperties('email').isRequired">
                        {{ 'profile-dialog.errors.email.required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="getFieldProperties('email').hasError && !email?.hasError('alreadyExist')">
                        {{ 'profile-dialog.errors.email.invalid' | translate }}
                    </mat-error>
                    <mat-error *ngIf="email?.hasError('alreadyExist')">
                        <!-- Replace key after json update   -->
                        {{  'signup-form.' + 'student' + '.step-contact.userExistError' | translate }}
                    </mat-error>
                </div>
                <div class="form-field">
                    <p>{{ 'profile-dialog.labels.firstName' | translate }}</p>
                    <mat-form-field subscriptSizing="dynamic">
                        <input tabindex="4" placeholder="{{ 'profile-dialog.placeholders.firstName' | translate }}" matInput formControlName="firstName">
                    </mat-form-field>
                    <mat-error *ngIf="getFieldProperties('firstName').isRequired">
                        {{ 'profile-dialog.errors.firstName.required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="getFieldProperties('firstName').hasError">
                        {{ 'profile-dialog.errors.firstName.invalid' | translate }}
                    </mat-error>
                </div>
                <div class="form-field">
                    <p>{{ 'profile-dialog.labels.language' | translate }}</p>
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-select tabindex="6" placeholder="{{ 'profile-dialog.placeholders.language' | translate }}" formControlName="language">
                            <mat-option *ngFor="let option of languageOptions" [value]="option.value">{{ option.label }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="getFieldProperties('language').isRequired">
                        {{ 'profile-dialog.errors.language.required' | translate }}
                    </mat-error>
                </div>
            </div>
            <div class="form-column">
                <div class="form-field">
                    <p>{{ 'profile-dialog.labels.gender' | translate }}</p>
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-select tabindex="3" placeholder="{{ 'profile-dialog.placeholders.gender' | translate }}" formControlName="gender">
                            <mat-option *ngFor="let option of selectGender" [value]="option.value">{{ 'genders.' + option.value | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <mat-error *ngIf="getFieldProperties('gender').isRequired">
                        {{ 'profile-dialog.errors.gender.required' | translate }}
                    </mat-error> -->
                    <mat-error *ngIf="getFieldProperties('email').hasAllErrors && !getFieldProperties('gender').hasAllErrors">
                    </mat-error>
                </div>
                <div class="form-field">
                    <p>{{ 'profile-dialog.labels.lastName' | translate }}</p>
                    <mat-form-field subscriptSizing="dynamic">
                        <input tabindex="5" placeholder="{{ 'profile-dialog.placeholders.lastName' | translate }}" matInput formControlName="lastName" required>
                    </mat-form-field>
                    <mat-error *ngIf="getFieldProperties('lastName').isRequired">
                        {{ 'profile-dialog.errors.lastName.required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="getFieldProperties('lastName').hasError">
                        {{ 'profile-dialog.errors.lastName.invalid' | translate }}
                    </mat-error>
                </div>
            </div>
        </div>
        </div>
        <!-- MOBILE-->
        <div *ngIf="isMobilePlatform">
            <div class="form-row">
                <div class="form-column">
                    <div class="form-field">
                        <p>{{ 'profile-dialog.labels.email' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input tabindex="2" placeholder="{{ 'profile-dialog.placeholders.email' | translate }}" matInput formControlName="email" required>
                        </mat-form-field>
                        <mat-error *ngIf="getFieldProperties('email').isRequired">
                            {{ 'profile-dialog.errors.email.required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="getFieldProperties('email').hasError && !email?.hasError('alreadyExist')">
                            {{ 'profile-dialog.errors.email.invalid' | translate }}
                        </mat-error>
                        <mat-error *ngIf="email?.hasError('alreadyExist')">
                            <!-- Replace key after json update   -->
                            {{  'signup-form.' + 'student' + '.step-contact.userExistError' | translate }}
                        </mat-error>
                    </div>
                    <div class="form-field">
                        <p>{{ 'profile-dialog.labels.firstName' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input tabindex="4" placeholder="{{ 'profile-dialog.placeholders.firstName' | translate }}" matInput formControlName="firstName">
                        </mat-form-field>
                        <mat-error *ngIf="getFieldProperties('firstName').isRequired">
                            {{ 'profile-dialog.errors.firstName.required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="getFieldProperties('firstName').hasError">
                            {{ 'profile-dialog.errors.firstName.invalid' | translate }}
                        </mat-error>
                    </div>

                    <div class="form-field">
                        <p>{{ 'profile-dialog.labels.lastName' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <input tabindex="5" placeholder="{{ 'profile-dialog.placeholders.lastName' | translate }}" matInput formControlName="lastName" required>
                        </mat-form-field>
                        <mat-error *ngIf="getFieldProperties('lastName').isRequired">
                            {{ 'profile-dialog.errors.lastName.required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="getFieldProperties('lastName').hasError">
                            {{ 'profile-dialog.errors.lastName.invalid' | translate }}
                        </mat-error>
                    </div>

                    <div class="form-field">
                        <p>{{ 'profile-dialog.labels.gender' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <mat-select tabindex="3" placeholder="{{ 'profile-dialog.placeholders.gender' | translate }}" formControlName="gender">
                                <mat-option *ngFor="let option of selectGender" [value]="option.value">{{ 'genders.' + option.value | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <mat-error *ngIf="getFieldProperties('gender').isRequired">
                            {{ 'profile-dialog.errors.gender.required' | translate }}
                        </mat-error> -->
                    </div>

                    <div class="form-field">
                        <p>{{ 'profile-dialog.labels.language' | translate }}</p>
                        <mat-form-field subscriptSizing="dynamic">
                            <mat-select tabindex="6" placeholder="{{ 'profile-dialog.placeholders.language' | translate }}" formControlName="language">
                                <mat-option *ngFor="let option of languageOptions" [value]="option.value">{{ option.label }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error *ngIf="getFieldProperties('language').isRequired">
                            {{ 'profile-dialog.errors.language.required' | translate }}
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="footer desktop">
    <mat-divider></mat-divider>
    <div class="btn-container custom-theme">
        <button tabindex="7" mat-stroked-button color="primary" (click)="discard()">{{ 'profile-dialog.buttons.discard' | translate }}</button>
        <button tabindex="8" mat-raised-button color="primary" [disabled]="loading" type="submit" (click)="onSubmit()">
            {{ data ? ('profile-dialog.buttons.editChanges' | translate) : ('profile-dialog.buttons.saveChanges' | translate) }}
        </button>
    </div>
</div>
</div>
