import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[currencyInput]'
})
export class CurrencyDirective {
    commaSeperatedRegEx = /^[0-9]+((?:[,][0-9]{0,2})?)€?$/;

    constructor(public el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if ([8, 9, 13, 27, 46].indexOf(event.keyCode) !== -1 ||
            // Allow: Ctrl+A
            (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+C
            (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+V
            (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+X
            (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
            // Allow: home, end, left, right
            (event.keyCode >= 35 && event.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
        }
        this.validateRegEx(event);
    }

    validateRegEx(event: any) {
        let current: string = this.el.nativeElement.value;
        current = current.replace(/€/g, '');
        let next: string = current.concat(event.key);

        if (next && !String(next).match(this.commaSeperatedRegEx)) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        let pastedInput;
        if (event.clipboardData) {
            pastedInput = event.clipboardData
                .getData('text/plain')
                .match(this.commaSeperatedRegEx);
            if (pastedInput) {
                pastedInput = pastedInput.join('');
            }
            document.execCommand('insertText', false, pastedInput || '');
        }
    }

    @HostListener('blur', ['$event'])
    onBlur(event: Event) {
        let currentValue: string = (event.target as HTMLInputElement).value;
        currentValue = currentValue.replace(/,(\D*)$/, '$1');
        (event.target as HTMLInputElement).value = currentValue;
    }

    @HostListener('input', ['$event'])
    onInput(event: InputEvent) {
        const inputElement = event.target as HTMLInputElement;
        let trimmedValue = inputElement.value.trim();

        // trimmedValue = trimmedValue.replace(/€/g, '');

        if (trimmedValue.startsWith(',')) {
            inputElement.value = '';
            return;
        }

        if (!trimmedValue.match(this.commaSeperatedRegEx)) {
            inputElement.value = '';
            return;
        }

        if (trimmedValue.startsWith('0') && /^\d/.test(trimmedValue.substring(1))) {
            trimmedValue = trimmedValue.replace(/^0(\d)/, '0,$1');
        }

        inputElement.value = trimmedValue;
    }

}
