import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-notify-lesson',
  templateUrl: './notify-lesson.component.html',
  styleUrls: ['./notify-lesson.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotifyLessonComponent {
    @Input() avatar: string;
    @Input() description: string;
    @Input() date?: string | Date;
}
