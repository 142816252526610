import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Step } from '../../../../../../../shared/constants/form-option';
import { MatChipListboxChange } from '@angular/material/chips';

@Component({
  selector: 'app-step-area-teacher',
  templateUrl: './step-area-teacher.component.html',
  styleUrls: ['./step-area-teacher.component.scss']
})
export class StepAreaTeacherComponent implements OnInit {
    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Output() submitForm = new EventEmitter<void>();
    @Input() stepper: MatStepper;

    get priorKnowledge() {
        return this.formGroup.get('teachingLocation');
    }

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        if (this.formGroup && Object.keys(this.formGroup?.controls)?.length === 0) {
            this.addControls();
        }
    }

    addControls(): void {
        this.formGroup.addControl('teachingLocation', this.fb.control('', Validators.required));
    }

    onRoleSelectionChange(event: MatChipListboxChange): void {
        const selectedRole = event.source.value;
        if (selectedRole.includes('no-preference')) {
            this.formGroup.get('teachingLocation')?.setValue(['no-preference']);
            this.submitForm.emit();
            this.stepper.next();
            return;
        }
        this.formGroup.get('teachingLocation')?.setValue(selectedRole);
        this.submitForm.emit();
    }

    onSubmit() {
        this.stepper.next();
    }
}
