<header>
  <div class="container">
    <div class="header-wrap">
      <div class="header-logo" [ngClass]="{'header-mobile-white-logo': mobileWhiteLogo}" (click)="handleClick()">
      </div>
      <div *ngIf="!hideBetaProgram" class="header-title">
        Beta Program
      </div>
    </div>
  </div>
</header>
