import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFieldProperties } from '../../../../../../../shared/validators/has-error';
import { Subject, takeUntil } from 'rxjs';
import { Step } from '../../../../../../../shared/constants/form-option';

@Component({
  selector: 'app-step-musical-education',
  templateUrl: './step-musical-education.component.html',
  styleUrls: ['./step-musical-education.component.scss']
})
export class StepMusicalEducationComponent implements OnInit, OnDestroy {
    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Output() submitForm = new EventEmitter<void>();
    @Input() stepper: MatStepper;
    protected readonly getFieldProperties = getFieldProperties;
    destroy$ = new Subject();

    get educationGroup(): any {
        return this.formGroup.get('educationGroup') as FormArray;
    }

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.addControls();
        if (this.educationGroup?.controls?.length === 0) {
            this.addEducationControls();
        }
        this.stepper.selectionChange.pipe(takeUntil(this.destroy$)).subscribe((selection) => {
            if (selection.selectedIndex === 1) {
                this.educationGroup.controls.forEach((formGroup: FormGroup) => {
                    Object.keys(formGroup.controls).forEach((key: string) => {
                        if (key !== 'degree') {
                            formGroup.get(key)?.setValidators(Validators.required);
                        }
                        formGroup.get(key)?.updateValueAndValidity();
                        formGroup.get(key)?.markAsUntouched();
                        formGroup.get(key)?.markAsPristine();
                    });
                });
            }
        });
    }

    onSubmit() {
        this.submitForm.emit();
        this.stepper.next();
    }
    addControls(): void {
        if (this.formGroup && Object.keys(this.formGroup?.controls)?.length === 0) {
            this.formGroup.addControl('educationGroup', this.fb.array([]));
        }
    }

    addEducationControls() {
        const control = this.fb.group({
            institution: ['', Validators.required],
            degree: ['']
        });
        this.educationGroup.push(control);
    }


    removeEducation(index: number) {
        this.educationGroup.removeAt(index);
    }

    next() {
        this.onSubmit();
    }

    skip() {
        while (this.educationGroup.controls.length > 1) {
            this.removeEducation(this.educationGroup.controls.length - 1);
        }
        this.educationGroup.controls.forEach((formGroup: FormGroup) => {
            Object.keys(formGroup.controls).forEach((key: string) => {
                formGroup.get(key)?.setValue('');
                formGroup.get(key)?.clearValidators();
                formGroup.get(key)?.updateValueAndValidity();
            });
        });

        this.onSubmit();
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
    }
}
