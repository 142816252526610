import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IntegratedTeacher } from '../../../services/api/api.type';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';

@Component({
  selector: 'app-edit-settings',
  templateUrl: './edit-settings.component.html',
  styleUrls: ['./edit-settings.component.scss']
})
export class EditSettingsComponent implements OnInit {
    get placeholderLink(): string {
        if (this.data?.integration.website?.includes('digital')) {
            return 'beta.digital-stage.net/join/1234';
        }
        if (this.data?.integration.website?.includes('sirius')) {
            return 'app.sirius.video/1234';
        }
        return '';
    }

    settingsFormGroup: FormGroup;
    loading = false;
    constructor(@Inject(MAT_DIALOG_DATA) public data: IntegratedTeacher & { teacherId: string },
                public dialogRef: MatDialogRef<EditSettingsComponent>,
                private _formBuilder: FormBuilder,
                private apiService: ApiService) {
    }

    ngOnInit() {
        this.settingsFormGroup = this._formBuilder.group({link: [this.data.personalLink, Validators.required]});
    }

    closeDialog() {
        this.dialogRef.close();
    }

    updateTeacherIntegration() {
        this.loading = true;
        const personalLink = this.settingsFormGroup.get('link')?.value;
        this.apiService.updateTeacherIntegration(this.data.id, personalLink).subscribe(() => {
            this.dialogRef.close(true);
        }, () => {
            this.loading = false;
        });
    }

    deactivate() {
        this.loading = true;
        this.apiService.deleteTeacherIntegration(this.data.id).subscribe(() => {
            this.dialogRef.close(true);
        }, () => {
            this.loading = false;
        });
    }


}
