import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { schoolId } from '../../../../../shared/constants/table-data';
import { UserRoles } from '../../../../../core/models/user-info.interface';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DashboardTableService } from '../../../../services/dashboard-table.service';
import { ApiService } from '../../../../services/api/api.service';
import { SchoolItem, SchoolListResponse, SocialsItem, Student } from '../../../../services/api/api.type';
import { OrganizationFormComponent } from '../../../profile/organization-form/organization-form.component';
import { OrganisationProfileInfoComponent } from '../organisation-profile-info/organisation-profile-info.component';
import { Subject, takeUntil } from 'rxjs';
import { PlatformService } from '../../../../services/platform.service';
import { ComponentType } from '@angular/cdk/overlay';
import { Sort } from '@angular/material/sort';
import { DeleteConfirmComponent } from '../../delete-confirm/delete-confirm.component';
import { TranslateService } from '@ngx-translate/core';


export interface SchoolApproveEvent<T> {
    organisationId: string;
    validated: boolean;
    modal?: T;
}

@Component({
  selector: 'app-organisation-dashboard-table',
  templateUrl: './organisation-dashboard-table.component.html',
  styleUrls: ['./organisation-dashboard-table.component.scss']
})
export class OrganisationDashboardTableComponent implements OnInit, AfterViewInit, OnDestroy {
    displayedColumns: string[] = [ 'name', 'location', 'teachers', 'signUpDate', 'socialMedia', 'association' ,'approvalStatus', 'actions', 'profile'];
    dataSource: MatTableDataSource<SchoolItem>;
    protected readonly schoolId = schoolId;
    currentIndex: number;
    dialogRef: MatDialogRef<(OrganizationFormComponent | OrganisationProfileInfoComponent)>;
    selectedSchoolId: string;
    destroy$ = new Subject();
    sort: Sort;

    pageSize = 10;
    currentPage = 0;


    @Input() role: UserRoles;
    @Input() organisations: SchoolListResponse;
    @Output() onApprove: EventEmitter<SchoolApproveEvent<OrganisationProfileInfoComponent>> = new EventEmitter<SchoolApproveEvent<OrganisationProfileInfoComponent>>();
    @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(MatPaginator) paginator: MatPaginator;

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    get paginatedData(): SchoolItem[] {
        const startIndex = this.currentPage * this.pageSize;
        const endIndex = startIndex + 10;
        return this.dataSource.data.slice(startIndex, endIndex);
    }

    constructor(private dialog: MatDialog,
                private dashboardTableService: DashboardTableService,
                private platformService: PlatformService,
                private translateService: TranslateService,
                private apiService: ApiService) {
    }

    ngOnInit() {
        this.dataSource = new MatTableDataSource<SchoolItem>(this.organisations?.list);
        this.dashboardTableService.needUpdateOrganisationTable.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.dataSource = new MatTableDataSource<SchoolItem>(this.organisations?.list);
            this.dataSource._updateChangeSubscription();
            this.dataSource.paginator = this.paginator;
        });

        this.dashboardTableService.updateSchoolProfile.pipe(takeUntil(this.destroy$)).subscribe((edit) => {
            if (!edit) {
                this.getOrganisations(true);
            }
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    addOrEditOrganisation(isAdd: boolean, organisationData?: SchoolItem) {
        if (isAdd) {
            this.openDialog(OrganizationFormComponent, 'organisation-form');
        } else {
            this.openDialog(OrganizationFormComponent, 'organisation-form', organisationData);
        }
    }

    openDialog(component: ComponentType<(OrganizationFormComponent | OrganisationProfileInfoComponent)>, panelClass: string, dialogData?: SchoolItem | Student, width = '800px'): void {
        document.body.classList.add('modal-open');
        let dialogRef: MatDialogRef<(OrganizationFormComponent | OrganisationProfileInfoComponent)>;
        if (this.platformService.isMobile) {
            dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                height: '95vh',
                maxWidth: '100vw',
                disableClose: true,
                position: { top: '48px' },
                data: dialogData
            });
        } else {
            dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                autoFocus: false,
                disableClose: false,
                position: { top: '32px' },
                data: dialogData
            });
        }
        dialogRef.afterClosed().subscribe((result) => {
            document.body.classList.remove('modal-open');
            if (result) {
                this.getOrganisations();
            }
            console.log('Dialog closed result', result);
        });
        if (panelClass === 'school-profile-info') {
            this.dialogRef = dialogRef;
            const modalComponentInstance = this.dialogRef.componentInstance as OrganisationProfileInfoComponent;
            modalComponentInstance.organisationUpdated.subscribe((id) => this.selectedSchoolId = id);
            modalComponentInstance.onSchoolApprove.subscribe((data) => {
                this.onApprove.emit({...data, modal : modalComponentInstance});
            });
        }
    }

    handleApprove(organisationId: string, validated: boolean) {
        validated = !validated;
        this.onApprove.emit({organisationId, validated})
    }


    getOrganisations(updateProfile = false) {
        this.apiService.getOrganisations( '1', '1000').subscribe(
            (organisations: SchoolListResponse) => {
                this.organisations.list.length = 0;
                organisations.list.forEach((item) => this.organisations?.list.push(item));
                this.dataSource = new MatTableDataSource<SchoolItem>(this.organisations?.list);
                this.dataSource._updateChangeSubscription();
                this.dataSource.paginator = this.paginator;
                if (updateProfile && this.selectedSchoolId) {
                    const selectedSchool = this.organisations.list.find((school: SchoolItem) => school.id === this.selectedSchoolId);
                    if (selectedSchool) {
                        const modalComponentInstance = this.dialogRef.componentInstance as OrganisationProfileInfoComponent;
                        modalComponentInstance.setSchool(selectedSchool);
                        modalComponentInstance.setImage();
                    }
                }
            });
    }


    getSortedOrganisations(sortBy: string, direction: string) {
        this.apiService.getSortedOrganisations( '1', '1000', sortBy, direction.toUpperCase()).subscribe(
            (organisations: SchoolListResponse) => {
                this.organisations.list.length = 0;
                organisations.list.forEach((item) => this.organisations?.list.push(item));
                this.dataSource = new MatTableDataSource<SchoolItem>(this.organisations?.list);
                this.dataSource._updateChangeSubscription();
                this.dataSource.paginator = this.paginator;
            });
    }


    formatSocials(socials: SocialsItem[]): string {
        return socials && socials.length > 0 ? socials.map(social => `${social.type}: ${social.link}`).join(', ') : '—';
    }

    redirectToLink(link: string): void {
        window.open(link, '_blank');
    }

    onPageChange(event: PageEvent) {
        this.currentIndex = event.pageIndex * event.pageSize;
        this.currentPage = event.pageIndex;
    }

    showProfile(schoolItem?: SchoolItem) {
        this.openDialog(OrganisationProfileInfoComponent, 'school-profile-info', schoolItem, '640px');
    }

    closeDialog() {
        this.closeModal.emit();
    }

    sortData(sort: Sort) {
        this.sort = sort;
        if (!sort.active || sort.direction === '') {
            this.getOrganisations();
            return;
        }
        this.getSortedOrganisations(sort.active, sort.direction);
    }

    deleteOrganisation(key: string, item: SchoolItem) {
        const title = this.translateService.instant(key);
        const type = this.translateService.instant('confirm_delete.organisation_type');
        const description = this.translateService.instant('confirm_delete.org_description');
        this.openDeleteConfirmDialog(DeleteConfirmComponent, 'delete-confirm', { id: item.id, title, name: item.name, type, description });
    }

    openDeleteConfirmDialog(component: ComponentType<DeleteConfirmComponent>, panelClass: string, dialogData?: { id: string, title: string, name: string, type: string, description: string }): void {
        document.body.classList.add('modal-open');
        const dialogRef = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width: '480px',
            autoFocus: false,
            disableClose: true,
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(schoolId => {
            document.body.classList.remove('modal-open');
            if (schoolId) {
                this.apiService.deleteOrganisation(schoolId).subscribe(() => {
                    if (this.sort) {
                        this.getSortedOrganisations(this.sort.active, this.sort.direction);
                    } else {
                        this.getOrganisations();
                    }
                });
            }
            console.log('Dialog closed result', schoolId);
        });
    }

}
