<div  *ngIf="!isMobilePlatform" class="title-container" [ngClass]="{'hide-empty-dashboard-line': teachers?.list?.length === 0}">
    <div class="title">
        <span>{{ 'teacher-dashboard-table.title.teachers' | translate }}</span>
    </div>
    <div class="btn-container custom-theme">
        <div>
            <button mat-button [ngStyle]="{ opacity: role === UserRoles.Organisation && !hasConfirmedAndValidated ? 0.3 : 1 }" [disabled]="role === UserRoles.Organisation && !hasConfirmedAndValidated" (click)="addOrEditTeacher(true)">{{ 'teacher-dashboard-table.button.addTeacher' | translate }}</button>
        </div>
        <button [disabled]="role !== UserRoles.Organisation || copied" [ngStyle]="{ opacity: role !== UserRoles.Organisation ? 0.3 : 1}" mat-stroked-button (click)="copyInviteUrl()">
            {{ !copied ? ('teacher-dashboard-table.button.copy-invite-link' | translate) : ('teacher-dashboard-table.button.copied' | translate) }}
        </button>
    </div>
</div>
<div *ngIf="teachers?.list && teachers.list.length > 0 && !isMobilePlatform; else emptyDesktopContainer" class="table-container">
    <table matSort (matSortChange)="sortData($event)" mat-table [dataSource]="dataSource">

        <!-- First Name Column -->
        <ng-container matColumnDef="firstName" sticky>
            <th mat-sort-header="firstName" mat-header-cell *matHeaderCellDef>{{ 'teacher-dashboard-table.header.first-name' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
        </ng-container>

        <!-- last Name Column -->
        <ng-container matColumnDef="lastName" sticky>
            <th mat-sort-header="lastName" mat-header-cell *matHeaderCellDef> {{ 'teacher-dashboard-table.header.last-name' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
        </ng-container>

        <!-- Email Address Column -->
        <ng-container matColumnDef="email">
            <th mat-sort-header="email" mat-header-cell *matHeaderCellDef> {{ 'teacher-dashboard-table.header.email-address' | translate }} </th>
            <td mat-cell *matCellDef="let element">
                <a target="_blank" class="menu-link" href="mailto:{{ element.email }}">{{ element.email }}</a>
            </td>
        </ng-container>

        <!-- Sign-up Date Column -->
        <ng-container matColumnDef="signUpDate">
            <th mat-sort-header="created" mat-header-cell *matHeaderCellDef> {{ 'school-dashboard-table.header.sign-up.date' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element?.created ? (element?.created | formatLessonStartTime) : '—'}} </td>
        </ng-container>

        <!-- Instruments Address Column -->
        <ng-container matColumnDef="instruments">
            <th mat-header-cell *matHeaderCellDef> {{ 'teacher-dashboard-table.header.instruments' | translate }} </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.teacher?.instruments">
                      <span *ngFor="let instrument of element.teacher?.instruments; let last = last">
                         {{ 'instrument_translations.' + instrument | translate }}
                          <span *ngIf="!last">, </span>
                     </span>
                </ng-container>
                <span *ngIf="!element.teacher?.instruments">—</span>
            </td>
        </ng-container>

        <!-- Genres Column -->
        <ng-container matColumnDef="genres">
            <th mat-header-cell *matHeaderCellDef>{{ 'teacher-dashboard-table.header.genres' | translate }}</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.teacher?.genres">
                     <span *ngFor="let genre of element.teacher?.genres; let last = last">
                        {{ 'genre_translations.' + genre | translate }}
                         <span *ngIf="!last">, </span>
                    </span>
                </ng-container>
                <span *ngIf="!element.teacher?.genres">—</span>
            </td>
        </ng-container>

        <!-- Students Column -->
        <ng-container matColumnDef="students">
            <th mat-header-cell *matHeaderCellDef>{{ 'teacher-dashboard-table.header.students' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.teacher?.studentsAmount ? element.teacher?.studentsAmount : 0}} </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef>{{ 'teacher-dashboard-table.header.actions' | translate }}</th>
            <td mat-cell *matCellDef="let element; let i = index">
             <div class="actions-container">
                    <button *ngIf="!teachers.list[!this.currentIndex ? i : this.currentIndex + i].teacher?.validated" class="raised-button" color="primary" mat-raised-button (click)="handleApprove(element.teacher.id, teachers.list[!this.currentIndex ? i : this.currentIndex + i].teacher.validated)">
                        {{ 'teacher-dashboard-table.button.approve' | translate }}
                    </button>

                    <button *ngIf="teachers.list[!this.currentIndex ? i : this.currentIndex + i].teacher?.validated" mat-button (click)="handleApprove(element.teacher.id, teachers.list[!this.currentIndex ? i : this.currentIndex + i].teacher.validated)">
                        {{ 'teacher-dashboard-table.button.disapprove' | translate }}
                    </button>
               </div>
            </td>
        </ng-container>


<!--  <ng-container matColumnDef="edit" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index" #td>
                <div class="actions-container">
                    <button mat-button (click)="addOrEditTeacher(false, teachers?.list[!this.currentIndex ? i : this.currentIndex + i])">{{ 'teacher-dashboard-table.button.edit' | translate }}</button>
                </div>
            </td>
        </ng-container> -->

        <ng-container matColumnDef="profile" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index" #td>
                <div class="actions-container">
                    <button mat-button (click)="showProfile(teachers.list[!this.currentIndex ? i : this.currentIndex + i])">{{ 'teacher-dashboard-table.button.showProfile' | translate }}</button>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<div class="mobile-table" *ngIf="teachers?.list && teachers.list.length > 0 && isMobilePlatform; else emptyMobileContainer">
    <ng-container *ngIf="isMobilePlatform">
        <div class="toolbar-wrapper">
            <mat-toolbar class="toolbar-form">
                <div class="left-side">
                    <button mat-icon-button class="example-icon" (click)="closeDialog()">
                        <mat-icon class="close-icon">chevron_left</mat-icon>
                    </button>
                    <span>{{ 'statistics.teachers' | translate }}</span>
                </div>
                <div class="more-container" *ngIf="role !== UserRoles.Student">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                                [ngStyle]="{ opacity: role === UserRoles.Organisation && !hasConfirmedAndValidated ? 0.3 : 1 }"
                                [disabled]="role === UserRoles.Organisation && !hasConfirmedAndValidated"
                                (click)="addOrEditTeacher(true)">{{ 'teacher-dashboard-table.button.addTeacher' | translate }}
                        </button>
                        <mat-divider></mat-divider>
                        <button mat-menu-item [disabled]="role !== UserRoles.Organisation || copied"
                                [ngStyle]="{ opacity: role !== UserRoles.Organisation ? 0.3 : 1}"
                                (click)="copyInviteUrl()">
                            {{ !copied ? ('teacher-dashboard-table.button.copy-invite-link' | translate) : ('teacher-dashboard-table.button.copied' | translate) }}
                        </button>
                    </mat-menu>
                </div>
            </mat-toolbar>
            <mat-divider></mat-divider>
        </div>
    </ng-container>
    <mat-list role="list">
        <ng-container *ngFor="let item of paginatedData; let i = index">
            <a mat-list-item matRipple (click)="showProfile(teachers.list[!this.currentIndex ? i : this.currentIndex + i])">
                <div class="list-wrapper">
                    <div class="logo-container">
                        <img
                            src="{{ item.files?.[0]?.url ? item.files?.[0]?.url : '/assets/images/profile/unknown.jpg' }}"
                            alt="Logo" class="logo">
                    </div>
                    <div class="text-container">
                        <span class="name">{{ item?.firstName }} {{ item?.lastName ? item?.lastName : ''}}</span>
                        <ng-container *ngIf="item.teacher?.instruments">
                      <span class="instrument">
                          <ng-container *ngFor="let instrument of item.teacher?.instruments; let last = last">
                         {{ 'instrument_translations.' + instrument | translate }}
                          <span *ngIf="!last">, </span>
                        </ng-container>
                     </span>
                        </ng-container>
                        <span *ngIf="!item.teacher?.instruments">—</span>
                    </div>
                </div>
            </a>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-list>
</div>
<ng-template #emptyDesktopContainer>
    <div *ngIf="!isMobilePlatform" class="empty-container">
        {{ 'teacher-dashboard-table.message.noDataAvailable' | translate }}
    </div>
</ng-template>

<ng-template #emptyMobileContainer>
    <ng-container *ngIf="isMobilePlatform">
        <mat-toolbar class="toolbar-form">
            <div class="left-side">
                <button mat-icon-button class="example-icon" (click)="closeDialog()">
                    <mat-icon class="close-icon">chevron_left</mat-icon>
                </button>
                <span>{{'statistics.teachers' | translate }}</span>
            </div>
            <div class="more-container">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item [ngStyle]="{ opacity: role === UserRoles.Organisation && !hasConfirmedAndValidated ? 0.3 : 1 }" [disabled]="role === UserRoles.Organisation && !hasConfirmedAndValidated" (click)="addOrEditTeacher(true)">{{ 'teacher-dashboard-table.button.addTeacher' | translate }}</button>
                    <mat-divider></mat-divider>
                    <button mat-menu-item [disabled]="role !== UserRoles.Organisation || copied" [ngStyle]="{ opacity: role !== UserRoles.Organisation ? 0.3 : 1}" (click)="copyInviteUrl()">
                        {{ !copied ? ('teacher-dashboard-table.button.copy-invite-link' | translate) : ('teacher-dashboard-table.button.copied' | translate) }}
                    </button>
                    <mat-divider></mat-divider>
                </mat-menu>
            </div>
        </mat-toolbar>
        <mat-divider></mat-divider>
        <div class="empty-container">
            {{ 'teacher-dashboard-table.message.noDataAvailable' | translate }}
        </div>
    </ng-container>
</ng-template>

<mat-paginator  *ngIf="!isMobilePlatform" [ngClass]="{'empty-paginator': dataSource && dataSource.data && dataSource.data.length < 10, 'hide-empty-dashboard-line': teachers?.list?.length === 0 }" [pageSizeOptions]="[10, 20, 30]"
               showFirstLastButtons
               (page)="onPageChange($event)">
</mat-paginator>

<div *ngIf="isMobilePlatform && teachers?.list && teachers.list.length > 0" class="paginator-wrapper">
    <mat-paginator
        [pageSize]="10"
        (page)="onPageChange($event)">
    </mat-paginator>
</div>
