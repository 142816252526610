import { Injectable } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ENGLISH, GERMAN, ISO_CONVERTER, ISO_CONVERTER_MAPPED } from '../constants/localstorage-constants';
import { TranslateCacheService } from 'ngx-translate-cache';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {
    selectedLanguage: string;
    selectedLangId: string;
    private destroy$: Subject<null> = new Subject();

    get language() {
        return ISO_CONVERTER_MAPPED[this.selectedLanguage as keyof typeof ISO_CONVERTER_MAPPED]
    }

    constructor(public translateService: TranslateService, private translateCacheService: TranslateCacheService) {
        this.translateService.addLangs([ENGLISH, GERMAN]);

        /** FYI according to ISO 639-1 German code is 'de', BUT according to ISO 639-2 its 'deu'
         * FYI according to ISO 639-1 English code is 'eng', BUT according to ISO 639-2 its 'eng'
         * Chrome currently uses kind of ISO-1, but you never know when it might change
         * Our back-end uses ISO-2
         */

            // Detect user browser default language
        this.selectedLanguage = GERMAN;

        // Check if the language code is one of the valid ISO 639-1 codes
        const localStorageLanguage = localStorage['language'];
        // @ts-ignore
        if (ISO_CONVERTER[localStorageLanguage]) {
            // @ts-ignore
            this.selectedLanguage = localStorage['language'] = ISO_CONVERTER[localStorageLanguage];
        } else if ([ENGLISH, GERMAN].includes(localStorage['language'])) { // Check if the language code is one of the valid ISO 639-2 codes
            this.selectedLanguage = localStorage['language'];
        } else {
            for (const language of navigator.languages) {
                // Language is usually like en_US or en_GB (in Chrome)
                const languageCode = language.slice(0, 2).toLocaleLowerCase();
                // @ts-ignore
                if (ISO_CONVERTER[languageCode] === GERMAN) {
                    this.selectedLanguage = GERMAN;  // Our json files are named according to ISO 639-2
                    break;
                }
            }
            localStorage.setItem('language', this.selectedLanguage);
        }

        this.translateService.onLangChange
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: LangChangeEvent) => {
                let langIso = '';

                if (event.lang === ENGLISH) {
                    langIso = 'en';
                } else if (event.lang === GERMAN) {
                    langIso = 'de';
                }
                // Add lang attribute to html element
                document.documentElement.lang = langIso;
            });

        this.translateService.setDefaultLang(this.selectedLanguage);
        this.translateService.use(this.selectedLanguage);
        this.translateService.getBrowserLang = () => this.selectedLanguage;
        this.translateCacheService.init();
    }

    change(selectedLanguage: keyof typeof ISO_CONVERTER): Promise<void | null> {
        const languageIso = ISO_CONVERTER[selectedLanguage];
        return this.translateService.use(languageIso).toPromise()
            .then(() => {
                this.selectedLanguage = languageIso;
                localStorage.setItem('language', languageIso);
                return null;
            });
    }

}
