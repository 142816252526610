import { Injectable } from '@angular/core';
import { TeacherProfileInfoComponent } from '../../music/components/dashboard/teacher-dashboard/teacher-profile-info/teacher-profile-info.component';
import { Teacher } from '../../music/services/api/api.type';

@Injectable({
  providedIn: 'root'
})
export class TeacherProfileService {

    private teacherProfileComponent: TeacherProfileInfoComponent;

    constructor() { }
    setTeacherProfileComponent(component: TeacherProfileInfoComponent) {
        this.teacherProfileComponent = component;
    }

    updateTeacher(teacher: Teacher) {
        if (this.teacherProfileComponent) {
            this.teacherProfileComponent.setTeacher(teacher);
            this.teacherProfileComponent.setImage();
        }
    }
}
