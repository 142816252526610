<div class="searching-match-container">
    <div class="left-side">
        <div class="header-wrapper">
            <app-header [hideBetaProgram]="true"></app-header>
        </div>
        <div class="searching-match-wrapper">
            <div class="title-container">
                <div class="title-1">{{ 'matching.searching.allSet' | translate }}</div>
                <div class="title-2">{{ 'matching.searching.searchingForMatch' | translate }}</div>
                <app-loading></app-loading>
            </div>
        </div>
    </div>
    <div class="right-side">
        <div class="background-searching">
        </div>
    </div>
</div>
