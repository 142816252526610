<form [formGroup]="formGroup" class="form-wrapper sign-up-form date-time-step">
    <div class="title-container">
        <div class="title-1">{{step.title1 | translate}} {{step.title2 | translate}}</div>
    </div>
    <div class="form-field">
        <mat-grid-list [cols]="isMobilePlatform ? 1 : 7" [rowHeight]="isMobilePlatform ? '370px' : '285px'">
            <mat-grid-tile *ngFor="let chip of step.chips; let i = index">
                <div class="chips-container">
                    <div class="day-container">
                        <div class="weekday">{{ 'days.' + chip.day | translate }}</div>
                        <div class="date">{{chip.date}}</div>
                    </div>
                    <mat-chip-listbox
                        formArrayName="availabilityTimeStamp"
                        class="mat-mdc-chip-set-stacked" multiple>
                        <mat-chip-option class="timeStamp select-all" [selected]="setSelectedAll(availabilityTimeStamp.value[i]?.selectedTime)" (click)="selectAllTimeSlots(i)">{{ 'days.all-day' | translate }}</mat-chip-option>
                        <mat-chip-option [selected]="setSelectedSlot(availabilityTimeStamp.value[i]?.selectedTime, timeSlot)" class="timeStamp" (selectionChange)="onSelectTime($event, i, timeSlot)"
                                         *ngFor="let timeSlot of chip.time"> {{ timeSlot }}</mat-chip-option>
                    </mat-chip-listbox>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
    <div align="end" class="custom-theme btn-container sign-up-form-field">
        <button mat-raised-button [color]="step.btn2Color" [disabled]="!isValidAvailabilityTimeStamp" (click)="onSubmit()">{{step.btnText2 | translate}}</button>
    </div>
</form>
