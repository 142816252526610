import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { MultiStepFormComponent } from './auth/sign-up/multi-step-form/multi-step-form.component';
import { MatStepperComponent } from './auth/sign-up/mat-stepper/mat-stepper.component';
import { StepPriorKnowledgeComponent } from './auth/sign-up/multi-step-form/student-sign-up/step-prior-knowledge/step-prior-knowledge.component';
import { MatStepperModule } from '@angular/material/stepper';
import { SignUpRoutingModule } from './sign-up-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { StepTeachingLocationComponent } from './auth/sign-up/multi-step-form/student-sign-up/step-teaching-location/step-teaching-location.component';
import { StepStudentPreferenceComponent } from './auth/sign-up/multi-step-form/student-sign-up/step-student-preference/step-student-preference.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { WelcomePopupComponent } from './auth/sign-up/welcome-popup/welcome-popup.component';
import { StepSocialMediaComponent } from './auth/sign-up/multi-step-form/teacher-sign-up/step-social-media/step-social-media.component';
import { StepMusicalEducationComponent } from './auth/sign-up/multi-step-form/teacher-sign-up/step-musical-education/step-musical-education.component';
import { StepPreferStudentPreferenceComponent } from './auth/sign-up/multi-step-form/teacher-sign-up/step-prefer-student-preference/step-prefer-student-preference.component';
import { StepAreaTeacherComponent } from './auth/sign-up/multi-step-form/teacher-sign-up/step-area-teacher/step-area-teacher.component';
import { StepInstrumentComponent } from './auth/sign-up/multi-step-form/shared/components/step-instrument/step-instrument.component';
import { StepNameComponent } from './auth/sign-up/multi-step-form/shared/components/step-name/step-name.component';
import { StepGenresComponent } from './auth/sign-up/multi-step-form/shared/components/step-genres/step-genres.component';
import { StepContactComponent } from './auth/sign-up/multi-step-form/shared/components/step-contact/step-contact.component';
import { StepAgeGroupComponent } from './auth/sign-up/multi-step-form/shared/components/step-age-group/step-age-group.component';
import { StepLanguageComponent } from './auth/sign-up/multi-step-form/shared/components/step-language/step-language.component';
import { StepAreaComponent } from './auth/sign-up/multi-step-form/shared/components/step-area/step-area.component';
import { StepDateAndTimeComponent } from './auth/sign-up/multi-step-form/shared/components/step-date-and-time/step-date-and-time.component';
import { StepPersonalGoalsComponent } from './auth/sign-up/multi-step-form/shared/components/step-personal-goals/step-personal-goals.component';
import { StepAdditionalInfoComponent } from './auth/sign-up/multi-step-form/shared/components/step-additional-info/step-additional-info.component';
import { OrganisationSignUpComponent } from './auth/sign-up/multi-step-form/organisation-sign-up/organisation-sign-up.component';
import { ChoosePasswordComponent } from './auth/choose-password/choose-password.component';

@NgModule({
    declarations: [
        SignUpComponent,
        MultiStepFormComponent,
        MatStepperComponent,
        StepInstrumentComponent,
        StepNameComponent,
        StepGenresComponent,
        StepContactComponent,
        StepPriorKnowledgeComponent,
        StepAgeGroupComponent,
        StepTeachingLocationComponent,
        StepLanguageComponent,
        StepAreaComponent,
        StepDateAndTimeComponent,
        StepPersonalGoalsComponent,
        StepStudentPreferenceComponent,
        StepAdditionalInfoComponent,
        WelcomePopupComponent,
        StepSocialMediaComponent,
        StepMusicalEducationComponent,
        StepPreferStudentPreferenceComponent,
        StepAreaTeacherComponent,
        OrganisationSignUpComponent,
        ChoosePasswordComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        SignUpRoutingModule,
        MatButtonModule,
        MatListModule,
        MatCheckboxModule,
        MatExpansionModule,
        TranslateModule,
        MatRadioModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatIconModule,
        MatListModule,
        MatDialogModule,
        MatToolbarModule,
        MatGridListModule,
        MatChipsModule,
        MatTableModule,
        MatPaginatorModule,
        MatStepperModule,
        SharedModule,
        MatDatepickerModule
    ]
})
export class SignUpModule {
}
