import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
    transform(value: string | string[]): string {
        if (!value) return '';

        if (Array.isArray(value)) {
            return value.map(word => this.capitalizeWord(word)).join(', ');
        } else {
            return this.capitalizeWord(value);
        }
    }

    private capitalizeWord(word: string): string {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }
}
