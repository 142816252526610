import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { JwtHelper } from '../../../../shared/helpers/jwt/jwt.helper';
import { UserInfo, UserLoginCredentials, UserRoles } from '../../../../core/models/user-info.interface';
import { UserService } from '../../../services/user/user.service';
import { EMAIL_VALIDATION_REG } from '../../../../shared/constants/form-option';
import { ISO_CONVERTER } from '../../../../shared/constants/localstorage-constants';
import { LanguageService } from '../../../../shared/services/language.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    userInfo: UserInfo;
    emailErrorMessage: string = '';
    passErrorMessage: string = '';


    private _showResetPassword$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showResetPassword$: Observable<boolean> = this._showResetPassword$.asObservable();
    public successfullyLoggedOut$: Observable<boolean> = this.auth._successfullyLoggedOut$.asObservable();

    get selectedLanguage() {
        return this.languageService.language as keyof typeof ISO_CONVERTER;
    }

    get chosenLanguage() {
        return this.languageService.language === 'EN' ? 'DE' : 'EN';
    }

    constructor(
        private fb: FormBuilder,
        private auth: AuthenticationService,
        private userService: UserService,
        private languageService: LanguageService,
        private router: Router) {}

    ngOnInit(): void {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.pattern(EMAIL_VALIDATION_REG)]],
            password: ['', Validators.required]
        });
    }

    getFieldProperties<T>(fieldName: string, controlField?:AbstractControl<T> | null): {
        control: AbstractControl<any> | null;
        hasError: boolean;
        isRequired: boolean;
        hasAllErrors: boolean;
    } {
        let control: AbstractControl<any> | null;
        if (controlField) {
            control = controlField.get(fieldName);
        } else {
            control = this.loginForm.get(fieldName);
        }
        const isRequired = !!((control?.hasError('required') && control?.dirty) || (control?.hasError('required') && control?.touched));
        const hasError = !!((control?.errors && !control?.hasError('required') && control?.dirty) || (control?.errors && !control?.hasError('required') && control?.touched));
        const hasAllErrors = isRequired || hasError;
        return { control, isRequired, hasError, hasAllErrors } ;
    }
    onSubmit() {
        this.loginForm.markAllAsTouched();
        if (this.loginForm.valid) {
            const userData: UserLoginCredentials = {
                email: this.loginForm.get('email')?.value,
                password:  this.loginForm.get('password')?.value
            }
            this.auth.logIn(userData).subscribe((data: { accessToken: string, refreshToken: string }) => {
                this.userInfo = JwtHelper.decode(data.accessToken);
                this.userService.userInfo = this.userInfo;
                this.auth.saveTokens(data.accessToken, data.refreshToken);
                if (Object.values(UserRoles).includes(this.userInfo.role as UserRoles)) {
                    // types  student, teacher, school_admin, root
                    this.router.navigate(['/dashboard']);
                } else {
                    console.log('Invalid user role');
                }
            }, error => {
                if (error?.error?.error === 'Bad Request') {
                    this.passErrorMessage = 'password.invalid';
                }

                if (error?.error?.error === 'Not Found') {
                    this.emailErrorMessage = 'email.invalid';
                }

            });
        }
    }

    forgotPassword() {
        this._showResetPassword$.next(true);
    }

    onPasswordResetCancel() {
        this._showResetPassword$.next(false);
    }

    signUp() {
        this.router.navigate(['/sign-up']);
    }

    togglePasswordType(event: MouseEvent, passwordInput: HTMLInputElement, id: number): void {
        event.preventDefault();
        passwordInput.classList.toggle('input-password-mask');
        const crossOutLine = document.querySelector(`.cross-out-${id}`);
        if (crossOutLine) {
            crossOutLine.classList.toggle(`hide-${id}`);
        }
    }

    onFocus() {
        this.emailErrorMessage = '';
        this.passErrorMessage = '';
    }

    changeLanguage() {
        let selectedLanguage: keyof typeof ISO_CONVERTER;

        if (this.selectedLanguage.toLocaleLowerCase() === 'en') {
            selectedLanguage = 'de';
        } else {
            selectedLanguage = 'en';
        }

        this.languageService.change(selectedLanguage).then();
    }

}
