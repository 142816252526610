import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationFormComponent } from '../profile/organization-form/organization-form.component';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMAIL_VALIDATION_REG, LANGUAGES, SINGLE_GENDER } from '../../../shared/constants/form-option';
import { ApiService } from '../../services/api/api.service';
import { UserService } from '../../services/user/user.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { PlatformService } from '../../services/platform.service';
import { UserResponse, UsersFormBody } from '../../services/api/api.type';

interface ProfileFormValues {
    firstName: string;
    lastName: string;
    email: string;
    gender?: string;
    language: string;
}

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
    profileForm: FormGroup;
    originalFormValues: ProfileFormValues;
    loading = false;

    selectGender = SINGLE_GENDER;

    languageOptions = LANGUAGES;
    private destroy$: Subject<void> = new Subject<void>();

    get email() {
        return this.profileForm.get('email');
    }

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }


    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<OrganizationFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UserResponse,
        private cd: ChangeDetectorRef,
        private apiService: ApiService,
        private platformService: PlatformService,
        private userService: UserService) {}

    ngOnInit(): void {
        this.profileForm = this.fb.group({
            firstName: [this.data?.firstName, [Validators.required]],
            lastName: [this.data?.lastName, [Validators.required]],
            email: [this.data?.email,  [Validators.required, Validators.pattern(EMAIL_VALIDATION_REG)]],
            gender: [this.data?.gender],
            language: [this.data?.language, [Validators.required]]
        });

        this.originalFormValues = { ...this.profileForm.value } as ProfileFormValues;
        this.email?.valueChanges.pipe(
            debounceTime(300),
            takeUntil(this.destroy$)
        ).subscribe((value) => {
            this.checkIfUserExist(value);
        });
    }

    getFieldProperties<T>(fieldName: string, controlField?:AbstractControl<T> | null): {
        control: AbstractControl<any> | null;
        hasError: boolean;
        isRequired: boolean;
        hasAllErrors: boolean;
    } {
        let control: AbstractControl<any> | null;
        if (controlField) {
            control = controlField.get(fieldName);
        } else {
            control = this.profileForm.get(fieldName);
        }
        const isRequired = !!((control?.hasError('required') && control?.dirty) || (control?.hasError('required') && control?.touched));
        const hasError = !!((control?.errors && !control?.hasError('required') && control?.dirty) || (control?.errors && !control?.hasError('required') && control?.touched));
        const hasAllErrors = isRequired || hasError;
        return { control, isRequired, hasError, hasAllErrors } ;
    }
    onSubmit() {
        this.profileForm.markAllAsTouched();
        if (this.profileForm.invalid) {
            return;
        }
        this.loading = true;

        const userBody: UsersFormBody = {
            language: this.profileForm.get('language')?.value,
            email: this.profileForm.get('email')?.value,
            firstName: this.profileForm.get('firstName')?.value,
            lastName: this.profileForm.get('lastName')?.value,
            gender: this.profileForm.get('gender')?.value,
        }
        this.apiService.editUser(userBody, this.data.id, true).subscribe(() => {
            this.loading = false;
            this.closeDialog(true);
        }, () => {
            this.loading = false;
        });
    }

    checkIfUserExist(email: string) {
        if (this.email?.valid) {
            if (email === this.data.email) {
                this.email?.setErrors(null);
                return;
            }
            this.apiService.checkIfUserExist(email).subscribe(
                () => {
                    this.email?.setErrors(null);
                },
                () => {
                    this.email?.setErrors({ alreadyExist: true });
                    this.cd.detectChanges();
                }
            );
        }
    }

    discard() {
        this.profileForm.reset(this.originalFormValues);
    }
    closeDialog(update = false) {
        this.destroy$.next();
        this.destroy$.complete();
        this.dialogRef.close(update);
    }

}
