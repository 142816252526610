<div mat-dialog-content class="form-content">
    <div class="btn-wrapper">
    <button mat-icon-button class="example-icon" (click)="cancel()">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    </div>
    <div class="form-title personal-data">{{data?.title}}</div>
    <div class="form-description">{{'confirm_delete.title' | translate: data }} {{data?.description}}</div>
</div>

<div class="footer">
    <div class="btn-container custom-theme">
        <button mat-stroked-button color="primary" (click)="cancel()">{{'confirm_delete.btnCancel' | translate}}</button>
        <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">{{data?.title}}</button>
    </div>
</div>
