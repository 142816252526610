<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{step.title1 | translate}} {{step.title2 | translate}}</div>
    </div>
    <div class="storybook-field">
        <p>{{ 'signup-form.' + signUpRole + '.step-name.form.firstNameLabel' | translate }}</p>
        <mat-form-field subscriptSizing="dynamic">
            <input matInput formControlName="firstName" placeholder="{{ 'signup-form.' + signUpRole + '.step-name.form.firstNamePlaceholder' | translate }}" required>
        </mat-form-field>
        <mat-error *ngIf="getFieldProperties('firstName', formGroup).isRequired">
            {{ 'signup-form.' + signUpRole + '.step-name.form.firstNameRequiredError' | translate }}
        </mat-error>
        <mat-error *ngIf="getFieldProperties('firstName', formGroup).hasError">
            {{ 'signup-form.' + signUpRole + '.step-name.form.firstNameFormatError' | translate }}
        </mat-error>
    </div>
    <div class="storybook-field">
        <p>{{ 'signup-form.' + signUpRole + '.step-name.form.lastNameLabel' | translate }}</p>
        <mat-form-field subscriptSizing="dynamic">
            <input matInput formControlName="lastName" placeholder="{{ 'signup-form.' + signUpRole + '.step-name.form.lastNamePlaceholder' | translate }}" required>
        </mat-form-field>
        <mat-error *ngIf="getFieldProperties('lastName', formGroup).isRequired">
            {{ 'signup-form.' + signUpRole + '.step-name.form.lastNameRequiredError' | translate }}
        </mat-error>
        <mat-error *ngIf="getFieldProperties('lastName', formGroup).hasError">
            {{ 'signup-form.' + signUpRole + '.step-name.form.lastNameFormatError' | translate }}
        </mat-error>
    </div>

    <div align="end" class="custom-theme btn-container storybook-field">
        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">
            {{ 'signup-form.' + signUpRole + '.step-name.btnText2' | translate }}
        </button>
    </div>
</form>
