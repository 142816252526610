<div class="edit-settings-container">
    <mat-toolbar class="toolbar-form">
        <span *ngIf="this.data?.integration?.website?.includes('digital')">Digital Stage {{ 'integration.settings' | translate }}</span>
        <span *ngIf="this.data?.integration?.website?.includes('sirius')">Sirius {{ 'integration.settings' | translate }}</span>
        <span class="example-spacer"></span>
        <button mat-icon-button class="example-icon" (click)="closeDialog()">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </mat-toolbar>

    <div mat-dialog-content class="form-content">
        <div class="wrapper">
            <div class="title">
                {{'integration.paste_link_to_stage_here' | translate}}
            </div>
        <form [formGroup]="settingsFormGroup">
            <div class="form-field">
                <mat-form-field subscriptSizing="dynamic">
                    <input matInput formControlName="link"
                           placeholder="{{'integration.room_id_placeholder' | translate : {placeholderLink: this.placeholderLink} }}">
                </mat-form-field>
            </div>
        </form>
        </div>
        <div class="footer">
            <div class="btn-container custom-theme">
                <div>
                    <button (click)="deactivate()" class="deactivate" color="primary" mat-stroked-button [disabled]="loading">
                        {{ 'integration.deactivate' | translate }}
                    </button>
                </div>
                <div class="right-side">
                    <button mat-button color="primary" (click)="closeDialog()">
                        {{ 'select_upload_image.cancel' | translate }}
                    </button>
                    <button mat-raised-button color="primary" type="submit" [disabled]="settingsFormGroup.invalid || loading"  (click)="updateTeacherIntegration()">
                        {{ 'integration.update_stage_link' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

