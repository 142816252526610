import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatchingTimeSelectComponent, TimeSelect } from '../matching-time-select/matching-time-select.component';
import { ApiService } from '../../../services/api/api.service';
import { Genre, Instrument, Student, Teacher } from '../../../services/api/api.type';
import { TEACHING_LOCATION } from '../../../../shared/constants/form-option';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '../../../services/platform.service';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'app-teacher-card',
  templateUrl: './teacher-card.component.html',
  styleUrls: ['./teacher-card.component.scss']
})
export class TeacherCardComponent implements OnInit {

    @Input() teacher: Teacher;
    @Input() student: Student;
    @Output() matchedConfirm = new EventEmitter();
    image: string | null;
    schoolLogo: string | null;
    schoolName: string | null;

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    constructor(private dialog: MatDialog,
                private apiService: ApiService,
                private translateService: TranslateService,
                private platformService: PlatformService) {
    }

    ngOnInit() {
        this.schoolLogo = this.teacher?.teacher?.school?.files && this.teacher?.teacher?.school?.files.length > 0 ? this.teacher?.teacher?.school?.files[0].url: null;
        this.schoolName = this.teacher?.teacher?.school?.name ? this.teacher?.teacher?.school?.name : null;
        this.image = this.teacher.files?.length > 0 ? this.teacher.files[0].url: null;
    }

    openDialog<T>(component: ComponentType<T>, panelClass: string, dialogData?: TimeSelect): void {
        document.body.classList.add('modal-open');
        let config: MatDialogConfig;
        if (this.isMobilePlatform) {
            config = {
                panelClass: panelClass,
                hasBackdrop: true,
                width: '100vw',
                height: '95vh',
                maxWidth: '100vw',
                disableClose: true,
                position: {top: '48px'},
                data: dialogData
            }
        } else {
            config = {
                panelClass: panelClass,
                hasBackdrop: true,
                autoFocus: false,
                width: '864px',
                disableClose: true,
                data: dialogData
            }
        }
        const dialogRef = this.dialog.open(component, config);

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                this.matchedConfirm.emit();
            }
            console.log('Dialog closed result', result);
        });
    }

    openTimeSelect(teacherId: string) {
        this.apiService.getOverlappingTimeSlots(teacherId, this.student.student.id, '60').subscribe((slots) => {
            const modalDescription = {
                title: 'matching.time_select.titleCard',
                question: this.translateService.instant('matching.time_select.questionCard')
            };
            const modalDescriptionQuestion = modalDescription.question.replace('{{teacherFirstName}}', this.teacher.firstName);
            this.openDialog(MatchingTimeSelectComponent, 'time-select', { slots, teacher: this.teacher, student: this.student, modalDescription: {...modalDescription, question: modalDescriptionQuestion}, multiple: true});
        });
    }

    getTeachingLocation(locations?: string[]): string {
        const availableLocations = TEACHING_LOCATION;
        const result = availableLocations.filter((availableLocation) => {
            return locations?.includes(availableLocation.value);
        });
        const translatedLocations = result.map(location => this.translateService.instant('teaching_locations.' + location.value));
        return translatedLocations.join(', ');
    }

    translateInstruments(instruments: Instrument[] | undefined): string {
        if (!instruments) return '—';
        return instruments.map(instrument => this.translateService.instant('instrument_translations.' + instrument) || instrument).join(' | ');
    }

    translateGenres(genres: Genre[] | undefined): string {
        if (!genres) return '—';
        return genres.map(genre => this.translateService.instant('genre_translations.' + genre) || genre).join(' | ');
    }

    translateGender(gender: string): string {
        if (!gender) return '—';
        return this.translateService.instant('genders.' + gender);
    }

}
