import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    CALENDAR_FORMATS,
    CHIPS, EMAIL_VALIDATION_REG,
    EXPERIENCE_LEVEL, GENDER,
    GENRES,
    INSTRUMENTS,
    LANGUAGES, SINGLE_GENDER,
    TEACHING_LOCATION_STUDENT
} from '../../../../shared/constants/form-option';
import { ApiService } from '../../../services/api/api.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ScheduleItem, Student, UsersFormBody } from '../../../services/api/api.type';
import { TranslateService } from '@ngx-translate/core';

import { debounceTime, mergeMap, Subject, takeUntil } from 'rxjs';
import { getSchedulesBody } from '../../../../shared/constants/schedules';
import { PlatformService } from '../../../services/platform.service';
import { MatChipSelectionChange } from '@angular/material/chips';

@Component({
    selector: 'app-student-form',
    templateUrl: './student-form.component.html',
    styleUrls: ['./student-form.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: CALENDAR_FORMATS},
    ],
})
export class StudentFormComponent implements OnInit {
    studentProfileForm: FormGroup;
    originalFormValues: typeof this.studentProfileForm.value;
    currentDate: Date = new Date();
    destroy$: Subject<void> = new Subject<void>();

    experienceLevel= EXPERIENCE_LEVEL;

    instrument = INSTRUMENTS;

    genres = GENRES;

    languageOptions = LANGUAGES

    singlegender = SINGLE_GENDER;
    selectGender = GENDER;

    teacherLocations = TEACHING_LOCATION_STUDENT;

    chips: { day: string; date: string; time: string[]; selectedDate?: Date }[] = CHIPS;

    loading = false;
    selectedSchedules: {list: ScheduleItem[] };

    get availabilityTimeStamp(): any {
        return this.studentProfileForm.get('availabilityTimeStamp') as FormArray;
    }

    get birthday() {
        return this.studentProfileForm.get('birthday');
    }

    get isValidAvailabilityTimeStamp(): boolean {
        return (!!this.availabilityTimeStamp.value.find((timeStamp: {
            day: string,
            date: string,
            selectedDate: Date,
            selectedTime: string[],
        }) => timeStamp.selectedTime.length > 0));
    }

    get email() {
        return this.studentProfileForm.get('email');
    }

    get getSelectedGenderOptions() {
        const genderControl = this.studentProfileForm.get('genderPreference');
        if (genderControl?.value?.includes('no_preference')) {
            return this.translateService.instant('teacher-profile.sections.student_preference.labels.age_group.no_preference');
        } else {
            if (genderControl?.value) {
                const translatedOptions = genderControl?.value?.map((value: string) =>
                    this.translateService.instant('genders.' + value)
                );
                return translatedOptions.join(', ')
            } else {
                return [];
            }
        }
    }

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    constructor(
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        public dialogRef: MatDialogRef<StudentFormComponent>,
        private readonly apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: Student,
        private translateService: TranslateService,
        private platformService: PlatformService,
        private elementRef: ElementRef) {}

    ngOnInit(): void {
        const weeklySchedule = this.generateWeekSchedule();
        this.chips.forEach((chip) => {
            const currentDate = weeklySchedule.find((d) => d.day === chip.day);
            if (currentDate) {
                chip.date = currentDate?.date;
                chip.selectedDate = currentDate.selectedDate;
            }
            chip.time.forEach((time: string | object) => time = ({name: time, selected: false}));
        });
        this.chips.forEach((chip) => {
            chip.time.forEach((time: string | object) => time = ({name: time, selected: false}));
        });
        this.studentProfileForm = this.fb.group({
            firstName: [this.data?.firstName, [Validators.required]],
            zipCode: [this.data?.postalCode?.toString(), [Validators.pattern(/^\d+$/)]],
            language: [this.data?.language, Validators.required],
            spokenLanguages: [this.data?.spokenLanguages, Validators.required],
            lastName: [this.data?.lastName, [Validators.required]],
            birthday: [this.data?.birthday, Validators.required],
            instrument: [this.data?.student?.instruments, Validators.required],
            genre: [this.data?.student?.genres, Validators.required],
            goals: [this.data?.student?.description],
            knowledge: [this.data.student.experienceLevel, Validators.required],
            location: [this.data.student.teachingLocation, Validators.required],
            email: [this.data?.email, [Validators.required, Validators.pattern(EMAIL_VALIDATION_REG)]],
            gender: [this.data?.gender],
            // genderPreference: ['', Validators.required],
            phone: [this.data?.phoneNumber],
            allowContactViaEmail: [!!this.data?.allowEmailContact],
            allowContactViaPhone: [!!this.data?.allowPhoneContact],
            availabilityTimeStamp: this.fb.array([]),
        });
        this.chips.forEach((chip) => this.addAvailabilityTimeStamp(chip));
        this.setSelectedSchedules();
        this.originalFormValues = { ...this.studentProfileForm.value };

        this.email?.valueChanges.pipe(
            debounceTime(300),
            takeUntil(this.destroy$)
        ).subscribe((value) => {
            this.checkIfUserExist(value);
        });
    }

    checkIfUserExist(email: string) {
        if (this.email?.valid) {
            if (this.data?.email && email === this.data?.email) {
                this.email?.setErrors(null);
                return;
            }
            this.apiService.checkIfUserExist(email).subscribe(
                () => {
                    this.email?.setErrors(null);
                },
                () => {
                    this.email?.setErrors({ alreadyExist: true });
                    this.cd.detectChanges();
                }
            );
        }
    }

    setSelectedAll(selectedTime: string[]) {
        const uniqueArray = selectedTime?.filter((value: string, index: number, self: string[]) => {
            return self.indexOf(value) === index;
        });
        return (uniqueArray.length === 5);
    }

    setSelectedSchedules() {
        if (this.data?.student) {
            this.apiService.getSchedules(this.data.id).subscribe(schedules => {
                this.selectedSchedules = schedules;
                this.selectedSchedules.list = this.selectedSchedules.list.filter((schedule: ScheduleItem) => {
                    const startHour = this.getHour(schedule.startTime);
                    const endHour = this.getHour(schedule.endTime);
                    return (startHour > 6) && (endHour < 22);
                });
                this.onSelectTime(null, NaN, this.selectedSchedules.list, true);
            });
        }
    }

    getHour(time: string): number {
        const [hours] = time.split(':').map(Number);
        return hours;
    }

    getFieldProperties<T>(fieldName: string, controlField?:AbstractControl<T> | null): {
        control: AbstractControl<any> | null;
        hasError: boolean;
        isRequired: boolean;
        hasAllErrors: boolean;
    } {
        let control: AbstractControl<any> | null;
        if (controlField) {
            control = controlField.get(fieldName);
        } else {
            control = this.studentProfileForm.get(fieldName);
        }
        const isRequired = !!((control?.hasError('required') && control?.dirty) || (control?.hasError('required') && control?.touched));
        const hasError = !!((control?.errors && !control?.hasError('required') && control?.dirty) || (control?.errors && !control?.hasError('required') && control?.touched));
        const hasAllErrors = isRequired || hasError;
        return { control, isRequired, hasError, hasAllErrors } ;
    }

    scrollToErrorField(): void {
        const firstErrorField = this.elementRef.nativeElement.querySelector('mat-form-field.ng-invalid');
        if (firstErrorField) {
            const parentElement = firstErrorField.parentElement;
            parentElement.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }

    onSubmit() {
        this.studentProfileForm.markAllAsTouched();
        if (this.studentProfileForm.invalid || !this.isValidAvailabilityTimeStamp) {
            this.scrollToErrorField();
            return;
        }
        this.loading = true;

        const birthday = this.studentProfileForm.get('birthday')?.value;
        const userBody: UsersFormBody = {
            language: this.studentProfileForm.get('language')?.value,
            role: 'student',
            email: this.studentProfileForm.get('email')?.value,
            firstName: this.studentProfileForm.get('firstName')?.value,
            lastName: this.studentProfileForm.get('lastName')?.value,
            gender: this.studentProfileForm.get('gender')?.value,
            postalCode: this.studentProfileForm.get('zipCode')?.value ? this.studentProfileForm.get('zipCode')?.value: '',
            phoneNumber: this.studentProfileForm.get('phone')?.value ? this.studentProfileForm.get('phone')?.value: null,
            allowPhoneContact: this.studentProfileForm.get('allowContactViaPhone')?.value,
            allowEmailContact: this.studentProfileForm.get('allowContactViaEmail')?.value,
            spokenLanguages: this.studentProfileForm.get('spokenLanguages')?.value,
            birthday: this.data ? new Date(birthday).toISOString() : birthday.toISOString(),
        }
        this.data ? this.editStudent(userBody) : this.addStudent(userBody);
    }

    editStudent(userBody: UsersFormBody) {
        const getSchedulesBodyFun = getSchedulesBody;
        const schedulesBody = getSchedulesBodyFun(this.studentProfileForm.get('availabilityTimeStamp')?.value);
        this.apiService.editUser(userBody, this.data.id, !!this.data.hasEditProfile).pipe(
            mergeMap((userData) => {
                const studentFormData = {
                    userId: userData.id,
                    instruments: this.studentProfileForm.get('instrument')?.value,
                    genres: this.studentProfileForm.get('genre')?.value,
                    experienceLevel: this.studentProfileForm.get('knowledge')?.value,
                    teachingLocation: this.studentProfileForm.get('location')?.value,
                    description: this.studentProfileForm.get('goals')?.value,
                }
                return this.apiService.editStudent(studentFormData, this.data.student.id);
            }),
            mergeMap((student) => {
                return this.apiService.persistSchedule(schedulesBody, student.id)
            })
        ).subscribe(() => {
            this.loading = false;
            this.closeDialog(true);
        }, () => {
            this.loading = false;
        });
    }

    addStudent(userBody: UsersFormBody) {
        const getSchedulesBodyFun = getSchedulesBody;
        const schedulesBody = getSchedulesBodyFun(this.studentProfileForm.get('availabilityTimeStamp')?.value);
        // const genderPreference = this.studentProfileForm.get('genderPreference')?.value.filter((gender: string) => gender !== 'no_preference');
        this.apiService.createUser(userBody).pipe(
            mergeMap((userData) => {
                const studentFormData = {
                    userId: userData.id,
                    instruments: this.studentProfileForm.get('instrument')?.value,
                    genres: this.studentProfileForm.get('genre')?.value,
                    experienceLevel: this.studentProfileForm.get('knowledge')?.value,
                    teachingLocation: this.studentProfileForm.get('location')?.value,
                    // genderPreference: genderPreference,
                    description: this.studentProfileForm.get('goals')?.value,
                }
                return this.apiService.createStudent(studentFormData)
            }),
            mergeMap((student) => {
                return this.apiService.persistSchedule(schedulesBody, student.id)
            })
        ).subscribe(() => {
            this.loading = false;
            this.closeDialog(true);
        }, () => {
            this.loading = false;
        });
    }

    discard() {
        this.studentProfileForm.reset(this.originalFormValues);
        if (this.data) {
            this.availabilityTimeStamp.clear();
            this.chips.forEach((chip) => this.addAvailabilityTimeStamp(chip));
            this.onSelectTime(null, NaN, this.selectedSchedules.list, true);
        }
    }

    closeDialog(update = false) {
        this.destroy$.next();
        this.destroy$.complete();
        this.dialogRef.close(update);
    }

    addAvailabilityTimeStamp(chip: { day: string; date: string; time: string[]; selectedDate?: Date }) {
        const control = this.fb.group({
            day: [ chip.day ],
            date: [ chip.date ],
            selectedDate: [ chip.selectedDate ],
            selectedTime: [ [] ],
        });
        this.availabilityTimeStamp.push(control);
    }

    onSelectTime(event: MatChipSelectionChange | null, i: number, timeSlot: ScheduleItem[] | string, setDefaultValue = false) {
        if (setDefaultValue) {
            if (typeof timeSlot == 'object') {
                timeSlot.forEach((slot) => {
                    let startTime = '';
                    let endTime = '';

                    if (this.convertTimeWithoutSeconds(slot.startTime)) {
                        startTime = this.convertTimeWithoutSeconds(slot.startTime)
                    }

                    if (this.convertTimeWithoutSeconds(slot.endTime)) {
                        endTime = this.convertTimeWithoutSeconds(slot.endTime);
                    }

                    const selectedTime = `${startTime} – ${endTime}`;
                    const dayNum = slot.day === 0 ? 6 : slot.day - 1;
                    this.availabilityTimeStamp.at(dayNum).value.selectedTime.push(selectedTime);
                });
            }
            return;
        }
        if (event?.selected && this.availabilityTimeStamp.at(i)) {
            this.availabilityTimeStamp.at(i).value.selectedTime.push(timeSlot);
        } else if (this.availabilityTimeStamp.at(i)) {
            this.availabilityTimeStamp.at(i).value.selectedTime = this.availabilityTimeStamp.at(i).value.selectedTime.filter((e: string) => e !== timeSlot)
        }
    }

    convertTimeWithoutSeconds(time: string): string {
        if (!time) {
            return '';
        }
        const parts = time.split(":");
        const hours = parts[0];
        const minutes = parts[1];
        return `${hours}:${minutes}`;
    }


    compareFn<T>(c1: T, c2: T): boolean {
        return c1 && c2 ? (c1 as any).id === (c2 as any).id : c1 === c2;
    }

    generateWeekSchedule(): {
        day: string;
        date: string;
        selectedDate: Date;
    }[] {
        const today = new Date();
        const daysInWeek = 7;
        const millisecondsInDay = 24 * 60 * 60 * 1000;
        const weekSchedule = [];

        for (let i = 0; i < daysInWeek; i++) {
            const currentDate = new Date(today.getTime() + i * millisecondsInDay);

            const dayOfWeek = currentDate.toLocaleDateString('en-US', {weekday: 'long'});
            const formattedDate = currentDate.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
            }).replaceAll('/', '.');

            weekSchedule.push({day: dayOfWeek, date: formattedDate, selectedDate: currentDate});
        }
        return weekSchedule;
    }

    setSelectedSlot(selectedTime: string[],timeSlot: string) {
        return !!selectedTime?.includes(timeSlot);
    }

    onNoGenderPreference() {
        const genderControl = this.studentProfileForm.get('genderPreference');
        const currentOptions = genderControl?.value || [];
        if (currentOptions.includes('no_preference')) {
            const genderValues = this.selectGender.map((exp) => exp.value);
            genderControl?.setValue([...genderValues, 'no_preference']);
        } else {
            genderControl?.setValue([]);
        }
    }

    onNoTeachingLocation() {
        const teachingLocationControl = this.studentProfileForm.get('location');
        const currentOptions = teachingLocationControl?.value || [];
        if (currentOptions.includes('no-preference')) {
            const teachingLocationValues = this.teacherLocations.map((exp) => exp.value);
            teachingLocationControl?.setValue([...teachingLocationValues]);
        } else {
            teachingLocationControl?.setValue([]);
        }
    }

    selectAllTimeSlots(index: number) {
        const allDaySelected = this.setSelectedAll(this.availabilityTimeStamp.at(index).value.selectedTime);
        if (!allDaySelected) {
            this.availabilityTimeStamp.at(index).value.selectedTime.splice(0);
            this.availabilityTimeStamp.at(index).value.selectedTime.push(...this.chips[index].time);
        } else {
            this.availabilityTimeStamp.at(index).value.selectedTime = this.availabilityTimeStamp.at(index).value.selectedTime.filter((e: string) => !this.chips[index].time.includes(e));
        }
    }

}
