<app-notify-lesson
    *ngIf="teacherNotifyData?.earliestLesson"
    [date]="teacherNotifyData?.earliestLesson"
    [avatar]="studentFileUrl"
    [description]="'next_piano_lesson' | translate: { firstName: teacherNotifyData?.student?.firstName }">
</app-notify-lesson>

<div class="new-students" *ngIf="role === UserRoles.Teacher &&  students?.list && students.list.length > 0; else emptyList">
    <div class="title">{{ 'student-dashboard-table.title.existing.students' | translate }}</div>
    <mat-list role="list">
        <ng-container *ngFor="let item of students.list; let i = index; let last = last">
            <a mat-list-item matRipple (click)="showProfile(item)">
                <div class="list-wrapper">
                    <div class="left-side">
                        <div class="text-container">
                            <span class="name">{{ item?.firstName }} {{ item?.lastName }}</span>
                            <div class="instrument-wrapper" *ngIf="item.student?.instruments">
                      <span class="instrument" *ngFor="let instrument of item.student?.instruments; let last = last">
                         {{ 'instrument_translations.' + instrument | translate }}
                          <span *ngIf="!last">| </span>
                     </span>
                            </div>
                            <span *ngIf="!item.student?.instruments">—</span>
                        </div>
                    </div>
                </div>
                <mat-divider *ngIf="!last"></mat-divider>
            </a>
            <mat-divider *ngIf="!last"></mat-divider>
        </ng-container>
    </mat-list>
</div>

<ng-template #emptyList>
    <div class="empty-container">
        {{ 'student-dashboard-table.message.noDataAvailable' | translate }}
    </div>
</ng-template>
