import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Avatar, SchoolItem } from '../../../../services/api/api.type';
import { EXPERIENCE_LEVEL, LANGUAGES, TEACHING_LOCATION_STUDENT } from '../../../../../shared/constants/form-option';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectImageComponent } from '../../../select-image/select-image.component';
import { OrganizationFormComponent } from '../../../profile/organization-form/organization-form.component';
import { DashboardTableService } from '../../../../services/dashboard-table.service';
import { UserInfo, UserRoles } from '../../../../../core/models/user-info.interface';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { OrganisationProfileService } from '../../../../../shared/services/org-profile.service';
import { ApiService } from '../../../../services/api/api.service';
import { UserService } from '../../../../services/user/user.service';
import { PlatformService } from '../../../../services/platform.service';
import { DeleteConfirmComponent } from '../../delete-confirm/delete-confirm.component';
import { TranslateService } from '@ngx-translate/core';
import { schoolId } from '../../../../../shared/constants/table-data';
import { SchoolApproveEvent } from '../organisation-dashboard-table/organisation-dashboard-table.component';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'app-organisation-profile-info',
  templateUrl: './organisation-profile-info.component.html',
  styleUrls: ['./organisation-profile-info.component.scss']
})
export class OrganisationProfileInfoComponent implements OnInit {
    school: SchoolItem;
    skillLevels = EXPERIENCE_LEVEL;
    languages = LANGUAGES;
    image: string | null;
    hasEditProfile = false;
    protected readonly schoolId = schoolId;
    @Output() organisationUpdated: EventEmitter<string> = new EventEmitter<string>();
    @Output() onSchoolApprove: EventEmitter<SchoolApproveEvent<OrganisationProfileInfoComponent>> = new EventEmitter<SchoolApproveEvent<OrganisationProfileInfoComponent>>();

    getImageStyle(image: string | null): SafeStyle {
        const backgroundImage = image ? `url(${image})` : 'url(\'/assets/images/profile/unknown.jpg\')';
        const backgroundColor = image ? '' : 'lightgray';

        return this.sanitizer.bypassSecurityTrustStyle(`
          background-image: ${backgroundImage};
          background-position: 50% 50%;
          background-size: cover;
          background-repeat: no-repeat;
          background-color: ${backgroundColor};
    `);
    }
    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    get user(): UserInfo {
        return this.userService?.userInfo;
    }

    constructor(
        public dialogRef: MatDialogRef<OrganisationProfileInfoComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: SchoolItem & { hasEditProfile: boolean },
        private sanitizer: DomSanitizer,
        private apiService: ApiService,
        private userService: UserService,
        private dashboardTableService: DashboardTableService,
        private platformService: PlatformService,
        private translateService: TranslateService,
        private orgProfileService: OrganisationProfileService) {
    }

    ngOnInit() {
        this.school = this.data;
        this.image = this.school.files.length > 0 ? this.school.files[0].url: null;
        this.orgProfileService.setOrganisationProfileComponent(this);
        this.hasEditProfile = this.data.hasEditProfile;
        if (this.hasEditProfile) {
            this.setSchoolProfile();
        }
    }

    setSchool(school: SchoolItem) {
        this.school = school;
        if (this.hasEditProfile) {
            this.setSchoolProfile();
        }
    }

    setSchoolProfile() {
        this.apiService.getUser(this.userService.userInfo.userId).subscribe((user) => {
            this.school.gender = user.gender;
            this.school.language = user.language;
            this.school.firstName = user.firstName;
            this.school.lastName = user.lastName;
            this.school.email = user.email;
            this.school.adminId = user.id;
        });
    }

    setImage() {
        this.image = this.school.files.length > 0 ? this.school.files[0].url: null;
    }

    getTeachingLocation(locations?: string[]) {
        const availableLocations = TEACHING_LOCATION_STUDENT;
        const result = availableLocations.filter((availableLocation) => {
            return locations?.includes(availableLocation.value);
        });
        return result.map(l => l.label).join(', ');
    }

    getLanguage(lang: string): string {
        return this.languages.find((language) => language.value === lang)?.label || '—';
    }

    closeDialog(update = false) {
        this.dialogRef.close(update);
    }

    skillLevel(skillLevel: string[]) {
        return this.skillLevels.find((skill) => skillLevel.includes(skill.value) || '');
    }

    upload() {
        this.openDialog(SelectImageComponent, 'select-image', { firstName: null, files: this.school?.files, userId: this.school.id, role: UserRoles.Organisation });
    }

    editProfile() {
        this.school.hasEditMyProfile = this.hasEditProfile;
        this.openDialog(OrganizationFormComponent, 'organisation-form', this.school);
    }

    openDialog(component: ComponentType<SelectImageComponent | OrganizationFormComponent>, panelClass: string, dialogData?: SchoolItem | { firstName: string | null, files: Avatar[], userId: string, role: UserRoles }, width = '800px'): void {
        document.body.classList.add('modal-open');
        let dialogRef: MatDialogRef<SelectImageComponent | OrganizationFormComponent>;
        if (this.platformService.isMobile) {
            dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                height: '95vh',
                maxWidth: '100vw',
                disableClose: true,
                position: { top: '48px' },
                data: dialogData
            });
        } else {
             dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                autoFocus: false,
                disableClose: false,
                position: { top: '32px' },
                data: dialogData
            });
        }

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                const fromEdit = this.hasEditProfile ? 'edit': '';
                this.dashboardTableService.updateSchoolProfile.next(fromEdit);
                this.dashboardTableService.needUpdateTeacherTable.next(null);
                this.organisationUpdated.emit(this.school.id);
                if (result?.delete) {
                    this.closeDialog();
                }
            }
            console.log('Dialog closed result', result);
        });
    }

    deleteOrganisation(key: string) {
        const title = this.translateService.instant(key);
        const type = this.translateService.instant('confirm_delete.organisation_type');
        const description = this.translateService.instant('confirm_delete.org_description');
        this.openDeleteConfirmDialog(DeleteConfirmComponent, 'delete-confirm', { id: this.data.id, title, name: this.data.name, type, description });
    }

    openDeleteConfirmDialog(component: ComponentType<DeleteConfirmComponent>, panelClass: string, dialogData?: { id: string, title: string, name: string, type: string, description: string }): void {
        document.body.classList.add('modal-open');
        const dialogRef = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width: '480px',
            autoFocus: false,
            disableClose: true,
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(schoolId => {
            document.body.classList.remove('modal-open');
            if (schoolId) {
                this.apiService.deleteOrganisation(schoolId).subscribe(() => {
                    this.dialogRef.close({delete: true});
                });
            }
            console.log('Dialog closed result', schoolId);
        });
    }

    handleApprove(organisationId: string, validated: boolean) {
        validated = !validated;
        this.onSchoolApprove.emit({organisationId, validated})
    }

    protected readonly UserRoles = UserRoles;
}
