<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{step.title1 | translate}} {{step.title2 | translate}}</div>
    </div>
    <div class="storybook-field">
        <div class="form-field checkbox" *ngFor="let control of socialMediaGroup?.controls; let i = index">
            <div class="fields-container">
                <mat-form-field subscriptSizing="dynamic">
                    <div *ngIf="control.get('custom').value" class="custom-type-wrapper">
                        <input id="custom-{{i}}" #customInput placeholder="{{ 'signup-form.teacher.step-socialMedia.socialMediaPlaceholder' | translate }}" matInput [formControl]="control.get('socialMedia')">
                        <mat-icon (click)="clearInput(control)">clear</mat-icon>
                    </div>
                    <mat-select *ngIf="!control.get('custom').value" (selectionChange)="onSelectSocialMedia($event, control, i)" placeholder="{{ 'signup-form.teacher.step-socialMedia.socialMediaPlaceholder' | translate }}" [ngClass]="{'default-select-icon-color': !((control.get('socialMedia')?.hasError('required') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('required') && control.get('socialMedia')?.touched))}" [formControl]="control.get('socialMedia')">
                        <mat-option *ngFor="let option of selectOptions" [value]="option.value">{{ option.label | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf="(control.get('socialMedia')?.hasError('required') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('required') && control.get('socialMedia')?.touched)">
                    {{ 'signup-form.teacher.step-socialMedia.socialMediaError' | translate }}
                </mat-error>
                <div class="todo-list">
                    <mat-form-field subscriptSizing="dynamic">
                        <input placeholder="{{ 'signup-form.teacher.step-socialMedia.' +
                            (['TikTok', 'Instagram'].includes(control.get('socialMedia').value) ?
                            'selectedPlaceholder.' + control.get('socialMedia').value :
                            'selectedPlaceholder.custom') | translate }}" maxlength="50" matInput [formControl]="control.get('nickName')" required>
                    </mat-form-field>
                </div>
                <mat-error *ngIf="(control.get('nickName')?.hasError('required') && control.get('nickName')?.dirty) || (control.get('nickName')?.hasError('required') && control.get('nickName')?.touched)">
                    {{ 'signup-form.teacher.step-socialMedia.nickNameError' | translate }}
                </mat-error>
            </div>
            <button mat-icon-button class="example-icon" [disabled]="socialMediaGroup?.controls.length < 2" (click)="removeSocial(i)">
                <mat-icon class="close-icon" [ngClass]="{'disabled': socialMediaGroup?.controls.length < 2}">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="custom-theme add-more">
        <button mat-stroked-button color="primary" (click)="addSocialMediaControls()">{{ 'signup-form.teacher.step-socialMedia.btnAddAnother' | translate }}</button>
    </div>
    <div align="end" class="custom-theme btn-container storybook-field">
        <button mat-stroked-button [color]="step.btn2Color" (click)="skip()">{{ 'signup-form.teacher.step-socialMedia.btnSkip' | translate }}</button>
        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">{{step.btnText2 | translate}}</button>
    </div>
</form>
