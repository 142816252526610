import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
    get isMobile(): boolean {
        return this.breakpointObserver.isMatched(Breakpoints.Handset);
    }
  constructor(private breakpointObserver: BreakpointObserver) { }
}
