import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'localizedDateTime',
    pure: false
})
export class LocalizedDateTimePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {}

    transform(date: string | Date | undefined): string {
        if (!date) {
            return '';
        }
        const options: Intl.DateTimeFormatOptions = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: 'UTC',
        };
        const localeLang = this.translateService.currentLang === 'eng' ? 'en-US' : 'de-DE';
        return new Intl.DateTimeFormat(localeLang, options).format(date as Date);
    }

}
