import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { getFieldProperties } from '../../../../../../../../shared/validators/has-error';
import { UserRoles } from '../../../../../../../../core/models/user-info.interface';
import { ApiService } from '../../../../../../../services/api/api.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { EMAIL_VALIDATION_REG, Step } from '../../../../../../../../shared/constants/form-option';

@Component({
  selector: 'app-step-contact',
  templateUrl: './step-contact.component.html',
  styleUrls: ['./step-contact.component.scss']
})
export class StepContactComponent implements OnInit {
    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Output() submitForm = new EventEmitter<void>();
    @Input() stepper: MatStepper;
    @Output() proceed = new EventEmitter<number>();
    @Input() signUpRole: UserRoles;
    @Input() part: number;
    protected readonly getFieldProperties = getFieldProperties;
    protected readonly UserRoles = UserRoles;
    private destroy$: Subject<void> = new Subject<void>();

    get email() {
        return this.formGroup.get('email');
    }

    get phone() {
        return this.formGroup.get('phone');
    }

    constructor(private fb: FormBuilder, private apiService: ApiService, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.addControls();
        this.email?.valueChanges.pipe(
            debounceTime(300),
            takeUntil(this.destroy$)
        ).subscribe((value) => {
            this.checkIfUserExist(value);
        });
    }

    onSubmit() {
        this.submitForm.emit();
        this.proceed.emit(2);
        this.destroy$.next();
        this.destroy$.complete();
    }
    addControls(): void {
        if (this.formGroup && Object.keys(this.formGroup?.controls)?.length === 0) {
            const defaultContactState = this.signUpRole === UserRoles.Organisation;
            const phoneValidator = this.signUpRole === UserRoles.Organisation ? Validators.required : null;
            this.formGroup.addControl('email', this.fb.control('', [Validators.required, Validators.pattern(EMAIL_VALIDATION_REG)]));
            this.formGroup.addControl('phone', this.fb.control('', phoneValidator));
            this.formGroup.addControl('allowContactViaEmail', this.fb.control(defaultContactState, []));
            this.formGroup.addControl('allowContactViaPhone', this.fb.control(defaultContactState, []));
        }
    }


    checkIfUserExist(email: string) {
        if (this.email?.valid) {
            this.apiService.checkIfUserExist(email).subscribe(
                () => {
                        this.email?.setErrors(null);
                },
                () => {
                    this.email?.setErrors({ alreadyExist: true });
                    this.cdr.detectChanges();
                }
            );
        }
    }

    backToPrevious() {
        this.proceed.emit(this.part -1);
    }

}
