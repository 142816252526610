<div class="notify-container">
    <div class="notify-wrapper">
        <div class="info">
            <div class="info-logo">
                <img src="assets/icons/info.svg">
            </div>
            {{'notify-panel.title' | translate}}
        </div>
        <div class="btn-container custom-theme">
            <button mat-stroked-button color="primary" (click)="openDefaultMailClient()">
                <span class="btn-text">{{'notify-panel.feedback-button' | translate}}</span></button>
        </div>
    </div>
</div>

