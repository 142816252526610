<mat-card class="activate-card" appearance="outlined">
    <img mat-card-image src="{{integration.files[0]?.url}}" alt="">
    <mat-card-content></mat-card-content>

    <div class="card-info">
        <div class="card-inf-content">{{integration.name}}</div>
    </div>

    <div *ngIf="integration?.website?.includes('digital')" class="logo-container">
        <div class="logo-title">{{'integration.powered_by' | translate}}</div>
        <svg xmlns="http://www.w3.org/2000/svg" width="83" height="32" viewBox="0 0 83 32" fill="none">
            <mask id="mask0_3752_2108887" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="14" width="24" height="17">
                <path d="M0.463976 15.2454C0.154659 16.4053 0 17.5652 0 18.8025C0 19.1892 2.93835e-07 19.5758 0.0773295 19.9624C8.1969 18.1839 16.4711 22.7463 19.255 30.5566C20.6469 29.8606 21.9615 28.8553 23.0441 27.6954C19.719 19.9624 12.1407 15.0134 3.7118 15.0134C2.55187 14.936 1.46926 15.0134 0.463976 15.2454Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_3752_2108887)">
                <path d="M-5.25781 28.539L2.86176 7.27344L28.1484 16.9396L20.0288 38.2051L-5.25781 28.539Z" fill="white"/>
            </g>
            <mask id="mask1_3752_2108887" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="2" y="0" width="25" height="24">
                <path d="M13.134 0.132812V5.46853C9.03552 5.46853 5.09172 7.40176 2.61719 10.6496C12.0514 10.2629 20.8669 15.1347 25.5066 23.3316C26.0479 21.8623 26.3573 20.2384 26.3573 18.6918V0.132812H13.134Z" fill="white"/>
            </mask>
            <g mask="url(#mask1_3752_2108887)">
                <path d="M-5.11328 20.3978L5.71281 -7.75L34.17 3.15342L23.3439 31.3013L-5.11328 20.3978Z" fill="white"/>
            </g>
            <mask id="mask2_3752_2108887" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="1" y="23" width="15" height="9">
                <path d="M1.07031 24.0386C1.92093 26.0491 3.31286 27.8277 5.0141 29.1423C6.01938 28.9103 7.02466 29.1423 7.79796 29.7609C8.33926 30.1476 8.72591 30.6889 8.9579 31.3075C10.9685 31.9262 13.0563 32.1581 15.0669 31.8488C12.979 26.1265 7.02466 22.8013 1.07031 24.0386Z" fill="white"/>
            </mask>
            <g mask="url(#mask2_3752_2108887)">
                <path d="M-1.71094 30.9251L2.85149 19.0938L17.8534 24.8161L13.2909 36.6475L-1.71094 30.9251Z" fill="white"/>
            </g>
            <path d="M36.3414 3.72135C37.424 3.72135 38.5066 4.18533 39.2026 5.11328V0.9375H41.4452V12.6915H39.2026V11.3769C38.584 12.3049 37.5014 12.8462 36.3414 12.8462C34.0989 12.8462 32.3203 10.9903 32.3203 8.28378C32.3203 5.57725 34.0989 3.72135 36.3414 3.72135ZM36.8827 5.65458C35.5681 5.65458 34.5629 6.73719 34.5629 8.05179C34.5629 8.12912 34.5629 8.20645 34.5629 8.28378C34.5629 9.98502 35.7228 10.913 36.8827 10.913C38.1973 10.913 39.2026 9.83036 39.2026 8.51576C39.2026 8.43843 39.2026 8.36111 39.2026 8.36111C39.2799 7.04651 38.352 5.88657 37.0374 5.80924C36.9601 5.73191 36.9601 5.73191 36.8827 5.65458Z" fill="white"/>
            <path d="M43.3815 1.4758C43.3042 0.702503 43.9228 0.0838693 44.6961 0.00654006C45.4694 -0.0707892 46.0881 0.547845 46.1654 1.32114C46.2427 2.09443 45.6241 2.71306 44.8508 2.79039H44.7735C44.0775 2.86772 43.4589 2.32642 43.3815 1.55312C43.3815 1.55312 43.3815 1.55312 43.3815 1.4758ZM43.6135 3.873H45.8561V12.6112H43.6135V3.873Z" fill="white"/>
            <path d="M51.4196 3.71824C52.5795 3.64092 53.6621 4.18222 54.3581 5.11017V3.8729H56.6006V12.6884C56.6006 15.0856 55.1314 16.9415 52.2702 16.9415C49.7956 16.9415 48.0944 15.7043 47.8624 13.6937H50.0276C50.2596 14.467 51.1102 15.0083 52.1929 15.0083C53.4301 15.0083 54.3581 14.3123 54.3581 12.6884V11.2965C53.6621 12.2245 52.5795 12.7658 51.4196 12.7658C49.177 12.7658 47.3984 10.9099 47.3984 8.20334C47.3984 5.49682 49.2543 3.71824 51.4196 3.71824ZM52.0382 5.65148C50.7236 5.65148 49.7183 6.73408 49.7183 8.04868C49.7183 8.12601 49.7183 8.20334 49.7183 8.28067C49.7183 9.98191 50.8783 10.9099 52.0382 10.9099C53.3528 10.9099 54.3581 9.82725 54.3581 8.51266C54.3581 8.43533 54.3581 8.358 54.3581 8.358C54.4354 7.0434 53.5075 5.88346 52.1929 5.80613C52.1155 5.7288 52.1155 5.65148 52.0382 5.65148Z" fill="white"/>
            <path d="M58.5339 1.4758C58.4566 0.702503 59.0752 0.0838693 59.8485 0.00654006C60.6218 -0.0707892 61.2404 0.547845 61.3177 1.32114C61.3951 2.09443 60.7764 2.71306 60.0031 2.79039H59.9258C59.2298 2.86772 58.6112 2.32642 58.5339 1.63045C58.5339 1.55312 58.5339 1.55312 58.5339 1.4758ZM58.7659 3.873H61.0084V12.6112H58.7659V3.873Z" fill="white"/>
            <path d="M63.485 5.65393H62.4023V3.79803H63.485V1.63281H65.7275V3.79803H67.6607V5.65393H65.7275V9.90704C65.7275 10.5257 65.9595 10.7577 66.6555 10.7577H67.6607V12.6136H66.2688C64.5676 12.6136 63.485 11.9176 63.485 9.90704V5.65393Z" fill="white"/>
            <path d="M72.7672 3.71824C73.9272 3.64092 75.0098 4.18222 75.7057 5.11017V3.8729H77.9483V12.6111H75.7057V11.2965C75.0098 12.2245 73.9272 12.7658 72.7672 12.6884C70.5247 12.6884 68.7461 10.8325 68.7461 8.12601C68.7461 5.41949 70.5247 3.71824 72.7672 3.71824ZM73.3085 5.65148C71.9939 5.65148 70.9886 6.73408 70.9886 8.04868C70.9886 8.12601 70.9886 8.20334 70.9886 8.28067C70.9886 9.98191 72.1486 10.9099 73.3085 10.9099C74.6231 10.9099 75.6284 9.82725 75.6284 8.51266C75.6284 8.43533 75.6284 8.358 75.6284 8.358C75.7057 7.0434 74.7778 5.88346 73.4632 5.80613C73.4632 5.7288 73.3858 5.65148 73.3085 5.65148Z" fill="white"/>
            <path d="M82.2738 0.9375H80.0312V12.6915H82.2738V0.9375Z" fill="white"/>
            <path d="M36.2649 27.7733C34.0997 27.7733 32.5531 26.4587 32.3984 24.8348H34.641C34.7956 25.6081 35.4916 26.072 36.2649 25.9947C37.1155 25.9947 37.5795 25.6081 37.5795 25.0668C37.5795 23.5202 32.6304 24.5255 32.6304 21.2776C32.6304 19.8084 33.945 18.6484 36.1102 18.6484C38.2755 18.6484 39.5127 19.8084 39.6674 21.5096H37.5795C37.5022 20.8137 36.8835 20.2724 36.1876 20.3497H36.1102C35.2596 20.3497 34.873 20.659 34.873 21.2003C34.873 22.8242 39.7447 21.8189 39.822 25.0668C39.6674 26.6907 38.3528 27.8506 36.2649 27.7733Z" fill="white"/>
            <path d="M41.8326 20.6547H40.75V18.8762H41.8326V16.7109H44.0752V18.8762H46.0857V20.6547H44.1525V24.9078C44.1525 25.5265 44.3845 25.7585 45.0804 25.7585H46.0857V27.6144H44.6938C42.9925 27.6144 41.9099 26.9184 41.9099 24.9078V20.6547H41.8326Z" fill="white"/>
            <path d="M51.1141 18.7182C52.274 18.6409 53.3566 19.1822 53.9753 20.1102V18.7956H56.2178V27.5338H53.9753V26.3738C53.2793 27.3018 52.1967 27.8431 51.0367 27.7658C48.7942 27.7658 47.0156 25.9099 47.0156 23.2033C47.0156 20.4968 48.8715 18.7182 51.1141 18.7182ZM51.6554 20.6515C50.3408 20.6515 49.3355 21.7341 49.3355 22.9714C49.3355 23.0487 49.3355 23.126 49.3355 23.2033C49.3355 24.9046 50.4954 25.8325 51.6554 25.8325C52.8153 25.8325 53.9753 24.9046 53.9753 23.2807C54.0526 21.9661 53.1246 20.8061 51.81 20.7288C51.81 20.7288 51.7327 20.7288 51.6554 20.6515Z" fill="white"/>
            <path d="M61.861 18.7182C63.0209 18.6409 64.1035 19.1822 64.7995 20.1102V18.7956H67.042V27.6884C67.042 30.0856 65.5728 31.9415 62.7116 31.9415C60.2371 31.9415 58.5358 30.7043 58.3038 28.6937H60.469C60.701 29.467 61.5517 30.0083 62.6343 30.0083C63.8715 30.0083 64.7995 29.3124 64.7995 27.6884V26.3738C64.1035 27.3018 63.0209 27.8431 61.861 27.8431C59.6184 27.8431 57.8398 25.9872 57.8398 23.2807C57.8398 20.5741 59.6184 18.7182 61.861 18.7182ZM62.4023 20.6515C61.0877 20.6515 60.0824 21.7341 60.0824 22.9714C60.0824 23.0487 60.0824 23.126 60.0824 23.2033C60.0824 24.9046 61.2423 25.8325 62.4023 25.8325C63.7942 25.8325 64.9541 24.6726 64.9541 23.2807C64.9541 21.8887 63.7942 20.6515 62.4023 20.6515Z" fill="white"/>
            <path d="M72.9976 27.7678C70.4457 27.7678 68.5898 25.9893 68.5898 23.2054C68.5898 20.4216 70.3684 18.7203 72.9976 18.7203C75.2402 18.5657 77.1734 20.3442 77.328 22.5868C77.328 22.7414 77.328 22.8961 77.328 23.0508C77.328 23.3601 77.328 23.5921 77.2507 23.9014H70.8324C70.8324 25.0613 71.7603 25.9119 72.9203 25.9119C73.6936 25.9893 74.3895 25.5253 74.6989 24.8293H77.0961C76.5548 26.6852 74.8535 27.9225 72.9976 27.7678ZM70.8324 22.4321H75.0082C75.0082 21.3495 74.0802 20.4989 73.0749 20.5762C72.9976 20.5762 72.9976 20.5762 72.9203 20.5762C71.8377 20.5762 70.9097 21.3495 70.8324 22.4321Z" fill="white"/>
        </svg>
    </div>

    <div *ngIf="integration?.website?.includes('sirius')" class="logo-container">
        <div class="logo-title">{{'integration.powered_by' | translate}}</div>
        <svg xmlns="http://www.w3.org/2000/svg" width="126" height="24" viewBox="0 0 126 24" fill="none">
            <g clip-path="url(#clip0_3842_97243)">
                <path d="M51.0203 9.67668C54.2223 10.3327 58.3829 11.8694 58.3417 16.5576C58.3417 18.7916 57.5068 20.544 55.8003 21.7734C54.1351 22.9568 52.0295 23.5716 49.5341 23.5716C45.0202 23.5716 41.9513 21.8192 40.332 18.3559L45.2404 15.5943C45.8963 17.4797 47.3001 18.443 49.5341 18.443C51.5525 18.443 52.5571 17.8283 52.5571 16.5576C52.5571 15.3741 50.8048 14.7181 48.5708 14.1447C45.3275 13.3099 41.2494 11.952 41.2494 7.39679C41.2494 5.24995 42.0385 3.53888 43.6165 2.31407C45.2404 1.0434 47.2084 0.428711 49.5754 0.428711C53.1259 0.428711 56.1948 2.0939 57.9013 5.07563L53.0801 7.70414C52.3782 6.21327 51.1947 5.47013 49.5754 5.47013C48.1304 5.47013 47.034 6.17198 47.034 7.26834C47.034 8.45186 48.7864 9.10788 51.0203 9.67668Z" fill="white"/>
                <path d="M59.373 22.9564V1.04297H65.0292V22.9564H59.373Z" fill="white"/>
                <path d="M71.731 4.8137C72.7815 2.00629 74.9651 1.05212 78.3917 1.05212V6.91469C76.6807 6.6945 75.1026 7.04771 73.7448 7.96517C72.3869 8.88723 71.7264 10.3781 71.7264 12.4791V22.9564H66.0703V1.04297H71.7264V4.8137H71.731Z" fill="white"/>
                <path d="M79.3145 22.9564V1.04297H84.9706V22.9564H79.3145Z" fill="white"/>
                <path d="M100.924 1.04297H106.58V22.9564H100.924V20.5022C99.5659 22.5619 97.3733 23.5711 94.3922 23.5711C91.9785 23.5711 90.0062 22.7821 88.4333 21.1582C86.9008 19.5343 86.1113 17.3461 86.1113 14.4975V1.04297H91.7676V13.7956C91.7676 16.7315 93.4793 18.3095 96.1073 18.3095C99.043 18.3095 100.929 16.5113 100.929 12.8735V1.04297H100.924Z" fill="white"/>
                <path d="M118.191 9.67668C121.393 10.3327 125.554 11.8694 125.513 16.5576C125.513 18.7916 124.678 20.544 122.971 21.7734C121.306 22.9568 119.2 23.5716 116.705 23.5716C112.191 23.5716 109.121 21.8192 107.498 18.3559L112.406 15.5943C113.063 17.4797 114.466 18.443 116.7 18.443C118.718 18.443 119.723 17.8283 119.723 16.5576C119.723 15.3741 117.971 14.7181 115.732 14.1447C112.489 13.3099 108.411 11.952 108.411 7.39679C108.411 5.24995 109.199 3.53888 110.777 2.31407C112.402 1.0434 114.374 0.428711 116.736 0.428711C120.287 0.428711 123.356 2.0939 125.063 5.07563L120.241 7.70414C119.539 6.21327 118.356 5.47013 116.736 5.47013C115.292 5.47013 114.196 6.17198 114.196 7.26834C114.204 8.45186 115.957 9.10788 118.191 9.67668Z" fill="white"/>
                <path d="M0 1.04297V22.9564H38.8039V1.04297H0ZM30.9779 17.8691C29.2669 17.8691 27.7347 17.1352 26.6613 15.97V15.9791C26.6613 15.9791 24.0052 12.8093 19.4363 12.8093C14.8674 12.8093 12.2114 15.9011 12.2114 15.9011V15.892C11.138 17.103 9.57368 17.8691 7.82591 17.8691C4.5827 17.8691 1.95877 15.2406 1.95877 12.002C1.95877 8.75875 4.58728 6.13484 7.82591 6.13484C9.57368 6.13484 11.138 6.90091 12.2114 8.11196V8.10277C12.2114 8.10277 14.8674 11.1946 19.4363 11.1946C24.0052 11.1946 26.6613 8.0248 26.6613 8.0248V8.02939C27.7347 6.86422 29.2669 6.13025 30.9779 6.13025C34.2212 6.13025 36.8451 8.75875 36.8451 11.9974C36.8451 15.2405 34.2165 17.8691 30.9779 17.8691Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_3842_97243">
                    <rect width="126" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>    </div>

    <mat-card-actions class="custom-theme">
        <button mat-button>{{'integration.not_interested' | translate}}</button>
        <button class="raised-button" mat-raised-button color="primary" (click)="activate()">{{'integration.activate' | translate}}</button>
    </mat-card-actions>
</mat-card>

