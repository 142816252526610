export class JwtHelper {

  public static decode<T>(token: string): T {
    if (!token) {
      throw 'Illegal base64url string!';
    }

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const decodeBase64 = window.atob(base64);
    const jsonPayload = decodeURIComponent(
      decodeBase64.split('').map((value: string) => '%' + ('00' + value.charCodeAt(0).toString(16)).slice(-2)).join('')
    );

    return JSON.parse(jsonPayload);
  }
}
