import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { convertTimeSlots } from '../../../utils';
import { Teacher, UserLanguage } from '../../../services/api/api.type';

@Component({
    selector: 'app-teacher-schedule',
    templateUrl: './teacher-schedule.component.html',
    styleUrls: ['./teacher-schedule.component.scss'],
})
export class TeacherScheduleComponent implements OnChanges {

    @Input() userTeacher: Teacher;
    @Input() teacherTimeSlots: string[] = [];
    @Output() onTeacherConfirmed = new EventEmitter<string[]>();
    @Output() onTeacherDeclined = new EventEmitter();
    convertedTimeSlots: string[];
    selectedTime: string[] = [];

    ngOnChanges(): void {
        this.convertedTimeSlots = this.convertTimeSlots(this.teacherTimeSlots);
    }

    convertTimeSlots(timeSlots: string[]): string[] {
        if (!timeSlots) {
            return [];
        }
        this.selectedTime = timeSlots;

        return convertTimeSlots(timeSlots, this.userTeacher.language as UserLanguage, this.userTeacher.timezone || undefined);
    }

    onTimeSelected(event: MatCheckboxChange, index: number) {
        if (event.checked) {
            this.selectedTime.push(this.teacherTimeSlots[index]);
        } else if (!event.checked) {
            const timeIndex = this.selectedTime.indexOf(this.teacherTimeSlots[index]);
            this.selectedTime.splice(timeIndex, 1);
        }
    }

    confirmMatching(timeSlots: string[]) {
        this.onTeacherConfirmed.emit(timeSlots);
    }

    declineMatching() {
        this.onTeacherDeclined.emit();
    }
}
