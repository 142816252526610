<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{ step.title1 | translate }} {{ step.title2 | translate }}</div>
    </div>
    <div class="storybook-field">
        <p>{{ 'signup-form.' + signUpRole + '.step-area.label' | translate }}</p>
        <mat-form-field subscriptSizing="dynamic">
            <input appNumericInput matInput formControlName="zipCode" placeholder="00000" maxlength="10" required>
        </mat-form-field>
        <mat-error *ngIf="(zipCode?.hasError('required') && zipCode?.dirty) || (zipCode?.hasError('required') && zipCode?.touched)">
            {{ 'signup-form.' + signUpRole + '.step-area.zipCodeRequiredError' | translate }}
        </mat-error>
    </div>

    <div align="end" class="custom-theme btn-container storybook-field">
        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">{{ step.btnText2 | translate }}</button>
    </div>
</form>


