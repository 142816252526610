import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { UserRoles } from '../../../core/models/user-info.interface';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { Student, Teacher } from '../../services/api/api.type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-match-student',
  templateUrl: './confirm-match-student.component.html',
  styleUrls: ['./confirm-match-student.component.scss']
})
export class ConfirmMatchStudentComponent implements OnInit {
    protected readonly UserRoles = UserRoles;
    teacherId: string = '';
    studentId: string = '';
    teacher: Teacher;
    student: Student;
    scheduleSlot: {
        slots: string[];
        teacherId: string;
        studentId: string;
        modalDescription: {
            title: string,
            question: string,
        };
        multiple: boolean;
        hasTeacher: boolean;
    }
    private _confirmTeacherMatch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public confirmTeacherMatch$: Observable<boolean> = this._confirmTeacherMatch$.asObservable();
    constructor(private route: ActivatedRoute,
                private readonly apiService: ApiService,
                private translateService: TranslateService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.teacherId = params['teacherId'];
            this.studentId = params['studentId'];
        });

        this.apiService.getMatching(this.teacherId, this.studentId).subscribe(
            (data) => {
                const modalDescription = {
                    title: this.translateService.instant('matching.time_select.titleCard'),
                    question: this.translateService.instant('matching.time_select.description')
                }
                this.scheduleSlot = {
                    slots: data.timeSlots,
                    teacherId: this.teacherId,
                    studentId: this.studentId,
                    modalDescription,
                    multiple: false,
                    hasTeacher: true
                }
            });
    }
    redirectToLogin() {
        this.router.navigate(['/app']).then(() => {
            this.router.navigate(['/login']);
        });
    }

    matchedConfirm() {
        this._confirmTeacherMatch$.next(true);
    }

}
