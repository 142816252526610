import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../services/api/api.service';
import { UserService } from '../../services/user/user.service';
import { UserRoles } from '../../../core/models/user-info.interface';
import { Avatar } from '../../services/api/api.type';
import imageCompression from 'browser-image-compression';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface UploadImageData {
    firstName: string;
    files: Avatar[];
    userId: string;
    role: UserRoles;
}

@Component({
  selector: 'app-select-image',
  templateUrl: './select-image.component.html',
  styleUrls: ['./select-image.component.scss']
})
export class SelectImageComponent {
    errorMessage: null | string;
    imageUrl: string | ArrayBuffer | null;
    ondrop = false;
    @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
    imageChangedEvent: Event | DragEvent;
    croppedImage: SafeUrl = '';
    filePath: string = '';
    selectedImage: Blob | null | undefined;
    loading = false;
    constructor(public dialogRef: MatDialogRef<SelectImageComponent>,
                private sanitizer: DomSanitizer,
                private translationService: TranslateService,
                private apiService: ApiService,
                private userService: UserService,
                private snackBar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) public data: UploadImageData) {
    }

    get getUserName() {
        return this.data?.firstName;
    }

    get isRoleSchool() {
        return UserRoles.Organisation === this.data?.role;
    }

    async onSubmit() {
        if (this.selectedImage) {
            this.loading = true;
            let finalImage: Blob;
            if (this.selectedImage.size > 5 * 1024 * 1024) {
                const options = {
                    maxSizeMB: 5,
                }
                finalImage = await imageCompression(<File>this.selectedImage, options);
            } else {
                finalImage = this.selectedImage;
            }
            this.apiService.uploadFile(finalImage, this.data?.userId, this.data.role, this.data.files).subscribe(() => {
                this.dialogRef.close(true);
                this.loading = false;
            }, () => {
                this.loading = false;
            });
        }
    }

    closeDialog(update = false) {
        this.dialogRef.close(update);
    }

    onDragOver(event: DragEvent | Event) {
        this.ondrop = true;
        event.preventDefault();
        event.stopPropagation();
    }

    onDragLeave(event: DragEvent) {
        this.ondrop = false;
        event.preventDefault();
        event.stopPropagation();
    }

    onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.ondrop = true;
        const file = event?.dataTransfer?.files[0];
        if (file) {
            this.onFileSelected(file, true);
        }
    }

    onFileSelected(event: Event | any, fromDrop = false) {
        let file;
        if (fromDrop) {
            file = event;
        } else {
            file = event?.target?.files[0];
        }
        if (file) {
            const fileName = file.name.split('\\').pop().split('/').pop();
            this.filePath = `.../${fileName}`;
            if (!['image/png', 'image/jpeg'].includes(file.type)) {
                this.ondrop = false;
                this.errorMessage = this.translationService.instant('select_upload_image.invalid_image_type');
                this.errorMessage && this.openSnackBar(this.errorMessage);
                return;
            }
            if (file.size > 5 * 1024 * 1024) {
                this.ondrop = false;
                this.errorMessage = this.translationService.instant('select_upload_image.image_size_exceeds_limit');
                this.errorMessage && this.openSnackBar(this.errorMessage);
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                this.errorMessage = null;
                this.imageUrl = reader.result;
            };

            let selectedEvent = event;
            if (fromDrop) {
                selectedEvent = {
                    isTrusted: true,
                    target: {
                        files: [event]
                    }
                };
            }
            this.imageChangedEvent = selectedEvent;

            reader.onerror = () => {
                this.ondrop = false;
                this.errorMessage = this.translationService.instant('select_upload_image.error_occurred');
                this.errorMessage && this.openSnackBar(this.errorMessage);
                this.imageUrl = null;
            };
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        if (event.objectUrl != null) {
            this.selectedImage = event.blob;
            this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
        }
    }

    change() {
        this.fileInput.nativeElement.click();
    }

    openSnackBar(error: string) {
        this.snackBar.open(error, '', {
            duration: 4000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['custom-snackBar-position']
        });
    }
}
