<div class="tab-container" appTabNavigation [containerSelector]="'.tab-container'">
<div class="form-content sign-up-form organisation">
    <form [formGroup]="formGroup">
        <div class="form-title personal-data">{{ 'signup-form.school_admin.step-profile.title' | translate }}</div>
        <div class="form-row additional-spacer-bottom">
            <div class="form-column">
                <div class="storybook-field form-field">
                    <p>{{ 'signup-form.school_admin.step-profile.namePlaceholder' | translate }}</p>
                    <mat-form-field subscriptSizing="dynamic">
                        <input tabindex="1" placeholder="{{ 'signup-form.school_admin.step-profile.namePlaceholder' | translate }}" matInput maxlength="100" formControlName="name" required>
                    </mat-form-field>
                    <mat-error *ngIf="getFieldProperties('name').isRequired">{{ 'signup-form.school_admin.step-profile.form.error.nameIsRequired' | translate }}</mat-error>
                </div>
                <div class="storybook-field form-field">
                    <p>{{ 'signup-form.school_admin.step-profile.zipCodeLabel' | translate }}</p>
                    <mat-form-field subscriptSizing="dynamic">
                        <input tabindex="3" appNumericInput matInput formControlName="postalCode" placeholder="00000" maxlength="10">
                    </mat-form-field>
                    <mat-error *ngIf="getFieldProperties('postalCode').isRequired">{{ 'signup-form.school_admin.step-profile.form.error.postalCodeIsRequired' | translate }}</mat-error>
                </div>
            </div>

            <div class="form-column">
                <div class="storybook-field form-field">
                    <p>{{ 'signup-form.school_admin.step-profile.sponsorPlaceholder' | translate }}</p>
                    <p class="small-label">{{ 'signup-form.school_admin.step-profile.optional' | translate }}</p>
                    <mat-form-field subscriptSizing="dynamic">
                        <input placeholder="{{ 'signup-form.school_admin.step-profile.sponsorPlaceholder' | translate }}" maxlength="100" matInput formControlName="sponsoringCompany" tabindex="2">
                    </mat-form-field>
                    <mat-error *ngIf="getFieldProperties('name').hasAllErrors"></mat-error>
                </div>


                <div class="storybook-field form-field mobile">
                    <p>{{ 'signup-form.school_admin.step-profile.websiteLabel' | translate }}</p>
                    <p class="small-label">{{ 'signup-form.school_admin.step-profile.optional' | translate }}</p>
                    <mat-form-field subscriptSizing="dynamic">
                        <input tabindex="4" placeholder="{{ 'signup-form.school_admin.step-profile.websitePlaceholder' | translate }}" matInput formControlName="website">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="storybook-field form-field mobile">
            <p>{{ 'signup-form.school_admin.step-profile.descriptionLabel' | translate }}</p>
            <p class="small-label">{{ 'signup-form.school_admin.step-profile.optional' | translate }}</p>
            <mat-form-field>
                <textarea tabindex="5" placeholder="{{ 'signup-form.school_admin.step-profile.descriptionPlaceholder' | translate }}" maxlength="300" matInput formControlName="description"></textarea>
            </mat-form-field>
        </div>

        <div class="form-row">
            <div class="form-column">
                <div class="storybook-field form-field mobile" *ngFor="let control of socialMediaGroup?.controls; let i = index" >
                    <p *ngIf="i < 1">{{ 'signup-form.school_admin.step-profile.socialMedia.label' | translate }}</p>
                    <p *ngIf="i < 1" class="small-label">{{ 'signup-form.school_admin.step-profile.optional' | translate }}</p>
                    <mat-form-field subscriptSizing="dynamic">
                        <div *ngIf="control.get('custom').value" class="custom-type-wrapper">
                            <input id="custom-{{i}}" #customInput placeholder="{{ 'signup-form.school_admin.step-profile.socialMedia.placeholder' | translate }}" matInput
                                   [formControl]="control.get('socialMedia')">
                            <mat-icon (click)="clearInput(control)">clear</mat-icon>
                        </div>
                        <mat-select tabindex="6" *ngIf="!control.get('custom').value" (selectionChange)="onSelectSocialMedia($event, control, i)" placeholder="{{ 'signup-form.school_admin.step-profile.socialMedia.placeholder' | translate }}" [ngClass]="{'default-select-icon-color': !((control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched))}" [formControl]="control.get('socialMedia')">
                            <mat-option *ngFor="let option of selectOptions"
                                        [value]="option.value">{{ option.label }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error
                        *ngIf="(control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched)">
                        {{ 'signup-form.school_admin.step-profile.form.error.socialMediaIsRequired' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="!!((control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched)) &&
                        !((control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched))">
                    </mat-error>
<!--                    <p *ngIf="i < 1" style="visibility: hidden">.</p>-->
                    <div class="todo-list mobile">
                        <mat-form-field  subscriptSizing="dynamic">
                            <input placeholder="{{ 'signup-form.school_admin.step-profile.socialMedia.' +
                            (['TikTok', 'Instagram'].includes(control.get('socialMedia').value) ?
                            'selectedPlaceholder.' + control.get('socialMedia').value :
                            'selectedPlaceholder.custom') | translate }}" matInput  [formControl]="control.get('link')">
                        </mat-form-field>
                        <button mat-icon-button class="example-icon" [disabled]="socialMediaGroup?.controls.length < 2" (click)="removeSocial(i)">
                            <mat-icon class="close-icon" [ngClass]="{'disabled': socialMediaGroup?.controls.length < 2}">close</mat-icon>
                        </button>
                    </div>
                    <div class="error-wrapper mobile">
                    <mat-error
                        *ngIf="(control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched)">
                        {{ 'signup-form.school_admin.step-profile.form.error.socialMediaUrlIsRequired' | translate }}
                    </mat-error>
                    </div>
                </div>
            </div>
            <div class="form-column desktop">
                <div  *ngFor="let control of socialMediaGroup?.controls; let i = index" class="form-field storybook-field">
                    <p *ngIf="i < 1" style="visibility: hidden">.</p>
                    <div class="todo-list">
                        <mat-form-field  subscriptSizing="dynamic">
                            <input tabindex="7" placeholder="{{ 'signup-form.school_admin.step-profile.socialMedia.' +
                            (['TikTok', 'Instagram'].includes(control.get('socialMedia').value) ?
                            'selectedPlaceholder.' + control.get('socialMedia').value :
                            'selectedPlaceholder.custom') | translate }}" matInput  [formControl]="control.get('link')">
                        </mat-form-field>
                        <button mat-icon-button class="example-icon" [disabled]="socialMediaGroup?.controls.length < 2" (click)="removeSocial(i)">
                            <mat-icon class="close-icon" [ngClass]="{'disabled': socialMediaGroup?.controls.length < 2}">close</mat-icon>
                        </button>
                    </div>
                    <mat-error
                        *ngIf="(control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched)">
                        {{ 'signup-form.school_admin.step-profile.form.error.socialMediaUrlIsRequired' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="!!((control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.dirty) || (control.get('socialMedia')?.hasError('requiredSocialMedia') && control.get('socialMedia')?.touched)) &&
                        !((control.get('link')?.hasError('requiredLink') && control.get('link')?.dirty) || (control.get('link')?.hasError('requiredLink') && control.get('link')?.touched))">
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="custom-theme add-more">
            <button tabindex="8" mat-stroked-button color="primary" (click)="addSocialMediaControls()">{{'signup-form.school_admin.step-profile.btnAddAnother' | translate }}</button>
        </div>

        <div class="form-row additional-spacer-top">
            <div class="form-column">
                <div class="form-field storybook-field mobile" *ngFor="let control of associationsGroup?.controls; let i = index" >
                    <p *ngIf="i < 1">{{ 'signup-form.school_admin.step-profile.associations.label' | translate }}</p>
                    <p *ngIf="i < 1" class="small-label">{{ 'signup-form.school_admin.step-profile.optional' | translate }}</p>
                    <mat-form-field  subscriptSizing="dynamic">
                        <input tabindex="9" placeholder="{{ 'signup-form.school_admin.step-profile.associations.namePlaceholder' | translate }}" matInput [formControl]="control.get('name')">
                    </mat-form-field>
                    <mat-error
                        *ngIf="(control.get('name')?.hasError('requiredName') && control.get('name')?.dirty) || (control.get('name')?.hasError('requiredName') && control.get('name')?.touched)">
                        {{ 'signup-form.school_admin.step-profile.form.error.associationNameIsRequired' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="!!((control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.dirty) || (control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.touched)) &&
                        !((control.get('name')?.hasError('requiredName') && control.get('name')?.dirty) || (control.get('name')?.hasError('requiredName') && control.get('name')?.touched))">
                    </mat-error>
                    <div class="todo-list mobile">
                        <mat-form-field  subscriptSizing="dynamic">
                            <input placeholder="{{ 'signup-form.school_admin.step-profile.associations.membershipPlaceholder' | translate }}" matInput  [formControl]="control.get('membership')">
                        </mat-form-field>
                        <button mat-icon-button class="example-icon" [disabled]="associationsGroup?.controls.length < 2" (click)="removeAssociations(i)">
                            <mat-icon class="close-icon" [ngClass]="{'disabled': associationsGroup?.controls.length < 2}">close</mat-icon>
                        </button>
                    </div>
                    <mat-error
                        *ngIf="(control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.dirty) || (control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.touched)">
                        {{ 'signup-form.school_admin.step-profile.form.error.membershipNumberIsRequired' | translate }}
                    </mat-error>
                </div>
            </div>
            <div class="form-column desktop">
                <div  *ngFor="let control of associationsGroup?.controls; let i = index" class="form-field storybook-field">
                    <p *ngIf="i < 1" style="visibility: hidden">.</p>
                    <div class="todo-list">
                        <mat-form-field  subscriptSizing="dynamic">
                            <input tabindex="10" placeholder="{{ 'signup-form.school_admin.step-profile.associations.membershipPlaceholder' | translate }}" matInput  [formControl]="control.get('membership')">
                        </mat-form-field>
                        <button mat-icon-button class="example-icon" [disabled]="associationsGroup?.controls.length < 2" (click)="removeAssociations(i)">
                            <mat-icon class="close-icon" [ngClass]="{'disabled': associationsGroup?.controls.length < 2}">close</mat-icon>
                        </button>
                    </div>
                    <mat-error
                        *ngIf="(control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.dirty) || (control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.touched)">
                        {{ 'signup-form.school_admin.step-profile.form.error.membershipNumberIsRequired' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="!!((control.get('name')?.hasError('requiredName') && control.get('name')?.dirty) || (control.get('name')?.hasError('requiredName') && control.get('name')?.touched)) && !((control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.dirty) || (control.get('membership')?.hasError('requiredMembership') && control.get('membership')?.touched))">
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="custom-theme add-more">
            <button tabindex="11" mat-stroked-button color="primary" (click)="addAssociationsControls()">{{'signup-form.school_admin.step-profile.btnAddAnother' | translate }}</button>
        </div>

        <div class="form-title personal-data matches">{{ 'signup-form.school_admin.step-profile.sub_title' | translate }}</div>

        <div class="form-row">
            <div class="form-column">
                <div class="form-field storybook-field mobile" *ngFor="let control of priceRangeGroup?.controls; let i = index" >
                    <p *ngIf="i < 1">{{ 'signup-form.school_admin.step-profile.priceLabel' | translate }}</p>
                    <mat-form-field  subscriptSizing="dynamic">
                            <input tabindex="12" currencyInput placeholder="{{ 'signup-form.school_admin.step-profile.pricePlaceholder' | translate }}" matInput [formControl]="control.get('price')" required>
                    </mat-form-field>
                    <mat-error
                        *ngIf="(control.get('price')?.hasError('required') && control.get('price')?.dirty) || (control.get('price')?.hasError('required') && control.get('price')?.touched)">
                        {{ 'signup-form.school_admin.step-profile.form.error.priceIsRequired' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="!!((control.get('length')?.hasError('required') && control.get('length')?.dirty) || (control.get('length')?.hasError('required') && control.get('length')?.touched)) &&
                        !((control.get('price')?.hasError('required') && control.get('price')?.dirty) || (control.get('price')?.hasError('required') && control.get('price')?.touched))">
                    </mat-error>
                    <div class="todo-list mobile">
                        <mat-form-field  subscriptSizing="dynamic">
                            <input placeholder="{{ 'signup-form.school_admin.step-profile.unitPlaceholder' | translate }}" matInput [formControl]="control.get('length')" required>
                        </mat-form-field>
                        <button mat-icon-button class="example-icon" [disabled]="priceRangeGroup?.controls.length < 2" (click)="removePriceRange(i)">
                            <mat-icon class="close-icon" [ngClass]="{'disabled': priceRangeGroup?.controls.length < 2}">close</mat-icon>
                        </button>
                    </div>
                    <mat-error class="error mobile"
                        *ngIf="(control.get('length')?.hasError('required') && control.get('length')?.dirty) || (control.get('length')?.hasError('required') && control.get('length')?.touched)">
                        {{ 'signup-form.school_admin.step-profile.form.error.unitIsRequired' | translate }}
                    </mat-error>
                </div>
            </div>
            <div class="form-column desktop">
                <div  *ngFor="let control of priceRangeGroup?.controls; let i = index" class="form-field storybook-field">
                    <p *ngIf="i < 1" style="visibility: hidden">.</p>
                    <div class="todo-list">
                        <mat-form-field  subscriptSizing="dynamic">
                            <input tabindex="13" placeholder="{{ 'signup-form.school_admin.step-profile.unitPlaceholder' | translate }}" matInput [formControl]="control.get('length')" required>
                        </mat-form-field>
                        <button mat-icon-button class="example-icon" [disabled]="priceRangeGroup?.controls.length < 2" (click)="removePriceRange(i)">
                            <mat-icon class="close-icon" [ngClass]="{'disabled': priceRangeGroup?.controls.length < 2}">close</mat-icon>
                        </button>
                    </div>
                    <mat-error
                        *ngIf="(control.get('length')?.hasError('required') && control.get('length')?.dirty) || (control.get('length')?.hasError('required') && control.get('length')?.touched)">
                        {{ 'signup-form.school_admin.step-profile.form.error.unitIsRequired' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="!!((control.get('price')?.hasError('required') && control.get('price')?.dirty) || (control.get('price')?.hasError('required') && control.get('price')?.touched)) && !((control.get('length')?.hasError('required') && control.get('length')?.dirty) || (control.get('length')?.hasError('required') && control.get('length')?.touched))">
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="custom-theme add-more additional-spacer-bottom">
            <button tabindex="14" mat-stroked-button color="primary" (click)="addPriceRangeControls()">{{'signup-form.school_admin.step-profile.btnAddAnother' | translate }}</button>
        </div>

        <div class="form-row">
            <div class="form-column">
                <div class="storybook-field form-field">
                    <p class="ellipsis">{{ 'signup-form.school_admin.step-profile.notifyLabel' | translate }}</p>
                    <mat-form-field  subscriptSizing="dynamic">
                        <mat-select tabindex="15" placeholder="{{ 'signup-form.school_admin.step-profile.notifyPlaceholder' | translate }}"  [ngClass]="{'default-select-icon-color': !getFieldProperties('matchNotification', formGroup).hasAllErrors}"  formControlName="matchNotification">
                            <mat-option *ngFor="let option of matchNotificationOptions"
                                        [value]="option.value">{{ 'match_notification_options.'+ option.value | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="getFieldProperties('matchNotification').isRequired">{{ 'signup-form.school_admin.step-profile.form.error.matchNotificationsIsRequired' | translate }}</mat-error>
                </div>
            </div>
            <div class="form-column">
                <div class="form-field">
                    <p [ngStyle]="{opacity: matchNotification?.value !== 'send_to_another_email' ? 0.2 : 1}">{{ 'organisation-profile.sections.admin.labels.email.label' | translate }}</p>
                    <mat-form-field subscriptSizing="dynamic">
                        <input tabindex="16" #matchEmailInput placeholder="{{ 'organisation-profile.sections.admin.labels.email.placeholder' | translate }}" matInput formControlName="matchEmail" required>
                    </mat-form-field>
                    <mat-error
                        *ngIf="getFieldProperties('matchEmail').isRequired">
                        {{ 'organisation-profile.sections.admin.labels.email.required_error' | translate }}
                    </mat-error>
                    <mat-error *ngIf="getFieldProperties('matchEmail').hasError">
                        {{ 'organisation-profile.sections.admin.labels.email.invalid_error' | translate }}
                    </mat-error>
                </div>
            </div>
        </div>

    </form>
</div>

<div align="end" class="custom-theme btn-container storybook-field">
    <button tabindex="17" mat-stroked-button [color]="step.btn2Color" (click)="backToPrevious()">{{ step.btnText1 | translate }}</button>

    <button tabindex="18" mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">{{ step.btnText2 | translate }}</button>
</div>
</div>

