import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {SharedDataService} from '../music/sign-up/auth/sign-up/shared/shared-data.service';
import {UserService} from '../music/services/user/user.service';
import {UserRoles} from "../core/models/user-info.interface";

@Injectable({
    providedIn: 'root'
})
export class ConfirmGuard implements CanActivate {
    constructor(private router: Router, private sharedDataService: SharedDataService, private userService: UserService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const url = state.url.toLowerCase();

        if (url.startsWith('/auth/registration/') && url.includes('?role=')) {
            const role = route.queryParams['role'];

            const urlParts = url.split('/');
            const confirmationIdWithQueryParams = urlParts.pop();
            let confirmationIdParts: string[] = [];
            if (confirmationIdWithQueryParams != null) {
                 confirmationIdParts = confirmationIdWithQueryParams.split('?');
            }
            const confirmationId = confirmationIdParts[0];

            const userId = urlParts[3];

            if (userId && confirmationId) {
                    this.router.navigate(['/sign-up']).then(() => {
                        this.sharedDataService.setUserConfirmationData = { userId, confirmationId, role };
                });
            }
        }

        if (url.startsWith('/auth/recovery/')) {
            const urlParts = url.split('/');
            const confirmationIdWithQueryParams = urlParts.pop();
            let confirmationIdParts: string[] = [];
            if (confirmationIdWithQueryParams != null) {
                confirmationIdParts = confirmationIdWithQueryParams.split('?');
            }
            const confirmationId = confirmationIdParts[0];

            const userId = urlParts[3];
            if (userId && confirmationId) {
                this.router.navigate(['/sign-up']).then(() => {
                    this.sharedDataService.setUserConfirmationData = { userId, confirmationId, role: null };
                });
            }
        }

        if (url.includes('/schools/') && url.includes('/invite')) {
            const parts = url.split('/');
            const schoolId = parts[parts.length - 2];
            if (schoolId) {
                const navigationExtras: NavigationExtras = {
                    queryParams: {
                        schoolId: schoolId
                    }
                };
                this.router.navigate(['/sign-up'], navigationExtras).then(() => {
                    this.sharedDataService.setSchoolId = schoolId;
                    this.userService.signUpRole = UserRoles.Teacher;
                });
            }
        }
        if (url.startsWith('/match/')) {
            // TODO
        }

        return of(true);
    }
}
