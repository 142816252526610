import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';


const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./music/musiq.module').then(m => m.MusiqModule),
        pathMatch: 'full',
    },
    {
        path: 'sign-up',
        loadChildren: () => import('./music/sign-up/sign-up.module').then(m => m.SignUpModule),
    },
    // TODO
    {
        path: 'app',
        component: AppComponent
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
