<div class="app-student-profile">
    <app-header></app-header>
    <app-notify-panel></app-notify-panel>
    <div *ngIf="student" class="main-container">
        <div class="container-wrapper">

            <ng-container *ngIf="showSchedule; else showConfirmation">
                <app-student-schedule
                    [userStudent]="student"
                    [studentTimeSlots]="studentTimeSlots"
                    (onStudentConfirmed)="confirmMatching($event)"
                    (onStudentDeclined)="declineMatching()">
                </app-student-schedule>
            </ng-container>
            <ng-template #showConfirmation>
                <app-matching-confirmation [userRole]="userRole" [matching]="matching"></app-matching-confirmation>
            </ng-template>
            <app-student-details [userStudent]="student"></app-student-details>
        </div>
    </div>
</div>
