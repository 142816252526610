<div class="form-content">
    <div class="title-container">
        <div class="form-description">{{data.modalDescription.title | translate}}</div>
        <div class="form-question">{{data.modalDescription.question | translate}}</div>
    </div>
    <div *ngIf="!isMobilePlatform" class="day-container-row">
    <div *ngFor="let chip of chips; let i = index" class="day-container">
        <div class="weekday">{{ 'days.' + chip.day | translate }}</div>
        <div class="date">{{ chip.date }}</div>
    </div>
    </div>
    <form [formGroup]="matchingTimeSelectForm" class="grid-container">
        <div class="form-field">
            <mat-grid-list [cols]="isMobilePlatform ? 1 : 7" [rowHeight]="isMobilePlatform ? '754px' : '554px'">
                <mat-grid-tile *ngFor="let chip of chips; let i = index">
                    <div class="chips-container">
                        <div *ngIf="isMobilePlatform" class="day-container">
                            <div class="weekday">{{ 'days.' + chip.day | translate }}</div>
                            <div class="date">{{chip.date}}</div>
                        </div>
                        <mat-chip-listbox
                            multiple
                            formArrayName="availabilityTimeStamp"
                            class="mat-mdc-chip-set-stacked">
                            <mat-chip-option
                                #options
                                [ngStyle]="{'opacity': !setSelectedSlot(data.slots, timeSlot, i) ? 0.2: 1 }"
                                [disabled]="!setSelectedSlot(data.slots, timeSlot, i)"
                                class="timeStamp" (selectionChange)="onSelectTime($event, i, timeSlot)"
                                *ngFor="let timeSlot of chip.time"> {{ timeSlot }}
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                </mat-grid-tile>
                <mat-error class="availability-error"
                           *ngIf="!isValidAvailabilityTimeStamp && availabilityTimeStamp?.touched">
                    {{ 'matching.time_select.availabilityError' | translate }}
                </mat-error>
            </mat-grid-list>
        </div>
    </form>
    <div class="footer">
        <div class="btn-container custom-theme">
            <button mat-stroked-button color="primary" (click)="cancel()">{{ 'matching.time_select.cancelBtn' | translate }}</button>
            <button mat-raised-button color="primary" [disabled]="!isValidAvailabilityTimeStamp" type="submit" (click)="onSubmit()">{{ 'matching.time_select.submitBtn' | translate }}</button>
        </div>
    </div>
</div>
