import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StepperOrientation, StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { UserRoles } from '../../../../../core/models/user-info.interface';
import { Steps } from '../../../../../shared/constants/form-option';
import { PlatformService } from '../../../../services/platform.service';
import { SchoolRegisterData, StudentRegisterData, TeacherRegisterData } from '../shared/shared-data.service';

@Component({
    selector: 'app-mat-stepper',
    templateUrl: './mat-stepper.component.html',
    styleUrls: ['./mat-stepper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatStepperComponent implements OnInit {
    formGroups: FormGroup[] = [];
    proceed = 1;
    protected readonly UserRoles = UserRoles;

    @ViewChild('stepper') stepper: MatStepper;

    @Input() signUpRole: UserRoles;
    @Input() stepData: Steps;
    @Input() isLinear: boolean;
    @Input() orientation: StepperOrientation = 'horizontal';

    @Output() formSubmit: EventEmitter<FormGroup[]> = new EventEmitter<FormGroup[]>();
    @Output() formChanged: EventEmitter<FormGroup[]> = new EventEmitter<FormGroup[]>();
    @Output() result: EventEmitter<{result: SchoolRegisterData | StudentRegisterData | TeacherRegisterData; role: UserRoles | null}> = new EventEmitter<{result: SchoolRegisterData | StudentRegisterData | TeacherRegisterData; role: UserRoles | null}>();

    constructor(private _formBuilder: FormBuilder, public platformService: PlatformService) {}

    ngOnInit(): void {
        this.stepData[this.proceed].forEach(() => {
            this.formGroups.push(this._formBuilder.group({}));
        });
    }


    onFormChanged(index: number): void {
        this.formChanged.emit(this.formGroups);
    }

    onProceed(event: number) {
        this.proceed = event;
        this.stepData[this.proceed].forEach(() => {
            this.formGroups.push(this._formBuilder.group({}));
        });
    }

    onFinish(event: UserRoles | null) {
        const formResults = this.formGroups.map((form) => form.value);
        const mergedResults = formResults.reduce((result, obj) => {
            return { ...result, ...obj };
        }, {});
        this.result.emit({result: mergedResults, role: event})
    }

    selectionChange(event: StepperSelectionEvent) {
        const element = document.querySelector('.date-time-step') as HTMLElement;
        if (this.stepData[3][event.selectedIndex].type === 'dateTime') {
            if (element && !this.platformService.isMobile) {
                element.style.minWidth = '780px';
            }
        } else {
            if (element) {
                element.style.minWidth = '';
            }
        }
    }

    hasDisabledPrevForms(index: number): boolean {
        for (let j = 0; j < index; j++) {
            if (this.formGroups[j] && this.formGroups[j].invalid) {
                return true;
            }
        }
        return false;
    }

}
