<div class="select-image-container">
    <mat-toolbar class="toolbar-form">
        <span>{{ 'select_upload_image.profile_picture' | translate }}</span>
        <span class="example-spacer"></span>
        <button mat-icon-button class="example-icon" (click)="closeDialog()">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </mat-toolbar>

    <div mat-dialog-content class="form-content">
        <div class="form-title personal-data">{{ 'select_upload_image.please_choose_picture' | translate }}</div>
        <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/png, image/jpeg" id="fileInput" style="display: none;" />
        <div *ngIf="!imageChangedEvent" class="image-container" (dragover)="onDragOver($event)" (drop)="onDrop($event)" (dragleave)="onDragLeave($event)" [class.on-drag]="ondrop" >
            <div class="choose-image-container">
                <ng-container *ngIf="!ondrop; else dropInfo">
                <div *ngIf="!ondrop" class="drop-info">{{ errorMessage ? errorMessage : 'select_upload_image.drop_image_here' | translate }}</div>
                <div *ngIf="!ondrop" class="btn-container custom-theme">
                    <button mat-stroked-button color="primary" (click)="fileInput.click()">
                        {{ 'select_upload_image.select_image' | translate }}
                    </button>
                </div>
                </ng-container>
                <ng-template #dropInfo>
                    <div class="drop-info primary">{{ 'select_upload_image.drop_info' | translate }}</div>
                </ng-template>
            </div>
        </div>
        <div class="cropper-wrapper">
            <div class="cropper">
                <image-cropper
                    [imageChangedEvent]="imageChangedEvent"
                    (imageCropped)="imageCropped($event)"
                ></image-cropper>
            </div>
            <div class="cropped-image-container">
                <img [ngStyle]="{ 'display': croppedImage ? 'block' : 'none'}" [src]="croppedImage"/>
                <div *ngIf="croppedImage && !isRoleSchool" class="info-name">{{ getUserName }}</div>
            </div>
        </div>
        <ng-container *ngIf="imageChangedEvent">
            <p class="select-image-label">{{ 'select_upload_image.select_image' | translate }}</p>
            <div class="select-image-wrapper">
                <div class="form-field select-image">
                    <mat-form-field subscriptSizing="dynamic">
                        <input matInput [value]="filePath" readonly>
                    </mat-form-field>
                </div>
                <div class="custom-theme">
                    <button mat-stroked-button color="primary" (click)="change()">
                        {{ 'select_upload_image.select_change' | translate }}
                    </button>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="footer">
        <div class="btn-container custom-theme">
            <div class="right-side">
                <button mat-stroked-button color="primary" (click)="closeDialog()">
                    {{ 'select_upload_image.cancel' | translate }}
                </button>
                <button mat-raised-button color="primary" [disabled]="!selectedImage || loading" type="submit" (click)="onSubmit()">
                    {{loading ? ('select_upload_image.uploading' | translate) : ('select_upload_image.save_profile_picture' | translate) }}
                </button>
            </div>
        </div>
    </div>
</div>
