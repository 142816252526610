// Language codes from ISO 639-2. We use those, because back-end also uses them
export const ENGLISH = 'eng';
export const GERMAN = 'deu';

// Keys are language codes from ISO 639-1 (browsers use them usually)
export const ISO_CONVERTER = {
  en: ENGLISH,
  de: GERMAN
};

export const ISO_CONVERTER_MAPPED = {
    'eng': 'EN',
    'deu': 'DE'
};
