<div class="teacher-profile">
    <app-header></app-header>
    <app-notify-panel></app-notify-panel>
    <div class="main-container">
        <div class="container-wrapper">

            <ng-container *ngIf="showSchedule; else showConfirmation">
                <app-teacher-schedule
                    [userTeacher]="teacher"
                    [teacherTimeSlots]="teacherTimeSlots"
                    (onTeacherConfirmed)="confirmMatching($event)"
                    (onTeacherDeclined)="declineMatching()">
                </app-teacher-schedule>
            </ng-container>
            <ng-template #showConfirmation>
                <app-matching-confirmation [userRole]="userRole" [matching]="matching"></app-matching-confirmation>
            </ng-template>

            <app-teacher-details [userTeacher]="teacher"></app-teacher-details>
        </div>
    </div>
</div>
