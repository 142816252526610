import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserIntroInfo, UserRoles } from '../../../../core/models/user-info.interface';
import { Avatar, Student, Teacher } from '../../../services/api/api.type';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { PlatformService } from '../../../services/platform.service';
import { SelectImageComponent } from '../../select-image/select-image.component';
import { ComponentType } from '@angular/cdk/overlay';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../services/api/api.service';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-dashboard-intro',
  templateUrl: './dashboard-intro.component.html',
  styleUrls: ['./dashboard-intro.component.scss']
})
export class DashboardIntroComponent {
 protected readonly UserRoles = UserRoles;
 @Input() role: UserRoles;
 @Input() userIntroInfo: UserIntroInfo;
 @Input() teacherNotifyData?: { earliestLesson?: string | undefined | Date; student: Student | undefined };
 @Input() studentNotifyData?: { earliestLesson?: string | undefined | Date; teacher: Teacher | undefined };
 @Input() fileUrl?: string;
 @Input() schoolName?: string;
 @Input() files: Avatar[];

 @Output() onAvatarChange: EventEmitter<void> = new EventEmitter<void>();


    get getBackgroundImage(): string {
        switch (this.role) {
            case UserRoles.Student:
                return 'url("/assets/images/header/background_student.jpg") lightgray 50%/cover no-repeat';
            case UserRoles.Teacher:
                return 'url("/assets/images/header/background_teacher.jpg") lightgray 50%/cover no-repeat';
            case UserRoles.Organisation:
                return 'url("/assets/images/header/background_org.jpg") lightgray 50%/cover no-repeat';
            case UserRoles.Root:
                return 'url("/assets/images/header/background_org.jpg") lightgray 50%/cover no-repeat';
            default:
                return 'none';
        }
    }

    get studentFileUrl(): string {
        if (this.teacherNotifyData?.student?.files) {
            return this.getFileUrl(this.teacherNotifyData?.student?.files);
        }
        return '';
    }

    get teacherFileUrl(): string {
        if (this.studentNotifyData?.teacher?.files) {
            return this.getFileUrl(this.studentNotifyData?.teacher?.files);
        }
        return '';
    }

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    constructor(private sanitizer: DomSanitizer,
                private platformService: PlatformService,
                private dialog: MatDialog,
                private apiService: ApiService,
                private userService: UserService) {
    }

    getFileUrl(files: Avatar[]): string {
        if (files && files.length > 0) {
            return files[0].url;
        }
        return '';
    }

    getImageStyle(image?: string): SafeStyle {
        const backgroundImage = `url(${image})`;

        return this.sanitizer.bypassSecurityTrustStyle(`
          background-image: ${backgroundImage};
          background-position: 50% 50%;
          background-size: cover;
          background-repeat: no-repeat;
          height: 100%;
    `);
    }

    upload() {
        this.apiService.getUser(this.userService.userInfo.userId).subscribe((user) => {
            const userId = UserRoles.Organisation === user.role ? user.manageSchoolId : user.id;
            this.openDialog(SelectImageComponent, 'select-image', {
                firstName: user.firstName,
                files: this.files,
                userId,
                role: this.role
            });
        });
    }

    openDialog<T>(
        component: ComponentType<T>,
        panelClass: string,
        dialogData?: { firstName: string; files: Avatar[]; userId: string; role: string; },
        width: string = '800px'
    ): void {
        document.body.classList.add('modal-open');

        const dialogRef: MatDialogRef<T> = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width,
            autoFocus: false,
            disableClose: false,
            position: { top: '32px' },
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                this.onAvatarChange.emit();
            }
            console.log('Dialog closed result', result);
        });
    }

}
