import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, delay, Observable, switchMap } from 'rxjs';
import { ApiService } from '../../services/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { Student ,Teacher } from '../../services/api/api.type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-match-teachers',
  templateUrl: './match-teachers.component.html',
  styleUrls: ['./match-teachers.component.scss'],
})
export class MatchTeachersComponent implements OnInit {

    private _showSearchingMatch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public showSearchingMatch$: Observable<boolean> = this._showSearchingMatch$.asObservable();
    private _confirmTeacherMatch$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public confirmTeacherMatch$: Observable<boolean> = this._confirmTeacherMatch$.asObservable();
    studentId: string;
    teachers: Teacher[];
    student: Student;

    constructor(
        private apiService: ApiService,
        private translateService: TranslateService,
        private route: ActivatedRoute) {
    }
    ngOnInit() {
        this.studentId = this.getStudentIdFromRoute();
        this.apiService.getStudent(this.studentId).pipe(
            switchMap((data) => {
                const matchBody = {
                    instruments: data.student?.instruments,
                    genres: data.student?.genres
                }
                this.student = data;
                return this.apiService.requestStudentMatching(this.studentId, matchBody);
            }),
            delay(3000)
        ).subscribe(
            (teachers) => {
                this.teachers = teachers;
                this._showSearchingMatch$.next(false);
            }
        );
    }

    getStudentIdFromRoute(): string {
        const snapshot = this.route.snapshot;
        return snapshot.paramMap.get('studentId') || '';
    }

    matchedConfirm() {
        this._confirmTeacherMatch$.next(true);
    }

    mailTo(type: string) {
        let email = '';
        const subject = this.translateService.instant('notify-panel.mail-subject')
        if (type === 'feedback') {
            email = `mailto:support@musiq.me?subject=${encodeURIComponent(subject)}`;
        } else if (type === 'admin') {
            email = `mailto:admin@musiq.me?subject=${encodeURIComponent(subject)}`;
        }
        window.open(email, '_blank');
    }

}
