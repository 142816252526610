import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private dialog: MatDialog) {}

    openDialog(data: any,  Component: any, config?: MatDialogConfig): Observable<any> {
        const dialogRef: MatDialogRef<any> = this.dialog.open(
            Component,
            {
                ...config,
                data,
            }
        );
        return dialogRef.afterClosed();
    }

    isDialogOpen(): boolean {
        return this.dialog.openDialogs.length > 0;
    }
}
