import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-match-teacher-confirm',
  templateUrl: './match-teacher-confirm.component.html',
  styleUrls: ['./match-teacher-confirm.component.scss']
})
export class MatchTeacherConfirmComponent {
    constructor(private router: Router) {
    }
    redirectToLogin() {
        this.router.navigate(['/app']).then(() => {
            this.router.navigate(['/login']);
        });
    }
}
