import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { UserRoles } from '../../../../../../core/models/user-info.interface';
import { UserService } from '../../../../../services/user/user.service';
import { filter, Subject, takeUntil } from 'rxjs';
import { EMAIL_VALIDATION_REG, Step } from '../../../../../../shared/constants/form-option';

@Component({
  selector: 'app-organisation-sign-up',
  templateUrl: './organisation-sign-up.component.html',
  styleUrls: ['./organisation-sign-up.component.scss']
})
export class OrganisationSignUpComponent implements OnInit {
    @ViewChildren('customInput') inputs: QueryList<ElementRef>;

    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Output() submitForm = new EventEmitter<void>();
    @Output() finish = new EventEmitter<UserRoles | null>();
    @Input() stepper: MatStepper;
    @Output() proceed = new EventEmitter<number>();
    @Input() part: number;

    @ViewChild('matchEmailInput') matchEmailInput!: ElementRef;

    get associationsGroup(): any {
        return this.formGroup.get('associationsGroup') as FormArray;
    }
    get socialMediaGroup(): any {
        return this.formGroup.get('socialMediaGroup') as FormArray;
    }

    get priceRangeGroup(): any {
        return this.formGroup.get('priceRangeGroup') as FormArray;
    }

    get matchNotification() {
        return this.formGroup.get('matchNotification');
    }

    get matchEmail() {
        return this.formGroup.get('matchEmail');
    }

    get price() {
        return this.formGroup.get('price');
    }

    selectOptions = [
        { label: 'Instagram', value: 'Instagram' },
        { label: 'Facebook', value: 'Facebook' },
        { label: 'X', value: 'X' },
        { label: 'TikTok', value: 'TikTok' },
        { label: 'YouTube', value: 'YouTube' },
        { label: 'LinkedIn', value: 'LinkedIn' },
        { label: 'Custom', value: 'custom' }
    ];

    unitOptions = [
        { label: '30 min', value: '30m' },
        { label: '45 min', value: '45m' },
        { label: '60 min', value: '60m' },
    ];

    matchNotificationOptions = [
        { value: 'send_to_teacher' },
        { value: 'send_to_school' },
        { value: 'send_to_another_email' },
        { value: 'send_to_school_and_teacher'}
    ];

    destroy$ = new Subject();

    constructor(
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        private userService: UserService) {}

    ngOnInit(): void {
        this.addControls();
        if (this.associationsGroup?.controls?.length === 0) {
            this.addAssociationsControls();
        }
        if (this.socialMediaGroup?.controls?.length === 0) {
            this.addSocialMediaControls();
        }
        if (this.priceRangeGroup?.controls?.length === 0) {
            this.addPriceRangeControls();
        }

        this.price?.valueChanges.pipe(
            filter(_ => !this.price?.pending),
            takeUntil(this.destroy$)
        ).subscribe((val) => {
            if (val) {
                val = val.replace(/€/g, '');
                val = val + '€';
                this.price?.setValue(val, { emitEvent: false });
            } else {
                this.price?.setValue(null);
            }
        });
    }

    addControls(): void {
        if (this.formGroup && Object.keys(this.formGroup?.controls)?.length === 0) {
            this.formGroup.addControl('name', this.fb.control('', Validators.required));
            this.formGroup.addControl('sponsoringCompany', this.fb.control(''));
            this.formGroup.addControl('postalCode', this.fb.control('', Validators.required));
            this.formGroup.addControl('matchNotification', this.fb.control('send_to_teacher', Validators.required));
            this.formGroup.addControl('matchEmail', this.fb.control('', [Validators.required, Validators.pattern(EMAIL_VALIDATION_REG)]));
            this.formGroup.addControl('description', this.fb.control(''));
            this.formGroup.addControl('website', this.fb.control(''));
            this.formGroup.addControl('associationsGroup',this.fb.array([]));
            this.formGroup.addControl('socialMediaGroup',this.fb.array([]));
            this.formGroup.addControl('priceRangeGroup',this.fb.array([]));
        }
        this.matchEmail?.disable();

        this.matchNotification?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            if (value !== 'send_to_another_email') {
                this.matchEmail?.disable();
                this.matchEmail?.setValue(null);
            } else {
                this.matchEmail?.enable();
                this.setFocusOnMatchEmailInput();
            }
        });
    }

    getFieldProperties(fieldName: string, controlField?:AbstractControl<any> | null): {
        control: AbstractControl<any> | null;
        hasError: boolean;
        isRequired: boolean;
        hasAllErrors: boolean;
    } {
        let control: AbstractControl<any> | null;
        if (controlField) {
            control = controlField.get(fieldName);
        } else {
            control = this.formGroup.get(fieldName);
        }
        const isRequired = !!((control?.hasError('required') && control?.dirty) || (control?.hasError('required') && control?.touched));
        const hasError = !!((control?.errors && !control?.hasError('required') && control?.dirty) || (control?.errors && !control?.hasError('required') && control?.touched));
        const hasAllErrors = isRequired || hasError;
        return { control, isRequired, hasError, hasAllErrors } ;
    }

    addAssociationsControls() {
            const control = this.fb.group({
                name: [ '', this.crossFieldValidator.bind(this) ],
                membership: [ '', this.crossFieldValidator.bind(this)]
            });
            this.associationsGroup.push(control);
    }

    addSocialMediaControls() {
            const control = this.fb.group({
                socialMedia: [ '', this.crossFieldSocialMediaValidator.bind(this) ],
                link: [ '', this.crossFieldSocialMediaValidator.bind(this) ],
                custom: [ false, Validators.required ]
            });
            this.socialMediaGroup.push(control);
    }

    addPriceRangeControls() {
        const control = this.fb.group({
            price: ['', Validators.required],
            length: ['', Validators.required]
        });
        control.get('price')?.valueChanges.pipe(
            filter(_ => !control.get('price')?.pending),
            takeUntil(this.destroy$)
        ).subscribe((val) => {
            if (val) {
                val = val.replace(/€/g, '');
                val = val + '€';
                control.get('price')?.setValue(val, { emitEvent: false });
            } else {
                control.get('price')?.setValue(null);
            }
        });
        control.get('length')?.valueChanges.pipe(
            filter(_ => !control.get('length')?.pending),
            takeUntil(this.destroy$)
        ).subscribe((val) => {
            if (val) {
                let numericPart = val.replace(/[^\d]/g, '');
                if (numericPart) {
                    let num = parseInt(numericPart, 10);
                    if (num < 1) {
                        num = 1;
                    } else if (num > 60) {
                        num = 60;
                    }
                    val = num + ' min';
                } else {
                    val = '';
                }
                control.get('length')?.setValue(val, { emitEvent: false });
            } else {
                control.get('length')?.setValue(null);
            }
        });
        this.priceRangeGroup.push(control);
    }

    crossFieldValidator(control: AbstractControl): ValidationErrors | null {
        const name = control?.parent?.get('name');
        const membership = control?.parent?.get('membership');

        if (membership?.value && !name?.value) {
            name?.setErrors({ requiredName: true });
            name?.markAsTouched();
            return { requiredName: true };
        }

        name?.setErrors(null);
        return null;
    }

    crossFieldSocialMediaValidator(control: AbstractControl): ValidationErrors | null {
        const socialMediaValue = control?.parent?.get('socialMedia');
        const linkValue = control?.parent?.get('link');

        if (socialMediaValue?.value && !linkValue?.value) {
            linkValue?.setErrors({ requiredLink: true });
            linkValue?.markAsTouched();
            return { requiredLink: true };
        }

        if (linkValue?.value && !socialMediaValue?.value) {
            socialMediaValue?.setErrors({ requiredSocialMedia: true });
            socialMediaValue?.markAsTouched();
            return { requiredSocialMedia: true };
        }

        socialMediaValue?.setErrors(null);
        linkValue?.setErrors(null);
        return null;
    }

    removeAssociations(index: number) {
        this.associationsGroup.removeAt(index);
    }

    removeSocial(index: number) {
        this.socialMediaGroup.removeAt(index);
    }

    removePriceRange(index: number) {
        this.priceRangeGroup.removeAt(index);
    }

    onSubmit() {
        this.submitForm.emit();
        this.finish.emit(this.userService.signUpRole);
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    onSelectSocialMedia(event: MatSelectChange, control: FormControl, index: number) {
        if (event.value === 'custom') {
            control.get('socialMedia')?.setValue('');
            control.get('custom')?.setValue(true);
            this.cd.detectChanges();
            const input = this.inputs.toArray().find(input => input.nativeElement.id === `custom-${index}`);
            input?.nativeElement.focus();
        }
    }

    clearInput(control: FormControl) {
        control.get('socialMedia')?.setValue('');
        control.get('custom')?.setValue(false);
    }

    backToPrevious() {
       console.log( this.formGroup)
      //  this.proceed.emit(this.part -1);
    }

    private setFocusOnMatchEmailInput(): void {
        setTimeout(() => {
            if (this.matchEmailInput) {
                (this.matchEmailInput.nativeElement as HTMLInputElement).focus();
            }
        })
    }

}
