import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lesson-booked-confirm',
  templateUrl: './lesson-booked-confirm.component.html',
  styleUrls: ['./lesson-booked-confirm.component.scss']
})
export class LessonBookedConfirmComponent {
    constructor(private router: Router) {
    }
    redirectToLogin() {
        this.router.navigate(['/app']).then(() => {
            this.router.navigate(['/login']);
        });
    }
}
