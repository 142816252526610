import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { UserIntroInfo, UserRoles } from '../../../../../core/models/user-info.interface';
import { Avatar, Student, Teacher, TeachersResponse } from '../../../../services/api/api.type';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../../services/api/api.service';
import {
    MatchingTimeSelectComponent,
    TimeSelect
} from '../../../match-teachers/matching-time-select/matching-time-select.component';
import { DashboardTeacherTableComponent } from '../dashboard-teacher-table/dashboard-teacher-table.component';
import { UserService } from '../../../../services/user/user.service';
import { TeacherProfileInfoComponent } from '../teacher-profile-info/teacher-profile-info.component';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'app-student-teachers-list-mobile',
  templateUrl: './student-teachers-list-mobile.component.html',
  styleUrls: ['./student-teachers-list-mobile.component.scss']
})
export class StudentTeachersListMobileComponent {

    protected readonly UserRoles = UserRoles;
    currentIndex: number;
    destroy$ = new Subject();

    @Input() role: UserRoles;
    @Input() teachers: TeachersResponse;
    @Input() schoolId: string;
    @Input() student: Student;
    @Input() studentNotifyData?: { earliestLesson?: string | undefined | Date; teacher: Teacher | undefined };
    @Output() onLessonTimeSelect = new EventEmitter();

    get teacherFileUrl(): string {
        if (this.studentNotifyData?.teacher?.files) {
            return this.getFileUrl(this.studentNotifyData?.teacher?.files);
        }
        return '';
    }

    get userIntroInfo(): UserIntroInfo {
        return this.userService.userIntroInfo;
    }

    constructor(private dialog: MatDialog,
                private userService: UserService,
                private apiService: ApiService) {
    }

    getFileUrl(files: Avatar[]): string {
        if (files && files.length > 0) {
            return files[0].url;
        }
        return '';
    }

    openTimeSelectDialog(component: ComponentType<MatchingTimeSelectComponent>, panelClass: string, dialogData: TimeSelect, width = '864px', position = '32px'): void {
        document.body.classList.add('modal-open');
        const config = {
            panelClass: panelClass,
            hasBackdrop: true,
            width: '100vw',
            height: '95vh',
            maxWidth: '100vw',
            disableClose: true,
            autoFocus: false,
            position: {top: '48px'},
            data: dialogData
        }
        const dialogRef = this.dialog.open(component, config);

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                this.getTeachers(dialogData);
                this.onLessonTimeSelect.emit();
            }
            console.log('Dialog closed result', result);
        });
    }

    getTeachers(dialogData: TimeSelect) {
        this.apiService.getTeachers( '1', '1000').subscribe(
            (teachers: TeachersResponse) => {
                this.teachers.list.length = 0;
                teachers.list.forEach((item) => {
                    this.teachers?.list.push(item);
                });
                const teacherId = dialogData.teacher?.teacher.id;
                const selectedTeacher = this.teachers.list.find((teacher: Teacher) => teacher?.teacher.id === teacherId);
                if (selectedTeacher) {
                    const profileDialog = dialogData.modal as TeacherProfileInfoComponent;
                    profileDialog.setTeacher(selectedTeacher);
                }
            });
    }

    openTeacherList() {
        this.openTeacherListDialog(DashboardTeacherTableComponent, 'teacher-dashboard');
    }


    openTeacherListDialog(component: ComponentType<DashboardTeacherTableComponent>, panelClass: string, dialogData = null, width = '800px'): void {
        document.body.classList.add('modal-open');
        const dialogRef: MatDialogRef<DashboardTeacherTableComponent> = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width,
            height: '95vh',
            maxWidth: '100vw',
            autoFocus: false,
            disableClose: true,
            position: { top: '48px' },
            data: dialogData
        });
        dialogRef.componentInstance.role = this.role;
        dialogRef.componentInstance.teachers = this.teachers;
        dialogRef.componentInstance.approve.subscribe((approveData: { teacher: Teacher, modal: TeacherProfileInfoComponent, id: string }) => {
            this.approve(approveData);
        });
        dialogRef.componentInstance.disapprove.subscribe((approveData: { teacher: Teacher, modal: TeacherProfileInfoComponent, id: string }) => {
                this.disapprove(approveData);
        });
        dialogRef.componentInstance.closeModal.subscribe(() => {
            dialogRef.close();
        });
        dialogRef.afterClosed().subscribe((result) => {
            document.body.classList.remove('modal-open');
            console.log('Dialog closed result', result);
        });
    }


    disapprove(data: { teacher: Teacher, modal: TeacherProfileInfoComponent, id: string }) {
        this.apiService.updateMatching({
            teacherId: data?.teacher.teacher.id || '',
            studentId: this.student.student.id,
            acceptedStudent: false,
        }).pipe(switchMap(() => {
            return this.apiService.getTeachers( '1', '1000');
        })).subscribe((teachers: TeachersResponse) => {
            this.teachers.list.length = 0;
            teachers.list.forEach((item) => {
                this.teachers?.list.push(item);
            });
            const teacherId = data.teacher.teacher.id;
            const selectedTeacher = this.teachers.list.find((teacher: Teacher) => teacher?.teacher.id === teacherId);
            if (selectedTeacher) {
                data.modal.setTeacher(selectedTeacher);
            }
            console.log('Successfully declined.')
        });
    }

    approve(approveData: { teacher: Teacher, modal: TeacherProfileInfoComponent, id: string }) {
        const teacher = approveData?.teacher;
        const teacherId = approveData?.teacher?.teacher.id || '';
        this.apiService.getMatching(teacherId, this.student.student.id).subscribe(
            (data) => {
                const modalDescription = {
                    title: 'matching.time_select.title',
                    question: 'matching.time_select.question'
                };
                this.openTimeSelectDialog(MatchingTimeSelectComponent, 'time-select', { slots: data.timeSlots, modal: approveData.modal, teacher, student: this.student, modalDescription, multiple: false, hasTeacher: false }, '864px', '');
            }
        )
    }
}
