<div class="match-teacher-confirm-container">
    <div class="left-side">
        <div class="header-wrapper">
            <app-header (headerClick)="redirectToLogin()" [hideBetaProgram]="true"></app-header>
        </div>
        <div class="match-teacher-confirm-wrapper">
            <div class="title-container">
                <div class="title-1">{{ 'matching.match_confirmed.title' | translate }}</div>
                <div class="title-2">{{ 'matching.match_confirmed.subtitle' | translate }}</div>
            </div>
        </div>
    </div>
    <div class="right-side">
        <div class="background-confirm">
        </div>
    </div>
</div>
