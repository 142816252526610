import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFieldProperties } from '../../../../shared/validators/has-error';
import { ApiService } from '../../../services/api/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { EMAIL_VALIDATION_REG } from '../../../../shared/constants/form-option';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    confirmResetPasswordForm: FormGroup;
    protected readonly getFieldProperties = getFieldProperties;

    private _showError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showError$: Observable<boolean> = this._showError$.asObservable();
    private _showSuccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showSuccess$: Observable<boolean> = this._showSuccess$.asObservable();

    get email(): AbstractControl<any> | null{
        return this.confirmResetPasswordForm.get('email');
    }
    constructor(
        private fb: FormBuilder,
        private apiService: ApiService
        ) {}

    ngOnInit(): void {
        this.confirmResetPasswordForm = this.fb.group({
            email: ['', [Validators.required, Validators.pattern(EMAIL_VALIDATION_REG)]],
        });
    }
    cancel() {
      this.onCancel.emit();
    }
    onSubmit() {
        this.apiService.recovery(this.email?.value).subscribe(
            () => {
                this._showSuccess$.next(true);
            },
            (error: { error: { message: string } }) => {
                if (error?.error && error?.error?.message && error?.error?.message.includes('Cannot find user')) {
                   this._showError$.next(true);
                    this._showSuccess$.next(false);
                }
            })
    }

    resetError() {
        this._showError$.next(false);
    }
}
