import { Component } from '@angular/core';

@Component({
  selector: 'app-notify-teacher-match',
  templateUrl: './notify-teacher-match.component.html',
  styleUrls: ['./notify-teacher-match.component.scss']
})
export class NotifyTeacherMatchComponent {

}
