import { UserLanguage } from '../services/api/api.type';

export const calculateAge = (date: string): number => {
    return Math.floor((new Date().getTime() - new Date(date).getTime()) / 3.15576e+10);
};

export const determineAgeGroup = (date: string) => {
    const age = calculateAge(date);
    const ageMap: any = {
        '0-12': 'Kind (unter 12 Jahre)',
        '13-17': 'Jugendlicher (13 bis 17 Jahre)',
        '18-39': 'Erwachsener (18 bis 39 Jahre)',
        '40-64': 'Erwachsener (40 bis 64 Jahre)',
        '65-100': 'Senior (65 Jahre und älter)',
    };
    let ageGroup;
    for (const range in ageMap) {
        const [start, end] = range.split('-').map(Number);

        if (age >= start && age <= end) {
            ageGroup = ageMap[range];
        }
    }

    return ageGroup;
}

export const convertTimeSlots = (timeSlots: string[], language: UserLanguage, timeZone: string = 'Europe/Berlin'): string[] => {
    return timeSlots.map(slot => {
        const localTime = new Date(slot).toLocaleString('uk-UA', { timeZone });
        const days = {
            english: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            german: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        };

        const dayAbbreviation = days[language][new Date(slot).getUTCDay()];
        const lessonDate = localTime.toString().substring(0, 10);
        const lessonTime = localTime.toString().substring(12, 17);

        return `${dayAbbreviation}. ${lessonDate} | ${lessonTime}`;
    });
};
