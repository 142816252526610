<ng-container *ngIf="!(showSearchingMatch$ | async) && !(confirmTeacherMatch$ | async)">
    <div class="matched-teachers-container">
        <app-dashboard-header [showBtnGroup]="false"></app-dashboard-header>
        <app-notify-banner (buttonClick)="mailTo('feedback')"  [buttonText]="'notify-panel.feedback-button'" [title]="'notify-panel.title'" ></app-notify-banner>
        <div class="card-container">
            <app-notify-teacher-match></app-notify-teacher-match>
            <div class="teachers-container">
                <app-teacher-card
                    *ngFor="let teacher of teachers"
                    [teacher]="teacher"
                    (matchedConfirm)="matchedConfirm()"
                    [student]="student">
                </app-teacher-card>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="(showSearchingMatch$ | async) && !(confirmTeacherMatch$ | async)">
    <app-searching-match></app-searching-match>
</ng-container>

<ng-container *ngIf="(confirmTeacherMatch$ | async)">
    <app-match-teacher-confirm></app-match-teacher-confirm>
</ng-container>
