import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../music/services/authentication/authentication.service';
import { UserService } from '../music/services/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthenticationService, private router: Router) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const isAuthenticated = !!this.auth.accessToken || !!localStorage.getItem('accessToken');
        this.auth.logoutSignal.subscribe(() => {
            this.router.navigate(['/app']).then(() => {
                this.router.navigate(['/login']);
            });
        });
        if (isAuthenticated) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class SignUpGuard implements CanActivate {
    constructor(private router: Router, private userService: UserService) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        let hasSignUpRole = false;
        const navigation = this.router.getCurrentNavigation();
        const navigationState = navigation?.extras.state;
        if (navigationState && navigationState['signUpRole']) {
            this.userService.signUpRole = navigationState['signUpRole'];
            hasSignUpRole = navigationState['signUpRole'];
        }
        if (hasSignUpRole) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
