import { AbstractControl, FormGroup } from '@angular/forms';

export const getFieldProperties = (fieldName: string, form: FormGroup): {
    control: AbstractControl<any> | null;
    hasError: boolean;
    isRequired: boolean;
    hasAllErrors: boolean;
} => {
    let control: AbstractControl<any> | null;
    control = form.get(fieldName);
    const isRequired = !!((control?.hasError('required') && control?.dirty) || (control?.hasError('required') && control?.touched));
    const hasError = !!((control?.errors && !control?.hasError('required') && control?.dirty) || (control?.errors && !control?.hasError('required') && control?.touched));
    const hasAllErrors = isRequired || hasError;
    return { control, isRequired, hasError, hasAllErrors } ;
}
