import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { UserRoles } from '../../../../../core/models/user-info.interface';
import { Step } from '../../../../../shared/constants/form-option';

@Component({
    selector: 'app-multi-step-form',
    templateUrl: './multi-step-form.component.html',
    styleUrls: ['./multi-step-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiStepFormComponent {
    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Input() stepper: MatStepper;
    @Input() part: number;
    @Output() formChanged = new EventEmitter<void>();
    @Output() proceed = new EventEmitter<number>();
    @Output() onFinish = new EventEmitter<UserRoles | null>();
    @Input() signUpRole: UserRoles;
    constructor() {}

    onSubmit() {
        this.formChanged.emit();
    }

    finish(event: UserRoles | null) {
        this.onFinish.emit(event);
    }

    onProceed(event: number) {
      this.proceed.emit(event);
    }
}
