<header>
    <div class="logo-container"></div>
        <div *ngIf="showBtnGroup" class="btn-container">
            <button mat-stroked-button (click)="getUserAndOpenDialog()">{{ 'header.edit-btn' | translate }}</button>
            <button mat-stroked-button (click)="changeLanguage()">{{chosenLanguage}}</button>
            <button mat-stroked-button (click)="logout()">{{ 'header.log-out-btn' | translate }}</button>
        </div>
<div class="more-container">
    <button mat-button [matMenuTriggerFor]="menu">More</button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="getUserAndOpenDialog()">{{ 'header.edit-btn' | translate }}</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="changeLanguage()">{{chosenLanguage}}</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logout()">{{ 'header.log-out-btn' | translate }}</button>
    </mat-menu>
</div>
</header>
