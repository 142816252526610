import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { UserRoles } from '../../../../../../../../core/models/user-info.interface';
import { Step } from '../../../../../../../../shared/constants/form-option';

@Component({
  selector: 'app-step-instrument',
  templateUrl: './step-instrument.component.html',
  styleUrls: ['./step-instrument.component.scss']
})
export class StepInstrumentComponent implements OnInit {
    @ViewChild('chipList') chipList: MatChipListbox;

    @Input() stepper: MatStepper;
    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Output() submitForm = new EventEmitter<void>();
    @Input() signUpRole: UserRoles;
    displayedChips: { name: string; selected: boolean; }[] = [];
    stepChips: any[] = [];

    constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) { }

    ngOnInit() {
        this.stepChips = this.step.chips;
        this.addControls();
        this.stepChips = this.stepChips.map((chip: string) => ({ name: chip, selected: false }));
        this.displayedChips = this.stepChips.slice(0, 14);
    }

    addControls(): void {
        if (this.formGroup && Object.keys(this.formGroup?.controls)?.length === 0) {
            this.formGroup.addControl('selectedInstruments', this.fb.control([], Validators.required));
        }
    }

    getSelectedChips() {
        if (this.chipList.selected) {
            const selectedValues: MatChipOption[] = Array.isArray(this.chipList.selected)
                ? this.chipList.selected
                : [this.chipList.selected];
            return selectedValues.map((chips: MatChipOption) => chips['_value']);
        }
        return [];
    }

    handleSelectedInstrumentsChange() {
        this.formGroup.get('selectedInstruments')?.setValue(this.getSelectedChips());
        this.submitForm.emit();
    }

    showMoreChips() {
        const chipsToAdd = this.stepChips.slice(this.displayedChips.length, this.displayedChips.length + (this.stepChips.length - this.displayedChips.length));
        this.displayedChips = [...this.displayedChips, ...chipsToAdd];
        this.cd.detectChanges();
    }

    showLessChips() {
        const newLength = Math.max(this.displayedChips.length - 18, 0);
        this.displayedChips = this.stepChips.slice(0, newLength);
        this.cd.detectChanges();
    }

    onSubmit() {
        this.stepper.next();
    }
}
