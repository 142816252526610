<app-dashboard-header (onEdit)="onEdit()"></app-dashboard-header>
<app-notify-banner *ngIf="showBanner"  (closeEvent)="onCloseBanner()" [role]="user.role" (buttonClick)="mailTo('feedback')" [buttonText]="'notify-panel.feedback-button'" [title]="'notify-panel.title'" ></app-notify-banner>
<app-dashboard-intro [role]="user.role" [userIntroInfo]="userIntroInfo" [studentNotifyData]="studentNotifyData" [files]="files" (onAvatarChange)="avatarChange()"></app-dashboard-intro>
<app-avatar-upload *ngIf="(student && !avatarFileUrl) && user.role !== UserRoles.Root && !isMobilePlatform" [files]="files" [role]="user.role" (onAvatarChange)="avatarChange()"></app-avatar-upload>
<div class="table-wrapper" *ngIf="teachers && !isMobilePlatform">
    <app-student-teachers-list-component
        [teachers]="teachers"
        [student]="student"
        (onLessonTimeSelect)="getTeachers()"
        [studentNotifyData]="studentNotifyData"
        [role]="user.role"></app-student-teachers-list-component>
</div>

<div class="table-wrapper" *ngIf="teachers && isMobilePlatform">
    <app-student-teachers-list-mobile
        [teachers]="teachers"
        [student]="student"
        (onLessonTimeSelect)="getTeachers()"
        [studentNotifyData]="studentNotifyData"
        [role]="user.role"></app-student-teachers-list-mobile>
</div>

