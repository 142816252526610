import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { passwordValidator } from '../../../../shared/validators/password';
import { UserConfirmationData } from '../sign-up/shared/shared-data.service';
import { ApiService } from '../../../services/api/api.service';
import { JwtHelper } from '../../../../shared/helpers/jwt/jwt.helper';
import { UserInfo, UserRoles } from '../../../../core/models/user-info.interface';
import { UserService } from '../../../services/user/user.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';

@Component({
  selector: 'app-choose-password',
  templateUrl: './choose-password.component.html',
  styleUrls: ['./choose-password.component.scss']
})
export class ChoosePasswordComponent implements OnInit {
    resetPasswordForm: FormGroup;
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Input() userConfirmationData: UserConfirmationData;

    get password(): AbstractControl<any> | null {
        return this.resetPasswordForm.get('password1');
    }

    constructor(
        private fb: FormBuilder,
        private apiService: ApiService,
        private userService: UserService,
        private auth: AuthenticationService,
        private router: Router) {}

    ngOnInit(): void {
        this.resetPasswordForm = this.fb.group({
            password1: ['', { validators: [Validators.required, passwordValidator] }],
            password2: ['', { validators: [Validators.required, this.passwordsMatchValidator.bind(this)] }]
        });
        const password1 = this.resetPasswordForm.get('password1');
        const password2 = this.resetPasswordForm.get('password2');
        if (password1) {
            password1.valueChanges.subscribe(() => {
                if (password2?.value) {
                    password2?.updateValueAndValidity();
                }
            });
        }
    }

    getFieldProperties<T>(fieldName: string, controlField?:AbstractControl<T> | null): {
        control: AbstractControl<any> | null;
        hasError: boolean;
        isRequired: boolean;
        hasAllErrors: boolean;
    } {
        let control: AbstractControl<any> | null;
        if (controlField) {
            control = controlField.get(fieldName);
        } else {
            control = this.resetPasswordForm.get(fieldName);
        }
        const isRequired = !!((control?.hasError('required') && control?.dirty) || (control?.hasError('required') && control?.touched));
        const hasError = !!((control?.errors && !control?.hasError('required') && control?.dirty) || (control?.errors && !control?.hasError('required') && control?.touched));
        const hasAllErrors = isRequired || hasError;
        return { control, isRequired, hasError, hasAllErrors } ;
    }

    private passwordsMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const formGroup = control.parent;
        let password1;
        if (formGroup) {
            password1 = formGroup.get('password1')?.value;
        }
        const password2 = control.value;

        if (password1 !== password2) {
            return { 'passwordMismatch': true };
        }
        return null;
    }


    cancel() {
        this.onCancel.emit();
    }
    onSubmit() {
        const { userId,confirmationId,role} = this.userConfirmationData;
        const password = this.password?.value;

        if (!!role) {
            this.apiService.userConfirmation(userId, confirmationId, role, {password}).subscribe(
                (data: { accessToken: string, refreshToken: string }) => {
                    const userInfo: UserInfo = JwtHelper.decode(data.accessToken);
                    this.userService.userInfo = userInfo;
                    this.auth.saveTokens(data.accessToken, data.refreshToken);
                    if (Object.values(UserRoles).includes(userInfo.role as UserRoles)) {
                        if (userInfo.role as UserRoles === UserRoles.Student) {
                            const studentId = this.userService.userInfo.studentId;
                            this.router.navigate(['/teachers', studentId]);
                            return;
                        }
                        this.router.navigate(['/dashboard']);
                    } else {
                        console.log('Invalid user role');
                    }
                }
            );
        }
        if (!role) {
            this.apiService.recoveryConfirmation(password, userId, confirmationId).subscribe(
                (data: { accessToken: string, refreshToken: string }) => {
                    const userInfo: UserInfo = JwtHelper.decode(data.accessToken);
                    this.userService.userInfo = userInfo;
                    this.auth.saveTokens(data.accessToken, data.refreshToken);
                    if (Object.values(UserRoles).includes(userInfo.role as UserRoles)) {
                        this.router.navigate(['/dashboard']);
                    } else {
                        console.log('Invalid user role');
                    }
                }
            );
        }

    }

    togglePasswordType(passwordInput: HTMLInputElement, id: number): void {
        passwordInput.classList.toggle('input-password-mask');

        const crossOutLine = document.querySelector(`.cross-out-${id}`);
        if (crossOutLine) {
            crossOutLine.classList.toggle(`hide-${id}`);
        }
    }
}
