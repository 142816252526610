<div class="student-schedule-container">
    <div class="title">
        {{'schedule.matched-student-title' | translate}}
    </div>
    <div class="description">{{'schedule.matched-student-time' | translate}}</div>
    <mat-list>

        <ng-container *ngFor="let slot of convertedTimeSlots; let i = index">
            <mat-list-item [slot]="slot">
                <span class="date">{{slot}}</span>
                <mat-radio-button class="radio" [checked]="(i === 0)" value="{{slot}}" (change)="onTimeSelected(i)"></mat-radio-button>
            </mat-list-item>
            <mat-divider *ngIf="i !== convertedTimeSlots.length - 1"></mat-divider>
        </ng-container>

    </mat-list>
    <div class="btn-container">
        <div class="container-wrapper custom-theme">
            <button mat-stroked-button color="primary">
                <span class="btn-text" (click)="declineMatching()">{{'schedule.decline-button' | translate}}</span>
            </button>
            <button mat-flat-button color="primary" [disabled]="(this.selectedTime === '')">
                <span class="btn-text"
                      (click)="confirmMatching(this.selectedTime)">
                    {{'schedule.confirm-button' | translate}}
                </span>
            </button>
        </div>
    </div>
</div>
