import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ageOrDash'
})
export class AgeOrDashPipe implements PipeTransform {
    transform(birthday: string): number | string {
        const dob: Date = new Date(birthday);
        const now: Date = new Date();

        let age: number = now.getFullYear() - dob.getFullYear();

        const monthDiff: number = now.getMonth() - dob.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dob.getDate())) {
            age--;
        }

        if (age >= 0 && age <= 3) {
            return '–';
        }

        return age;
    }
}
