<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{ step.title1 | translate }} {{ step.title2 | translate }}</div>
    </div>
    <div class="storybook-field">
        <mat-form-field>
            <textarea [placeholder]="'signup-form.' + signUpRole +'.step-goals.placeholder' | translate" maxlength="300" matInput formControlName="goals"></textarea>
        </mat-form-field>
        <mat-error *ngIf="(goals?.hasError('required') && goals?.dirty) || (goals?.hasError('required') && goals?.touched)">
            {{  step.requiredError ? (step.requiredError | translate) : '' }}
            {{ 'signup-form.' + signUpRole +'.step-goals.requiredError' | translate }}
        </mat-error>
    </div>

    <div align="end" class="custom-theme btn-container storybook-field" [ngStyle]="{'justify-content': step.hasPreviousBtn ? 'space-between' : 'flex-end'}">
        <button *ngIf="step.hasPreviousBtn" mat-stroked-button [color]="step.btn2Color" (click)="backToPrevious()">{{ step.btnText1 | translate }}</button>
        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">{{ step.btnText2 | translate }}</button>
    </div>
</form>
