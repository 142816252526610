<div class="container" [class.noAvatar]="!fileUrl" [ngClass]="'container-' + role" [ngStyle]="{ 'background': getBackgroundImage }">
    <ng-container *ngIf="!isMobilePlatform">
    <img *ngIf="role === 'school_admin' || role === 'root'" src="/assets/images/header/shape_org.svg" alt="Organization" class="svg-shape" />
    <img *ngIf="role === 'student'" src="/assets/images/header/shape_student.svg" alt="Student" class="svg-shape" />
    <img *ngIf="role === 'teacher'" src="/assets/images/header/shape_teacher.svg" alt="Teacher" class="svg-shape" />
    </ng-container>
    <ng-container *ngIf="isMobilePlatform">
        <img *ngIf="role === 'school_admin' || role === 'root'" src="/assets/images/dashboard/mobile/shape_org.svg" alt="Organization" class="admin svg-shape" />
        <img *ngIf="role === 'student'" src="/assets/images/dashboard/mobile/shape_student.svg" alt="Student" class="student svg-shape" />
        <img *ngIf="role === 'teacher'" src="/assets/images/dashboard/mobile/shape_teacher.svg" alt="Teacher" class="teacher svg-shape" />
    </ng-container>
<div class="personal-info-container">

    <div *ngIf="(role === 'school_admin' || role === 'teacher') && fileUrl" class="avatar-container">
        <div class="info-image-container"
             [style]="getImageStyle(fileUrl)">
            <div class="upload-btn" (click)="upload()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clip-path="url(#clip0_2294_107)">
                        <path
                            d="M4.25695 9.98472C4.44552 9.27761 4.71068 8.62354 5.05245 8.02249C5.39422 7.42145 5.81259 6.87345 6.30756 6.37847C7.88677 4.79927 9.77828 4.00377 11.9821 3.99199C14.1859 3.9802 16.0774 4.76391 17.6566 6.34312L17.7804 6.46686L17.7804 4.20412L19.7603 4.20412L19.7603 9.86097L14.1034 9.86097L14.1034 7.88107H16.3662L16.2424 7.75733C15.0639 6.57882 13.6467 5.99251 11.9909 5.9984C10.3351 6.00429 8.91207 6.60239 7.72178 7.79268C7.41536 8.0991 7.1502 8.43497 6.92628 8.80031C6.70237 9.16565 6.5197 9.56045 6.37827 9.98472L4.25695 9.98472ZM4.23928 19.7958L4.23928 14.139L9.89613 14.139L9.89613 16.1189L7.63339 16.1189L7.75713 16.2426C8.93564 17.4211 10.3528 18.0074 12.0086 18.0015C13.6644 17.9956 15.0875 17.3976 16.2778 16.2073C16.5842 15.9008 16.8493 15.565 17.0733 15.1996C17.2972 14.8343 17.4799 14.4395 17.6213 14.0152L19.7426 14.0152C19.554 14.7223 19.2889 15.3764 18.9471 15.9774C18.6053 16.5785 18.187 17.1265 17.692 17.6215C16.1128 19.2007 14.2213 19.9962 12.0175 20.008C9.81363 20.0197 7.92212 19.236 6.34292 17.6568L6.21918 17.5331L6.21918 19.7958H4.23928Z"
                            fill="#C97D70"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_2294_107">
                            <rect width="24" height="24" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    </div>

    <div class="greeting-container">
        <span>{{role === UserRoles.Organisation ? schoolName : ('intro.welcome' | translate) + ' ' + userIntroInfo['userFirstName']+'!'}}</span>
    </div>
</div>
    <ng-container [ngSwitch]="role">
        <ng-container *ngSwitchCase="'root'">
            <app-statistics *ngIf="!isMobilePlatform" [role]="role" [rootIntroInfo]="userIntroInfo"></app-statistics>
        </ng-container>
        <ng-container *ngSwitchCase="'school_admin'">
            <app-statistics *ngIf="!isMobilePlatform" [role]="role" [rootIntroInfo]="userIntroInfo"></app-statistics>
        </ng-container>
        <ng-container *ngSwitchCase="'teacher'">
            <app-notify-lesson
                *ngIf="teacherNotifyData?.earliestLesson && !isMobilePlatform"
                [date]="teacherNotifyData?.earliestLesson"
                [avatar]="studentFileUrl"
                               [description]="'next_piano_lesson' | translate: { firstName: teacherNotifyData?.student?.firstName }">
            </app-notify-lesson>
        </ng-container>
        <ng-container *ngSwitchCase="'student'">
            <app-notify-lesson *ngIf="studentNotifyData?.earliestLesson  && !isMobilePlatform"
                               [date]="studentNotifyData?.earliestLesson"
                               [avatar]="teacherFileUrl"
                               [description]="'next_piano_lesson' | translate: { firstName: studentNotifyData?.teacher?.firstName }">
            </app-notify-lesson>
        </ng-container>
    </ng-container>
</div>
