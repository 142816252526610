import { Component, OnInit } from '@angular/core';
import { UserInfo, UserIntroInfo, UserRoles } from '../../../../core/models/user-info.interface';
import { UserService } from '../../../services/user/user.service';
import { ApiService } from '../../../services/api/api.service';
import { Avatar, Lesson, LessonsList, Student, Teacher, TeachersResponse } from '../../../services/api/api.type';
import { ActivatedRoute } from '@angular/router';
import { map, mergeMap, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StudentProfileService } from '../../../../shared/services/student-profile.service';
import { PlatformService } from '../../../services/platform.service';

@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './teacher-dashboard.component.html',
  styleUrls: ['./teacher-dashboard.component.scss']
})
export class TeacherDashboardComponent implements OnInit {
    schoolId: string;
    teachers: TeachersResponse;
    student: Student;
    studentNotifyData: { earliestLesson: string | Date; teacher: Teacher | undefined };
    avatarFileUrl = '';
    files: Avatar[];
    showBanner = true;

    get user(): UserInfo {
        return this.userService.userInfo;
    }

    get userIntroInfo(): UserIntroInfo {
        return this.userService.userIntroInfo;
    }

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }
    constructor(
        private userService: UserService,
        private apiService: ApiService,
        private translateService: TranslateService,
        private studentProfileService: StudentProfileService,
        private platformService: PlatformService,
        private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.getTeachers();
        this.getStudent();
    }

    getStudent(editProfile = false) {
        const studentId = this.user?.studentId || '';
        if (studentId) {
            this.apiService.getStudent(studentId).subscribe(
                (student) => {
                    this.student = student;
                    this.avatarFileUrl = this.student.files[0] && this.student.files[0].url;
                    this.files = this.student.files;
                    if (editProfile) {
                        this.studentProfileService.updateStudent(student);
                    }
                });
        }
    }

    getTeachers() {
        this.apiService.getTeachers( '1', '1000').pipe(
            mergeMap((teachers) => {
                this.teachers = teachers;
                if (this.userService.userInfo.role === UserRoles.Student) {
                    return this.getEarliestLessonForEachStudent(teachers.list);
                }
                return of(null)
            })
        ).subscribe((data) => {
            if (data) {
                this.studentNotifyData = data;
            }
        });
    }

    getEarliestLessonForEachStudent(teachers: Teacher[]): Observable<{
        earliestLesson: Date | string;
        teacher: Teacher | undefined;
    }> {
        const studentLessons$ = this.apiService.getLessonsList(this.userService.userInfo.userId).pipe(
            map((lessons: LessonsList) => {
                const earliestLesson = lessons.list.length > 0 ? this.findEarliestLesson(lessons.list) : null;
                const teacher = earliestLesson ? teachers.find(teacher => teacher.teacher.id === earliestLesson.teacherId) : undefined;
                return {
                    teacher,
                    earliestLesson
                };
            })
        );

        return studentLessons$.pipe(
            map(({ teacher, earliestLesson }) => ({
                teacher,
                earliestLesson: earliestLesson
                    ? new Date(earliestLesson.startTime)
                    : '',
            }))
        );
    }

    findEarliestLesson(lessons: Lesson[]): Lesson {
        return <Lesson>lessons.reduce((earliest: Lesson | null, current) => {
            if (!earliest || (current && current.startTime < earliest.startTime)) {
                return current;
            }
            return earliest;
        }, null);
    }

    avatarChange() {
        this.getStudent();
    }

    mailTo(type: string) {
        let email = '';
        const subject = this.translateService.instant('notify-panel.mail-subject')
        if (type === 'feedback') {
            email = `mailto:support@musiq.me?subject=${encodeURIComponent(subject)}`;
        } else if (type === 'admin') {
            email = `mailto:admin@musiq.me?subject=${encodeURIComponent(subject)}`;
        }
        window.open(email, '_blank');

    }

    onEdit() {
        if (this.user.role === UserRoles.Student) {
            this.getStudent(true);
        }
    }

    onCloseBanner() {
        this.showBanner = false;
    }

    protected readonly UserRoles = UserRoles;
}
