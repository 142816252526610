import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services/api/api.service';
import { Observable } from 'rxjs';
import { Teacher, UserTeacher } from '../../../services/api/api.type';

@Component({
    selector: 'teacher-profile',
    templateUrl: './teacher-profile.component.html',
    styleUrls: ['./teacher-profile.component.scss'],
})
export class TeacherProfileComponent implements OnInit {

    teacherId: string = '';
    studentId: string = '';
    teacher: Teacher;
    teacherTimeSlots: string[];
    userRole: 'teacher' | 'student';
    matching: 'accepted' | 'declined';
    showSchedule = true;

    constructor(private route: ActivatedRoute, private readonly apiService: ApiService) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.teacherId = params['teacherId'];
            this.studentId = params['studentId'];
        });

        this.apiService.getTeacher(this.teacherId).subscribe((teacher) => {
            if (!teacher.timezone) {
                teacher.timezone ='Europe/Berlin';
            }
            this.teacher = teacher;
        });
        this.apiService.getTimeOverlaps(this.teacherId, this.studentId).subscribe(slots => this.teacherTimeSlots = slots);
    }

    confirmMatching(timeSlots: string[]) {
        this.userRole = 'student';
        this.matching = 'accepted';
        this.showSchedule = !this.showSchedule;

        this.apiService.updateMatching({
            teacherId: this.teacherId,
            studentId: this.studentId,
            acceptedStudent: true,
            timeSlots,
        }).subscribe(() => console.log('Successfully confirmed.'));
    }

    declineMatching() {
        this.userRole = 'student';
        this.matching = 'declined';
        this.showSchedule = !this.showSchedule;

        this.apiService.updateMatching({
            teacherId: this.teacherId,
            studentId: this.studentId,
            acceptedStudent: false,
        }).subscribe(() => console.log('Successfully declined.'));
    }
}
