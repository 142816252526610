import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { CHIPS, Step } from '../../../../../../../../shared/constants/form-option';
import { PlatformService } from '../../../../../../../services/platform.service';
import { ScheduleItem, TimeSlotData } from '../../../../../../../services/api/api.type';
import { MatChipSelectionChange } from '@angular/material/chips';

@Component({
  selector: 'app-step-date-and-time',
  templateUrl: './step-date-and-time.component.html',
  styleUrls: ['./step-date-and-time.component.scss']
})
export class StepDateAndTimeComponent implements OnInit {
    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Output() submitForm = new EventEmitter<void>();
    @Input() stepper: MatStepper;
    chips: TimeSlotData[] = CHIPS;
    isMobile: boolean;

    get availabilityTimeStamp(): any {
        return this.formGroup.get('availabilityTimeStamp') as FormArray;
    }

    get isValidAvailabilityTimeStamp(): boolean {
        return (!!this.availabilityTimeStamp.value.find((timeStamp: {
            day: string,
            date: string,
            selectedDate: Date,
            selectedTime: string[],
        }) => timeStamp.selectedTime.length > 0));
    }

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    constructor(private fb: FormBuilder, public platformService: PlatformService) {
    }

    ngOnInit() {
        this.addControls();
        const weeklySchedule = this.generateWeekSchedule();
        this.chips.forEach((chip) => {
            const currentDate = weeklySchedule.find((d) => d.day === chip.day);
            if (currentDate) {
                chip.date = currentDate?.date;
                chip.selectedDate = currentDate.selectedDate;
            }
            chip.time.forEach((time) => {
                time = {name: time as string, selected: false}
            })
        });
        this.chips.forEach((chip) => {
            chip.time.forEach((time) => {
                time = {name: time as string, selected: false}
            })
        });
        this.chips.forEach((chip) => this.addAvailabilityTimeStamp(chip));
    }

    addControls(): void {
        if (this.formGroup && Object.keys(this.formGroup?.controls)?.length === 0) {
            this.formGroup.addControl('availabilityTimeStamp', this.fb.array([]));
            this.formGroup.addControl('availability', this.fb.control('', Validators.required));
        }
    }

    onSubmit() {
        this.submitForm.emit();
        this.stepper.next();
    }

    addAvailabilityTimeStamp(chip: TimeSlotData) {
        const control = this.fb.group({
            day: [ chip.day ],
            date: [ chip.date ],
            selectedDate: [ chip.selectedDate ],
            selectedTime: [ []],
        });
        this.availabilityTimeStamp.push(control);
    }

    onSelectTime(event: MatChipSelectionChange, i: number, timeSlot: ScheduleItem[] | string, setDefaultValue = false) {
        if (setDefaultValue) {
            if (typeof timeSlot == 'object') {
                timeSlot.forEach((slot) => {
                    let startTime = '';
                    let endTime = '';

                    if (this.convertTimeWithoutSeconds(slot.startTime)) {
                        startTime = this.convertTimeWithoutSeconds(slot.startTime)
                    }

                    if (this.convertTimeWithoutSeconds(slot.endTime)) {
                        endTime = this.convertTimeWithoutSeconds(slot.endTime);
                    }

                    const selectedTime = `${startTime} – ${endTime}`;
                    this.availabilityTimeStamp.at(slot.day).value.selectedTime.push(selectedTime);
                });
            }
            return;
        }

        if (event.selected && this.availabilityTimeStamp.at(i)) {
            this.availabilityTimeStamp.at(i).value.selectedTime.push(timeSlot);
        } else if (this.availabilityTimeStamp.at(i)) {
            this.availabilityTimeStamp.at(i).value.selectedTime = this.availabilityTimeStamp.at(i).value.selectedTime.filter((e: string) => e !== timeSlot)
        }
        this.formGroup.get('availability')?.setValue(this.isValidAvailabilityTimeStamp ? true : null);
    }



    setSelectedSlot(selectedTime: string[], timeSlot: string) {
        const containsEnDash = timeSlot.includes('–');
        if (!containsEnDash) {
            if (timeSlot.includes('9:00')) {
                timeSlot = '00:01 – 09:00';
            }
            if (timeSlot.includes('18:00')) {
                timeSlot = '18:00 – 23:59';
            }
            return !!selectedTime.includes(timeSlot);

        }
        return !!selectedTime?.includes(timeSlot);
    }

    convertTimeWithoutSeconds(time: string): string {
        if (!time) {
            return '';
        }
        const parts = time.split(":");
        const hours = parts[0];
        const minutes = parts[1];
        return `${hours}:${minutes}`;
    }

    generateWeekSchedule(): {
        day: string;
        date: string;
        selectedDate: Date;
    }[] {
        const today = new Date();
        const daysInWeek = 7;
        const millisecondsInDay = 24 * 60 * 60 * 1000;
        const weekSchedule = [];

        for (let i = 0; i < daysInWeek; i++) {
            const currentDate = new Date(today.getTime() + i * millisecondsInDay);

            const dayOfWeek = currentDate.toLocaleDateString('en-US', {weekday: 'long'});
            const formattedDate = currentDate.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
            }).replaceAll('/', '.');

            weekSchedule.push({day: dayOfWeek, date: formattedDate, selectedDate: currentDate});
        }
        return weekSchedule;
    }

    setSelectedAll(selectedTime: string[]) {
        const uniqueArray = selectedTime?.filter((value: string, index: number, self: string[]) => {
            return self.indexOf(value) === index;
        });
        return (uniqueArray.length === 5);
    }

    selectAllTimeSlots(index: number) {
        const allDaySelected = this.setSelectedAll(this.availabilityTimeStamp.at(index).value.selectedTime);
        if (!allDaySelected) {
            this.availabilityTimeStamp.at(index).value.selectedTime.splice(0);
            this.availabilityTimeStamp.at(index).value.selectedTime.push(...this.chips[index].time);
        } else {
            this.availabilityTimeStamp.at(index).value.selectedTime = this.availabilityTimeStamp.at(index).value.selectedTime.filter((e: string) => !this.chips[index].time.includes(e));
        }
    }
}
