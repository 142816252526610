import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EditProfileComponent } from '../../edit-profile/edit-profile.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../services/user/user.service';
import { UserInfo, UserRoles } from '../../../../core/models/user-info.interface';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ApiService } from '../../../services/api/api.service';
import { LanguageService } from '../../../../shared/services/language.service';
import { ISO_CONVERTER } from '../../../../shared/constants/localstorage-constants';
import { OrganisationProfileInfoComponent } from '../organisation-dashboard/organisation-profile-info/organisation-profile-info.component';
import { of, Subject, switchMap, takeUntil } from 'rxjs';
import { DashboardTableService } from '../../../services/dashboard-table.service';
import { TeacherProfileInfoComponent } from '../teacher-dashboard/teacher-profile-info/teacher-profile-info.component';
import { StudentProfileInfoComponent } from '../student-dashboard/student-profile-info/student-profile-info.component';
import { PlatformService } from '../../../services/platform.service';
import { ComponentType } from '@angular/cdk/overlay';
import { SchoolItem, Student, Teacher, UserResponse } from '../../../services/api/api.type';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit,  OnDestroy {
    protected readonly UserRoles = UserRoles;
    dialogRef: MatDialogRef<any>;

    @Input() showBtnGroup = true;
    @Output() onEdit = new EventEmitter();
    destroy$ = new Subject();

    get selectedLanguage() {
        return this.languageService.language as keyof typeof ISO_CONVERTER;
    }

    get chosenLanguage() {
        return this.languageService.language === 'EN' ? 'DE' : 'EN';
    }
    get user(): UserInfo {
        return this.userService.userInfo;
    }
    constructor(private dialog: MatDialog,
                private userService: UserService,
                private apiService: ApiService,
                private auth: AuthenticationService,
                private languageService: LanguageService,
                private dashboardTableService: DashboardTableService,
                private platformService: PlatformService) {
    }

    ngOnInit() {
        this.dashboardTableService.updateSchoolProfile.pipe(takeUntil(this.destroy$)).subscribe((type) => {
            if (type === 'edit') {
                this.onEdit.emit();
            }
        });
        this.dashboardTableService.needUpdateTeacherTable.pipe(takeUntil(this.destroy$)).subscribe((update) => {
            if (update) {
                this.onEdit.emit();
            }
        });

        this.dashboardTableService.needUpdateStudentTable.pipe(takeUntil(this.destroy$)).subscribe((update) => {
            if (update) {
                this.onEdit.emit();
            }
        });
    }

    getUserProfile() {
        return this.apiService.getUser(this.user.userId);
    }

    getOrganisationProfile() {
        return this.apiService.getOrganisation(this.user.manageSchoolId).pipe(
            switchMap((organisation) => {
                organisation.hasEditProfile = true;
                return of(organisation);
            })
        );
    }

    openOrganisationProfileDialog() {
        this.getOrganisationProfile().subscribe((organisation) => {
            this.openDialog(OrganisationProfileInfoComponent, 'school-profile-info', organisation, '640px');
        });
    }

    openTeacherProfileDialog() {
        const teacherId: string = this.user?.teacherId || '';
        if (!teacherId) {
            return;
        }
        return this.apiService.getTeacher(teacherId).subscribe((teacher) => {
            teacher.hasEditProfile = true;
            this.openDialog(TeacherProfileInfoComponent, 'school-profile-info', teacher, '640px');
        });
    }

    openStudentProfileDialog() {
        const studentId: string = this.user?.studentId || '';
        if (!studentId) {
            return;
        }
        return this.apiService.getStudent(studentId).subscribe((student) => {
            student.hasEditProfile = true;
            this.openDialog(StudentProfileInfoComponent, 'student-profile-info', student, '640px');
        });
    }

    openUserProfileDialog(userInfo: UserResponse) {
        this.openDialog(EditProfileComponent, 'edit-profile', userInfo);
    }

    getUserAndOpenDialog() {
        this.getUserProfile().pipe(
            switchMap((userInfo) => {
                switch (this.user.role) {
                    case UserRoles.Organisation:
                        this.openOrganisationProfileDialog();
                        break;
                    case UserRoles.Teacher:
                        this.openTeacherProfileDialog();
                        break;
                    case UserRoles.Student:
                        this.openStudentProfileDialog();
                        break;
                    default:
                        this.openUserProfileDialog(userInfo);
                        break;
                }
                return of(null);
            })
        ).subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    openDialog<T>(component: ComponentType<T>, panelClass: string, dialogData?: (UserResponse | SchoolItem | Teacher | Student), width = '864px'): void {
        width = this.platformService.isMobile ? '100vw' : width;
        document.body.classList.add('modal-open');
        if (this.platformService.isMobile) {
            this.dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                height: '95vh',
                maxWidth: '100vw',
                disableClose: true,
                position: { top: '48px' },
                data: dialogData
            });
        } else {
            this.dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                autoFocus: false,
                disableClose: false,
                position: { top: '32px' },
                data: dialogData
            });
        }

        this.dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog closed result', result);
            document.body.classList.remove('modal-open');
        });
    }

    logout() {
        this.auth.logOut({ token: this.auth.accessToken }).subscribe(() => {
        }, error => {
            console.log(error, 'error');
        });
    }

    changeLanguage() {
        let selectedLanguage: keyof typeof ISO_CONVERTER;

        if (this.selectedLanguage.toLocaleLowerCase() === 'en') {
            selectedLanguage = 'de';
        } else {
            selectedLanguage = 'en';
        }

        this.languageService.change(selectedLanguage).then();
    }

}
