<div class="student-details-container">
    <div class="title">
        {{'schedule.student-details-title' | translate}}
    </div>
    <div class="items-container">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.name' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>{{userStudent.firstName}} {{userStudent.lastName}}</p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.instruments.label' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>
            <span *ngFor="let instrument of userStudent.student?.instruments; let last = last">
                {{ 'user.instruments.' + instrument | translate }}{{ !last ? ', ' : '' }}
            </span>
                </p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.instruments.label' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>
            <span *ngFor="let genre of userStudent.student?.genres; let last = last">
                {{ 'user.genres.' + genre | translate }}{{ !last ? ', ' : '' }}
            </span>
                </p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.zip-code' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>{{userStudent.postalCode}}</p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

<!--            <mat-divider></mat-divider>-->
<!--            <mat-expansion-panel (opened)="panelOpenState = true"-->
<!--                                 (closed)="panelOpenState = false">-->
<!--                <mat-expansion-panel-header>-->
<!--                    <mat-panel-title>-->
<!--                        Language-->
<!--                    </mat-panel-title>-->
<!--                    <mat-panel-description>-->
<!--                    </mat-panel-description>-->
<!--                </mat-expansion-panel-header>-->
<!--                <p>{{userStudent.language}}</p>-->
<!--            </mat-expansion-panel>-->
<!--            <mat-divider></mat-divider>-->

<!--            <mat-divider></mat-divider>-->
<!--            <mat-expansion-panel (opened)="panelOpenState = true"-->
<!--                                 (closed)="panelOpenState = false">-->
<!--                <mat-expansion-panel-header>-->
<!--                    <mat-panel-title>-->
<!--                        Gender-->
<!--                    </mat-panel-title>-->
<!--                    <mat-panel-description>-->
<!--                    </mat-panel-description>-->
<!--                </mat-expansion-panel-header>-->
<!--                <p>{{userStudent.gender}}</p>-->
<!--            </mat-expansion-panel>-->
<!--            <mat-divider></mat-divider>-->

            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.age-group' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>{{userStudent.age}}</p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-divider></mat-divider>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.experienceLevel.label' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>{{'user.experienceLevel.' + userStudent.student?.experienceLevel | translate}}</p>
            </mat-expansion-panel>
            <mat-divider></mat-divider>

            <mat-divider></mat-divider>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'user.teachingLocation.label' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p>
                <span *ngFor="let location of userStudent.student?.teachingLocation; let last = last">
                    {{ 'user.teachingLocation.' + location | translate }}{{ !last ? ', ' : '' }}
                </span>
                </p>
            </mat-expansion-panel>

        </mat-accordion>
    </div>
</div>
