import { Component } from '@angular/core';

@Component({
  selector: 'app-searching-match',
  templateUrl: './searching-match.component.html',
  styleUrls: ['./searching-match.component.scss']
})
export class SearchingMatchComponent {

}
