import { Component, Input } from '@angular/core';
import { Student } from '../../../services/api/api.type';
import { determineAgeGroup } from '../../../utils';

@Component({
    selector: 'app-student-details',
    templateUrl: './student-details.component.html',
    styleUrls: ['./student-details.component.scss'],
})
export class StudentDetailsComponent {

    @Input() userStudent: Student & { age?: number }
    panelOpenState = false;

    ngOnChanges(): void {
        const ageGroup = determineAgeGroup(this.userStudent.birthday);

        this.userStudent = {
            ...this.userStudent,
            age: ageGroup,
        };
    }
}
