import { Injectable } from '@angular/core';
import {
    AgePreference,
    EducationItem,
    ExperienceLevel,
    Genre,
    Instrument, PriceItem, SchoolSocialsItem,
    SocialsItem, SocialsMediaItem,
    TeachingLocation, UnionsItem
} from '../../../../services/api/api.type';

export interface UserConfirmationData {
    userId: string;
    confirmationId: string;
    role: string | null;
}

interface AvailabilityTimeStamp {
    day: string;
    date: string;
    selectedDate: Date;
    selectedTime: string[];
}

export interface StudentRegisterData {
    firstName: string;
    lastName: string;
    selectedInstruments: Instrument[];
    genres: Genre[];
    email: string;
    phone: string | null;
    allowContactViaEmail: boolean;
    allowContactViaPhone: boolean;
    priorKnowledge: string;
    zipCode: number | null;
    teachingLocation: TeachingLocation[];
    language: string[];
    gender: string | null;
    birthday: Date;
    availabilityTimeStamp: AvailabilityTimeStamp[];
    availability: boolean;
    goals: string;
}


export interface TeacherRegisterData {
    firstName: string;
    lastName: string;
    selectedInstruments: Instrument[];
    genres: Genre[];
    email: string;
    phone: string;
    allowContactViaEmail: boolean;
    allowContactViaPhone: boolean;
    socialMediaGroup: SocialsMediaItem[];
    educationGroup: EducationItem[];
    studentExperienceLevel: ExperienceLevel[];
    studentPreferenceAgeGroup: AgePreference[];
    language: string[];
    teachingLocation: TeachingLocation[];
    zipCode: string;
    gender: string;
    birthday: Date;
    availabilityTimeStamp: AvailabilityTimeStamp[];
    availability: boolean;
    goals: string;
}

export interface SchoolRegisterData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string | null;
    allowContactViaEmail: boolean;
    allowContactViaPhone: boolean;
    name: string;
    sponsoringCompany?: string | null;
    postalCode: string | null;
    price: string;
    unit: string;
    matchNotification: string;
    matchEmail?: string;
    description: string;
    website: string;
    associationsGroup: UnionsItem[];
    socialMediaGroup: SchoolSocialsItem[];
    priceRangeGroup: PriceItem[];
}

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
    userConfirmationData: UserConfirmationData;
    schoolId: string;

    constructor() { }

    set setUserConfirmationData(userData: UserConfirmationData) {
        this.userConfirmationData = userData;
    }

    get setUserConfirmationData() {
        return this.userConfirmationData;
    }

    set setSchoolId(schoolId: string) {
        this.schoolId = schoolId;
    }

    get invitedSchoolId(): string {
        return this.schoolId;
    }
}
