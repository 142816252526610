import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Genre, Instrument, MatchNotification, Student, Teacher } from '../../../../services/api/api.type';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EXPERIENCE_LEVEL, TEACHING_LOCATION_STUDENT } from '../../../../../shared/constants/form-option';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../../services/api/api.service';
import { DeleteConfirmComponent } from '../../delete-confirm/delete-confirm.component';
import { UserService } from '../../../../services/user/user.service';
import { UserRoles } from '../../../../../core/models/user-info.interface';
import { StudentFormComponent } from '../../../profile/student-form/student-form.component';
import { DashboardTableService } from '../../../../services/dashboard-table.service';
import { StudentProfileService } from '../../../../../shared/services/student-profile.service';
import { PlatformService } from '../../../../services/platform.service';
import { ComponentType } from '@angular/cdk/overlay';

type StudentWithTeacher = Student & { teacher?: Teacher };

@Component({
  selector: 'app-student-profile-info',
  templateUrl: './student-profile-info.component.html',
  styleUrls: ['./student-profile-info.component.scss']
})
export class StudentProfileInfoComponent implements OnInit{
  student: Student;
  skillLevels = EXPERIENCE_LEVEL;
  fromMatch = false;
  image: string | null;
    protected readonly UserRoles = UserRoles;

  @Output() studentUpdated: EventEmitter<string> = new EventEmitter<string>();
  @Output() approve: EventEmitter<{student: Student, id: string}> = new EventEmitter<{student: Student, id: string}>();
  @Output() disapprove: EventEmitter<{student: Student, id: string}> = new EventEmitter<{student: Student, id: string}>();



    get userRole(): UserRoles {
      return this.userService?.userInfo?.role;
  }

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    get hasApprovePermission(): boolean {
        if ('teacher' in this.data && this.data?.teacher?.teacher?.school) {
            return this.data?.teacher.teacher.school.matchNotification === MatchNotification.TEACHER;
        }
        return false;
    }

    constructor(
        public dialogRef: MatDialogRef<StudentProfileInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Student | StudentWithTeacher,
        private translateService: TranslateService,
        private apiService: ApiService,
        private dialog: MatDialog,
        private userService: UserService,
        private platformService: PlatformService,
        private studentProfileService: StudentProfileService,
        private dashboardTableService: DashboardTableService) {
    }

    ngOnInit() {
        this.student = this.data;
        this.image = this.student.files.length > 0 ? this.student.files[0].url: null;
        this.studentProfileService.setStudentProfileComponent(this);
        this.fromMatch = !!this.data?.fromMatch;
    }

    setStudent(student: Student) {
        this.student = student;
    }

    getTeachingLocation(locations?: string[]): string {
        const availableLocations = TEACHING_LOCATION_STUDENT;
        const result = availableLocations.filter((availableLocation) => {
            return locations?.includes(availableLocation.value);
        });
        const translatedLocations = result.map(location => this.translateService.instant('teaching_locations.' + location.value));
        return translatedLocations.join(', ');
    }

    translateInstruments(instruments: Instrument[] | undefined): string {
        if (!instruments) return '—';
        return instruments.map(instrument => this.translateService.instant('instrument_translations.' + instrument) || instrument).join(' | ');
    }

    translateGenres(genres: Genre[] | undefined): string {
        if (!genres) return '—';
        return genres.map(genre => this.translateService.instant('genre_translations.' + genre) || genre).join(' | ');
    }

    translateGender(gender: string): string {
        if (!gender) return '—';
        return this.translateService.instant('genders.' + gender);
    }

    closeDialog(update = false) {
        this.dialogRef.close(update);
    }

    skillLevel(skillLevel: string): string {
        const translatedLabel = this.translateService.instant(`experience_levels.${skillLevel}`);
        return translatedLabel || '';
    }

    contact(contactInfo: string | null, type: string) {
        if (type === 'email') {
            const email = "mailto:" + contactInfo;
            window.open(email, '_blank');
        } else if (type === 'phone') {
            const phone = "tel:" + contactInfo;
            window.open(phone, '_blank');
        }
    }

    deleteStudent(key: string) {
        const title = this.translateService.instant(key);
        const type = this.translateService.instant('confirm_delete.student_type');
        this.openDeleteConfirmDialog(DeleteConfirmComponent, 'delete-confirm', { id: this.data.student.id, title, name: this.data.firstName, type });
    }

    openDeleteConfirmDialog(component: ComponentType<DeleteConfirmComponent>, panelClass: string, dialogData?: { id: string; title: string, name: string, type: string }): void {
        document.body.classList.add('modal-open');
        const dialogRef = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width: '480px',
            autoFocus: false,
            disableClose: true,
            data: dialogData
        });

        dialogRef.afterClosed().subscribe((studentId: string) => {
            document.body.classList.remove('modal-open');
            if (studentId) {
                this.apiService.deleteStudent(studentId).subscribe(() => {
                    this.dialogRef.close({delete: true});
                });
            }
            console.log('Dialog closed result', studentId);
        });
    }

    editProfile() {
        this.openDialog(StudentFormComponent, 'student-form', this.student, '864px');
    }

    openDialog(component: ComponentType<StudentFormComponent>, panelClass: string, dialogData?: Student, width = '800px'): void {
        document.body.classList.add('modal-open');
        let dialogRef: MatDialogRef<StudentFormComponent>;
        if (this.platformService.isMobile) {
            dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                height: '95vh',
                maxWidth: '100vw',
                disableClose: true,
                position: { top: '48px' },
                data: dialogData
            });
        } else {
            dialogRef = this.dialog.open(component, {
                panelClass: panelClass,
                hasBackdrop: true,
                width,
                autoFocus: false,
                disableClose: false,
                position: { top: '32px' },
                data: dialogData,
            });
        }

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                this.dashboardTableService.needUpdateStudentTable.next(true);
                this.studentUpdated.emit(this.student.id);
                if (result?.delete) {
                    this.closeDialog();
                }
            }
            console.log('Dialog closed result', result);
        });
    }

    handleDisapprove(student: Student) {
        this.disapprove.emit({student, id: student.student.id});
    }

    handleApprove(student: Student) {
        this.approve.emit({student, id: student.student.id});
    }

}
