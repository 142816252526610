import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Integration } from '../../../services/api/api.type';
import { ComponentType } from '@angular/cdk/overlay';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivateFormComponent } from '../activate-form/activate-form.component';

@Component({
  selector: 'app-activate-card',
  templateUrl: './activate-card.component.html',
  styleUrls: ['./activate-card.component.scss']
})
export class ActivateCardComponent {
    @Input() integration: Integration;
    @Input() teacherId: string;
    @Output() updateIntegrations: EventEmitter<void> = new EventEmitter<void>();

    constructor(private dialog: MatDialog) {
    }

    activate() {
       this.openDialog(ActivateFormComponent, 'activate-form',  {...this.integration, teacherId: this.teacherId});
    }

    openDialog<T>(
        component: ComponentType<T>,
        panelClass: string,
        dialogData?: Integration & { teacherId: string },
        width: string = '640px',
    ): void {
        document.body.classList.add('modal-open');

        const dialogRef: MatDialogRef<T> = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width,
            autoFocus: false,
            disableClose: false,
            position: { top: '32px' },
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
               this.updateIntegrations.emit();
            }
            console.log('Dialog closed result', result);
        });
    }
}
