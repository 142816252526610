<div class="card-container">
    <ng-container *ngIf="integrationTeacherList && integrationTeacherList.length < 1">
        <app-activate-card (updateIntegrations)="updateIntegrations()" [teacherId]="teacherId" *ngFor="let integration of integrationList" [integration]="integration">
        </app-activate-card>
    </ng-container>

    <ng-container *ngIf="integrationTeacherList && integrationTeacherList.length > 0">
        <ng-container *ngFor="let integration of integrationTeacherList; let i = index">
            <app-session-item *ngIf="i < integrationTeacherList.length" [integration]="integrationTeacherList[i]" [teacherId]="teacherId" (updateIntegrations)="updateIntegrations()">
            </app-session-item>

            <app-activate-card (updateIntegrations)="updateIntegrations()" [teacherId]="teacherId" *ngIf="i < integrationList.length" [integration]="integrationList[i]">
            </app-activate-card>
        </ng-container>

        <ng-container *ngIf="integrationList.length > integrationTeacherList.length">
            <app-activate-card (updateIntegrations)="updateIntegrations()" [teacherId]="teacherId" *ngFor="let integration of integrationList.slice(integrationTeacherList?.length)" [integration]="integration">
            </app-activate-card>
        </ng-container>
    </ng-container>
</div>
