import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { UserRoles } from '../../../../../../../../core/models/user-info.interface';
import { UserService } from '../../../../../../../services/user/user.service';
import { Step } from '../../../../../../../../shared/constants/form-option';

@Component({
  selector: 'app-step-personal-goals',
  templateUrl: './step-personal-goals.component.html',
  styleUrls: ['./step-personal-goals.component.scss']
})
export class StepPersonalGoalsComponent implements OnInit {
    @Input() step: Step;
    @Input() formGroup: FormGroup;
    @Output() submitForm = new EventEmitter<void>();
    @Input() stepper: MatStepper;
    @Output() finish = new EventEmitter<UserRoles | null>();
    @Input() signUpRole: UserRoles;
    @Output() proceed = new EventEmitter<number>();
    @Input() part: number;

    get goals() {
        return this.formGroup.get('goals');
    }

    constructor(private fb: FormBuilder, private userService: UserService) {}

    ngOnInit() {
        this.addControls()
    }

    onSubmit() {
        this.submitForm.emit();
        this.finish.emit(this.userService.signUpRole);
    }
    addControls(): void {
        if (this.formGroup && Object.keys(this.formGroup?.controls)?.length === 0) {
            this.formGroup.addControl('goals', this.fb.control(''));
        }
    }

    backToPrevious() {
        this.proceed.emit(this.part -1);
    }
}
