<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{step.title1 | translate}} {{step.title2 | translate}}</div>
    </div>

    <mat-chip-listbox aria-label="Prior Knowledge selection" formControlName="priorKnowledge" (change)="onPriorKnowledgeSelectionChange($event)">
        <mat-chip-option *ngFor="let chip of step.chips" [value]="chip.value">
            {{ 'experience_levels.' + chip.value | translate }}
        </mat-chip-option>
    </mat-chip-listbox>
</form>
