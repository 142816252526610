import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirm',
  templateUrl: './delete-confirm.component.html',
  styleUrls: ['./delete-confirm.component.scss']
})
export class DeleteConfirmComponent {

    constructor(
        public dialogRef: MatDialogRef<DeleteConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            id: string;
            name: string;
            title: string;
            type: string;
            description?: string;
        }) {}

    cancel() {
        this.dialogRef.close(false);
    }
    onSubmit() {
        this.dialogRef.close(this.data?.id);
    }
}
