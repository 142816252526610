<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{step.title1 | translate}} {{step.title2 | translate}}</div>
    </div>

    <mat-chip-listbox aria-label="Prior Knowledge selection" [multiple]="true" formControlName="teachingLocation" (change)="onSelectionChange($event)">
        <mat-chip-option *ngFor="let chip of step.chips; let i = index" [value]="chip.value" [disabled]="priorKnowledge?.value?.includes('no-preference') && step.chips[i].value !== 'no-preference'">
            {{ 'teaching_locations.' + chip.value | translate }}
        </mat-chip-option>
    </mat-chip-listbox>

    <div align="end" class="custom-theme btn-container sign-up-form-field">
        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">{{step.btnText2 | translate}}</button>
    </div>
</form>
