<div class="title-container">
    <div class="btn-container custom-theme">
        <div *ngIf="role === UserRoles.Root">
            <button mat-button (click)="addOrganisation()">{{ 'school-dashboard-table.button.addOrganisation' | translate }}</button>
        </div>
        <div *ngIf="role === UserRoles.Organisation">
            <button mat-button [ngStyle]="{ opacity: role === UserRoles.Organisation && !hasConfirmedAndValidated ? 0.3 : 1 }" [disabled]="role === UserRoles.Organisation && !hasConfirmedAndValidated" (click)="addTeacher()">{{ 'teacher-dashboard-table.button.addTeacher' | translate }}</button>
        </div>
        <button [disabled]="true" style="opacity: 0.3" mat-stroked-button>{{ 'school-dashboard-table.button.copyInviteLink' | translate }}</button>
    </div>
</div>

<div class="new-organisations" *ngIf="role === UserRoles.Root && newOrganisations?.list && newOrganisations.list.length > 0">
    <div class="title">{{ 'school-dashboard-table.title.new.organisation' | translate }}</div>
    <mat-list role="list">
        <ng-container *ngFor="let item of newOrganisations.list; let i = index; let last = last">
            <a mat-list-item>
                <div class="list-wrapper">
                    <div class="left-side">
                        <div class="logo-container">
                            <img
                                src="{{ item.files?.[0]?.url ? item.files?.[0]?.url : '/assets/images/profile/unknown.jpg' }}"
                                alt="Logo" class="logo">
                        </div>
                        <div class="text-container">
                            <span class="name">{{ item?.name }}</span>
                            <span class="city">{{ item?.city }}</span>
                        </div>
                    </div>
                    <div class="actions-container">
                        <button
                                class="raised-button"
                                color="primary"
                                mat-raised-button
                                (click)="handleApprove(item.id)">
                            {{ 'school-dashboard-table.button.approve' | translate }}
                        </button>
                    </div>

                </div>
            </a>
            <mat-divider *ngIf="!last"></mat-divider>
        </ng-container>
    </mat-list>
</div>

<div class="statistics-container">
    <app-statistics
        [role]="role"
        [rootIntroInfo]="userIntroInfo"
        (openTeachersList)="openTeacherList()"
        (openStudentsList)="openStudentList()"
        (openActiveMatchesList)="openActiveMatchesList()"
        (openPendingMatchesList)="openPendingMatchesList()"
        (openPastMatchesList)="openPastMatchesList()"
        (openOrgList)="openOrgList()">
    </app-statistics>
</div>

