<div class="header-wrapper" *ngIf="!(showUserPasswordConfirmation$ | async)">
    <app-header (headerClick)="redirectToLogin()" [hideBetaProgram]="true" [mobileWhiteLogo]="true"></app-header>
</div>
<ng-container *ngIf="!(showUserPasswordConfirmation$ | async)">
    <div class="main-container">
        <div class="stepper-wrapper" [ngClass]="signUpRole">
            <div class="lang-btn-container">
                <button mat-stroked-button (click)="changeLanguage()">{{ chosenLanguage }}</button>
            </div>
            <app-welcome-popup *ngIf="!signUpRole"></app-welcome-popup>
            <ng-container *ngIf="!(showSuccess$ | async)">
                <app-mat-stepper
                    *ngIf="signUpRole && signUpRole === 'student'"
                    [signUpRole]="signUpRole"
                    [orientation]=orientation
                    [stepData]="studentStepData"
                    (result)="onResult($event)"
                    [isLinear]="isLinear">
                </app-mat-stepper>
                <app-mat-stepper
                    *ngIf="signUpRole && signUpRole === 'teacher'"
                    [signUpRole]="signUpRole"
                    [orientation]=orientation
                    [stepData]="teacherStepData"
                    (result)="onResult($event)"
                    [isLinear]="isLinear">
                </app-mat-stepper>
                <app-mat-stepper
                    *ngIf="signUpRole && signUpRole === 'school_admin'"
                    [signUpRole]="signUpRole"
                    [orientation]=orientation
                    [stepData]="schoolStepData"
                    (result)="onResult($event)"
                    [isLinear]="isLinear">
                </app-mat-stepper>
            </ng-container>
            <ng-container *ngIf="(showSuccess$ | async)">
                <div class="title-container">
                    <div class="title-1" [innerHTML]="'signup-form.signupFinished' | translate"></div>
                    <div class="title-2">{{ 'signup-form.checkEmail' | translate }}</div>
                </div>
            </ng-container>
        </div>
        <div class="image-container isDesktop"
             [ngClass]="getBackgroundShapeClass(signUpRole)"
             [ngStyle]="{ 'background': getBackgroundImage(signUpRole) ?
             'url(' + getBackgroundImage(signUpRole) + ') lightgray 50% / cover no-repeat' :
             'url(/assets/images/sign-up/signup-teacher.jpg) lightgray 50% / cover no-repeat' }"></div>
        <div class="image-container isMobile"
             [ngClass]="getMobileBackgroundShapeClass(signUpRole)"
             [ngStyle]="{ 'background': getMobileBackgroundImage(signUpRole) ?
             'url(' + getMobileBackgroundImage(signUpRole) + ') lightgray 50% / cover no-repeat' :
             'url(/assets/images/sign-up/signup-teacher-mobile.png) lightgray 50% / cover no-repeat' }">
        </div>
    </div>
</ng-container>
<ng-container *ngIf="(showUserPasswordConfirmation$ | async)">
    <app-choose-password [userConfirmationData]="userConfirmationData"></app-choose-password>
</ng-container>

