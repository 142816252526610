import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../services/user/user.service';
import { MatChipListboxChange } from '@angular/material/chips';

@Component({
  selector: 'app-welcome-popup',
  templateUrl: './welcome-popup.component.html',
  styleUrls: ['./welcome-popup.component.scss']
})
export class WelcomePopupComponent implements OnInit {
    formGroup: FormGroup;
    roles = [{
        label: 'I’m a student, looking for a music teacher',
        value: 'student',
        translationKey: "welcome-popup.role_translations.roles.student"
    }, {
        label: 'I’m a teacher, looking for music students',
        value: 'teacher',
        translationKey: "welcome-popup.role_translations.roles.teacher"
    }, {
        label: 'I want to sign up as a music school',
        value: 'school_admin',
        translationKey: "welcome-popup.role_translations.roles.school_admin"
    }];
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private fb: FormBuilder,
        private userService: UserService) {
    }

    ngOnInit() {
        this.formGroup = this.fb.group({
            selectedRole: ['', Validators.required],
        });
    }

    onRoleSelectionChange(event: MatChipListboxChange): void {
        const selectedRole = event.source.value;
        this.formGroup.get('selectedRole')?.setValue(selectedRole);
    }

    close() {
        this.onCancel.emit();
    }

    onSubmit() {
       const signUpRole = this.formGroup.get('selectedRole')?.value;
        this.userService.signUpRole = signUpRole;
    }

}
