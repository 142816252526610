import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services/api/api.service';
import {
    LessonDuration,
    LessonPlace,
    LessonStatus,
    LessonType,
    Student
} from '../../../services/api/api.type';

@Component({
    selector: 'app-student-profile',
    templateUrl: './student-profile.component.html',
    styleUrls: ['./student-profile.component.scss'],
})
export class StudentProfileComponent implements OnInit {

    teacherId: string = '';
    studentId: string = '';
    student: Student;
    studentTimeSlots: string[];
    userRole: 'teacher' | 'student';
    matching: 'accepted' | 'declined';
    showSchedule = true;

    constructor(private route: ActivatedRoute, private readonly apiService: ApiService) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.teacherId = params['teacherId'];
            this.studentId = params['studentId'];
        });

        this.apiService.getStudent(this.studentId).subscribe((student) => this.student = student);
        this.apiService.getMatching(this.teacherId, this.studentId).subscribe(matching => this.studentTimeSlots = matching.timeSlots);
    }

    confirmMatching(time: string) {
        this.userRole = 'teacher';
        this.matching = 'accepted';
        this.showSchedule = !this.showSchedule;

        const endTime = new Date(new Date(time).setUTCHours(new Date(time).getUTCHours() + 1)).toISOString();

        this.apiService.updateMatching({
            teacherId: this.teacherId,
            studentId: this.studentId,
            acceptedTeacher: true,
            lesson: {
                place: LessonPlace.ONLINE,
                teacherId: this.teacherId,
                studentId: this.studentId,
                title: 'Musiq lesson',
                startTime: time,
                endTime: endTime,
                type: LessonType.TRIAL,
                duration: LessonDuration['60_M'],
                status: LessonStatus.ACCEPTED,
            },
        }).subscribe(() => console.log('Successfully confirmed.'));
    }

    declineMatching() {
        this.userRole = 'teacher';
        this.matching = 'declined';
        this.showSchedule = !this.showSchedule;

        this.apiService.updateMatching({
            teacherId: this.teacherId,
            studentId: this.studentId,
            acceptedTeacher: false,
        }).subscribe(() => console.log('Successfully declined.'));
    }
}
