import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IntegratedTeacher, Integration } from '../../../services/api/api.type';
import { Clipboard } from '@angular/cdk/clipboard';
import { ComponentType } from '@angular/cdk/overlay';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditSettingsComponent } from '../edit-settings/edit-settings.component';

@Component({
  selector: 'app-session-item',
  templateUrl: './session-item.component.html',
  styleUrls: ['./session-item.component.scss']
})
export class SessionItemComponent {
    @Input() integration: IntegratedTeacher;
    @Input() teacherId: string;
    @Output() updateIntegrations: EventEmitter<void> = new EventEmitter<void>();
    copied = false;

    constructor(private clipboard: Clipboard, private dialog: MatDialog) {
    }

    join() {
        const url = this.integration.personalLink;
        if (url) {
            window.open(url, '_blank');
        }
    }

    copyInviteUrl() {
        const url = this.integration.personalLink || '';
        const success = this.clipboard.copy(url);

        if (success) {
            this.copied = true;
            setTimeout(() => { this.copied = false; }, 2000);
        } else {
            console.error('Could not copy the invite link.');
        }
    }

    openSettings() {
        this.openDialog(EditSettingsComponent, 'edit-settings',  {...this.integration, teacherId: this.teacherId});

    }

    openDialog<T>(
        component: ComponentType<T>,
        panelClass: string,
        dialogData?: IntegratedTeacher & { teacherId: string },
        width: string = '640px',
    ): void {
        document.body.classList.add('modal-open');

        const dialogRef: MatDialogRef<T> = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width,
            autoFocus: false,
            disableClose: false,
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                this.updateIntegrations.emit();
            }
            console.log('Dialog closed result', result);
        });
    }
}
