import { Component, Input, OnChanges } from '@angular/core';
import { Teacher } from '../../../services/api/api.type';
import { calculateAge } from '../../../utils';

@Component({
    selector: 'app-teacher-details',
    templateUrl: './teacher-details.component.html',
    styleUrls: ['./teacher-details.component.scss'],
})
export class TeacherDetailsComponent implements OnChanges {

    @Input() userTeacher: Teacher & { age?: number };
    panelOpenState = false;

    ngOnChanges(): void {
        const teacherAge = calculateAge(this.userTeacher.birthday);
        this.userTeacher = {
            ...this.userTeacher,
            age: teacherAge,
        };
    }
}
