import { UserRole } from '../../music/services/api/api.type';

/*export const INSTRUMENTS = [
        'e-guitar','piano-grand-piano', 'violin', 'trumpet', 'flute-transverse-flute'
        , 'singing-vocal', 'dj', 'e-piano-keyboard'
        , 'horn','bongo', 'guitar', 'music-production', 'viola'
        , 'cello-violoncello', 'bass-guitar-e-bass', 'recorder', 'double-bass', 'trombone', 'tuba'
        , 'harp', 'saxophone', 'music-theory', 'composition', 'sound-engineering', 'drums-percussion'
        , 'sound-therapy', 'ukulele', 'marimba', 'bagpipe', 'accordion', 'chimes', 'other-instrument'
]; */

export const INSTRUMENTS = [
    'violin', 'viola', 'piano-grand-piano', 'music-theory', 'composition', 'flute-transverse-flute', 'guitar', 'drums-percussion', 'bass-guitar-e-bass', 'saxophone'
];

/* export const GENRES = ['classic', 'pop', 'rock', 'jazz', 'musical', 'hip-hop', 'funk', 'soul', 'folk-music',
    'schlager', 'electro','do-not-know', 'other-genre'];
 */
export const GENRES = ['classic', 'pop', 'hip-hop', 'other-genre', 'jazz', 'soul'];

export const AGE_GROUP = ['0-12','13-17','18-39','40-64','65-100'];
export const LANGUAGES = [
    { label: 'English', value: 'english' },
    { label: 'Deutsch', value: 'german' },
   ];

export const GENDER = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Non-binary', value: 'non-binary'},
    { label: 'Transgender', value: 'transgender'},
    { label: 'Intersex', value: 'intersex' },
    { label: 'Other', value: 'other' },
   ];

export const SINGLE_GENDER = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Non-binary', value: 'non-binary'},
    { label: 'Transgender', value: 'transgender'},
    { label: 'Intersex', value: 'intersex' },
    { label: 'Other', value: 'other' },
];



export const TEACHING_LOCATION_STUDENT = [
    { label: 'No preference', value: 'no-preference' },
    { label: 'Online', value: 'online' },
    // { label: 'At the students home', value: 'at-the-students-home' },
    { label: 'In the music school', value: 'in-the-music-school' },
    // { label: 'At the teachers home', value: 'at-the-teachers-home' },
];

export const TEACHING_LOCATION = [
    { label: 'No preference', value: 'no-preference' },
    { label: 'Online', value: 'online' },
    // { label: 'At home', value: 'at-home' },
    { label: 'In the music school', value: 'in-the-music-school' },
    { label: 'Hybrid', value: 'hybrid' },
];

export const CHIPS = [
    {
        time: ['07:00 – 09:00', '09:00 – 12:00', '12:00 – 15:00', '15:00 – 18:00', '18:00 – 21:00'],
        day: 'Monday',
        date: '13.02.23'
    },
    {
        time: ['07:00 – 09:00', '09:00 – 12:00', '12:00 – 15:00', '15:00 – 18:00', '18:00 – 21:00'],
        day: 'Tuesday',
        date: '13.02.23'
    },
    {
        time: ['07:00 – 09:00', '09:00 – 12:00', '12:00 – 15:00', '15:00 – 18:00', '18:00 – 21:00'],
        day: 'Wednesday',
        date: '13.02.23'
    },
    {
        time: ['07:00 – 09:00', '09:00 – 12:00', '12:00 – 15:00', '15:00 – 18:00', '18:00 – 21:00'],
        day: 'Thursday',
        date: '13.02.23'
    },
    {
        time: ['07:00 – 09:00', '09:00 – 12:00', '12:00 – 15:00', '15:00 – 18:00', '18:00 – 21:00'],
        day: 'Friday',
        date: '13.02.23'
    },
    {
        time: ['07:00 – 09:00', '09:00 – 12:00', '12:00 – 15:00', '15:00 – 18:00', '18:00 – 21:00'],
        day: 'Saturday',
        date: '13.02.23'
    },
    {
        time: ['07:00 – 09:00', '09:00 – 12:00', '12:00 – 15:00', '15:00 – 18:00', '18:00 – 21:00'],
        day: 'Sunday',
        date: '13.02.23'
    }
];


export const MATCH_CHIPS = [
    {
        time: ['07:00 – 08:00','08:00 – 09:00', '09:00 – 10:00', '10:00 – 11:00', '11:00 – 12:00', '12:00 – 13:00', '13:00 – 14:00', '14:00 – 15:00', '15:00 – 16:00', '16:00 – 17:00', '17:00 – 18:00', '18:00 – 19:00', '19:00 – 20:00', '20:00 – 21:00'],
        day: 'Monday',
        date: '13.02.23'
    },
    {
        time: ['07:00 – 08:00','08:00 – 09:00', '09:00 – 10:00', '10:00 – 11:00', '11:00 – 12:00', '12:00 – 13:00', '13:00 – 14:00', '14:00 – 15:00', '15:00 – 16:00', '16:00 – 17:00', '17:00 – 18:00', '18:00 – 19:00', '19:00 – 20:00', '20:00 – 21:00'],
        day: 'Tuesday',
        date: '13.02.23'
    },
    {
        time: ['07:00 – 08:00','08:00 – 09:00', '09:00 – 10:00', '10:00 – 11:00', '11:00 – 12:00', '12:00 – 13:00', '13:00 – 14:00', '14:00 – 15:00', '15:00 – 16:00', '16:00 – 17:00', '17:00 – 18:00', '18:00 – 19:00', '19:00 – 20:00', '20:00 – 21:00'],
        day: 'Wednesday',
        date: '13.02.23'
    },
    {
        time: ['07:00 – 08:00','08:00 – 09:00', '09:00 – 10:00', '10:00 – 11:00', '11:00 – 12:00', '12:00 – 13:00', '13:00 – 14:00', '14:00 – 15:00', '15:00 – 16:00', '16:00 – 17:00', '17:00 – 18:00', '18:00 – 19:00', '19:00 – 20:00', '20:00 – 21:00'],
        day: 'Thursday',
        date: '13.02.23'
    },
    {
        time: ['07:00 – 08:00','08:00 – 09:00', '09:00 – 10:00', '10:00 – 11:00', '11:00 – 12:00', '12:00 – 13:00', '13:00 – 14:00', '14:00 – 15:00', '15:00 – 16:00', '16:00 – 17:00', '17:00 – 18:00', '18:00 – 19:00', '19:00 – 20:00', '20:00 – 21:00'],
        day: 'Friday',
        date: '13.02.23'
    },
    {
        time: ['07:00 – 08:00','08:00 – 09:00', '09:00 – 10:00', '10:00 – 11:00', '11:00 – 12:00', '12:00 – 13:00', '13:00 – 14:00', '14:00 – 15:00', '15:00 – 16:00', '16:00 – 17:00', '17:00 – 18:00', '18:00 – 19:00', '19:00 – 20:00', '20:00 – 21:00'],
        day: 'Saturday',
        date: '13.02.23'
    },
    {
        time: ['07:00 – 08:00','08:00 – 09:00', '09:00 – 10:00', '10:00 – 11:00', '11:00 – 12:00', '12:00 – 13:00', '13:00 – 14:00', '14:00 – 15:00', '15:00 – 16:00', '16:00 – 17:00', '17:00 – 18:00', '18:00 – 19:00', '19:00 – 20:00', '20:00 – 21:00'],
        day: 'Sunday',
        date: '13.02.23'
    }
];

export const EXPERIENCE_LEVEL = [
    {value: 'none', label: 'No prior experience'},
    {value: 'self-acquired-skills', label: 'Self taught'},
    {value: '1-to-3-years-of-instruction', label: '1-3 years of lessons'},
    {value: '4-to-6-years-of-instruction', label: '3-6 years of lessons'},
    {value: 'more-than-6-years-of-teaching', label: 'more than 6 years of lessons'}
];

export const MATCH_NOTIFICATION = ['To music school', 'To the teacher'];

export const LEARNING_OPTIONS = ['No preference', 'Online', 'Hybrid', 'At the music school', 'At the teachers home', 'At the students home'];


export const STUDENT_STEPS = {
    1: [
        {
            stepName: 'signup-form.student.step-name.stepName',
            title1: 'signup-form.student.step-name.title1',
            title2: 'signup-form.student.step-name.title2',
            selectOptionTitle: 'signup-form.student.step-name.selectOptionTitle',
            checkBoxLabelTitle: 'signup-form.student.step-name.checkBoxLabelTitle',
            btnText1: 'signup-form.student.step-name.btnText1',
            btn1Color: 'accent',
            btn2Color: 'primary',
            optional: false,
            btnText2: 'signup-form.student.step-name.btnText2',
            inputLabel: 'signup-form.student.step-name.inputLabel',
            inputHint: 'signup-form.student.step-name.inputHint',
            question: 'signup-form.student.step-name.question',
            linkColor: `#C97D70`,
            redirectLink: '',
            linkText: 'signup-form.student.step-name.linkText',
            completed: false,
            type: 'name',
            chips: [],
            selectOptions: [
                { label: 'Option 1', value: 'option1' },
                { label: 'Option 2', value: 'option2' },
                { label: 'Option 3', value: 'option3' }
            ],
            hasPreviousBtn: false
        },
        {
            stepName: 'signup-form.student.step-instrument.stepName',
            title1: 'signup-form.student.step-instrument.title1',
            title2: 'signup-form.student.step-instrument.title2',
            selectOptionTitle: 'signup-form.student.step-instrument.selectOptionTitle',
            checkBoxLabelTitle: 'signup-form.student.step-instrument.checkBoxLabelTitle',
            btnText1: 'signup-form.student.step-instrument.btnText1',
            btn1Color: 'accent',
            btn2Color: 'primary',
            optional: false,
            btnText2: 'signup-form.student.step-instrument.btnText2',
            inputLabel: 'signup-form.student.step-instrument.inputLabel',
            inputHint: 'signup-form.student.step-instrument.inputHint',
            question: 'signup-form.student.step-instrument.question',
            linkColor: `#C97D70`,
            redirectLink: '',
            linkText: 'signup-form.student.step-instrument.linkText',
            completed: false,
            type: 'instrument',
            chips: INSTRUMENTS,
            selectOptions: [],
            hasPreviousBtn: false
        },
        {
            stepName: 'signup-form.student.step-genres.stepName',
            title1: 'signup-form.student.step-genres.title1',
            title2: 'signup-form.student.step-genres.title2',
            selectOptionTitle: 'signup-form.student.step-genres.selectOptionTitle',
            checkBoxLabelTitle: 'signup-form.student.step-genres.checkBoxLabelTitle',
            btnText1: 'signup-form.student.step-genres.btnText1',
            btn1Color: 'accent',
            btn2Color: 'primary',
            optional: false,
            btnText2: 'signup-form.student.step-genres.btnText2',
            inputLabel: 'signup-form.student.step-genres.inputLabel',
            inputHint: 'signup-form.student.step-genres.inputHint',
            question: 'signup-form.student.step-genres.question',
            linkColor: `#C97D70`,
            redirectLink: '',
            linkText: 'signup-form.student.step-genres.linkText',
            completed: false,
            type: 'genres',
            chips: GENRES,
            selectOptions: [
                { label: 'Option 1', value: 'option1' },
                { label: 'Option 2', value: 'option2' },
                { label: 'Option 3', value: 'option3' }
            ]
        },
        {
            stepName: 'signup-form.student.step-contact.stepName',
            title1: 'signup-form.student.step-contact.title1',
            title2: 'signup-form.student.step-contact.title2',
            selectOptionTitle: 'signup-form.student.step-contact.selectOptionTitle',
            checkBoxLabelTitle: {
                [UserRole.STUDENT]: [ 'signup-form.student.step-contact.checkBoxLabelTitle.allowEmail',
                'signup-form.student.step-contact.checkBoxLabelTitle.allowPhone'],
            },
            btnText1: 'signup-form.student.step-contact.btnText1',
            btn1Color: 'accent',
            btn2Color: 'primary',
            optional: false,
            btnText2: 'signup-form.student.step-contact.btnText2',
            inputLabel: 'signup-form.student.step-contact.inputLabel',
            inputHint: 'signup-form.student.step-contact.inputHint',
            question: 'signup-form.student.step-contact.question',
            linkColor: `#C97D70`,
            redirectLink: '',
            linkText: 'signup-form.student.step-contact.linkText',
            completed: false,
            type: 'contact',
            chips: [],
            selectOptions: [],
            hasPreviousBtn: false
        },
    ],
    2: [
        {
            stepName: 'signup-form.student.step-priorKnowledge.stepName',
            title1: 'signup-form.student.step-priorKnowledge.title1',
            title2: 'signup-form.student.step-priorKnowledge.title2',
            selectOptionTitle: 'signup-form.student.step-priorKnowledge.selectOptionTitle',
            checkBoxLabelTitle: 'signup-form.student.step-priorKnowledge.checkBoxLabelTitle',
            btnText1: 'signup-form.student.step-priorKnowledge.btnText1',
            btn1Color: 'accent',
            btn2Color: 'primary',
            optional: false,
            btnText2: 'signup-form.student.step-priorKnowledge.btnText2',
            inputLabel: 'signup-form.student.step-priorKnowledge.inputLabel',
            inputHint: 'signup-form.student.step-priorKnowledge.inputHint',
            question: 'signup-form.student.step-priorKnowledge.question',
            linkColor: `#C97D70`,
            redirectLink: '',
            linkText: 'signup-form.student.step-priorKnowledge.linkText',
            completed: false,
            type: 'priorKnowledge',
            chips: EXPERIENCE_LEVEL,
            selectOptions: [
                { label: 'Option 1', value: 'option1' },
                { label: 'Option 2', value: 'option2' },
                { label: 'Option 3', value: 'option3' }
            ],
            hasPreviousBtn: false
        },
/*{
    stepName: 'signup-form.student.step-genderPreference.stepName',
    title1: 'signup-form.student.step-genderPreference.title1',
    title2: 'signup-form.student.step-genderPreference.title2',
    selectOptionTitle: 'signup-form.student.step-genderPreference.selectOptionTitle',
    checkBoxLabelTitle: 'signup-form.student.step-genderPreference.checkBoxLabelTitle',
    btnText1: 'signup-form.student.step-genderPreference.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.student.step-genderPreference.btnText2',
    inputLabel: 'signup-form.student.step-genderPreference.inputLabel',
    inputHint: 'signup-form.student.step-genderPreference.inputHint',
    question: 'signup-form.student.step-genderPreference.question',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'signup-form.student.step-genderPreference.linkText',
    completed: false,
    type: 'genderPreference',
    chips: GENDER,
    selectOptions: [],
    hasPreviousBtn: false
}, */
        {
            stepName: 'signup-form.student.step-area.stepName',
            title1: 'signup-form.student.step-area.title1',
            title2: 'signup-form.student.step-area.title2',
            selectOptionTitle: 'signup-form.student.step-area.selectOptionTitle',
            checkBoxLabelTitle: 'signup-form.student.step-area.checkBoxLabelTitle',
            btnText1: 'signup-form.student.step-area.btnText1',
            btn1Color: 'accent',
            btn2Color: 'primary',
            optional: false,
            btnText2: 'signup-form.student.step-area.btnText2',
            inputLabel: 'signup-form.student.step-area.inputLabel',
            inputHint: 'signup-form.student.step-area.inputHint',
            question: 'signup-form.student.step-area.question',
            linkColor: `#C97D70`,
            redirectLink: '',
            linkText: 'signup-form.student.step-area.linkText',
            completed: false,
            type: 'area',
            chips: [],
            selectOptions: [],
            hasPreviousBtn: false,
        },
{
    stepName: 'signup-form.student.step-teachingLocation.stepName',
    title1: 'signup-form.student.step-teachingLocation.title1',
    title2: 'signup-form.student.step-teachingLocation.title2',
    selectOptionTitle: 'signup-form.student.step-teachingLocation.selectOptionTitle',
    checkBoxLabelTitle: 'signup-form.student.step-teachingLocation.checkBoxLabelTitle',
    btnText1: 'signup-form.student.step-teachingLocation.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.student.step-teachingLocation.btnText2',
    inputLabel: 'signup-form.student.step-teachingLocation.inputLabel',
    inputHint: 'signup-form.student.step-teachingLocation.inputHint',
    question: 'signup-form.student.step-teachingLocation.question',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'signup-form.student.step-teachingLocation.linkText',
    completed: false,
    type: 'teachingLocation',
    chips: TEACHING_LOCATION_STUDENT,
    selectOptions: [],
    hasPreviousBtn: false
},
{
    stepName: 'signup-form.student.step-language.stepName',
    title1: 'signup-form.student.step-language.title1',
    title2: 'signup-form.student.step-language.title2',
    selectOptionTitle: 'signup-form.student.step-language.selectOptionTitle',
    checkBoxLabelTitle: 'signup-form.student.step-language.checkBoxLabelTitle',
    btnText1: 'signup-form.student.step-language.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.student.step-language.btnText2',
    inputLabel: 'signup-form.student.step-language.inputLabel',
    inputHint: 'signup-form.student.step-language.inputHint',
    question: 'signup-form.student.step-language.question',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'signup-form.student.step-language.linkText',
    completed: false,
    type: 'language',
    chips: LANGUAGES,
    selectOptions: [],
    hasPreviousBtn: true,
},
],
3:  [
/* {
    stepName: 'signup-form.student.step-area.stepName',
    title1: 'signup-form.student.step-area.title1',
    title2: 'signup-form.student.step-area.title2',
    selectOptionTitle: 'signup-form.student.step-area.selectOptionTitle',
    checkBoxLabelTitle: 'signup-form.student.step-area.checkBoxLabelTitle',
    btnText1: 'signup-form.student.step-area.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.student.step-area.btnText2',
    inputLabel: 'signup-form.student.step-area.inputLabel',
    inputHint: 'signup-form.student.step-area.inputHint',
    question: 'signup-form.student.step-area.question',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'signup-form.student.step-area.linkText',
    completed: false,
    type: 'area',
    chips: [],
    selectOptions: [],
    hasPreviousBtn: false,
}, */
{
    stepName: 'signup-form.student.step-additionalInfo.stepName',
    title1: 'signup-form.student.step-additionalInfo.title1',
    title2: 'signup-form.student.step-additionalInfo.title2',
    selectOptionTitle: 'signup-form.student.step-additionalInfo.selectOptionTitle',
    checkBoxLabelTitle: 'signup-form.student.step-additionalInfo.checkBoxLabelTitle',
    btnText1: 'signup-form.student.step-additionalInfo.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.student.step-additionalInfo.btnText2',
    inputLabel: 'signup-form.student.step-additionalInfo.inputLabel',
    inputHint: 'signup-form.student.step-additionalInfo.inputHint',
    question: 'signup-form.student.step-additionalInfo.question',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: '',
    completed: false,
    type: 'additionalInfo',
    chips: SINGLE_GENDER,
    selectOptions: [],
    hasPreviousBtn: false,
},
{
    stepName: 'signup-form.student.step-dateTime.stepName',
    title1: 'signup-form.student.step-dateTime.title1',
    title2: 'signup-form.student.step-dateTime.title2',
    selectOptionTitle: 'signup-form.student.step-dateTime.selectOptionTitle',
    checkBoxLabelTitle: 'signup-form.student.step-dateTime.checkBoxLabelTitle',
    btnText1: 'signup-form.student.step-dateTime.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.student.step-dateTime.btnText2',
    inputLabel: 'signup-form.student.step-dateTime.inputLabel',
    inputHint: 'signup-form.student.step-dateTime.inputHint',
    question: 'signup-form.student.step-dateTime.question',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'signup-form.student.step-dateTime.linkText',
    completed: false,
    type: 'dateTime',
    chips: CHIPS,
    selectOptions: [],
    hasPreviousBtn: false,
},
{
    stepName: 'signup-form.student.step-goals.stepName',
    title1: 'signup-form.student.step-goals.title1',
    title2: 'signup-form.student.step-goals.title2',
    selectOptionTitle: 'signup-form.student.step-goals.selectOptionTitle',
    checkBoxLabelTitle: 'signup-form.student.step-goals.checkBoxLabelTitle',
    btnText1: 'signup-form.student.step-goals.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.student.step-goals.btnText2',
    inputLabel: 'signup-form.student.step-goals.inputLabel',
    inputHint: 'signup-form.student.step-goals.inputHint',
    question: 'signup-form.student.step-goals.question',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'signup-form.student.step-goals.linkText',
    completed: false,
    type: 'goals',
    chips: [],
    selectOptions: [],
    hasPreviousBtn: true,
},
],
}


export const TEACHER_STEPS = {
1: [
{
    stepName: 'signup-form.teacher.step-name.stepName',
    title1: 'signup-form.teacher.step-name.title1',
    title2: 'signup-form.teacher.step-name.title2',
    selectOptionTitle: 'signup-form.teacher.step-name.selectOptionTitle',
    checkBoxLabelTitle: 'signup-form.teacher.step-name.checkBoxLabelTitle',
    btnText1: 'signup-form.teacher.step-name.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.teacher.step-name.btnText2',
    inputLabel: 'signup-form.teacher.step-name.form.pronounLabel',
    inputHint: 'signup-form.teacher.step-name.form.selectPronounPlaceholder',
    question: 'signup-form.teacher.step-name.question',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'signup-form.teacher.step-name.linkText',
    completed: false,
    type: 'name',
    chips: [],
    selectOptions: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' }
    ],
    hasPreviousBtn: false,
},
{
    stepName: 'signup-form.teacher.step-instrument.stepName',
    title1: 'signup-form.teacher.step-instrument.title1',
    title2: 'signup-form.teacher.step-instrument.title2',
    selectOptionTitle: 'signup-form.teacher.step-instrument.selectOptionTitle',
    checkBoxLabelTitle: 'signup-form.teacher.step-instrument.checkBoxLabelTitle',
    btnText1: 'signup-form.teacher.step-instrument.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.teacher.step-instrument.btnText2',
    inputLabel: 'signup-form.teacher.step-instrument.inputLabel',
    inputHint: 'signup-form.teacher.step-instrument.inputHint',
    question: 'No account yet?',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'Sign Up',
    completed: false,
    type: 'instrument',
    chips: INSTRUMENTS,
    selectOptions: [],
    hasPreviousBtn: false,
},
{
    stepName: 'signup-form.teacher.step-genres.stepName',
    title1: 'signup-form.teacher.step-genres.title1',
    title2: 'signup-form.teacher.step-genres.title2',
    selectOptionTitle: 'signup-form.teacher.step-genres.selectOptionTitle',
    checkBoxLabelTitle: '',
    btnText1: 'signup-form.teacher.step-genres.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.teacher.step-genres.btnText2',
    inputLabel: 'signup-form.teacher.step-genres.inputLabel',
    inputHint: 'signup-form.teacher.step-genres.inputHint',
    question: 'No account yet?',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'Sign Up',
    completed: false,
    type: 'genres',
    chips: GENRES,
    selectOptions: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' },
    ],
    hasPreviousBtn: false,
},
{
    stepName: 'signup-form.teacher.step-contact.stepName',
    title1: 'signup-form.teacher.step-contact.title1',
    title2: 'signup-form.teacher.step-contact.title2',
    selectOptionTitle: 'signup-form.teacher.step-contact.selectOptionTitle',
    checkBoxLabelTitle: {
        [UserRole.TEACHER]: ['signup-form.teacher.step-contact.checkBoxLabelTitle.allowEmail', 'signup-form.teacher.step-contact.checkBoxLabelTitle.allowPhone'],
    },
    btnText1: 'signup-form.teacher.step-contact.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.teacher.step-contact.btnText2',
    inputLabel: 'signup-form.teacher.step-contact.inputLabel',
    inputHint: 'signup-form.teacher.step-contact.inputHint',
    question: 'No account yet?',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'Sign Up',
    completed: false,
    type: 'contact',
    chips: [],
    selectOptions: [],
    hasPreviousBtn: false,
},
],
2: [
{
    stepName: 'signup-form.teacher.step-socialMedia.stepName',
    title1: 'signup-form.teacher.step-socialMedia.title1',
    title2: 'signup-form.teacher.step-socialMedia.title2',
    selectOptionTitle: 'signup-form.teacher.step-socialMedia.selectOptionTitle',
    checkBoxLabelTitle: 'signup-form.teacher.step-socialMedia.checkBoxLabelTitle',
    btnText1: 'signup-form.teacher.step-socialMedia.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.teacher.step-socialMedia.btnText2',
    inputLabel: 'signup-form.teacher.step-socialMedia.inputLabel',
    inputHint: 'signup-form.teacher.step-socialMedia.inputHint',
    question: 'No account yet?',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'Sign Up',
    completed: false,
    type: 'socialMedia',
    chips: [],
    selectOptions: [],
    hasPreviousBtn: false,
},
{
    stepName: 'signup-form.teacher.step-musicalEducation.stepName',
    title1: 'signup-form.teacher.step-musicalEducation.title1',
    title2: 'signup-form.teacher.step-musicalEducation.title2',
    selectOptionTitle: 'signup-form.teacher.step-musicalEducation.selectOptionTitle',
    checkBoxLabelTitle: '',
    btnText1: 'signup-form.teacher.step-musicalEducation.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.teacher.step-musicalEducation.btnText2',
    inputLabel: 'signup-form.teacher.step-musicalEducation.inputLabel',
    inputHint: 'signup-form.teacher.step-musicalEducation.inputHint',
    question: '',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'Sign Up',
    completed: false,
    type: 'musicalEducation',
    chips: [],
    selectOptions: [],
    hasPreviousBtn: false,
},
{
    stepName: 'signup-form.teacher.step-preferStudentPreference.stepName',
    title1: 'signup-form.teacher.step-preferStudentPreference.title1',
    title2: 'signup-form.teacher.step-preferStudentPreference.title2',
    subTitle: 'signup-form.teacher.step-preferStudentPreference.sub-title',
    selectOptionTitle: 'signup-form.teacher.step-preferStudentPreference.selectOptionTitle',
    checkBoxLabelTitle: '',
    btnText1: 'signup-form.teacher.step-preferStudentPreference.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.teacher.step-preferStudentPreference.btnText2',
    inputLabel: 'signup-form.teacher.step-preferStudentPreference.inputLabel',
    inputHint: 'signup-form.teacher.step-preferStudentPreference.inputHint',
    question: 'No account yet?',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'Sign Up',
    completed: false,
    type: 'preferStudentPreference',
    chips: [],
    selectOptions: [],
    hasPreviousBtn: false,
},
{
    stepName: 'signup-form.teacher.step-language.stepName',
    title1: 'signup-form.teacher.step-language.title1',
    title2: 'signup-form.teacher.step-language.title2',
    selectOptionTitle: '',
    checkBoxLabelTitle: '',
    btnText1: 'signup-form.teacher.step-language.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.teacher.step-language.btnText2',
    inputLabel: 'signup-form.teacher.step-language.inputLabel',
    inputHint: 'signup-form.teacher.step-language.inputHint',
    question: 'No account yet?',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'Sign Up',
    completed: false,
    type: 'language',
    chips: LANGUAGES,
    selectOptions: [],
    hasPreviousBtn: true,
},
],
3:  [
{
    stepName: 'signup-form.teacher.step-teacherArea.stepName',
    title1: 'signup-form.teacher.step-teacherArea.title1',
    title2: 'signup-form.teacher.step-teacherArea.title2',
    selectOptionTitle: '',
    checkBoxLabelTitle: '',
    btnText1: 'signup-form.teacher.step-teacherArea.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.teacher.step-teacherArea.btnText2',
    inputLabel: 'signup-form.teacher.step-teacherArea.inputLabel',
    inputHint: 'signup-form.teacher.step-teacherArea.inputHint',
    question: 'No account yet?',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'Sign Up',
    completed: false,
    type: 'teacherArea',
    chips: TEACHING_LOCATION,
    selectOptions: [],
    hasPreviousBtn: false,
},
{
    stepName: 'signup-form.teacher.step-area.stepName',
    title1: 'signup-form.teacher.step-area.title1',
    title2: 'signup-form.teacher.step-area.title2',
    selectOptionTitle: '',
    checkBoxLabelTitle: '',
    btnText1: 'signup-form.teacher.step-area.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.teacher.step-area.btnText2',
    inputLabel: 'signup-form.teacher.step-area.inputLabel',
    inputHint: 'signup-form.teacher.step-area.inputHint',
    question: 'No account yet?',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'Sign Up',
    completed: false,
    type: 'area',
    chips: [],
    selectOptions: [],
    hasPreviousBtn: false,
},
{
    stepName: 'signup-form.teacher.step-additionalInfo.stepName',
    title1: 'signup-form.teacher.step-additionalInfo.title1',
    title2: 'signup-form.teacher.step-additionalInfo.title2',
    selectOptionTitle: '',
    checkBoxLabelTitle: '',
    btnText1: 'signup-form.teacher.step-additionalInfo.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.teacher.step-additionalInfo.btnText2',
    inputLabel: 'signup-form.teacher.step-additionalInfo.inputLabel',
    inputHint: 'signup-form.teacher.step-additionalInfo.inputHint',
    question: 'No account yet?',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: '',
    completed: false,
    type: 'additionalInfo',
    chips: SINGLE_GENDER,
    selectOptions: [],
    hasPreviousBtn: false,
},
{
    stepName: 'signup-form.teacher.step-dateTime.stepName',
    title1: 'signup-form.teacher.step-dateTime.title1',
    title2: 'signup-form.teacher.step-dateTime.title2',
    selectOptionTitle: 'signup-form.teacher.step-dateTime.selectOptionTitle',
    checkBoxLabelTitle: 'signup-form.teacher.step-dateTime.checkBoxLabelTitle',
    btnText1: 'signup-form.teacher.step-dateTime.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.teacher.step-dateTime.btnText2',
    inputLabel: 'signup-form.teacher.step-dateTime.inputLabel',
    inputHint: 'signup-form.teacher.step-dateTime.inputHint',
    question: 'No account yet?',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'Sign Up',
    completed: false,
    type: 'dateTime',
    chips: CHIPS,
    selectOptions: [],
    hasPreviousBtn: false,
},
{
    stepName: 'signup-form.teacher.step-goals.stepName',
    title1: 'signup-form.teacher.step-goals.title1',
    title2: 'signup-form.teacher.step-goals.title2',
    selectOptionTitle: 'signup-form.teacher.step-goals.selectOptionTitle',
    checkBoxLabelTitle: '',
    btnText1: 'signup-form.teacher.step-goals.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.teacher.step-goals.btnText2',
    inputLabel: 'signup-form.teacher.step-goals.inputLabel',
    inputHint: 'signup-form.teacher.step-goals.inputHint',
    question: 'No account yet?',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'Sign Up',
    completed: false,
    type: 'goals',
    chips: [],
    selectOptions: [],
    hasPreviousBtn: true,
},
]
};


export const SCHOOL_STEPS: Steps  = {
1: [
{
    stepName: 'signup-form.school_admin.step-name.stepName',
    title1: 'signup-form.school_admin.step-name.title1',
    title2: 'signup-form.school_admin.step-name.title2',
    selectOptionTitle: 'signup-form.school_admin.step-name.selectOptionTitle',
    checkBoxLabelTitle: 'signup-form.school_admin.step-name.checkBoxLabelTitle',
    btnText1: 'signup-form.school_admin.step-name.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.school_admin.step-name.btnText2',
    inputLabel: 'signup-form.school_admin.step-name.inputLabel',
    inputHint: 'signup-form.school_admin.step-name.inputHint',
    question: 'signup-form.school_admin.step-name.question',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'signup-form.school_admin.step-name.linkText',
    completed: false,
    type: 'name',
    chips: [],
    selectOptions: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' }
    ]
},
{
    stepName: 'signup-form.school_admin.step-contact.stepName',
    title1: 'signup-form.school_admin.step-contact.title1',
    title2: 'signup-form.school_admin.step-contact.title2',
    selectOptionTitle: 'signup-form.school_admin.step-contact.selectOptionTitle',
    checkBoxLabelTitle: {
        [UserRole.SCHOOL_ADMIN]: [ 'signup-form.school_admin.step-contact.checkBoxLabelTitle.allowEmail',
            'signup-form.school_admin.step-contact.checkBoxLabelTitle.allowPhone'],
    },
    btnText1: 'signup-form.school_admin.step-contact.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.school_admin.step-contact.btnText2',
    inputLabel: 'signup-form.school_admin.step-contact.inputLabel',
    inputHint: 'signup-form.school_admin.step-contact.inputHint',
    question: 'signup-form.school_admin.step-contact.question',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: 'signup-form.school_admin.step-contact.linkText',
    completed: false,
    type: 'contact',
    chips: [],
    selectOptions: []
},
],
2: [
{
    stepName: 'signup-form.school_admin.step-profile.stepName',
    title1: '',
    title2: '',
    selectOptionTitle: '',
    checkBoxLabelTitle: '',
    btnText1: 'signup-form.school_admin.step-profile.btnText1',
    btn1Color: 'accent',
    btn2Color: 'primary',
    optional: false,
    btnText2: 'signup-form.school_admin.step-profile.btnText2',
    inputLabel: '',
    inputHint: '',
    question: '',
    linkColor: `#C97D70`,
    redirectLink: '',
    linkText: '',
    completed: false,
    type: 'organisation',
    chips: [],
    selectOptions: []
}
]
}

export interface Step {
    stepName: string;
    title1: string;
    title2: string;
    subTitle?: string;
    selectOptionTitle: string;
    checkBoxLabelTitle: string | string[] | any | {
        [UserRole.TEACHER]?: string[];
        [UserRole.STUDENT]?: string[];
        [UserRole.SCHOOL_ADMIN]?: string[];
    };
    btnText1: string;
    btn1Color: string;
    btn2Color: string;
    optional: boolean;
    btnText2: string;
    inputLabel: string;
    inputHint: string;
    question: string;
    linkColor: string;
    redirectLink: string;
    linkText: string;
    requiredError?: string;
    completed: boolean;
    hasPreviousBtn?: boolean;
    type: string;
    chips: { label: string; value: string }[] | any[];
    selectOptions: { label: string; value: string }[] | [];
}

export interface Steps {
[key: number]: Step[];
}




export const CALENDAR_FORMATS = {
parse: {
dateInput: 'DD.MM.YYYY',
},
display: {
dateInput: 'DD.MM.YYYY',
monthYearLabel: 'MMMM YYYY',
dateA11yLabel: 'LL',
monthYearA11yLabel: 'MMMM YYYY'
},
};

export const EMAIL_VALIDATION_REG = '^[a-zA-Z0-9.!#$%&’*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$';

