import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectImageComponent } from '../select-image/select-image.component';
import { UserRoles } from '../../../core/models/user-info.interface';
import { UserService } from '../../services/user/user.service';
import { ApiService } from '../../services/api/api.service';
import { Avatar } from '../../services/api/api.type';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'app-avatar-upload',
  templateUrl: './avatar-upload.component.html',
  styleUrls: ['./avatar-upload.component.scss']
})
export class AvatarUploadComponent {
    protected readonly UserRoles = UserRoles;

    @Input() role: UserRoles;
    @Input() files: Avatar[];
    @Output() onAvatarChange: EventEmitter<void> = new EventEmitter<void>();
    constructor(private dialog: MatDialog, private userService: UserService, private apiService: ApiService) {
    }

    openSelectImageModal() {
        this.apiService.getUser(this.userService.userInfo.userId).subscribe((user) => {
            const userId = UserRoles.Organisation === user.role ? user.manageSchoolId : user.id;
            this.openDialog(SelectImageComponent, 'select-image', { firstName: user.firstName, files: this.files, userId, role: user.role });
        });
    }
    openDialog<T>(
        component: ComponentType<T>,
        panelClass: string,
        dialogData?: { firstName: string; files: Avatar[]; userId: string; role: string; },
        width: string = '800px'
    ): void {
        document.body.classList.add('modal-open');

        const dialogRef: MatDialogRef<T> = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            width,
            autoFocus: false,
            disableClose: false,
            position: { top: '32px' },
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                this.onAvatarChange.emit();
            }
            console.log('Dialog closed result', result);
        });
    }
}
