import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MusiqModule } from './music/musiq.module';
import { SignUpModule } from './music/sign-up/sign-up.module';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './shared/services/language.service';
import { TranslateCacheModule, TranslateCacheService, TranslateCacheSettings } from 'ngx-translate-cache';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntlService } from './shared/services/custom-mat-paginator-intl.service';
import { TokenInterceptorService } from './shared/services/token-interceptor.service';

export function appInitializerFactory() {
    return () => {};
}
export function TranslateCacheFactory(translateService: TranslateService, translateCacheSettings: TranslateCacheSettings) {
    return new TranslateCacheService(translateService, translateCacheSettings);
}

export function HttpLoaderFactory(handler: HttpBackend) {
    const http = new HttpClient(handler);
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime());
}



@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MusiqModule,
        SignUpModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend]
            }
        }),
        TranslateCacheModule.forRoot({
            cacheService: {
                provide: TranslateCacheService,
                useFactory: TranslateCacheFactory,
                deps: [ TranslateService, TranslateCacheSettings ]
            }
        }),
    ],
    providers: [
        TranslateService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [LanguageService],
            multi: true,
        },
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntlService,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
