<div class="teachers-container">
    <mat-card class="teacher-card">
        <div class="teacher-image-container" [ngStyle]="{
        'background': this.image ? 'url(' + this.image + ')' : 'url(\'/assets/images/profile/unknown.jpg\')',
        'background-color': this.image ? '' : 'lightgray',
        'background-position': '50%',
        'background-size': 'cover',
        'background-repeat': 'no-repeat'
        }">
            <mat-card-title class="teacher-name">{{teacher.firstName}}</mat-card-title>
        </div>
        <mat-card-content class="teacher-card-content">
            <div class="info-block">
                <div class="item">
                    <div class="label">{{ 'matching.card.teachers.instruments' | translate }}</div>
                    <div class="value large">{{ translateInstruments(teacher.teacher?.instruments)}}</div>
                </div>
                <div class="item p-bottom-1">
                    <div class="label">{{ 'matching.card.teachers.genres' | translate }}</div>
                    <div class="value">{{ translateGenres(teacher.teacher?.genres) }}</div>
                </div>
                <mat-divider></mat-divider>
                <!-- <div class="item hybrid p-top-1 p-bottom-1">
                    <div class="image-container"></div>
                    <div class="item-wrapper">
                        <div class="label">Organisation</div>
                        <div class="value">Hamburger Konservatorium</div>
                    </div>
                </div>
                <mat-divider></mat-divider> -->
                <div class="item org-block p-bottom-1">
                    <div class="org-image" [ngStyle]="{
  'background': this.schoolLogo ? 'url(' + this.schoolLogo + ')' : '#F2F2F2',
  'background-color': this.schoolLogo ? '' : 'lightgray',
  'background-position': '50%',
  'background-size': 'cover',
  'background-repeat': 'no-repeat'
}"></div>
                    <div class="org-info">
                        <div class="label">{{ 'teacher_profile_info.labels.organization' | translate }}</div>
                        <div class="value">{{schoolName ? schoolName : '—'}}</div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="item hybrid">
                    <div class="item-block">
                        <div class="label">{{ 'matching.card.teachers.language' | translate }}</div>
                        <div class="value">{{teacher.spokenLanguages | capitalize}}</div>
                    </div>
                    <mat-divider vertical></mat-divider>
                    <div class="item-block">
                        <div class="label">{{ 'matching.card.teachers.location' | translate }}</div>
                        <div class="value">{{teacher.city ? teacher.city: '—'}}</div>
                    </div>
                    <mat-divider vertical></mat-divider>
                    <div class="item-block">
                        <div class="label">{{ 'matching.card.teachers.distance' | translate }}</div>
                        <div class="value">{{teacher.distance}} km</div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="item p-top-1">
                    <div class="label">{{ 'matching.card.teachers.shortBio' | translate }}</div>
                    <div class="value">{{teacher.teacher.description}}</div>
                </div>
                <mat-divider></mat-divider>
                <div class="item hybrid">
                    <div class="item-block">
                        <div class="label">{{ 'matching.card.teachers.priceRange' | translate }}</div>
                        <div class="value">
                            {{ teacher.teacher?.school?.priceRange ? ('organization_profile_info.perLesson' | translate : {price: teacher.teacher?.school?.priceRange?.price, duration: teacher.teacher?.school?.priceRange?.length}) : '—' }}
                        </div>
                    </div>
                    <mat-divider vertical></mat-divider>
                    <div class="item-block">
                        <div class="label">{{ 'matching.card.teachers.teachingLocation' | translate }}</div>
                        <div class="value">{{getTeachingLocation(teacher.teacher.teachingLocation)}}</div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="item hybrid">
                    <div class="item-block">
                        <div class="label">{{ 'matching.card.teachers.gender' | translate }}</div>
                        <div class="value">{{ translateGender(teacher.gender) }}</div>
                    </div>
                    <mat-divider vertical></mat-divider>
                    <div class="item-block">
                        <div class="label">{{ 'matching.card.teachers.age' | translate }}</div>
                        <div class="value">{{ teacher.birthday | ageOrDash }}</div>
                    </div>
                </div>
                <ng-container *ngIf="teacher?.teacher?.socials">
                    <mat-divider></mat-divider>
                    <div class="item socials" *ngFor="let social of teacher?.teacher?.socials">
                        <a href="{{social.link}}" target="_blank" class="label-link">{{social.type}}</a>
                    </div>
                </ng-container>
            </div>
            <mat-divider></mat-divider>
            <mat-card-actions class="btn-container">
                <button mat-raised-button color="primary" type="submit" (click)="openTimeSelect(teacher.teacher.userId)">
                    {{ 'matching.card.buttons.learnWith' | translate }} {{teacher.firstName}}
                </button>
            </mat-card-actions>
        </mat-card-content>
    </mat-card>
</div>
