import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IntegratedTeacher, Integration, Teacher } from '../../services/api/api.type';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
    @Input() teacher: Teacher;
    @Input() integrationList: Integration[] = [];
    @Input() integrationTeacherList: IntegratedTeacher[] = [];
    @Output() onUpdateIntegrations: EventEmitter<void> = new EventEmitter<void>();
    schoolId: string;
    teacherId: string;
    constructor() {
    }

    ngOnInit() {
        this.schoolId = this.teacher?.teacher.schoolId;
        this.teacherId = this.teacher?.teacher.id;
    }

    updateIntegrations() {
        this.onUpdateIntegrations.emit();
    }

}
