<div class="not-found-container">
    <app-header></app-header>
    <app-notify-panel></app-notify-panel>
    <div class="main-container">
        <div class="container-wrapper">
            <div class="title">{{'not-found-panel.title' | translate}}</div>
            <div class="description">{{'not-found-panel.description' | translate}}</div>
            <div class="btn-container">
                <a mat-flat-button color="primary" href="https://musiq.network">
                    <span class="btn-text">{{'not-found-panel.button' | translate}}</span>
                </a>
            </div>
        </div>
    </div>
</div>
