<div class="info-container student-profile-info">
    <mat-divider></mat-divider>
        <div class="toolbar-wrapper">
            <mat-toolbar class="toolbar-form">
              <div class="toolbar-container">
                <button mat-icon-button class="example-icon" (click)="closeDialog()">
                    <mat-icon class="close-icon">chevron_left</mat-icon>
                </button>
                <span>{{ 'active_matches_table.details_title' | translate }}</span>
              </div>
                <div *ngIf="data?.matchType === 'pending'" class="more-container">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button [disabled]="!!data.student?.student?.teacherId" mat-menu-item (click)="approve(data.student, data.teacher)">{{ 'student-dashboard-table.button.approve' | translate }}
                        </button>
                    </mat-menu>
                </div>
            </mat-toolbar>
            <mat-divider></mat-divider>
        </div>


    <div class="info-card">
        <div class="image-wrapper">
            <div class="info-block right-side-block">
                <div class="item">
                    <div class="label">{{ 'active_matches_table.student' | translate }}</div>
                    <div class="value large">{{data.student?.firstName}} {{data.student?.lastName}}</div>
                </div>
                <div class="item hybrid">
                    <div class="item-block">
                        <div class="label">{{ 'active_matches_table.teacher' | translate }}</div>
                        <div class="value">{{data.teacher?.firstName}} {{data.teacher?.lastName}}</div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="item">
                    <div class="label">{{ 'active_matches_table.instrument_column_header' | translate }}</div>
                    <div class="value">{{translateInstruments(data?.instruments)}}</div>
                </div>
                <mat-divider></mat-divider>
                <div class="item hybrid">
                    <div class="item-block">
                        <div class="label">{{ 'active_matches_table.matching_date' | translate }}</div>
                        <div class="value"> {{ data?.lesson ? (data.lesson.startTime | formatLessonStartTime: true) : '—'}}</div>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>

        <div class="info-card-content">
            <div class="info-block">
                <div class="btn-container custom-theme">
                    <button mat-stroked-button color="primary" (click)="showTeacherProfile()">{{ 'active_matches_table.teacher_profile_button' | translate }}</button>
                    <button mat-stroked-button color="primary" (click)="showStudentProfile()">{{ 'active_matches_table.student_profile_button' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
