import { Injectable } from '@angular/core';
import { UserInfo, UserIntroInfo, UserRoles } from '../../../core/models/user-info.interface';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private _userInfo: UserInfo;

    userIntroInfo: UserIntroInfo = {
        userFirstName: '',
        organisationAmount: 0,
        studentsAmount: 0,
        teachersAmount: 0,
        pendingMatches: 0,
        acceptedMatches: 0
    };

    private _signUpRole: UserRoles;

    set userInfo(userInfo: UserInfo) {
        this._userInfo = userInfo;
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
    }

    get userInfo(): UserInfo {
        return this._userInfo;
    }

    get signUpRole() {
        return this._signUpRole;
    }
    set signUpRole(role: UserRoles) {
        this._signUpRole = role;
    }

    constructor() {
        const userInfoString = localStorage.getItem('userInfo');
        this._userInfo = userInfoString ? JSON.parse(userInfoString) : null;
    }
}
