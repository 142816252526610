<div class="header-wrapper">
    <app-header (headerClick)="redirectToLogin()" [hideBetaProgram]="true" [mobileWhiteLogo]="true"></app-header>
</div>

<div *ngIf="scheduleSlot && !(confirmTeacherMatch$ | async)" class="main-container">
    <div class="stepper-wrapper">
        <app-time-select-match-student [data]="scheduleSlot" (matchedConfirm)="matchedConfirm()">
        </app-time-select-match-student>
    </div>
    <div class="image-container isDesktop student"
         style="background: url(/assets/images/sign-up/signup-student.jpg) lightgray 50% / cover no-repeat"></div>
    <div class="image-container isMobile student"
         style="background: url(/assets/images/sign-up/signup-student.jpg) lightgray 50% / cover no-repeat">
    </div>
</div>

<ng-container *ngIf="(confirmTeacherMatch$ | async)">
    <app-lesson-booked-confirm></app-lesson-booked-confirm>
</ng-container>


