<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{step.title1 | translate}} {{step.title2 | translate}}</div>
        <div class="title-2">{{step.subTitle ? (step.subTitle | translate) : ''}}</div>
    </div>
    <div class="storybook-field">
        <p>{{ 'signup-form.teacher.step-preferStudentPreference.priorKnowledgeTitle' | translate }}</p>
        <mat-form-field subscriptSizing="dynamic">
            <mat-select placeholder="{{ 'signup-form.teacher.step-preferStudentPreference.priorKnowledgePlaceholder' | translate }}"
                        [ngClass]="{'default-select-icon-color': !getFieldProperties('studentExperienceLevel', formGroup).hasAllErrors}"
                        formControlName="studentExperienceLevel" multiple>
                <mat-option [value]="'no_preference'" (click)="onNoPreferenceExperienceLevel()">{{'signup-form.teacher.step-preferStudentPreference.no_preference' | translate}}</mat-option>
                <mat-option *ngFor="let option of experienceLevel" [disabled]="getFieldProperties('studentExperienceLevel', formGroup).control?.value?.includes('no_preference')"
                            [value]="option.value">{{ 'experience_levels.' + option.value | translate }}
                </mat-option>
                <mat-select-trigger>
                    <span class="example-additional-selection">
                     {{getSelectedExperienceLevelOptions}}
                   </span>
                </mat-select-trigger>
            </mat-select>
        </mat-form-field>
        <mat-error
            *ngIf="getFieldProperties('studentExperienceLevel', formGroup).isRequired">
            {{ 'signup-form.teacher.step-preferStudentPreference.priorKnowledgeError' | translate }}
        </mat-error>
    </div>
    <div class="storybook-field">
        <p>{{ 'signup-form.teacher.step-preferStudentPreference.ageGroupTitle' | translate }}</p>
        <p class="small-label">{{'signup-form.teacher.step-preferStudentPreference.multiple_possible' | translate}}</p>
        <mat-form-field subscriptSizing="dynamic">
            <mat-select placeholder="{{'signup-form.teacher.step-preferStudentPreference.ageGroupPlaceholder' | translate}}"
                         [ngClass]="{'default-select-icon-color': !getFieldProperties('studentPreferenceAgeGroup', formGroup).hasAllErrors}"
                         formControlName="studentPreferenceAgeGroup" multiple>
                <mat-option [value]="'no_preference'" (click)="onNoPreference()">{{'signup-form.teacher.step-preferStudentPreference.no_preference' | translate}}</mat-option>
                <mat-option [disabled]="getFieldProperties('studentPreferenceAgeGroup', formGroup).control?.value?.includes('no_preference')"
                            *ngFor="let option of ageGroupOptions"
                            [value]="option">{{ option }}</mat-option>
                <mat-select-trigger>
                    <span class="example-additional-selection">
                     {{getSelectedOptions}}
                   </span>
                </mat-select-trigger>
            </mat-select>
        </mat-form-field>
        <mat-error
            *ngIf="getFieldProperties('studentPreferenceAgeGroup', formGroup).isRequired">
            {{'signup-form.teacher.step-preferStudentPreference.ageGroupError' | translate}}
        </mat-error>
    </div>
    <!--<div class="storybook-field">
        <p>{{ 'signup-form.teacher.step-preferStudentPreference.gendersTitle' | translate }}</p>
        <mat-form-field subscriptSizing="dynamic">
            <mat-select placeholder="Select Gender" [ngClass]="{'default-select-icon-color': !getFieldProperties('studentGenderPreference', formGroup).hasAllErrors}"
                        formControlName="studentGenderPreference" multiple>
                    <mat-option [value]="'no_preference'" (click)="onNoGenderPreference()">{{ 'teacher-profile.sections.student_preference.labels.age_group.no_preference' | translate }}</mat-option>
                        <mat-option *ngFor="let option of selectGender" [disabled]="option.value !== 'no_preference' && getFieldProperties('studentGenderPreference', formGroup).control?.value?.includes('no_preference')"
                                    [value]="option.value">{{ 'genders.' + option.value | translate }}
                        </mat-option>
                <mat-select-trigger>
                    <span class="example-additional-selection">
                     {{getSelectedGenderOptions}}
                   </span>
                </mat-select-trigger>
            </mat-select>
        </mat-form-field>
        <mat-error *ngIf="getFieldProperties('studentGenderPreference', formGroup).isRequired">
            {{ 'signup-form.teacher.step-preferStudentPreference.genderError' | translate }}
        </mat-error>
    </div>
    -->

    <div align="end" class="custom-theme btn-container storybook-field">
        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">{{step.btnText2 | translate}}</button>
    </div>
</form>

