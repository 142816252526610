import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TeacherProfileComponent } from './components/profile/teacher-profile/teacher-profile.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { StudentProfileComponent } from './components/profile/student-profile/student-profile.component';
import { TeacherFormComponent } from './components/profile/teacher-form/teacher-form.component';
import { StudentFormComponent } from './components/profile/student-form/student-form.component';
import { OrganizationFormComponent } from './components/profile/organization-form/organization-form.component';
import { DialogResolver } from './services/dialog/dialog-resolver.service';
import { AuthGuard } from '../guard/auth';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './sign-up/auth/login/login.component';
import { ConfirmGuard } from '../guard/confirm-guard';
import { MatchTeachersComponent } from './components/match-teachers/match-teachers.component';
import { ConfirmMatchStudentComponent } from './components/confirm-match-student/confirm-match-student.component';

const routes: Routes = [
    {
        path: 'matching/teacher/:teacherId/:studentId',
        component: TeacherProfileComponent,
    },
    {
        path: 'matching/student/:teacherId/:studentId',
        component: StudentProfileComponent,
    },
    {
        path: 'match/:teacherId/:studentId',
        component: ConfirmMatchStudentComponent,
    },
    {
        path: 'organization-profile',
        component: OrganizationFormComponent,
        resolve: {
            dialogResult: DialogResolver,
        },
        data: {
            component: OrganizationFormComponent,
            dialogData: { key: 'value' },
            dialogConfig: {
                panelClass: 'organization-form',
                hasBackdrop: true,
                width: '864px',
                disableClose: true,
                position:{ top: '32px' }
            },
        },
    },
    {
        path: 'student-profile',
        component: StudentFormComponent,
        resolve: {
            dialogResult: DialogResolver,
        },
        data: {
            component: StudentFormComponent,
            dialogData: { key: 'value' },
            dialogConfig: {
                panelClass: 'student-form',
                hasBackdrop: true,
                width: '864px',
                disableClose: true,
                position:{ top: '32px' }
            },
        },
    },
    {
        path: 'teacher-profile',
        component: TeacherFormComponent,
        resolve: {
            dialogResult: DialogResolver,
        },
        data: {
            component: TeacherFormComponent,
            dialogData: { key: 'value' },
            dialogConfig: {
                panelClass: 'teacher-form',
                hasBackdrop: true,
                width: '864px',
                disableClose: true,
                position:{ top: '32px' }
            },
        },
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'teachers/:studentId',
        component: MatchTeachersComponent,
    },
    {   path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        canActivate: [ConfirmGuard]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MusiqRoutingModule {
}
