import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserRoles } from '../../../../../core/models/user-info.interface';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DashboardTableService } from '../../../../services/dashboard-table.service';
import { ApiService } from '../../../../services/api/api.service';
import { Student, Teacher, TeachersResponse } from '../../../../services/api/api.type';
import { Subject, switchMap, takeUntil } from 'rxjs';
import {
    MatchingTimeSelectComponent,
    TimeSelect
} from '../../../match-teachers/matching-time-select/matching-time-select.component';
import { ComponentType } from '@angular/cdk/overlay';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-student-teachers-list-component',
  templateUrl: './student-teachers-list-component.component.html',
  styleUrls: ['./student-teachers-list-component.component.scss']
})
export class StudentTeachersListComponentComponent implements OnInit, AfterViewInit, OnDestroy {
    displayedColumns: string[] = [ 'firstName','lastName', 'instruments', 'terminate', 'contact'];
    dataSource: MatTableDataSource<Teacher>;
    currentIndex: number;
    destroy$ = new Subject();
    sort: Sort;

    @Input() role: UserRoles;
    @Input() teachers: TeachersResponse;
    @Input() schoolId: string;
    @Input() student: Student;
    @Input() studentNotifyData?: { earliestLesson?: string | undefined | Date; teacher: Teacher | undefined };
    @Output() onLessonTimeSelect: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private dialog: MatDialog,
                private dashboardTableService: DashboardTableService,
                private apiService: ApiService) {
    }

    ngOnInit() {
        this.dataSource = new MatTableDataSource<Teacher>(this.teachers?.list);
        this.dashboardTableService.needUpdateTable.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.dataSource = new MatTableDataSource<Teacher>(this.teachers?.list);
            this.dataSource._updateChangeSubscription();
            this.dataSource.paginator = this.paginator;
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    openDialog(component: ComponentType<MatchingTimeSelectComponent>, panelClass: string, dialogData?: TimeSelect, width = '864px', position = '32px'): void {
        document.body.classList.add('modal-open');
        const config = {
            panelClass: panelClass,
            hasBackdrop: true,
            width,
            autoFocus: false,
            disableClose: true,
            position: { top: position },
            data: dialogData
        }
        if (position) {
            config.position = { top: position }
        }
        const dialogRef = this.dialog.open(component, config);

        dialogRef.afterClosed().subscribe(result => {
            document.body.classList.remove('modal-open');
            if (result) {
                this.getTeachers();
                this.onLessonTimeSelect.emit();
            }
            console.log('Dialog closed result', result);
        });
    }


    getTeachers() {
        this.apiService.getTeachers( '1', '1000').subscribe(
            (teachers: TeachersResponse) => {
                this.teachers.list.length = 0;
                teachers.list.forEach((item) => {
                    this.teachers?.list.push(item);
                })
                this.dataSource = new MatTableDataSource<Teacher>(this.teachers?.list);
                this.dataSource._updateChangeSubscription();
                this.dataSource.paginator = this.paginator;
            });
    }

    getSortedTeachers(sortBy: string, direction: string) {
        this.apiService.getSortedTeachers( '1', '1000', sortBy, direction).subscribe(
            (teachers: TeachersResponse) => {
                this.teachers.list.length = 0;
                teachers.list.forEach((item) => {
                    this.teachers?.list.push(item);
                })
                this.dataSource = new MatTableDataSource<Teacher>(this.teachers?.list);
                this.dataSource._updateChangeSubscription();
                this.dataSource.paginator = this.paginator;
            });
    }

    onPageChange(event: PageEvent) {
        this.currentIndex = event.pageIndex * event.pageSize;
    }

    disapprove(teacher: Teacher) {
        this.apiService.updateMatching({
            teacherId: teacher?.teacher.id || '',
            studentId: this.student.student.id,
            acceptedStudent: false,
        }).pipe(switchMap(() => {
            return this.apiService.getTeachers( '1', '1000');
        })).subscribe((teachers) => {
            this.teachers.list.length = 0;
            teachers.list.forEach((item) => {
                this.teachers?.list.push(item);
            })
            this.dataSource = new MatTableDataSource<Teacher>(this.teachers?.list);
            this.dataSource._updateChangeSubscription();
            this.dataSource.paginator = this.paginator;
            console.log('Successfully declined.')
        });
    }

    approve(teacher: Teacher) {
        const teacherId = teacher?.teacher?.id || '';
        this.apiService.getMatching(teacherId, this.student.student.id).subscribe(
            (data) => {
                const modalDescription = {
                    title: 'matching.time_select.title',
                    question: 'matching.time_select.question'
                };
                this.openDialog(MatchingTimeSelectComponent, 'time-select', { slots: data.timeSlots, teacher, student: this.student, modalDescription, multiple: false, hasTeacher: false }, '864px', '');
            }
        )
    }

    sortData(sort: Sort) {
        this.sort = sort;
        if (!sort.active || sort.direction === '') {
            this.getTeachers();
            return;
        }
        this.getSortedTeachers(sort.active, sort.direction);
    }

}
