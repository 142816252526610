<div class="upload-container">
    <div *ngIf="role === UserRoles.Organisation" class="description">{{ 'avatar_upload.organisation_description' | translate }}</div>
    <div *ngIf="role === UserRoles.Teacher" class="description">{{ 'avatar_upload.teacher_description' | translate }}</div>
    <div *ngIf="role === UserRoles.Student" class="description">{{ 'avatar_upload.student_description' | translate }}</div>
    <div class="actions-container">
        <button color="primary" mat-raised-button (click)="openSelectImageModal()">
            {{ 'avatar_upload.button_text' | translate }}
        </button>
    </div>
</div>
