import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Integration } from '../../../services/api/api.type';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';

@Component({
  selector: 'app-activate-form',
  templateUrl: './activate-form.component.html',
  styleUrls: ['./activate-form.component.scss']
})
export class ActivateFormComponent {

    loading = false;

    get placeholderLink(): string {
       if (this.data?.website?.includes('digital')) {
         return 'beta.digital-stage.net/join/1234';
       }
        if (this.data?.website?.includes('sirius')) {
            return 'app.sirius.video/1234';
        }
        return '';
    }
    constructor(@Inject(MAT_DIALOG_DATA) public data: Integration & { teacherId: string },
                public dialogRef: MatDialogRef<ActivateFormComponent>,
                private _formBuilder: FormBuilder,
                private apiService: ApiService) {
    }
    linkFormGroup: FormGroup = this._formBuilder.group({link: ['', Validators.required]});
    default: FormGroup = this._formBuilder.group({default: ['']});
    closeDialog() {
        this.dialogRef.close();
    }

    onSubmit() {
        this.loading = true;
        const personalLink = this.linkFormGroup.get('link')?.value;
        const data = {
            integrationId: this.data.id,
            teacherId: this.data.teacherId,
            personalLink
        }
        this.apiService.addTeacherIntegration(data).subscribe(() => {
            this.dialogRef.close(true);
        }, () => {
            this.loading = false;
        });
    }

    goTo() {
        const url = this.data.website;
        if (url) {
            window.open(url, '_blank');
        }
    }
}
