import { environment } from '../../../environments/environment';

export interface PeriodicElement {
    name: string;
    location: string;
    teachers: number;
    socialMedia: string;
    verband: string;
    approvalStatus: string;
}

export interface TeacherPeriodicElement {
    firstName: string;
    lastName: string;
    instrument: string;
    time: string;
    duration: string;
    frequency: string;
    approvalStatus: string;
}

export interface TeacherTableElement {
    firstName: string;
    lastName: string;
    email: string;
    instruments: string;
    genres: string;
    students: number;
    nextLesson: string;
    status: string;
}

export interface StudentPeriodicElement {
    firstName: string;
    lastName: string;
    instrument: string;
    school?: string;
    time: string;
    duration: string;
    frequency?: string;
    action?: string;
}

export const TEACHER_ELEMENT_DATA: TeacherPeriodicElement[] = [
    {
        firstName: 'Max',
        lastName: 'Mustermann',
        instrument: 'Guitar',
        time: 'Monday, 17:00',
        duration: '1h',
        frequency: 'Weekly',
        approvalStatus: 'Pending',
    },
    {
        firstName: 'Max',
        lastName: 'Mustermann',
        instrument: 'Guitar',
        time: 'Monday, 17:00',
        duration: '1h',
        frequency: 'Weekly',
        approvalStatus: 'Pending',
    },
    {
        firstName: 'Max',
        lastName: 'Mustermann',
        instrument: 'Guitar',
        time: 'Monday, 17:00',
        duration: '1h',
        frequency: 'Weekly',
        approvalStatus: 'Pending',
    }
]


export const STUDENT_ELEMENT_DATA: StudentPeriodicElement[] = [
    {
        firstName: 'Herbert',
        lastName: 'Hancock',
        instrument: 'Piano',
        time: 'Monday, 17:00',
        duration: '1h',
        frequency: 'Weekly',
        school: 'HHkon',
    },
    {
        firstName: 'Herbert',
        lastName: 'Hancock',
        instrument: 'Piano',
        time: 'Monday, 17:00',
        duration: '1h',
        frequency: 'Weekly',
        school: 'HHkon',
    },
    {
        firstName: 'Herbert',
        lastName: 'Hancock',
        instrument: 'Piano',
        time: 'Monday, 17:00',
        duration: '1h',
        frequency: 'Weekly',
        school: 'HHkon',
    },
]



export const TEACHER_ELEMENT_DATA_ACTIVE_STUDENTS: TeacherPeriodicElement[] = [
    {
        firstName: 'Max',
        lastName: 'Mustermann',
        instrument: 'Guitar',
        time: 'Monday, 17:00',
        duration: '1h',
        frequency: 'Weekly',
        approvalStatus: 'Pending',
    },
    {
        firstName: 'Max',
        lastName: 'Mustermann',
        instrument: 'Guitar',
        time: 'Monday, 17:00',
        duration: '1h',
        frequency: 'Weekly',
        approvalStatus: 'Pending',
    },
    {
        firstName: 'Max',
        lastName: 'Mustermann',
        instrument: 'Guitar',
        time: 'Monday, 17:00',
        duration: '1h',
        frequency: 'Weekly',
        approvalStatus: 'Pending',
    },
    {
        firstName: 'Max',
        lastName: 'Mustermann',
        instrument: 'Guitar',
        time: 'Monday, 17:00',
        duration: '1h',
        frequency: 'Weekly',
        approvalStatus: 'Pending',
    }
]
export const ELEMENT_DATA: PeriodicElement[] = [
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    },
    {
        name: 'Hamburger Konservatorium',
        location: 'Hamburg',
        teachers: 349,
        socialMedia: 'Website, Instagram, Facebookbb',
        verband: 'VDM, DTKV',
        approvalStatus: 'Approved'
    }
];

export const TEACHER_DATA: TeacherTableElement[] = [
    {
        firstName: 'asdasdas',
        lastName: 'asdasdsa',
        email: 'asdasd@mail.ru',
        instruments: 'dasdasda',
        genres: 'sadasd',
        students: 0,
        nextLesson: 'asddasdd',
        status: 'sad'
    }
];

export const schoolId = environment.production ? '086a8020-de45-497a-bf88-9df8b83559b2' :'ca819890-e147-44e8-ab54-650363b9d155';



