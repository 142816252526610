<form [formGroup]="formGroup" class="form-wrapper sign-up-form">
    <div class="title-container">
        <div class="title-1">{{ step.title1 | translate }} {{ step.title2 | translate }}</div>
    </div>
    <div class="storybook-field">
        <p>{{ 'signup-form.' + signUpRole +'.step-additionalInfo.genderLabel' | translate }} <span class="optional">{{  'signup-form.' + signUpRole + '.step-contact.optionalLabel' | translate }}</span></p>
        <mat-form-field subscriptSizing="dynamic">
            <mat-select placeholder="{{ 'signup-form.' + signUpRole +'.step-additionalInfo.genderPlaceholder' | translate }}" [ngClass]="{'default-select-icon-color': !getFieldProperties('gender', formGroup).hasAllErrors}" formControlName="gender">
                <mat-option *ngFor="let option of step.chips"
                            [value]="option.value">
                    {{ 'genders.' + option.value | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!--<mat-error *ngIf="getFieldProperties('gender', formGroup).isRequired">
            {{ 'signup-form.' + signUpRole +'.step-additionalInfo.genderRequiredError' | translate }}
        </mat-error> -->
    </div>

    <div class="storybook-field">
        <p>{{ 'signup-form.' + signUpRole +'.step-additionalInfo.birthdayLabel' | translate }}</p>
        <mat-form-field subscriptSizing="dynamic">
            <input matInput formControlName="birthday" placeholder="{{ 'signup-form.' + signUpRole +'.step-additionalInfo.birthdayPlaceholder' | translate }}" [matDatepicker]="picker" [max]="currentDate">
            <mat-datepicker-toggle matIconSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-error *ngIf="getFieldProperties('birthday', formGroup).isRequired">
            {{ 'signup-form.' + signUpRole +'.step-additionalInfo.birthdayRequiredError' | translate }}
        </mat-error>
    </div>

    <div align="end" class="custom-theme btn-container storybook-field">
        <button mat-raised-button [color]="step.btn2Color" [disabled]="formGroup.invalid" (click)="onSubmit()">{{ step.btnText2 | translate }}</button>
    </div>
</form>


