import { Component } from '@angular/core';

@Component({
    selector: 'app-notify-panel',
    templateUrl: './notify-panel.component.html',
    styleUrls: ['./notify-panel.component.scss'],
})
export class NotifyPanelComponent {

    openDefaultMailClient() {
        const email = 'mailto:support@musiq.network';
       window.open(email, '_blank');
    }
}
